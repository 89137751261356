import React, { FC } from 'react'

import { toCurrencyFormat } from '@/common/utils'
import { ISafeNote } from '@/features/safe-note'
import { Color } from '@/packages/palette'
import { Checkbox, Row, Text, TextTypes } from '@/packages/ui'
import { DiscountAndCap, SAFE } from '@/packages/ui/table/columns'

import styles from './SafeNotesMinimizedTable.module.scss'

interface SafeNotesMinimizedTableProps {
  onSelectAll: () => void
  isAllSelected: boolean
  safeNotes: ISafeNote[]
  onSingleSelect: (id: string) => void
  selectedRows: string[]
}

const SafeNotesMinimizedTable: FC<SafeNotesMinimizedTableProps> = (props) => {
  const {
    onSelectAll,
    isAllSelected,
    safeNotes,
    onSingleSelect,
    selectedRows
  } = props

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th>Name</th>
          <th>Amount</th>
          <th>Terms</th>
          <th>
            <Row items="center" gap={8} justify="end">
              Select all
              <Checkbox
                name="selectAll"
                onChange={onSelectAll}
                checked={isAllSelected}
              />
            </Row>
          </th>
        </tr>
      </thead>
      <tbody>
        {safeNotes.map((safeNote) => (
          <tr key={safeNote.id}>
            <td>
              <SAFE withDate row={safeNote} />
            </td>
            <td>
              <Text type={TextTypes.BODY_SMALL} color={Color.neutral500}>
                {toCurrencyFormat(safeNote.safeAmount, '$')}
              </Text>
            </td>
            <td>
              <DiscountAndCap row={safeNote} />
            </td>
            <td>
              <Checkbox
                name={safeNote.id}
                onChange={() => onSingleSelect(safeNote.id)}
                checked={selectedRows.includes(safeNote.id)}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default SafeNotesMinimizedTable
