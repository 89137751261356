import api, { withParams, withQuery, withRemoveNullable } from '@/common/api'
import { FORM_DATA_HEADERS } from '@/common/api/constants'
import { InviteStatus } from '@/features/auth/enums'
import { IEntrepreneurProfile } from '@/features/profile/api/types'
import { ICompany } from '@/features/profile/types'
import { ProfileTypes } from '@/features/user/enums'

import {
  CreateNewProfileDto,
  IPublicUser,
  IUser,
  UpdateTeamMemberProps,
  UpdateUserDto,
  UpdateUserSubscription
} from './types'

const USER_ENDPOINTS = {
  ME: '/user/me',
  MY_COMPANIES: '/company?includeDeleted=false',
  MY_DELETED_COMPANIES: '/company?isDeleted=true',
  GET_COMPANY: '/company/:id',
  UPDATE_USER: '/user',
  UPDATE_SUBSCRIPTION: '/user/subscription',
  CREATE_ENTREPRENEUR_COMPANY: '/company/entrepreneur',
  CREATE_ANGEL_COMPANY: '/company/angel',
  CREATE_ADVISOR_COMPANY: '/company/advisor',
  PUBLIC_USER_BY_EMAIl: '/user/public-by-email',
  RESEND_VERIFY_EMAIL: '/user/resend-verify-email',
  JOIN_TO_COMPANY: '/company/:profileId/team-member/accept-invite',
  UPDATE_TEAM_MEMBER: '/company/:profileId/team-member/:teamMemberId',
  DELETE: '/company/:id',
  RESTORE_COMPANY: '/company/:id/restore'
}

const createCompanyMapper = {
  [ProfileTypes.ENTREPRENEUR]: USER_ENDPOINTS.CREATE_ENTREPRENEUR_COMPANY,
  [ProfileTypes.ANGEL]: USER_ENDPOINTS.CREATE_ANGEL_COMPANY,
  [ProfileTypes.ADVISOR]: USER_ENDPOINTS.CREATE_ADVISOR_COMPANY
}

export class UserRequests {
  static fetchMe(): Promise<IUser> {
    return api.get(USER_ENDPOINTS.ME)
  }

  static async deleteCompany(companyId: string): Promise<IEntrepreneurProfile> {
    return api.delete(withParams(USER_ENDPOINTS.DELETE, { id: companyId }))
  }

  static fetchMyCompanies(): Promise<ICompany[]> {
    return api.get(USER_ENDPOINTS.MY_COMPANIES)
  }

  static fetchDeletedCompanies(): Promise<ICompany[]> {
    return api.get(USER_ENDPOINTS.MY_DELETED_COMPANIES)
  }

  static fetchCompany(id: string): Promise<ICompany> {
    return api.get(withParams(USER_ENDPOINTS.GET_COMPANY, { id }))
  }

  // todo move to profile feature
  static createCompany(
    data: CreateNewProfileDto,
    type: ProfileTypes
  ): Promise<any> {
    return api.post(createCompanyMapper[type], withRemoveNullable(data), {
      headers: FORM_DATA_HEADERS
    })
  }

  static updateCurrentUser(payload: UpdateUserDto) {
    return api.patch(USER_ENDPOINTS.UPDATE_USER, payload, {
      headers: FORM_DATA_HEADERS
    })
  }

  static updateUserSubscription(payload: UpdateUserSubscription) {
    return api.patch(USER_ENDPOINTS.UPDATE_SUBSCRIPTION, payload)
  }

  static restoreCompany(id: string) {
    return api.patch(withParams(USER_ENDPOINTS.RESTORE_COMPANY, { id }))
  }

  static getPublicUserByEmail(email: string): Promise<IPublicUser | undefined> {
    return api.get(withQuery(USER_ENDPOINTS.PUBLIC_USER_BY_EMAIl, { email }))
  }

  static updateInviteStatus(
    profileId: string,
    status: InviteStatus
  ): Promise<IPublicUser | undefined> {
    return api.post(withParams(USER_ENDPOINTS.JOIN_TO_COMPANY, { profileId }), {
      status
    })
  }

  static updateTeamMember(
    profileId: string,
    teamMemberId: string,
    values: UpdateTeamMemberProps
  ): Promise<IPublicUser | undefined> {
    return api.patch(
      withParams(USER_ENDPOINTS.UPDATE_TEAM_MEMBER, {
        profileId,
        teamMemberId
      }),
      values
    )
  }

  static deleteTeamMember(
    profileId: string,
    teamMemberId: string
  ): Promise<number> {
    return api.delete(
      withParams(USER_ENDPOINTS.UPDATE_TEAM_MEMBER, {
        profileId,
        teamMemberId
      })
    )
  }

  static resendVerifyEmail(): Promise<void> {
    return api.get(USER_ENDPOINTS.RESEND_VERIFY_EMAIL)
  }
}
