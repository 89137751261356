import isCreditCard from 'validator/lib/isCreditCard'
import * as yup from 'yup'

import { DateHelper } from '@/common/helpers'

export const CardPaymentSchema = yup.object({
  cardNumber: yup
    .string()
    .min(16, 'Invalid card number')
    .max(16, 'Invalid card number')
    .test('credit-card', 'Credit card is invalid', (value) =>
      isCreditCard(value ?? '')
    )
    .required('Credit card number is required'),
  expirationDate: yup
    .string()
    .matches(/^(0[1-9]|1[0-2])\/\d{2}$/, 'Invalid expiration date')
    .test('year-validation', 'Invalid year', (value) => {
      const currentYear = new Date().getFullYear()
      const century = DateHelper.getCurrentCentury()

      const yearNumber = value?.split('/')?.[1]

      const fullYear = Number(String(century + (yearNumber || '')))

      return fullYear >= currentYear && fullYear <= currentYear + 10
    })
    .test('month-validation', 'Invalid month', (value) => {
      const currentYear = new Date().getFullYear()
      const currentMonth = +(new Date().getMonth() + 1)
        .toString()
        .padStart(2, '0')
      const century = DateHelper.getCurrentCentury()

      const monthNumber = Number(value?.split('/')?.[0])
      const yearNumber = value?.split('/')?.[1]

      const fullYear = Number(String(century + (yearNumber || '')))
      const isSameYear = fullYear === currentYear

      if (!isSameYear) {
        return true
      }

      return monthNumber > currentMonth
    })

    .required('Expiration date is required'),
  cvv: yup
    .string()
    .matches(/^\d{3,4}$/, 'Invalid CVV code')
    .optional()
})

export const BankPaymentSchema = yup.object({
  accountNumber: yup.string().required('Account number is required'),
  routingNumber: yup.string().required('Routing number is required'),
  accountHolderType: yup.string().required('Account holder type  is required'),
  billingDetails: yup.string().required('Billing details is required')
})

const validations = {
  isNotMinimized: {
    keys: ['appointment_type', 'power_unit_type'],
    validate: () => {}
  },

  isNotEmpty: {
    keys: ['appointment_type', 'power_unit_type', 'load_status'],
    validate: () => {}
  },

  isChassisVisible: {
    keys: ['appointment_type', 'power_unit_type', 'cargo_asset_asset_type'],
    validate: () => {}
  }
}

export const CheckInSchema = yup.object({
  // General Section
  date: yup.date(),
  appointment_type: yup
    .mixed<any>()
    .oneOf(Object.values({}))
    .required('Appointment Type is required'),

  // Power Unit Section
  power_unit_owner_id: yup
    .string()
    .max(50, 'Max length is 50')
    .required('ID is required'),
  power_unit_type: yup
    .mixed<any>()
    .oneOf(Object.values({}))
    .required('Type is required'),
  power_unit_license_plate_number: yup
    .string()
    .trim()
    .min(1, 'LPN is required')
    .max(20, 'Max length is 20')
    .required('LPN is required'),
  power_unit_license_plate_state: yup.string().required('State is required'),
  power_unit_carrier_name: yup.string().required('Carrier is required'),
  power_unit_vin: yup.string().max(20, 'Max length is 20').optional(),
  power_unit_weight_class: yup
    .mixed<any>()
    .oneOf(Object.values({}))
    .required('Class is required'),
  power_unit_fuel_type: yup
    .mixed<any>()
    .oneOf(Object.values({}))
    .required('Fuel Type is required'),
  account_name: yup.array(),

  // Driver Section
  driver_first_name: yup
    .string()
    .max(50, 'Max length is 50')
    .required('First Name is required'),
  driver_last_name: yup
    .string()
    .max(50, 'Max length is 50')
    .required('Last Name is required'),
  driver_license_number: yup
    .string()
    .max(20, 'Max length is 20')
    .required('Driver License is required'),

  driver_license_state: yup.string().required('State is required'),

  // Cargo Asset Section
  cargo_asset_asset_type: yup
    .mixed<any>()
    .oneOf(Object.values({}))
    .when(validations.isNotMinimized.keys, {
      is: validations.isNotMinimized.validate,
      then: (schema) => schema.required('Asset type is required'),
      otherwise: (schema) => schema
    }),
  cargo_asset_owner_id: yup.string().when(validations.isNotMinimized.keys, {
    is: validations.isNotMinimized.validate,
    then: (schema) =>
      schema.max(50, 'Max length is 50').required('ID is required'),
    otherwise: (schema) => schema
  }),
  cargo_asset_carrier_name: yup.string().when(validations.isNotMinimized.keys, {
    is: validations.isNotMinimized.validate,
    then: (schema) => schema.required('Carrier is required'),
    otherwise: (schema) => schema
  }),
  chassis_id: yup.string().when(validations.isChassisVisible.keys, {
    is: validations.isChassisVisible.validate,
    then: (schema) => schema.max(50, 'Max length is 50'),
    otherwise: (schema) => schema
  }),
  cargo_asset_license_plate_number: yup
    .string()
    .trim()
    .when(validations.isNotMinimized.keys, {
      is: validations.isNotMinimized.validate,
      then: (schema) => schema.max(20, 'Max length is 20'),
      otherwise: (schema) => schema
    }),
  cargo_asset_license_plate_state: yup.string(),
  shipment_number: yup.string().when(validations.isNotEmpty.keys, {
    is: validations.isNotEmpty.validate,
    then: (schema) => schema.max(50, 'Max length is 50'),
    otherwise: (schema) => schema
  }),
  seal_number: yup.string().when(validations.isNotEmpty.keys, {
    is: validations.isNotEmpty.validate,
    then: (schema) => schema.max(50, 'Max length is 50'),
    otherwise: (schema) => schema
  }),
  seal_matchPW: yup.boolean().notRequired()
})
