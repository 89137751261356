import clsx from 'clsx'
import cloneDeep from 'lodash/cloneDeep'
import { observer } from 'mobx-react-lite'
import React, { FC, useEffect, useRef } from 'react'

import { ConditionalRender } from '@/common/components'
import { Marker } from '@/common/helpers'
import { useStore } from '@/common/store'
import {
  SAFE_NOTE_TEMPLATE_ANCHORS,
  SAFE_NOTE_TEMPLATE_ID,
  SafeNoteSignatureNew,
  SafeNoteTemplate,
  ViewSafeAs
} from '@/features/safe-note'
import { useSafeNoteContext } from '@/features/safe-note/components/SafeNote/context'
import { SignatureData } from '@/features/safe-note/modals'
import { getRawSafeTemplate } from '@/features/safe-note/utils/raw-template'
import { useSmartState } from '@/packages/hooks'
import { Color } from '@/packages/palette'
import {
  Button,
  FontWeight,
  FullScreenLoading,
  Heading,
  HeadingTypes,
  Row,
  Tooltip,
  TooltipKind
} from '@/packages/ui'

import { DocumentControl } from './components/DocumentControl'
import styles from './SafeNoteDocument.module.scss'

interface SafeNoteDocumentProps {
  fullScreen: boolean
  toggleFullScreen: () => void
}

const SafeNoteDocument: FC<SafeNoteDocumentProps> = (props) => {
  const { fullScreen, toggleFullScreen } = props

  const {
    safeNoteId,
    sender,
    recipient,
    mfn,
    discountRate,
    discountRateActive,
    valuationCap,
    valuationCapActive,
    recipients,
    companyName,
    stateOfIncorporation,
    highlights,
    recipientIndex,
    signatures,
    onUpdateData,
    viewMode,
    viewAs,
    customData,
    isSignatureAdded,
    onFinish
  } = useSafeNoteContext()

  const senderSignature = {
    signature: signatures.senderSignature,
    signatureName: signatures.senderSignatureName,
    signatureDate: signatures.senderSignatureDate
  }

  const recipientSignature = {
    signature: signatures.recipientSignature,
    signatureName: signatures.recipientSignatureName,
    signatureDate: signatures.recipientSignatureDate
  }

  const generatePdf = useStore((store) => store.safeNote.generatePdf)
  const generatePdfLoading = useStore(
    (store) => store.safeNote.functionLoading.generatePdf
  )

  const recipientFromList =
    recipients.length > 1 ? recipients[recipientIndex] : recipients[0]

  const [state, setState] = useSmartState({
    searchValue: '',
    matchesCount: 0,
    currentWordIndex: 0
  })
  const marker = useRef<any>()

  const onDownload = () => {
    const template = getRawSafeTemplate()
    const fileName = `SAFE_${recipientFromList.name}`

    generatePdf({
      data: {
        html: template,
        fileName
      }
    })
  }

  const updateSignature = (value: SignatureData | undefined) => {
    // if (viewMode) return

    const _signatures = cloneDeep(signatures)

    if (viewAs === ViewSafeAs.SENDER) {
      _signatures.senderSignature = value
      _signatures.senderSignatureDate = value ? new Date() : undefined
    } else {
      _signatures.recipientSignature = value
      _signatures.recipientSignatureDate = value ? new Date() : undefined
    }

    onUpdateData({ signatures: _signatures })
  }

  const updateSignatureName = (value: string | undefined) => {
    // if (viewMode) return

    const _signatures = cloneDeep(signatures)

    if (viewAs === ViewSafeAs.SENDER) {
      _signatures.senderSignatureName = value
    } else {
      _signatures.recipientSignatureName = value
    }

    onUpdateData({ signatures: _signatures })
  }

  useEffect(() => {
    setTimeout(() => {
      marker.current = new Marker(SAFE_NOTE_TEMPLATE_ID)
    })
  }, [])

  useEffect(() => {
    const markerResult = marker.current?.mark(state.searchValue)
    setState({
      matchesCount: markerResult,
      currentWordIndex: 0
    })
  }, [state.searchValue])

  const renderSignature = (type: 'sender' | 'recipient') => {
    const isViewMode =
      (viewMode && type === 'sender') ||
      (viewMode && type === 'recipient' && isSignatureAdded)

    // const showFinishButtonForSignature =
    //   (type === 'sender' && !!senderSignature.signatureDate && !viewMode) ||
    //   (type === 'recipient' &&
    //     !!recipientSignature.signatureDate &&
    //     !isSignatureAdded)

    const showFinishButtonForSignature =
      type === 'sender' && !!senderSignature.signatureDate && !viewMode

    return (
      <div id={SAFE_NOTE_TEMPLATE_ANCHORS.SIGNATURES}>
        <SafeNoteSignatureNew
          {...(type === 'sender' ? senderSignature : recipientSignature)}
          safeNoteId={safeNoteId}
          viewMode={isViewMode}
          updateName={updateSignatureName}
          updateSignature={updateSignature}
          showReminder={
            type === 'recipient' &&
            viewAs !== ViewSafeAs.RECIPIENT &&
            !recipientSignature?.signatureDate
          }
          user={type === 'sender' ? sender : recipient}
          showSignatureInfoModal={!viewMode && customData.viewAsSender}
        />

        {showFinishButtonForSignature && (
          <Row justify="end" className="tw-mt-12">
            <Button onClick={() => onFinish?.()} width="default">
              Send SAFE
            </Button>
          </Row>
        )}
      </div>
    )
  }

  const renderSignatureBlock = () => {
    let signatureBlock: any = renderSignature('sender')

    if (viewMode && customData.viewAsSender) {
      signatureBlock = (
        <div className={styles.multiSignatures}>
          {renderSignature('sender')}
          {renderSignature('recipient')}
        </div>
      )
    }

    // allow recipient to add the signature if SAFE was accepted
    if (viewMode && customData.viewAsRecipient) {
      signatureBlock = customData.isSafeAccepted ? (
        <div className={styles.multiSignatures}>
          {renderSignature('sender')}
          {renderSignature('recipient')}
        </div>
      ) : null
    }

    return (
      <ConditionalRender condition={!!signatureBlock}>
        <Row items="center" gap={8} className="tw-mt-24">
          <Heading
            type={HeadingTypes.H3}
            weight={FontWeight.BOLD}
            color={Color.neutral500}
          >
            Signatures
          </Heading>

          <Tooltip
            kind={TooltipKind.INFO}
            content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua"
            title="Signature"
          />
        </Row>

        {signatureBlock}
      </ConditionalRender>
    )
  }

  // const showWarningMessage = viewAs === ViewSafeAs.SENDER && !hasSenderSignature
  return (
    <div className={clsx(styles.container, fullScreen && styles.fullScreen)}>
      <FullScreenLoading loading={generatePdfLoading} />

      <DocumentControl
        onFullScreen={toggleFullScreen}
        fullScreenActive={fullScreen}
        searchValue={state.searchValue}
        onSearch={(val: string) => setState({ searchValue: val })}
        matchesCount={state.matchesCount}
        currentWordIndex={state.searchValue ? state.currentWordIndex + 1 : 0}
        onPrevWord={() =>
          setState({ currentWordIndex: marker.current?.markPrev() })
        }
        onNextWord={() =>
          setState({ currentWordIndex: marker.current?.markNext() })
        }
        prevButtonDisabled={state.currentWordIndex === 0}
        nextButtonDisabled={state.currentWordIndex >= state.matchesCount}
        onDownload={onDownload}
      />

      <div className={clsx(styles.template)}>
        <SafeNoteTemplate
          id={SAFE_NOTE_TEMPLATE_ID}
          mfn={mfn}
          discountRate={discountRateActive ? discountRate : undefined}
          valuationCap={valuationCapActive ? valuationCap : undefined}
          safeAmount={recipientFromList.amount}
          companyName={companyName}
          recipientName={recipientFromList.name}
          highlights={highlights}
          stateOfIncorporation={stateOfIncorporation}
          anchors={{
            company: SAFE_NOTE_TEMPLATE_ANCHORS.COMPANY,
            recipient: SAFE_NOTE_TEMPLATE_ANCHORS.INVESTOR,
            amount: SAFE_NOTE_TEMPLATE_ANCHORS.AMOUNT,
            discountRate: SAFE_NOTE_TEMPLATE_ANCHORS.DISCOUNT_RATE,
            valuationCap: SAFE_NOTE_TEMPLATE_ANCHORS.VALUATION_CAP
          }}
        />

        {renderSignatureBlock()}
      </div>
    </div>
  )
}

export default observer(SafeNoteDocument)
