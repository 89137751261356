import '@/common/styles/main.scss'

import * as Sentry from '@sentry/react'
import React from 'react'
import ReactDOM from 'react-dom/client'
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom'

import { store, StoreProvider } from '@/common/store'

import App from './App'

window.global ||= window

const sentryDsn = import.meta.env.VITE_SENTRY_DSN
const isProduction = import.meta.env.PROD

if (isProduction) {
  Sentry.init({
    dsn: sentryDsn,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        )
      })
    ],

    tracesSampleRate: 0.2,
    attachStacktrace: true,
    ignoreErrors: [
      'Network Error',
      'Warning: Invalid DOM property',
      'Failed to load resource',
      'Warning: Using UNSAFE_componentWillMount',
      'Unchecked runtime.lastError: The message port closed before a response was received.'
    ]
  })
} else {
  console.log('SENTRY DISABLED IN THIS ENVIRONMENT')
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <BrowserRouter>
      <StoreProvider {...store}>
        <App />
      </StoreProvider>
    </BrowserRouter>
  </React.StrictMode>
)
