import React, { FC } from 'react'

import { Avatar } from '@/common/components'
import { DashboardTeamMembers } from '@/features/dashboard'
import { ICompany, ICompanyTeamMember } from '@/features/profile/types'
import { Color } from '@/packages/palette'
import {
  Button,
  Col,
  FontWeight,
  Heading,
  HeadingTypes,
  Row,
  Text,
  TextAlign,
  TextTypes
} from '@/packages/ui'

import declineImg from '../images/decline-request.png'
import styles from '../InvitationsModal.module.scss'

interface DeclineProps {
  company: ICompany
  onFinish: () => void
  onCancel: () => void
  teamMembers: ICompanyTeamMember[]
}

const Decline: FC<DeclineProps> = (props) => {
  const { company, onCancel, onFinish, teamMembers } = props

  return (
    <Col items="center">
      <img src={declineImg} alt="" width={125} />

      <Heading
        type={HeadingTypes.H2}
        align={TextAlign.CENTER}
        className={styles.title}
      >
        Are you sure you want to decline {company.name} invitation?
      </Heading>

      <Row items="center" justify="between" gap={15} className={styles.company}>
        <Row items="center" gap={8}>
          <Avatar size={44} name={company.name} image={company.image} />

          <Text
            type={TextTypes.BODY_DEFAULT}
            color={Color.neutral500}
            weight={FontWeight.BOLD}
          >
            {company.name}
          </Text>
        </Row>

        <DashboardTeamMembers
          hideText
          whiteBorder
          teamMembers={teamMembers}
          owner={company.owner}
          companyId={company.id}
          companyName={company.name}
        />
      </Row>

      <div className={styles.footer}>
        <Button
          uppercase
          appearance="secondary"
          width="full"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button uppercase width="full" onClick={onFinish}>
          Decline it
        </Button>
      </div>
    </Col>
  )
}

export default Decline
