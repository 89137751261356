import React, { FC } from 'react'

import { FormatterHelper } from '@/common/helpers'
import { PaymentImages } from '@/features/payment/assets'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import {
  Badge,
  BadgeType,
  Button,
  FontWeight,
  Heading,
  HeadingTypes,
  Row,
  Spacer,
  Text,
  TextTypes
} from '@/packages/ui'

import styles from './PaymentFailedView.module.scss'

interface PaymentFailedProps {
  amount?: number
  onTryAgain?: () => void
}

const PaymentFailedView: FC<PaymentFailedProps> = ({
  amount,
  onTryAgain
}: PaymentFailedProps) => (
  <div className={styles.container}>
    <div>
      <Heading type={HeadingTypes.H3} color={Color.warning300}>
        Payment failed.
      </Heading>

      <Spacer size={18} />
      <Row items="center">
        <Heading type={HeadingTypes.H2}>
          {FormatterHelper.toCurrency(amount)}
        </Heading>

        <Spacer size={10} vertical />
        <Badge
          addonBefore={<SharedIcons.Checkmark color={Color.neutral0} />}
          type={BadgeType.ERROR}
        >
          Failed
        </Badge>
      </Row>

      <Text type={TextTypes.BODY_SMALL} className="tw-mt-[30px] tw-w-[50%]">
        The payment was unsuccessful due to an abnormality. Please try again
        later or use another payment method.
      </Text>

      <Button
        onClick={onTryAgain}
        width="default"
        className="tw-mt-[24px]"
        uppercase
      >
        try again
      </Button>

      <Text
        type={TextTypes.BODY_SMALL}
        onClick={onTryAgain}
        className="tw-mt-[16px]"
        color={Color.primary400}
        weight={FontWeight.SEMIBOLD}
        clickable
      >
        Need help?
      </Text>
    </div>

    <img
      className={styles.image}
      src={PaymentImages.PaymentFailed}
      alt="payment-failed"
    />
  </div>
)

export default PaymentFailedView
