import React from 'react'

import { Terms, TermsBlockItem, ViewSafeAs } from '@/features/safe-note'
import { useSafeNoteContext } from '@/features/safe-note/components/SafeNote/context'
import { Color } from '@/packages/palette'
import { Col, Row, Text, TextTypes } from '@/packages/ui'

import styles from '../SafeNoteNavigation/SafeNoteNavigation.module.scss'

interface ISectionItem {
  id: Terms
  title: string
  checked: boolean
}

const TermsBlock = () => {
  const {
    discountRate,
    valuationCap,
    selectedTermsId,
    onUpdateData,
    viewMode,
    viewAs
  } = useSafeNoteContext()

  const isMfnSelected = selectedTermsId === Terms.MFN
  const isCustomTermsSelected = selectedTermsId === Terms.HELP_SET_TERMS

  const sections: ISectionItem[] = [
    {
      id: Terms.MFN,
      title: 'MFN',
      checked: isMfnSelected
    },
    {
      id: Terms.HELP_SET_TERMS,
      title: 'Discount and/or Valuation Cap',
      checked: isCustomTermsSelected
    }
  ]

  const onItemSelect = (id: Terms) => {
    onUpdateData({
      selectedTermsId: id,
      mfn: id !== Terms.HELP_SET_TERMS,
      discountRateActive: id === Terms.HELP_SET_TERMS && !!discountRate,
      valuationCapActive: id === Terms.HELP_SET_TERMS && !!valuationCap
    })
  }

  const renderItem = (item: ISectionItem) => (
    <TermsBlockItem
      viewMode={!!viewMode}
      key={`section-${item.id}`}
      item={item}
      onItemSelect={onItemSelect}
    />
  )

  return (
    <Col gap={12} items="stretch" className={styles.navItem}>
      <Row justify="between">
        <Text type={TextTypes.BODY_SMALL} color={Color.neutral300}>
          SAFE terms
        </Text>

        {viewAs === ViewSafeAs.SENDER && (
          <Text
            className="tw-uppercase tw-ml-8"
            type={TextTypes.BODY_EXTRA_SMALL}
            color={Color.neutral300}
          >
            * For all investors
          </Text>
        )}
      </Row>

      <Col gap={2} items="stretch">
        {viewMode
          ? renderItem(isMfnSelected ? sections[0] : sections[1])
          : sections.map((item) => renderItem(item))}
      </Col>
    </Col>
  )
}

export default TermsBlock
