import React, { FC } from 'react'

import { Table } from '@/common/components'
import columns from '@/features/investments/components/InvestmentsHistory/columns'
import { INVESTMENTS_TABS } from '@/features/investments/enums'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import { TableTab } from '@/packages/ui/table/Table'
import { RowsData } from '@/packages/ui/table/types'

import styles from './InvestmentsHistory.module.scss'

const tabs: TableTab[] = [
  {
    accessor: INVESTMENTS_TABS.ALL,
    title: 'All',
    filterFunc: (data: RowsData) => data
  },
  {
    accessor: INVESTMENTS_TABS.RECEIVED,
    title: 'Payment Received',
    filterFunc: (data: RowsData) => data
  },
  {
    accessor: INVESTMENTS_TABS.SENT,
    title: 'Payment Sent',
    filterFunc: (data: RowsData) => data
  }
]

const data = [
  {
    id: 1,
    author: 'me',
    investor: {
      fullName: 'Xavier Xicay'
    },
    createdAt: '11/11/2022',
    safeAmount: 10000,
    status: 'paid',
    safeLink: 'some link'
  }
]

const InvestmentsHistory: FC = () => (
  <div className={styles.container}>
    <Table
      searchFields={['username']}
      columns={columns}
      data={data}
      tabs={tabs}
      customActions={
        <div className={styles.createSafe}>
          <div className={styles.iconWrapper}>
            <SharedIcons.Plus color={Color.primary400} />
          </div>
        </div>
      }
    />
  </div>
)

export default InvestmentsHistory
