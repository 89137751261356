import { FC } from 'react'

import { IconProps, withIcon } from '@/common/hocs'
import { SharedIcons } from '@/packages/icons'

const ViewTermSheet: FC<IconProps> = withIcon((props) => (
  <svg
    width="22"
    height="23"
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.25 6.91665V16.0833C19.25 18.8333 17.875 20.6666 14.6667 20.6666H7.33333C4.125 20.6666 2.75 18.8333 2.75 16.0833V6.91665C2.75 4.16665 4.125 2.33331 7.33333 2.33331H14.6667C17.875 2.33331 19.25 4.16665 19.25 6.91665Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.2891 4.625V6.45833C13.2891 7.46667 14.1141 8.29167 15.1224 8.29167H16.9557"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.33594 12.4167H11.0026"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.33594 16.0833H14.6693"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
))

const ChangeEmail: FC<IconProps> = withIcon((props) => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.3333 17.5834H6.16667C3.66667 17.5834 2 16.3334 2 13.4167V7.58341C2 4.66675 3.66667 3.41675 6.16667 3.41675H14.5C17 3.41675 18.6667 4.66675 18.6667 7.58341V10.0834"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.5052 8L11.8969 10.0833C11.0385 10.7667 9.6302 10.7667 8.77187 10.0833L6.17188 8"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.3425 12.8083L13.3925 15.7583C13.2759 15.875 13.1675 16.0917 13.1425 16.25L12.9842 17.375C12.9258 17.7833 13.2092 18.0667 13.6175 18.0083L14.7425 17.85C14.9009 17.825 15.1258 17.7167 15.2342 17.6L18.1842 14.65C18.6925 14.1417 18.9342 13.55 18.1842 12.8C17.4425 12.0583 16.8508 12.3 16.3425 12.8083Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.9141 13.2334C16.1641 14.1334 16.8641 14.8334 17.7641 15.0834"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
))

const ViewSummary: FC<IconProps> = withIcon((props) => (
  <svg
    width="22"
    height="23"
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.1693 12.4167V8.75004C20.1693 4.16671 18.3359 2.33337 13.7526 2.33337H8.2526C3.66927 2.33337 1.83594 4.16671 1.83594 8.75004V14.25C1.83594 18.8334 3.66927 20.6667 8.2526 20.6667H11.9193"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.1693 12.4167V8.75004C20.1693 4.16671 18.3359 2.33337 13.7526 2.33337H8.2526C3.66927 2.33337 1.83594 4.16671 1.83594 8.75004V14.25C1.83594 18.8334 3.66927 20.6667 8.2526 20.6667H11.9193"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.1693 12.4167V8.75004C20.1693 4.16671 18.3359 2.33337 13.7526 2.33337H8.2526C3.66927 2.33337 1.83594 4.16671 1.83594 8.75004V14.25C1.83594 18.8334 3.66927 20.6667 8.2526 20.6667H11.9193"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.6641 18.3109L16.1124 19.75L19.2474 16.0834"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.41406 10.125V12.875"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11 10.125V12.875"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.5859 10.125V12.875"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
))

const Add: FC<IconProps> = (props) => <SharedIcons.PlusRounded {...props} />

export default {
  Add,
  ChangeEmail,
  ViewSummary,
  ViewTermSheet
}
