import clsx from 'clsx'
import React, { FC, MouseEvent } from 'react'
import { NumberFormatValues } from 'react-number-format'

import { ConditionalRender, LearnMoreLink } from '@/common/components'
import { RoundGoalKind } from '@/features/term-sheet/enums'
import { useModal } from '@/packages/hooks'
import { Color } from '@/packages/palette'
import {
  Col,
  Heading,
  HeadingTypes,
  NumberInput,
  Radio,
  Row,
  Text,
  TextTypes
} from '@/packages/ui'

import PartOfRoundModal from '../PartOfRoundModal/PartOfRoundModal'
import styles from './RoundGoalItem.module.scss'

interface RoundGoalItemProps {
  id: RoundGoalKind
  active: boolean
  title: string
  subtitle: string
  onSelect: (id: RoundGoalKind) => void
  img: string
  goal?: number
  onChangeGoal?: (value: number | undefined) => void
}

const RoundGoalItem: FC<RoundGoalItemProps> = ({
  id,
  active,
  onSelect,
  title,
  subtitle,
  img,
  goal,
  onChangeGoal
}) => {
  const { visible, openModal, closeModal } = useModal<boolean>(false)

  const onLearnMore = (e?: MouseEvent<HTMLElement>) => {
    e?.stopPropagation?.()
    openModal()
  }

  return (
    <Row
      gap={15}
      className={clsx(styles.roundGoalItem, active && styles.selected)}
      onClick={() => onSelect(id)}
    >
      {visible && (
        <PartOfRoundModal
          closeModal={closeModal}
          isPartOfRound={id === RoundGoalKind.WithGoal}
        />
      )}

      <Radio name="round-goal" value={id} checked={active} />

      <Col>
        <Heading type={HeadingTypes.H3}>{title}</Heading>
        <Text
          type={TextTypes.BODY_DEFAULT}
          color={Color.neutral400}
          className="tw-mt-8"
        >
          {subtitle}
        </Text>

        <LearnMoreLink className="tw-mt-12" onClick={onLearnMore} />

        <ConditionalRender condition={active && id === RoundGoalKind.WithGoal}>
          <div className="tw-mt-15">
            <NumberInput
              prefix="$"
              label="Goal amount"
              placeholder="Amount"
              value={goal}
              onValueChange={(values: NumberFormatValues) =>
                onChangeGoal?.(values.floatValue)
              }
            />
          </div>
        </ConditionalRender>
      </Col>

      <img src={img} width={120} alt="" />
    </Row>
  )
}

export default RoundGoalItem
