import { yupResolver } from '@hookform/resolvers/yup'
import React, { FC, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { NumberFormatValues } from 'react-number-format'
import { UsaStates } from 'usa-states'

import { EntrepreneurFillInfoSchema } from '@/features/auth/schemas/AuthSchema'
import { ICompany } from '@/features/profile/types'
import { Color } from '@/packages/palette'
import {
  Form,
  FormHelper,
  FormItem,
  Heading,
  HeadingTypes,
  NumberInput,
  Row,
  Select,
  Spacer,
  Text,
  TextInput,
  TextTypes
} from '@/packages/ui'

interface DetailsProps {
  company: ICompany
  setValid: (valid: boolean) => void
}

const states = new UsaStates().states.map((item) => ({
  value: item.name.toLowerCase(),
  label: item.name
}))

const VerificationCompanyDetails: FC<DetailsProps> = (props) => {
  const { company, setValid } = props

  const {
    control,
    register,
    formState: { errors, isValid }
  } = useForm({
    resolver: yupResolver(EntrepreneurFillInfoSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: {
      stateOfIncorporation: company.stateOfIncorporation,
      address1: company.address?.address1,
      address2: company.address?.address2,
      city: company.address?.city,
      state: company.address?.state,
      zipCode: company.address?.zipCode,
      country: 'USA'
    }
  })

  useEffect(() => {
    setValid(isValid)
  }, [isValid])

  return (
    <Form schema={EntrepreneurFillInfoSchema}>
      <Heading type={HeadingTypes.H2} color={Color.neutral500}>
        Complete required information
      </Heading>
      <Text type={TextTypes.BODY_DEFAULT} color={Color.neutral400}>
        Fill out information needed to verify your company.
      </Text>

      <Spacer size={5} />

      <FormItem errors={errors.country?.message}>
        <TextInput
          {...register('country')}
          disabled
          label="Country"
          tooltip="We’re currently only working within the USA, but hold tight, MySAFEnotes will be available in other countries shortly."
          placeholder="Country"
          invalid={FormHelper.isFieldInvalid('country', errors)}
        />
      </FormItem>

      <FormItem errors={errors.stateOfIncorporation?.message}>
        <Controller
          control={control}
          render={({ field: { value, onChange } }) => (
            <Select
              withPortal
              name="stateOfIncorporation"
              label="State of Incorporation"
              placeholder="State of Incorporation"
              options={states}
              value={value!}
              toTopOnFocus
              onChange={(val: string) => onChange(val)}
            />
          )}
          name="stateOfIncorporation"
        />
      </FormItem>

      <Row gap={20}>
        <FormItem errors={errors.address1?.message}>
          <TextInput
            {...register('address1')}
            label="Address"
            placeholder="Address"
            invalid={FormHelper.isFieldInvalid('address1', errors)}
          />
        </FormItem>

        <FormItem>
          <TextInput
            {...register('address2')}
            label="Apartment, suite, etc."
            placeholder="Apartment, suite, etc."
          />
        </FormItem>
      </Row>

      <Row gap={20}>
        <FormItem errors={errors.city?.message}>
          <TextInput
            {...register('city')}
            label="City"
            placeholder="City"
            invalid={FormHelper.isFieldInvalid('city', errors)}
          />
        </FormItem>

        <FormItem errors={errors.state?.message}>
          <Controller
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                name="state"
                label="State"
                placeholder="State"
                options={states}
                value={value!}
                toTopOnFocus
                onChange={(val: string) => onChange(val)}
              />
            )}
            name="state"
          />
        </FormItem>
      </Row>

      <FormItem errors={errors.zipCode?.message}>
        <Controller
          control={control}
          name="zipCode"
          render={({ field: { onChange, value } }) => (
            <NumberInput
              value={value}
              onValueChange={(val: NumberFormatValues) => {
                onChange(val.value)
              }}
              label="Zip code"
              placeholder="Zip code"
              name="zipCode"
              invalid={FormHelper.isFieldInvalid('zipCode', errors)}
              thousandSeparator={false}
            />
          )}
        />
      </FormItem>
    </Form>
  )
}

export default VerificationCompanyDetails
