import React, { FC } from 'react'

import { DashboardTeamMembers } from '@/features/dashboard'
import { ICompanyTeamMember } from '@/features/profile/types'
import { TermSheetRecipientItem } from '@/features/term-sheet/components'
import { Col, FontWeight, Text, TextTypes, Tooltip } from '@/packages/ui'

import styles from './RecipientsTooltip.module.scss'

interface RecipientsTooltipProps {
  people: ICompanyTeamMember[]
}

const RecipientsTooltip: FC<RecipientsTooltipProps> = (props) => {
  const { people } = props

  const PopupDetails = (
    <Col gap={14} className="tw-p-10">
      <Text
        type={TextTypes.BODY_DEFAULT}
        weight={FontWeight.SEMIBOLD}
        className="!tw-leading-snug"
      >
        Recipients
      </Text>

      <Col gap={7} className={styles.recipientList}>
        {people.map((person) => (
          <TermSheetRecipientItem
            small
            key={person.user.email}
            fullName={
              person.user.fullName === person.user.email
                ? ''
                : person.user.fullName
            }
            image={person.user.image}
            email={person.user.email}
          />
        ))}
      </Col>
    </Col>
  )

  return (
    <Tooltip
      placement="top"
      className="!tw-max-w-none"
      interactive
      content={PopupDetails}
    >
      <div>
        <DashboardTeamMembers
          teamMembers={people}
          companyId=""
          companyName=""
          hideText
        />
      </div>
    </Tooltip>
  )
}

export default RecipientsTooltip
