import clsx from 'clsx'
import React, { useMemo } from 'react'

import { SharedImages } from '@/assets/images'
import { Avatar } from '@/common/components'
import { toCurrencyFormat } from '@/common/utils'
import { useSafeNoteContext } from '@/features/safe-note/components/SafeNote/context'
import { Color } from '@/packages/palette'
import { Col, FontWeight, Row, Text, TextTypes } from '@/packages/ui'

import styles from '../SafeNoteNavigation/SafeNoteNavigation.module.scss'

const InvestorsBlock = () => {
  const { recipients, recipientIndex, setRecipientIndex, viewMode } =
    useSafeNoteContext()

  const multiple = recipients.length > 1
  const totalAmount = useMemo(
    () => recipients.reduce((acc, next) => acc + next.amount, 0),
    [recipients]
  )

  return (
    <Col gap={12} items="stretch" className={styles.navItem}>
      <Text
        className="!tw-leading-snug"
        type={TextTypes.BODY_SMALL}
        color={Color.neutral300}
      >
        {viewMode ? 'Investment amount' : 'Investors'}
      </Text>

      {viewMode ? (
        <Text
          type={TextTypes.BODY_DEFAULT}
          weight={FontWeight.SEMIBOLD}
          color={Color.neutral500}
          className="!tw-leading-snug"
        >
          {toCurrencyFormat(totalAmount, '$')}
        </Text>
      ) : (
        <Col gap={8} items="stretch" className={styles.investorsList}>
          {recipients.map((item, index) => (
            <Row
              key={item.email}
              items="center"
              justify="between"
              gap={15}
              onClick={() => setRecipientIndex(index)}
              className={clsx(
                styles.investorItem,
                multiple && 'tw-cursor-pointer',
                index === recipientIndex && multiple && styles.active
              )}
            >
              <Row items="center" gap={8}>
                <Avatar
                  size={26}
                  image={item.img}
                  name={item.name}
                  defaultImage={SharedImages.UserPlaceholder}
                  className={styles.miniAvatar}
                />

                <Text
                  className="!tw-leading-snug"
                  type={TextTypes.BODY_SMALL}
                  color={Color.neutral500}
                >
                  {item.name}
                </Text>
              </Row>

              <Text
                weight={FontWeight.SEMIBOLD}
                type={TextTypes.BODY_SMALL}
                color={Color.neutral400}
              >
                {toCurrencyFormat(item.amount, '$')}
              </Text>
            </Row>
          ))}
        </Col>
      )}
    </Col>
  )
}

export default InvestorsBlock
