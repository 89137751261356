import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react'

import {
  BankCard,
  BankCardProps,
  ConditionalRender,
  DownloadReceipt
} from '@/common/components'
import { DateHelper, FormatterHelper } from '@/common/helpers'
import { Nullable } from '@/common/types'
import { PaymentMethod } from '@/features/payment'
import { PaymentImages } from '@/features/payment/assets'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import {
  Badge,
  BadgeType,
  Col,
  FontWeight,
  Heading,
  HeadingTypes,
  Row,
  Spacer,
  Text,
  TextTypes
} from '@/packages/ui'

import styles from './PaymentSuccessView.module.scss'

interface PaymentSuccessProps extends BankCardProps {
  amount?: number
  paymentMethod?: Nullable<PaymentMethod>
  paymentDate?: Nullable<string>
  transactionId?: Nullable<string>
  inProcessing?: boolean
  onDownloadReceipt?: () => void
  hasReceipt?: boolean
}

interface PaymentSuccessState {
  headerText: string | ReactNode
  image: ReactNode
  badgeText: string
  badgeType: BadgeType
  badgeIcon: ReactNode
}

const PaymentSuccessView: FC<PaymentSuccessProps> = ({
  amount,
  paymentDate,
  transactionId,
  paymentMethod,
  inProcessing,
  onDownloadReceipt,
  ...rest
}) => {
  const initialState = {
    headerText: (
      <>
        <span>Thanks a lot!</span>
        <br /> Your money has been sent.
      </>
    ),
    image: PaymentImages.PaymentSuccess,
    badgeText: 'Paid',
    badgeType: BadgeType.SUCCESS,
    badgeIcon: <SharedIcons.Checkmark color={Color.neutral0} />
  }

  const [state, setState] = useState<PaymentSuccessState>(initialState)

  useEffect(() => {
    if (inProcessing) {
      setState({
        headerText: 'Your money it\'s on way',
        image: PaymentImages.PaymentProcessing,
        badgeText: 'On way',
        badgeType: BadgeType.WARNING,
        badgeIcon: <SharedIcons.Clock color={Color.neutral0} />
      })
      return
    }
    setState(initialState)
  }, [inProcessing])

  const getPaymentMethod = useCallback(
    (method: Nullable<PaymentMethod> | undefined) => {
      switch (method) {
        case PaymentMethod.CARD:
          return 'Credit Card'
        case PaymentMethod.BANK_TRANSFER:
        case 'us_bank_account' as string:
          return 'Bank Transfer'
        default:
          return '-'
      }
    },
    []
  )

  const SummaryItem = useCallback(
    (label: string, value: Nullable<string> | undefined) => (
      <>
        <Col gap={4} className={styles.dataItem}>
          <Text type={TextTypes.BODY_DEFAULT} weight={FontWeight.SEMIBOLD}>
            {label}
          </Text>
          <Text
            type={TextTypes.BODY_DEFAULT}
            color={Color.neutral300}
            className={styles.dataValue}
          >
            {value ?? '-'}
          </Text>
        </Col>
      </>
    ),
    []
  )

  const image = inProcessing
    ? PaymentImages.PaymentProcessing
    : PaymentImages.PaymentSuccess

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div>
          <Heading type={HeadingTypes.H3} color={Color.warning300}>
            {state.headerText}
          </Heading>

          <Spacer size={18} />
          <Row items="center">
            <Heading type={HeadingTypes.H2}>
              {FormatterHelper.toCurrency(amount)}
            </Heading>

            <Spacer size={10} vertical />

            <Badge addonBefore={state.badgeIcon} type={state.badgeType}>
              {state.badgeText}
            </Badge>
          </Row>

          <ConditionalRender condition={inProcessing}>
            <Text type={TextTypes.BODY_SMALL} className="tw-mt-[13px]">
              Money will arrive in 1-5 business days
            </Text>
          </ConditionalRender>
        </div>

        <img className={styles.image} src={image} alt="payment-success" />
      </div>

      <div className={styles.details}>
        <Heading type={HeadingTypes.H3}>Payment details</Heading>

        <Row items="start" justify="between">
          <Col justify="between">
            <div className={styles.data}>
              {SummaryItem('Cash Amount', FormatterHelper.toCurrency(amount))}
              {SummaryItem(
                'Payment Date',
                paymentDate ? DateHelper.toPrettyFormat(paymentDate) : '-'
              )}
              {SummaryItem('Transaction ID', transactionId)}
              {SummaryItem('Payment Method', getPaymentMethod(paymentMethod))}
            </div>

            <Spacer size={20} />
            <DownloadReceipt
              className="tw-w-1/2"
              onDownload={onDownloadReceipt}
            />
          </Col>

          <BankCard {...rest} />
        </Row>
      </div>
    </div>
  )
}

export default PaymentSuccessView
