import clsx from 'clsx'
import { noop } from 'lodash'
import React, { FC } from 'react'

import { ConditionalRender } from '@/common/components'
import { Color } from '@/packages/palette'
import { FontWeight, Radio, Text, TextAlign, TextTypes } from '@/packages/ui'

import styles from './SafeForItem.module.scss'

interface CardItemProps {
  radioGroup?: string
  image: string
  disabled?: boolean
  title: string
  checked?: boolean
  description: string
  onSelect?: () => void
}

const SafeForItem: FC<CardItemProps> = ({
  radioGroup = 'radio',
  image,
  title,
  checked,
  disabled,
  description,
  onSelect
}: CardItemProps) => (
  <div
    className={clsx(styles.container, disabled && styles.disabled)}
    onClick={!disabled ? onSelect : noop}
  >
    <ConditionalRender
      condition={!disabled}
      fallbackElement={<div className={styles.comingSoon}>Coming Soon</div>}
    >
      <div className={styles.radio}>
        <Radio checked={checked} name={radioGroup} disabled={disabled} />
      </div>
    </ConditionalRender>

    <img className={styles.image} src={image} alt={title} />
    <Text
      type={TextTypes.BODY_MAIN}
      weight={FontWeight.BOLD}
      align={TextAlign.CENTER}
      className={styles.title}
    >
      {title}
    </Text>
    <Text
      align={TextAlign.CENTER}
      type={TextTypes.BODY_SMALL}
      color={Color.neutral400}
      className={styles.description}
    >
      {description}
    </Text>
  </div>
)

export default SafeForItem
