import clsx from 'clsx'
import React, { FC, useState } from 'react'

import { ConditionalRender } from '@/common/components'
import { ToastService } from '@/common/services'
import { StoreType, withStore } from '@/common/store'
import successImg from '@/features/auth/assets/images/subscription-done.png'
import { SafeNotesMinimizedTable } from '@/features/dashboard'
import remindImg from '@/features/dashboard/assets/images/remind-to-sign.png'
import { ISafeNote } from '@/features/safe-note'
import { Color } from '@/packages/palette'
import {
  Button,
  Col,
  Heading,
  HeadingTypes,
  IModalWithCloseFn,
  Loader,
  Modal,
  Text,
  TextAlign,
  TextTypes
} from '@/packages/ui'

import styles from './MultiReminderModal.module.scss'

const mapStateToProps = ({ safeNote }: StoreType) => ({
  sendReminder: safeNote.notifyToSign
})

interface MultiReminderModalProps extends IModalWithCloseFn {
  safeNotes: ISafeNote[]
}

const MultiReminderModal: FC<
  MultiReminderModalProps & ReturnType<typeof mapStateToProps>
> = (props) => {
  const { closeModal, sendReminder, safeNotes } = props

  const [loading, setLoading] = useState(false)
  const [allSelected, setAllSelected] = useState(false)
  const [selectedSafes, setSelectedSafes] = useState<string[]>([])

  const [completed, setCompleted] = useState(false)

  const selectAll = () => {
    const ids = safeNotes.map((item) => item.id)

    setAllSelected(!allSelected)

    if (allSelected) {
      setSelectedSafes([])
    } else {
      setSelectedSafes(ids)
    }
  }

  const onItemSelect = (id: string) => {
    if (selectedSafes.includes(id)) {
      setSelectedSafes((prev) => prev.filter((item) => item !== id))
    } else {
      setSelectedSafes([...selectedSafes, id])
    }
  }

  const handleSendReminder = async () => {
    setLoading(true)

    try {
      await Promise.all(
        selectedSafes.map((safeId: string) =>
          sendReminder({
            data: { id: safeId }
          })
        )
      )

      setCompleted(true)
    } catch (e) {
      ToastService.showError('We faced some problems with sending reminders')
    }

    setLoading(false)
  }

  const MainScreen = (
    <Col items="center">
      <img src={remindImg} alt="Delete company" width={130} />

      <Heading
        type={HeadingTypes.H2}
        color={Color.neutral500}
        className="tw-mt-[24px]"
        align={TextAlign.CENTER}
      >
        Remind to sign SAFEs
      </Heading>
      <Text
        type={TextTypes.BODY_DEFAULT}
        color={Color.neutral400}
        align={TextAlign.CENTER}
        className="tw-mt-[8px]"
      >
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
      </Text>

      <SafeNotesMinimizedTable
        onSelectAll={selectAll}
        isAllSelected={allSelected}
        safeNotes={safeNotes}
        onSingleSelect={onItemSelect}
        selectedRows={selectedSafes}
      />

      <div className={clsx(styles.footer)}>
        <Button
          uppercase
          appearance="secondary"
          width="full"
          onClick={closeModal}
        >
          Cancel
        </Button>

        <Button
          uppercase
          width="full"
          disabled={!selectedSafes?.length}
          onClick={handleSendReminder}
        >
          Send
        </Button>
      </div>
    </Col>
  )

  const SuccessScreen = (
    <Col items="center">
      <img src={successImg} alt="Success" width={255} />

      <Heading
        type={HeadingTypes.H2}
        color={Color.neutral500}
        className="tw-mt-[24px]"
      >
        Your reminders were sent
      </Heading>
      <Text
        type={TextTypes.BODY_DEFAULT}
        color={Color.neutral300}
        className="tw-mt-[8px]"
        align={TextAlign.CENTER}
      >
        Recipients you've selected will get an email with reminder to sign SAFE
      </Text>

      <Button
        uppercase
        width="default"
        onClick={closeModal}
        className="tw-mt-[32px]"
      >
        Close
      </Button>
    </Col>
  )

  return (
    <Modal size="sm" visible setVisible={closeModal} id="delete-company">
      <ConditionalRender
        condition={!loading}
        fallbackElement={<Loader width="100%" />}
      >
        <ConditionalRender condition={completed} fallbackElement={MainScreen}>
          {SuccessScreen}
        </ConditionalRender>
      </ConditionalRender>
    </Modal>
  )
}

export default withStore(mapStateToProps)(MultiReminderModal)
