import React, {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useMemo
} from 'react'
import screenfull from 'screenfull'

interface FullScreenContextValue {
  isFullScreenActive: boolean
  toggleFullScreen: () => void
}

const FullScreenContext = React.createContext<FullScreenContextValue>(
  {} as FullScreenContextValue
)

interface IProps {
  active: boolean
  setActive: Dispatch<SetStateAction<boolean>>
}

const FullScreenContextProvider: FC<PropsWithChildren<IProps>> = (props) => {
  const { children, active, setActive } = props

  const toggle = useCallback(() => {
    if (active) {
      screenfull.exit()
    } else {
      screenfull.request()
    }

    setActive((prev) => !prev)
  }, [active])

  const value = useMemo(
    () => ({ isFullScreenActive: active, toggleFullScreen: toggle }),
    [active, toggle]
  )

  return (
    <FullScreenContext.Provider value={value}>
      {children}
    </FullScreenContext.Provider>
  )
}

const useFullScreenContext = () => useContext(FullScreenContext)

export { FullScreenContextProvider, useFullScreenContext }
