import React, { FC } from 'react'

import { Decline } from '@/features/dashboard/modals/InvitationsModal/parts'
import { ICompany, ICompanyTeamMember } from '@/features/profile/types'
import { Modal } from '@/packages/ui'

interface DeclineRequestModalProps {
  closeModal: () => void
  teamMembers: ICompanyTeamMember[]
  currentRequest: ICompany
  handleDecline: () => void
}

const DeclineRequestModal: FC<DeclineRequestModalProps> = (props) => {
  const { closeModal, teamMembers, currentRequest, handleDecline } = props

  return (
    <Modal id="decline-request-modal" size="sm" visible setVisible={closeModal}>
      <Decline
        teamMembers={teamMembers}
        company={currentRequest}
        onFinish={handleDecline}
        onCancel={() => closeModal()}
      />
    </Modal>
  )
}

export default DeclineRequestModal
