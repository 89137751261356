import './Select.scss'

import clsx from 'clsx'
import React, {
  ChangeEvent,
  FC,
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import Select from 'react-select'

import { ConditionalRender } from '@/common/components'
import { useScreenSize } from '@/packages/hooks'
import {
  FormInputContext,
  IInputProps,
  RequiredFieldStar,
  Spacer
} from '@/packages/ui'
import FormatOptionLabel from '@/packages/ui/select/FormatOptionLabel'

import { DropdownOption } from '../dropdown'
import { Label } from '../label'
import styles from './Select.module.scss'

// @ts-ignore
interface ISelectProps extends Omit<IInputProps, 'onChange'> {
  name: string
  required?: boolean
  options: DropdownOption[]
  onChange?: (value: string) => void
  disabled?: boolean
  className?: string
  highlighted?: boolean
  withoutPadding?: boolean
  label?: string
  small?: boolean
  onUpgrade?: () => void
  withoutBorders?: boolean
  withSearch?: boolean
  tooltip?: string
  toTopOnFocus?: boolean
  custom?: boolean
  withPortal?: boolean
  CustomOption?: FC<any>
  NoOptionMessage?: FC<any>
  valueHolderClassName?: string
}

export const SelectComponent: FC<ISelectProps> = (props: ISelectProps) => {
  const {
    name,
    withoutPadding,
    onUpgrade,
    withPortal,
    withSearch = true,
    withoutBorders,
    required,
    value,
    disabled,
    className,
    options,
    NoOptionMessage,
    onChange,
    CustomOption,
    label,
    small,
    tooltip,
    highlighted,
    toTopOnFocus,
    boldLabel = true,
    placeholder = 'Select...',
    custom,
    valueHolderClassName
    // invalid,
    // containerClassName
  } = props
  const { requiredFields } = useContext(FormInputContext)
  const _required = requiredFields?.includes?.(name) || required

  const ref: any = useRef()
  const { isMobile } = useScreenSize()

  const [selectedOption, setSelectedOption] = useState<unknown | null>()

  const selectedOptionObject = useMemo(() => {
    if (selectedOption) {
      return options.find((item) => item.value === selectedOption)
    }

    return null
  }, [selectedOption])

  const handleChange = (option: any | null) => {
    setSelectedOption(option)
    if (option?.value) {
      onChange?.(option.value)
    }
  }

  const onFocus = (e: ChangeEvent<HTMLInputElement>) => {
    if (!toTopOnFocus || !isMobile) return
    e.preventDefault()
    ref.current.scrollIntoView(true)
  }

  useEffect(() => {
    if (value) {
      setSelectedOption(value)
    } else {
      setSelectedOption(undefined)
    }
  }, [value])

  const formatOptionLabel = (_props: any) => (
    <FormatOptionLabel {..._props} onClick={onUpgrade} />
  )

  // @ts-ignore
  return (
    <div
      ref={ref}
      className={clsx(
        disabled && 'disabled-element',
        styles.container,
        className,
        small && styles.small
      )}
    >
      <ConditionalRender condition={!!label}>
        <RequiredFieldStar required={_required}>
          <Label
            className={clsx(disabled && styles.disabled)}
            label={label}
            bold={boldLabel}
            tooltip={tooltip}
          />
        </RequiredFieldStar>
        <Spacer size={8} />
      </ConditionalRender>

      <Select
        styles={{
          menuPortal: (_styles) => ({ ..._styles, zIndex: 9999 })
        }}
        isSearchable={withSearch}
        menuPortalTarget={withPortal ? document.body : undefined}
        placeholder={placeholder}
        value={selectedOptionObject}
        onChange={handleChange}
        options={options}
        onFocus={onFocus}
        classNamePrefix="react-select"
        isDisabled={disabled}
        className={clsx(
          valueHolderClassName,
          small && 'small',
          withoutBorders && 'without-borders',
          !!value && 'has-value',
          highlighted && 'highlighted',
          withoutPadding && 'without-padding'
        )}
        noOptionsMessage={NoOptionMessage}
        isOptionDisabled={(option) => !!option.disabled}
        formatOptionLabel={
          custom ? CustomOption || formatOptionLabel : undefined
        }
      />
    </div>
  )
}

export const MemoSelect = memo<ISelectProps>(SelectComponent)
