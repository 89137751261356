import React, { FC } from 'react'

import { IModalWithCloseFn, Modal } from '@/packages/ui'

// const mapStateToProps = ({ user }: StoreType) => ({
//   user: user.me,
//   addNewCompany: user.addNewCompany
// })

type AddCompanyModalProps = IModalWithCloseFn

const AddCompanyModal: FC<AddCompanyModalProps> = ({
  closeModal
  // user,
  // addNewCompany
}) => (
  <Modal size="sm" id="addCompanyModal" visible setVisible={closeModal}>
    company info modal
  </Modal>
)

export default AddCompanyModal
