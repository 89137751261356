import React, {
  createContext,
  type FC,
  type PropsWithChildren,
  useContext
} from 'react'

export enum SignUpSteps {
  SELECT_ROLE = 'selectRole',
  GENERAL = 'general',
  DETAILS = 'details',
  TEAM = 'team',
  ANGEL_TEAM = 'angelTeam',
  JOIN_TO_TEAM = 'entrepreneurJoinTeam',
  JOIN_TO_ANGEL_TEAM = 'angelJoinTeam',
  PERSONAL = 'personal',
  INVESTOR_DETAILS = 'investorDetails'
}

export const entrepreneurStepsMapper = [
  SignUpSteps.GENERAL,
  SignUpSteps.DETAILS,
  SignUpSteps.TEAM
]

export const angelStepsMapper = [
  SignUpSteps.PERSONAL,
  SignUpSteps.INVESTOR_DETAILS,
  SignUpSteps.ANGEL_TEAM
]

export interface CompleteSignUpContextData {
  step?: SignUpSteps
  setStep?: (value: SignUpSteps) => void
}

const CompleteSignUpContext = createContext<CompleteSignUpContextData>({})

type CompleteSignUpContextProviderProps =
  PropsWithChildren<CompleteSignUpContextData>

export const CompleteSignUpContextProvider: FC<any> = ({
  children,
  ...props
}: CompleteSignUpContextProviderProps) => (
  <CompleteSignUpContext.Provider value={props as CompleteSignUpContextData}>
    {children}
  </CompleteSignUpContext.Provider>
)

export const useCompleteSignUpContext = (): CompleteSignUpContextData =>
  useContext<CompleteSignUpContextData>(CompleteSignUpContext)
