import clsx from 'clsx'
import React, { FC, useCallback, useMemo } from 'react'

import { ConditionalRender } from '@/common/components'
import { Row } from '@/packages/ui'

import { SharedIcons } from '../../../icons'
import { Color } from '../../../palette'
import { Text, TextTypes } from '../../typography'
import styles from './FileItem.module.scss'

interface FileItemProps {
  id: string
  name: string
  icon?: string
  onDelete?: (id: string) => void
  onEdit?: () => void
  onClick?: (id: string) => void
  uploadProgress?: number
  roundIcon?: boolean
  showUploadProgress?: boolean
}

const FileItem: FC<FileItemProps> = ({
  id,
  name,
  onClick,
  onDelete,
  icon,
  onEdit,
  showUploadProgress = false,
  uploadProgress = 0,
  roundIcon = false
}: FileItemProps) => {
  const handleDelete = useCallback(() => {
    onDelete?.(id)
  }, [id, onDelete])

  const handleClick = useCallback(() => {
    onClick?.(id)
  }, [id, onClick])

  const showProgressBar = useMemo(
    () => showUploadProgress && uploadProgress < 100 && uploadProgress > 0,
    [showUploadProgress, uploadProgress]
  )
  const iconClasses = clsx(
    styles.iconWrapper,
    roundIcon && styles.roundIcon,
    !icon && styles.bluePlaceholder
  )

  return (
    <div className={styles.fileCard} key={id}>
      <div className={styles.infoRow}>
        <div className={styles.nameBlock} onClick={handleClick}>
          <div className={iconClasses}>
            {!!icon ? (
              <img src={icon} alt="Icon" />
            ) : (
              <SharedIcons.File color={Color.neutral0} />
            )}
          </div>
          <span className={styles.fileName}>{name}</span>
        </div>

        <Row items="center" gap={5}>
          <ConditionalRender condition={!!onEdit}>
            <div className={clsx(styles.button, styles.edit)} onClick={onEdit}>
              <SharedIcons.PencilModern
                size={22}
                color={Color.neutral300}
                className={styles.deleteIcon}
              />
            </div>
          </ConditionalRender>

          <div className={styles.button} onClick={handleDelete}>
            <SharedIcons.Trash
              size={22}
              color={Color.neutral300}
              className={styles.deleteIcon}
            />
          </div>
        </Row>
      </div>
      {showProgressBar && (
        <div className={styles.progress}>
          <progress id="file" max="100" value={uploadProgress} />
          <Text
            type={TextTypes.BODY_EXTRA_SMALL}
            color={Color.neutral400}
            className={styles.progressInfo}
          >
            {uploadProgress}%
          </Text>
        </div>
      )}
    </div>
  )
}

export default FileItem
