import { FC, useCallback } from 'react'

import {
  BASE_TABLE_ACTIONS,
  BaseColumnProps,
  LinkTableColumn
} from '@/common/components'
import { Color } from '@/packages/palette'
import { FontWeight, Text, TextTypes } from '@/packages/ui'

interface LinkColumnProps extends BaseColumnProps {
  row: any
  column: LinkTableColumn
}

const LinkColumn: FC<LinkColumnProps> = ({ row, column, handleAction }) => {
  const { accessor, valueExtractor, customActionName } = column

  const value = valueExtractor?.(row) ?? row[accessor]

  const handleClick = useCallback(() => {
    handleAction?.({
      action: customActionName ?? BASE_TABLE_ACTIONS.LINK_CLICK,
      row,
      accessor
    })
  }, [])

  return (
    <Text
      weight={FontWeight.MEDIUM}
      type={TextTypes.BODY_DEFAULT}
      color={Color.primary400}
      clickable
      onClick={handleClick}
    >
      {value}
    </Text>
  )
}

export default LinkColumn
