import React, { FC, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { StoreType, withStore } from '@/common/store'
import { DashboardEntrepreneurView } from '@/features/dashboard'
import { ProfileTypes } from '@/features/user/enums'
import { Loader, Row } from '@/packages/ui'

import DashboardAngelView from '../DashboardAngelView/DashboardAngelView'

const mapStateToProps = ({ user }: StoreType) => ({
  companies: user.companies
})

type DashboardViewProps = ReturnType<typeof mapStateToProps>

const DashboardView: FC<DashboardViewProps> = (props) => {
  const { companies } = props

  const { id: companyId, innerCompanyId } = useParams()

  const currentCompany: any | undefined = useMemo(() => {
    if (!companyId) return undefined

    const company = companies.find((c: any) => c.id === companyId)

    return company
  }, [companyId, companies])

  if (currentCompany?.type === ProfileTypes.ENTREPRENEUR) {
    return <DashboardEntrepreneurView currentCompany={currentCompany} />
  }

  if (currentCompany?.type === ProfileTypes.ANGEL) {
    return <DashboardAngelView currentCompany={currentCompany} />
  }

  return (
    <Row items="center" justify="center" className="tw-w-full">
      <Loader width="100%" height="100%" />
    </Row>
  )
}

export default withStore(mapStateToProps)(DashboardView)
