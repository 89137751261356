import { yupResolver } from '@hookform/resolvers/yup'
import React, { FC, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { NumberFormatValues } from 'react-number-format'
import { useLocation } from 'react-router-dom'
import { UsaStates } from 'usa-states'

import {
  ConditionalRender,
  ModalContentBox,
  ModalFooter
} from '@/common/components'
import { StoreType, withStore } from '@/common/store'
import { FillCompanyImages } from '@/features/auth/assets'
import { EntrepreneurFillInfoSchema } from '@/features/auth/schemas/AuthSchema'
import { UpdateCompanyValues } from '@/features/profile/api/types'
import { SafeForItem } from '@/features/safe-note'
import { SafeNoteImages } from '@/features/safe-note/assets'
import { Color } from '@/packages/palette'
import {
  Button,
  Form,
  FormHelper,
  FormItem,
  Heading,
  IModalWithCloseFn,
  Modal,
  NumberInput,
  Row,
  Select,
  Spacer,
  Text,
  TextInput,
  TextTypes
} from '@/packages/ui'
import { useNavigator } from '@/router'

import styles from './FillCompanyInfoModal.module.scss'

const states = new UsaStates().states.map((item) => ({
  value: item.name.toLowerCase(),
  label: item.name
}))

const MODAL_ID = 'fill-company-info-modal'

const mapStateToProps = ({ user, profile }: StoreType) => ({
  companies: user.companies,
  refetchCompany: user.refetchCompany,
  updateCompany: profile.updateCompany,
  loading: profile.functionLoading.updateCompany
})

interface FillCompanyInfoModalProps extends IModalWithCloseFn {
  type?: 'termsheet' | 'safe-note'
}

const FillCompanyInfoModal: FC<
  FillCompanyInfoModalProps & ReturnType<typeof mapStateToProps>
> = (props) => {
  const navigate = useNavigator()
  const location = useLocation()

  const {
    type = 'safe-note',
    closeModal,
    companies,
    refetchCompany,
    loading,
    updateCompany
  } = props

  const [saved, setSaved] = useState(false)

  const name = type === 'safe-note' ? 'SAFE note' : 'Term Sheet'

  const path = location.pathname.split('/')
  const id = path[path.length - 1]

  const currentCompany = companies.find((company) => company.id === id)

  const {
    control,
    register,
    getValues,
    formState: { errors, isValid }
  } = useForm({
    resolver: yupResolver(EntrepreneurFillInfoSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: {
      stateOfIncorporation: currentCompany?.stateOfIncorporation,
      address1: currentCompany?.address?.address1,
      address2: currentCompany?.address?.address2,
      city: currentCompany?.address?.city,
      state: currentCompany?.address?.state,
      zipCode: currentCompany?.address?.zipCode,
      country: 'USA'
    }
  })

  const redirect = () => {
    closeModal?.()

    if (type === 'safe-note') {
      navigate.toCreateSafeNote(id)
    } else {
      navigate.toCreateTermSheet(id)
    }
  }

  const onSubmit = async () => {
    const { stateOfIncorporation, ...address } = getValues()
    const query = {
      name: currentCompany?.name,
      stateOfIncorporation,
      address
    }

    await updateCompany({
      data: { companyId: id, values: query as UpdateCompanyValues },
      options: {
        onSuccess: async () => {
          await refetchCompany({ data: { id } })
          redirect()
          // setSaved(true)
        }
      }
    })
  }

  const CompanyForm = (
    <div className={styles.wrapper}>
      <div className={styles.form}>
        <Row justify="between" items="center" gap={50}>
          <Heading>Create {name}</Heading>
        </Row>
        <Spacer size={8} />
        <Text type={TextTypes.BODY_DEFAULT} color={Color.neutral400}>
          Provide the remaining information for {currentCompany?.name}, in order
          to create a SAFE note.
        </Text>

        <Spacer size={24} />
        <Form schema={EntrepreneurFillInfoSchema}>
          <Row gap={18}>
            <FormItem errors={errors.stateOfIncorporation?.message}>
              <Controller
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Select
                    withPortal
                    name="stateOfIncorporation"
                    label="State of Incorporation"
                    placeholder="State of Incorporation"
                    options={states}
                    value={value!}
                    toTopOnFocus
                    onChange={(val: string) => onChange(val)}
                  />
                )}
                name="stateOfIncorporation"
              />
            </FormItem>
          </Row>

          <Row gap={18}>
            <FormItem errors={errors.address1?.message}>
              <TextInput
                {...register('address1')}
                label="Address"
                placeholder="Address"
                invalid={FormHelper.isFieldInvalid('address1', errors)}
              />
            </FormItem>

            <FormItem>
              <TextInput
                {...register('address2')}
                label="Apartment, suite, etc."
                placeholder="Apartment, suite, etc."
              />
            </FormItem>
          </Row>

          <Row gap={18}>
            <FormItem errors={errors.city?.message}>
              <TextInput
                {...register('city')}
                label="City"
                placeholder="City"
                invalid={FormHelper.isFieldInvalid('city', errors)}
              />
            </FormItem>

            <FormItem errors={errors.state?.message}>
              <Controller
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Select
                    withPortal
                    name="state"
                    label="State"
                    placeholder="State"
                    options={states}
                    value={value!}
                    toTopOnFocus
                    onChange={(val: string) => onChange(val)}
                  />
                )}
                name="state"
              />
            </FormItem>
          </Row>

          <Row items="center" gap={18}>
            <FormItem errors={errors.zipCode?.message}>
              <Controller
                control={control}
                name="zipCode"
                render={({ field: { onChange, value } }) => (
                  <NumberInput
                    value={value}
                    onValueChange={(val: NumberFormatValues) => {
                      onChange(val.value)
                    }}
                    label="Zip Code"
                    placeholder="Zip Code"
                    name="zipCode"
                    invalid={FormHelper.isFieldInvalid('zipCode', errors)}
                    thousandSeparator={false}
                  />
                )}
              />
            </FormItem>

            <FormItem errors={errors.country?.message}>
              <TextInput
                {...register('country')}
                disabled
                label="Country"
                tooltip="We’re currently only working within the USA, but hold tight, MySAFEnotes will be available in other countries shortly."
                placeholder="Country"
                invalid={FormHelper.isFieldInvalid('country', errors)}
              />
            </FormItem>
          </Row>
        </Form>

        <Button
          uppercase
          onClick={onSubmit}
          disabled={!isValid || loading}
          width="default"
          className="tw-mt-[15px]"
        >
          {loading ? 'Loading...' : 'Save & move on'}
        </Button>
      </div>
      <div className={styles.stepImage}>
        <img src={FillCompanyImages.EntrepreneurFillInfo} alt="" />
      </div>
    </div>
  )

  return (
    <Modal
      id={MODAL_ID}
      visible
      size={saved ? 'sm' : 'lg'}
      setVisible={closeModal}
      className={saved ? undefined : styles.modalStyles}
    >
      <ConditionalRender condition={saved} fallbackElement={CompanyForm}>
        <ModalContentBox
          name="Select SAFE Recipient"
          title="Choose who will receive this SAFE note"
          description="Select which description matches the recipient of this SAFE."
        >
          <div className={styles.itemsHolder}>
            <SafeForItem
              checked
              title="Angel"
              description="Angel investors provide seed funding in exchange for future company shares"
              image={SafeNoteImages.AngelBig}
            />
            <SafeForItem
              disabled
              title="Freelancer"
              description="Freelancers provide services in exchange for future company shares."
              image={SafeNoteImages.FreelancerBig}
            />
          </div>

          <ModalFooter
            leftButtonText="Cancel"
            leftButtonAction={closeModal}
            rightButtonText="Next"
            rightButtonActon={redirect}
          />
        </ModalContentBox>
      </ConditionalRender>
    </Modal>
  )
}

export default withStore(mapStateToProps)(FillCompanyInfoModal)
