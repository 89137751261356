import React, { FC, useMemo, useRef, useState } from 'react'
import { useOnClickOutside } from 'usehooks-ts'

import { WarningModal } from '@/common/modals'
import { StoreType, withStore } from '@/common/store'
import { SendReminder } from '@/features/dashboard/assets/icons/TableOptionsIcons'
import {
  ITermSheetRecipient,
  TermSheetStatus
} from '@/features/term-sheet/api/types'
import { TermSheetActions } from '@/features/term-sheet/assets/icons'
import { useModal } from '@/packages/hooks'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import { Tooltip } from '@/packages/ui'
import { useNavigator } from '@/router'

import styles from './RecipientActions.module.scss'

interface RecipientActionsProps {
  recipient: ITermSheetRecipient
  companyId: string
  termSheetOptions: any
  refetch: () => void
}

enum Actions {
  ChangeEmail = 'changeEmail',
  Delete = 'delete',
  Remind = 'sendReminder',
  CreateNewTermSheet = 'newTermSheet',
  CreateSafe = 'createSafe'
}

const mapStateToProps = ({ termSheet }: StoreType) => ({
  deleteRecipient: termSheet.deleteRecipient,
  loading: termSheet.functionLoading.deleteRecipient
})

const RecipientActions: FC<
  RecipientActionsProps & ReturnType<typeof mapStateToProps>
> = (props) => {
  const {
    recipient,
    refetch,
    companyId,
    loading,
    termSheetOptions,
    deleteRecipient
  } = props

  const { status } = recipient
  const popupRef = useRef<any>()

  const navigate = useNavigator()

  const [isOpen, setOpen] = useState(false)
  const { visible, openModal, closeModal } = useModal<boolean>(false)

  const options = useMemo(() => {
    const result = []

    const notNullStatus = status || TermSheetStatus.Pending

    if (notNullStatus === TermSheetStatus.Pending) {
      result.push(
        {
          id: Actions.ChangeEmail,
          label: 'Change email',
          icon: (
            <TermSheetActions.ChangeEmail size={18} color={Color.neutral500} />
          )
        },
        {
          id: Actions.Remind,
          label: 'Send reminder',
          icon: <SendReminder size={17} color={Color.neutral500} />
        }
      )
    }

    if (notNullStatus === TermSheetStatus.Rejected) {
      result.push({
        id: Actions.CreateNewTermSheet,
        label: 'Create new term sheet',
        icon: <TermSheetActions.Add size={18} color={Color.neutral500} />
      })
    }

    if (notNullStatus === TermSheetStatus.Accepted) {
      result.push({
        id: Actions.CreateSafe,
        label: 'Create SAFE',
        icon: <TermSheetActions.Add size={18} color={Color.neutral500} />
      })
    }

    if (
      [TermSheetStatus.Rejected, TermSheetStatus.Pending].includes(
        notNullStatus
      )
    ) {
      result.push({
        id: Actions.Delete,
        label: 'Delete',
        icon: <SharedIcons.Trash size={18} color={Color.neutral500} />
      })
    }

    return result
  }, [status])

  const handleActionClick = (action: Actions) => {
    switch (action) {
      case Actions.ChangeEmail: {
        break
      }

      case Actions.CreateSafe:
      case Actions.CreateNewTermSheet: {
        const redirectOptions: any = Object.fromEntries(
          Object.entries(termSheetOptions).filter(
            ([, value]) => value !== null && value !== undefined
          )
        )

        redirectOptions.recipient = recipient.user.email

        const encodedOptions = encodeURIComponent(
          JSON.stringify(redirectOptions)
        )

        if (action === Actions.CreateNewTermSheet) {
          navigate.toCreateTermSheet(companyId, { data: encodedOptions })
        } else {
          navigate.toCreateSafeNote(companyId, { data: encodedOptions })
        }
        break
      }

      case Actions.Delete: {
        openModal()
        break
      }

      case Actions.Remind: {
        break
      }

      default: {
        break
      }
    }

    setOpen(false)
  }

  const OptionsComponent = (
    <ul className={styles.options} ref={popupRef}>
      {options.map((option) => (
        <li key={option.id} onClick={() => handleActionClick(option.id)}>
          {option.icon}
          {option.label}
        </li>
      ))}
    </ul>
  )

  useOnClickOutside(popupRef, () => {
    if (isOpen) {
      setOpen(false)
    }
  })

  return (
    <>
      {visible && (
        <WarningModal
          id="delete-recipient"
          closeModal={closeModal}
          description="All of your progress will be lost."
          title={`Are you sure you want to delete this term sheet for ${recipient.user.fullName}?`}
          leftButtonText="Cancel"
          rightButtonText="Delete"
          loading={loading}
          rightButtonAction={() =>
            deleteRecipient({
              data: { id: termSheetOptions.id, userId: recipient.user.id },
              options: { onSuccess: refetch }
            })
          }
        />
      )}
      <Tooltip
        interactive
        visible={isOpen}
        content={OptionsComponent}
        trigger="click"
        placement="bottom"
        className="!tw-max-w-none"
      >
        <div className={styles.arrowHolder} onClick={() => setOpen(true)}>
          <SharedIcons.Dots size={16} color={Color.neutral400} />
        </div>
      </Tooltip>
    </>
  )
}

export default withStore(mapStateToProps)(RecipientActions)
