import React, { FC, useMemo } from 'react'

import { ConditionalRender } from '@/common/components'
import { StoreType, withStore } from '@/common/store'
import { CompanyOption } from '@/features/dashboard/modals/IncomingSafesModal/parts/SafesList'
import { ICompany } from '@/features/profile/types'
import selectCompanyImg from '@/features/term-sheet/assets/images/select-termsheet-company.png'
import { Color } from '@/packages/palette'
import {
  Button,
  Col,
  Heading,
  HeadingTypes,
  IModalWithCloseFn,
  Modal,
  Row,
  Select,
  Text,
  TextAlign,
  TextTypes
} from '@/packages/ui'

const mapStateToProps = ({ user }: StoreType) => ({
  availableCompanies: user.sortedCompanies.angel
})

interface SelectCompanyForTermsheetProps extends IModalWithCloseFn {
  toCreateCompany: () => void
  permanentlyDelete?: boolean
  onMainAction: (id: string) => void
  onSecondaryAction?: () => void
}

const MODAL_ID = 'selectCompanyForTermsheet-modal'

const SelectCompanyForTermsheet: FC<
  SelectCompanyForTermsheetProps & ReturnType<typeof mapStateToProps>
> = (props) => {
  const {
    toCreateCompany,
    onMainAction,
    permanentlyDelete,
    closeModal,
    onSecondaryAction,
    availableCompanies
  } = props
  const [selectedCompany, setSelectedCompany] = React.useState<string>()

  const options = useMemo(() => {
    const list = availableCompanies.map((company: ICompany) => ({
      value: company.id,
      label: company.name,
      image: company.image
    }))

    list.push({
      value: 'addCompany',
      label: 'Create a Company',
      image: ''
    })

    return list
  }, [availableCompanies])

  return (
    <Modal size="sm" id={MODAL_ID} visible setVisible={closeModal}>
      <Col items="center">
        <img src={selectCompanyImg} width={127} alt="" />

        <Heading
          type={HeadingTypes.H2}
          color={Color.neutral500}
          align={TextAlign.CENTER}
          className="tw-mt-[16px]"
        >
          {permanentlyDelete
            ? 'Do you want to save or permanently delete this term sheet?'
            : 'Which of your companies is accepting this term sheet?'}
        </Heading>

        <Text
          type={TextTypes.BODY_DEFAULT}
          color={Color.neutral400}
          className="tw-mt-[12px]"
          align={TextAlign.CENTER}
        >
          {permanentlyDelete
            ? 'Select a company to save the declined term sheet for future viewing.'
            : 'Choose the company that will make an investment.'}
        </Text>

        <Select
          custom
          withPortal
          CustomOption={CompanyOption}
          className="tw-mt-[10px] tw-self-stretch"
          placeholder="Write or select the company"
          name="companies"
          options={options}
          value={selectedCompany}
          onChange={(val: string) =>
            val === 'addCompany' ? toCreateCompany?.() : setSelectedCompany(val)
          }
        />

        <Row items="center" gap={15} className="tw-mt-[20px]">
          <ConditionalRender condition={permanentlyDelete}>
            <Button
              uppercase
              appearance="secondary"
              width="default"
              onClick={onSecondaryAction}
            >
              Delete
            </Button>
          </ConditionalRender>

          <Button
            uppercase
            disabled={!selectedCompany}
            width="default"
            onClick={() => onMainAction(selectedCompany!)}
          >
            {permanentlyDelete ? 'Save' : 'Get started'}
          </Button>
        </Row>
      </Col>
    </Modal>
  )
}

export default withStore(mapStateToProps)(SelectCompanyForTermsheet)
