import { FC } from 'react'

import { IconProps, withIcon } from '@/common/hocs'

export const Cancel: FC<IconProps> = withIcon((props) => (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.99984 0.666748C2.774 0.666748 0.166504 3.27425 0.166504 6.50008C0.166504 9.72592 2.774 12.3334 5.99984 12.3334C9.22567 12.3334 11.8332 9.72592 11.8332 6.50008C11.8332 3.27425 9.22567 0.666748 5.99984 0.666748ZM5.99984 11.1667C3.42734 11.1667 1.33317 9.07258 1.33317 6.50008C1.33317 3.92758 3.42734 1.83341 5.99984 1.83341C8.57234 1.83341 10.6665 3.92758 10.6665 6.50008C10.6665 9.07258 8.57234 11.1667 5.99984 11.1667ZM8.094 3.58341L5.99984 5.67758L3.90567 3.58341L3.08317 4.40591L5.17734 6.50008L3.08317 8.59425L3.90567 9.41675L5.99984 7.32258L8.094 9.41675L8.9165 8.59425L6.82234 6.50008L8.9165 4.40591L8.094 3.58341Z"
      fill="#242424"
    />
  </svg>
))

export const SendReminder: FC<IconProps> = withIcon((props) => (
  <svg
    width="19"
    height="20"
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.85906 5.50333L12.5803 3.26292C15.5966 2.2575 17.2353 3.90417 16.2378 6.92042L13.9974 13.6417C12.4932 18.1621 10.0232 18.1621 8.51906 13.6417L7.85406 11.6467L5.85906 10.9817C1.33865 9.4775 1.33865 7.01542 5.85906 5.50333Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8 11.3062L10.8342 8.46411"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
))

export const ViewSafe: FC<IconProps> = withIcon((props) => (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.11111 4.55556H9.94444V5.83333H6.11111V4.55556ZM6.11111 7.11111H9.94444V8.38889H6.11111V7.11111ZM6.11111 9.66667H9.94444V10.9444H6.11111V9.66667ZM3.55556 4.55556H4.83333V5.83333H3.55556V4.55556ZM3.55556 7.11111H4.83333V8.38889H3.55556V7.11111ZM3.55556 9.66667H4.83333V10.9444H3.55556V9.66667ZM11.925 2H1.575C1.25556 2 1 2.25556 1 2.575V12.925C1 13.1806 1.25556 13.5 1.575 13.5H11.925C12.1806 13.5 12.5 13.1806 12.5 12.925V2.575C12.5 2.25556 12.1806 2 11.925 2ZM11.2222 12.2222H2.27778V3.27778H11.2222V12.2222Z"
      fill="#242424"
    />
  </svg>
))
