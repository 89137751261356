import { FC } from 'react'

import { IconProps, withIcon } from '@/common/hocs'

export const ComparePlans: FC<IconProps> = withIcon((props) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9 1.5C4.86 1.5 1.5 4.86 1.5 9C1.5 13.14 4.86 16.5 9 16.5C13.14 16.5 16.5 13.14 16.5 9"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.75 8.24998L15.9 2.09998"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.5004 5.1225V1.5H12.8779"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
))

export const MasterCard: FC<IconProps> = withIcon((props) => (
  <svg
    width="31"
    height="20"
    viewBox="0 0 31 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.6911 2.46777H11.3086V17.5314H19.6911V2.46777Z"
      fill="#FF5F00"
    />
    <path
      d="M11.8412 10.0001C11.8399 8.54932 12.1686 7.11731 12.8025 5.81241C13.4365 4.50751 14.359 3.36393 15.5002 2.46825C14.0869 1.35741 12.3897 0.66659 10.6024 0.474757C8.81507 0.282925 7.00986 0.59782 5.39306 1.38345C3.77626 2.16908 2.41311 3.39374 1.45943 4.91747C0.505746 6.44119 0 8.20249 0 10.0001C0 11.7976 0.505746 13.5589 1.45943 15.0827C2.41311 16.6064 3.77626 17.831 5.39306 18.6167C7.00986 19.4023 8.81507 19.7172 10.6024 19.5254C12.3897 19.3335 14.0869 18.6427 15.5002 17.5319C14.359 16.6362 13.4365 15.4926 12.8025 14.1877C12.1686 12.8828 11.8399 11.4508 11.8412 10.0001Z"
      fill="#EB001B"
    />
    <path
      d="M30.9997 10.0001C30.9998 11.7976 30.4941 13.5589 29.5405 15.0826C28.5868 16.6063 27.2237 17.831 25.607 18.6167C23.9902 19.4023 22.185 19.7172 20.3977 19.5254C18.6105 19.3335 16.9132 18.6427 15.5 17.5319C16.6402 16.6353 17.562 15.4915 18.1958 14.1868C18.8296 12.8821 19.159 11.4506 19.159 10.0001C19.159 8.54955 18.8296 7.11798 18.1958 5.81329C17.562 4.5086 16.6402 3.36483 15.5 2.46825C16.9132 1.3574 18.6105 0.666586 20.3977 0.474756C22.185 0.282925 23.9902 0.597826 25.607 1.38346C27.2237 2.1691 28.5868 3.39377 29.5405 4.9175C30.4941 6.44122 30.9998 8.20251 30.9997 10.0001Z"
      fill="#F79E1B"
    />
    <path
      d="M30.0859 15.9362V15.6278H30.2103V15.5649H29.8936V15.6278H30.018V15.9362H30.0859ZM30.7008 15.9362V15.5643H30.6037L30.492 15.8201L30.3803 15.5643H30.2832V15.9362H30.3517V15.6557L30.4565 15.8975H30.5275L30.6323 15.6551V15.9362H30.7008Z"
      fill="#F79E1B"
    />
  </svg>
))

export const ChangePaymentMethod: FC<IconProps> = withIcon((props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.04 13.55C17.62 13.96 17.38 14.55 17.44 15.18C17.53 16.26 18.52 17.05 19.6 17.05H21.5V18.24C21.5 20.31 19.81 22 17.74 22H7.63C7.94 21.74 8.21 21.42 8.42 21.06C8.79 20.46 9 19.75 9 19C9 16.79 7.21 15 5 15C4.06 15 3.19 15.33 2.5 15.88V11.51C2.5 9.44001 4.19 7.75 6.26 7.75H17.74C19.81 7.75 21.5 9.44001 21.5 11.51V12.95H19.48C18.92 12.95 18.41 13.17 18.04 13.55Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.5 12.4101V7.8401C2.5 6.6501 3.23 5.59006 4.34 5.17006L12.28 2.17006C13.52 1.70006 14.85 2.62009 14.85 3.95009V7.75008"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.5588 13.9702V16.0302C22.5588 16.5802 22.1188 17.0302 21.5588 17.0502H19.5988C18.5188 17.0502 17.5288 16.2602 17.4388 15.1802C17.3788 14.5502 17.6188 13.9602 18.0388 13.5502C18.4088 13.1702 18.9188 12.9502 19.4788 12.9502H21.5588C22.1188 12.9702 22.5588 13.4202 22.5588 13.9702Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7 12H14"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9 19C9 19.75 8.79 20.46 8.42 21.06C8.21 21.42 7.94 21.74 7.63 22C6.93 22.63 6.01 23 5 23C3.54 23 2.27 22.22 1.58 21.06C1.21 20.46 1 19.75 1 19C1 17.74 1.58 16.61 2.5 15.88C3.19 15.33 4.06 15 5 15C7.21 15 9 16.79 9 19Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.49171 18.9795H3.51172"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5 17.5195V20.5095"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
))
