import { FC } from 'react'

import { Avatar } from '@/common/components'
import { ICompany } from '@/features/profile/types'
import { Color } from '@/packages/palette'
import { Col, FontWeight, Row, Text, TextTypes } from '@/packages/ui'

interface CompanyColumnProps {
  company: ICompany
}

const CompanyColumn: FC<CompanyColumnProps> = (props) => {
  const { company } = props

  return (
    <Row items="center" gap={8}>
      <Avatar size={38} image={company.image} name={company.name} />

      <Col>
        <Text
          type={TextTypes.BODY_SMALL}
          weight={FontWeight.SEMIBOLD}
          color={Color.neutral500}
        >
          {company.name}
        </Text>

        <Text
          type={TextTypes.BODY_EXTRA_SMALL}
          weight={FontWeight.MEDIUM}
          color={Color.neutral400}
        >
          {company.owner.fullName}
        </Text>
      </Col>
    </Row>
  )
}

export default CompanyColumn
