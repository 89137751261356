import React, { FC } from 'react'

import { Avatar, ConditionalRender, Table } from '@/common/components'
import { DateHelper } from '@/common/helpers'
import { StoreType, withStore } from '@/common/store'
import { toCurrencyFormat } from '@/common/utils'
import { VerificationMark } from '@/features/auth/assets/icons'
import { ITermSheet, TermSheetStatus } from '@/features/term-sheet/api/types'
import { TermSheetActions } from '@/features/term-sheet/assets/icons'
import { VerificationStatus } from '@/features/user/enums'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import {
  Badge,
  BadgeType,
  Button,
  Col,
  FontWeight,
  Heading,
  HeadingTypes,
  Loader,
  Row,
  Text,
  TextTypes
} from '@/packages/ui'
import { useNavigator } from '@/router'

import columns from './columns'
import styles from './TermSheetSummary.module.scss'

interface TermSheetSummaryProps {
  termSheet: ITermSheet
  refetch: () => void
}

const mapStateToProps = ({ termSheet }: StoreType) => ({
  loading: termSheet.functionLoading.deleteRecipient
})

const TermSheetSummary: FC<
  TermSheetSummaryProps & ReturnType<typeof mapStateToProps>
> = (props) => {
  const { refetch, termSheet, loading } = props

  const navigate = useNavigator()

  const {
    id,
    sender,
    createdAt,
    recipients,
    senderCompany,
    mfn,
    roundAmount,
    discountRate,
    valuationCap
  } = termSheet

  const isAnyoneAccepted = recipients?.some?.(
    (recipient) => recipient.status === TermSheetStatus.Accepted
  )

  const NoInfo = (
    <Text
      type={TextTypes.BODY_DEFAULT}
      color={Color.neutral400}
      weight={FontWeight.MEDIUM}
    >
      -
    </Text>
  )

  const termSheetOptions = {
    id,
    mfn,
    roundAmount,
    discountRate,
    valuationCap
  }

  const renderSummaryColumn = (
    text: string,
    value: number | string | undefined,
    badgeType = BadgeType.SUCCESS
  ) => (
    <Col gap={7}>
      <Text type={TextTypes.BODY_SMALL} color={Color.neutral300}>
        {text}:
      </Text>

      <ConditionalRender condition={!!value} fallbackElement={NoInfo}>
        <Badge size="large" className="!tw-pt-6 !tw-pb-6" type={badgeType}>
          {value}
        </Badge>
      </ConditionalRender>
    </Col>
  )

  const renderColumnWithImage = (
    text: string,
    name: string,
    image: string | null,
    verified: boolean
  ) => (
    <Col gap={7} justify="between">
      <Text type={TextTypes.BODY_SMALL} color={Color.neutral300}>
        {text}:
      </Text>

      <Row items="center" gap={8}>
        <Avatar size={30} name={name} image={image} />

        <Text
          type={TextTypes.BODY_DEFAULT}
          color={Color.neutral500}
          weight={FontWeight.MEDIUM}
        >
          {name}
        </Text>

        <ConditionalRender condition={verified}>
          <VerificationMark size={18} color={Color.primary400} />
        </ConditionalRender>
      </Row>
    </Col>
  )

  const RoundAmount = renderSummaryColumn(
    'Round amount',
    !!roundAmount ? toCurrencyFormat(roundAmount, '$') : undefined,
    BadgeType.INFO
  )

  const DiscountRate = renderSummaryColumn(
    'Discount rate',
    !!discountRate ? `${discountRate}%` : undefined
  )

  const ValuationCap = renderSummaryColumn(
    'Valuation cap',
    !!valuationCap ? toCurrencyFormat(valuationCap, '$') : undefined
  )

  const MFN = renderSummaryColumn('Terms', 'MFN')

  return (
    <div>
      <Row items="center" justify="between" gap={20}>
        <Row items="center" gap={10}>
          <TermSheetActions.ViewTermSheet size={24} color={Color.neutral500} />

          <Heading type={HeadingTypes.H2} color={Color.neutral500}>
            Term sheet
          </Heading>

          <Text type={TextTypes.BODY_SMALL} color={Color.neutral300}>
            sent {DateHelper.toPrettyFormat(createdAt)}
          </Text>
        </Row>

        <Button
          width="fit"
          disabled={!isAnyoneAccepted}
          className="!tw-px-40"
          onClick={() => navigate.toCreateSafeNote(senderCompany.id)}
        >
          <Row items="center" gap={5}>
            <SharedIcons.PlusThin color={Color.neutral0} size={20} />
            Create SAFE
          </Row>
        </Button>
      </Row>

      <Row gap={50} className={styles.summary}>
        {renderColumnWithImage('Sent by', sender.fullName, sender.image, false)}

        {renderColumnWithImage(
          'Company',
          senderCompany.name,
          senderCompany.image,
          senderCompany.verificationStatus === VerificationStatus.Verified
        )}

        {RoundAmount}
        <ConditionalRender condition={!mfn} fallbackElement={MFN}>
          {!!discountRate && DiscountRate}
          {!!valuationCap && ValuationCap}
        </ConditionalRender>
      </Row>

      <div className={styles.tableWrapper}>
        <ConditionalRender
          condition={!loading}
          fallbackElement={<Loader width="100%" />}
        >
          <Table
            columns={columns(senderCompany.id, termSheetOptions, refetch)}
            data={termSheet.recipients}
          />
        </ConditionalRender>
      </div>
    </div>
  )
}

export default withStore(mapStateToProps)(TermSheetSummary)
