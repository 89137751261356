import { yupResolver } from '@hookform/resolvers/yup'
import React, { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { ConditionalRender } from '@/common/components'
import { StoreType, withStore } from '@/common/store'
import { EmailSchema } from '@/features/auth/schemas/AuthSchema'
import noPeople from '@/features/term-sheet/assets/images/no-added-people.png'
import { TermSheetRecipientItem } from '@/features/term-sheet/components'
import { IUser } from '@/features/user'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import {
  Button,
  Col,
  Heading,
  HeadingTypes,
  Loader,
  Row,
  Text,
  TextAlign,
  TextInput,
  TextTypes
} from '@/packages/ui'

import styles from './TermSheetRecipients.module.scss'

const mapStateToProps = ({ user }: StoreType) => ({
  myEmail: user.me?.email,
  loading: user.functionLoading.getPublicUserByEmail,
  getPublicUserByEmail: user.getPublicUserByEmail
})

interface TermSheetRecipientsProps {
  invitedPeople: IUser[]
  onUpdate: (users: IUser[]) => void
}

const TermSheetRecipients: FC<
  TermSheetRecipientsProps & ReturnType<typeof mapStateToProps>
> = (props) => {
  const [additionalError, setAdditionalError] = useState<string>('')
  const { myEmail, loading, getPublicUserByEmail, invitedPeople, onUpdate } =
    props

  const {
    watch,
    setValue,
    register,
    formState: { errors, isValid }
  } = useForm({
    resolver: yupResolver(EmailSchema),
    reValidateMode: 'onChange',
    mode: 'onChange'
  })

  const errorMsg = Array.isArray(errors?.email?.message)
    ? errors?.email?.message[0]
    : errors?.email?.message || additionalError

  const invite = async () => {
    const email = watch('email')

    if (email === myEmail) {
      setAdditionalError('You cannot invite yourself')
      return
    }

    const existedUser = invitedPeople.find((user) => user.email === email)

    if (!!existedUser) {
      setAdditionalError('This person is already invited')
      return
    }

    try {
      const invitedUser = await getPublicUserByEmail({ data: { email } })

      if (invitedUser?.email) {
        onUpdate([...invitedPeople, invitedUser])
      } else {
        onUpdate([...invitedPeople, { email, fullName: '' } as IUser])
      }

      setValue('email', '')
    } catch {
      setValue('email', '')
    }
  }

  const deleteItem = (email: string) => {
    onUpdate(invitedPeople.filter((user: IUser) => user.email !== email))
  }

  useEffect(() => {
    if (!!additionalError) {
      setAdditionalError('')
    }
  }, [watch('email')])

  const NoItems = (
    <Col items="center" gap={7} justify="center" className="tw-mt-20">
      <img src={noPeople} width={130} alt="" />

      <Text
        type={TextTypes.BODY_DEFAULT}
        color={Color.neutral300}
        align={TextAlign.CENTER}
        className="tw-mt-8"
      >
        You haven’t added any recipients yet
      </Text>
    </Col>
  )

  return (
    <Col items="center">
      <Heading
        type={HeadingTypes.H2}
        color={Color.neutral500}
        align={TextAlign.CENTER}
      >
        Add recipients to your term sheet
      </Heading>

      <Text
        type={TextTypes.BODY_DEFAULT}
        color={Color.neutral400}
        align={TextAlign.CENTER}
        className="tw-mt-8"
      >
        Enter emails of the term sheet recipient or recipients below.
      </Text>

      <Col className="tw-self-stretch tw-mt-30" gap={24}>
        <Col gap={5}>
          <Row items="stretch" gap={15}>
            <TextInput
              placeholder="Email"
              {...register('email')}
              invalid={!!errorMsg}
            />
            <Button
              disabled={!isValid || loading}
              width="default"
              uppercase
              appearance="secondary"
              onClick={invite}
            >
              Add
            </Button>
          </Row>
          <ConditionalRender condition={!!errorMsg}>
            <Text type={TextTypes.BODY_DEFAULT} color={Color.error300}>
              {errorMsg}
            </Text>
          </ConditionalRender>
        </Col>

        <ConditionalRender
          condition={!loading}
          fallbackElement={<Loader width="100%" />}
        >
          <ConditionalRender
            condition={!!invitedPeople?.length}
            fallbackElement={NoItems}
          >
            <ul className={styles.usersList}>
              {invitedPeople.map((user) => (
                <li key={user.email}>
                  <Row items="center" justify="between" gap={30}>
                    <TermSheetRecipientItem
                      fullName={user.fullName}
                      image={user.image}
                      email={user.email}
                    />

                    <SharedIcons.Trash
                      size={24}
                      color={Color.neutral400}
                      onClick={() => deleteItem(user.email)}
                    />
                  </Row>
                </li>
              ))}
            </ul>
          </ConditionalRender>
        </ConditionalRender>
      </Col>
    </Col>
  )
}

export default withStore(mapStateToProps)(TermSheetRecipients)
