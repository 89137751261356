import clsx from 'clsx'
import Lottie from 'lottie-react'
import React, { FC, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { SharedImages } from '@/assets/images'
import { LottieConfetti } from '@/assets/lottie'
import { Avatar, HeaderBar } from '@/common/components'
import { toCurrencyFormat } from '@/common/utils'
import { ISafeNoteRecipient } from '@/features/safe-note'
import successImg from '@/features/safe-note/assets/images/safe-created.png'
import { Color } from '@/packages/palette'
import {
  Badge,
  BadgeType,
  Button,
  Col,
  FontWeight,
  Heading,
  HeadingTypes,
  Row,
  Text,
  TextAlign,
  TextTypes
} from '@/packages/ui'
import { useNavigator } from '@/router'

import styles from './SafeNoteSummary.module.scss'

interface SafeNoteSummaryProps {
  recipients: ISafeNoteRecipient[]
  mfn: boolean
  discountRate: number | undefined
  valuationCap: number | undefined
}

const SafeNoteSummary: FC<SafeNoteSummaryProps> = (props) => {
  const { recipients, mfn, discountRate, valuationCap } = props

  const { companyId } = useParams()
  const navigate = useNavigator()

  const totalAmount = useMemo(
    () => recipients.reduce((acc, next) => acc + next.amount, 0),
    [recipients]
  )

  const handleClick = () => {
    if (companyId) {
      navigate.toDashboardCompany(companyId)
    } else {
      navigate.toDashboard()
    }
  }

  const renderTermsItem = (title: string, value: string) => (
    <Row gap={20} justify="between" items="center" className={styles.whiteBox}>
      <Text
        type={TextTypes.BODY_DEFAULT}
        weight={FontWeight.MEDIUM}
        color={Color.neutral400}
      >
        {title}
      </Text>

      <Badge type={BadgeType.SUCCESS}>{value}</Badge>
    </Row>
  )

  const Recipients = (
    <div className={clsx(styles.whiteBox, '!tw-py-17')}>
      <Row
        items="center"
        gap={20}
        justify="between"
        className={styles.totalAmount}
      >
        <Text
          type={TextTypes.BODY_DEFAULT}
          weight={FontWeight.MEDIUM}
          color={Color.neutral400}
          className="!tw-leading-snug"
        >
          Total investment amount:
        </Text>

        <Badge type={BadgeType.INFO}>
          {toCurrencyFormat(totalAmount, '$')}
        </Badge>
      </Row>

      <Col gap={16} items="stretch" className={styles.recipients}>
        {recipients.map((item) => (
          <Row key={item.email} gap={20} justify="between" items="center">
            <Row items="center" gap={8}>
              <Avatar
                size={32}
                image={item.img}
                defaultImage={SharedImages.UserPlaceholder}
              />

              <Text
                weight={FontWeight.SEMIBOLD}
                className={Color.neutral500}
                type={TextTypes.BODY_SMALL}
              >
                {item.name}
              </Text>

              <Text className={Color.neutral400} type={TextTypes.BODY_SMALL}>
                {item.email}
              </Text>
            </Row>

            <Text
              className={Color.neutral400}
              type={TextTypes.BODY_SMALL}
              weight={FontWeight.SEMIBOLD}
            >
              {toCurrencyFormat(item.amount, '$')}
            </Text>
          </Row>
        ))}
      </Col>
    </div>
  )

  return (
    <div className={styles.summaryScreen}>
      <Lottie animationData={LottieConfetti} className={styles.leftConfetti} />

      <Lottie animationData={LottieConfetti} className={styles.rightConfetti} />

      <HeaderBar />

      <div className={styles.summaryScreenInner}>
        <div className={styles.content}>
          <img src={successImg} alt="SAFE note created" width={442} />
          <Heading
            type={HeadingTypes.H2}
            align={TextAlign.CENTER}
            color={Color.neutral500}
          >
            Your SAFE note was sent!
          </Heading>
          <Text
            type={TextTypes.BODY_DEFAULT}
            color={Color.neutral300}
            align={TextAlign.CENTER}
            className="tw-mt-8"
          >
            You’re getting closer to your fundraising goals! An email was sent
            to your investors, and you can find a summary of the SAFE below.
          </Text>

          <Col gap={12} items="stretch" className="tw-w-full tw-mt-24">
            {Recipients}

            {mfn ? (
              renderTermsItem('Terms:', 'MFN')
            ) : (
              <>
                {!!discountRate &&
                  renderTermsItem('Discount:', `${discountRate}%`)}
                {!!valuationCap &&
                  renderTermsItem(
                    'Valuation cap:',
                    toCurrencyFormat(valuationCap, '$')
                  )}
              </>
            )}

            <Button
              uppercase
              className="tw-self-center tw-mt-10"
              width="default"
              onClick={handleClick}
            >
              Go to SAFE notes
            </Button>
          </Col>
        </div>
      </div>
    </div>
  )
}

export default SafeNoteSummary
