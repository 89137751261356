import clsx from 'clsx'
import { noop } from 'lodash'
import React, { FC, FunctionComponent } from 'react'

import ConditionaRender from '@/common/components/ConditionalRender/ConditionaRender'
import { IconProps } from '@/common/hocs'
import { Color } from '@/packages/palette'
import {
  Badge,
  BadgeType,
  Col,
  Radio,
  Row,
  Text,
  TextTypes
} from '@/packages/ui'

import styles from './PaymentOption.module.scss'

interface PaymentOptionProps {
  id: string
  selected?: boolean
  onSelect: (id: string) => void
  title: string
  description: string
  icon?: FunctionComponent<IconProps>
  disabled?: boolean
  comingSoon?: boolean
}

const PaymentOption: FC<PaymentOptionProps> = ({
  id,
  selected,
  onSelect,
  title,
  description,
  icon,
  disabled,
  comingSoon
}) => (
  <div
    className={clsx(
      styles.paymentMethod,
      selected && styles.active,
      disabled && styles.disabled
    )}
    onClick={!disabled ? () => onSelect(id) : noop}
  >
    <Row justify="between" items="center">
      {icon?.({ size: 20 })}
      <Radio
        disabled={disabled}
        alwaysBold
        value="card"
        name="payWith"
        checked={selected}
        onChange={!disabled ? () => onSelect(id) : noop}
      />
    </Row>

    <Col className="tw-mt-12">
      <Text type={TextTypes.BODY_SMALL} color={Color.neutral400}>
        {title}
      </Text>

      <Row items="center" justify="between">
        <Text type={TextTypes.BODY_EXTRA_SMALL} color={Color.neutral300}>
          {description}
        </Text>

        <ConditionaRender condition={comingSoon}>
          <Badge type={BadgeType.WARNING} size="small">
            Coming soon
          </Badge>
        </ConditionaRender>
      </Row>
    </Col>
  </div>
)

export default PaymentOption
