import { ModalFooter } from '@/common/components'
import { StoreType, withStore } from '@/common/store'
import { SafeNoteImages } from '@/features/safe-note/assets'
import { Col, Heading, HeadingTypes, Modal, TextAlign } from '@/packages/ui'

const mapStateToProps = ({ safeNote }: StoreType) => ({
  declineSafeNote: safeNote.declineSafeNote,
  loading: safeNote.functionLoading.declineSafeNote
})

type DeclineSafeModalProps = {
  closeModal: () => void
  safeNote: { id: string }
  loading: boolean
  onDecline: () => void
  declineSafeNote: (params: {
    data: { id: string }
    options: { onSuccess: () => void }
  }) => Promise<void>
}

const DeclineSafeModal = ({
  closeModal,
  safeNote,
  loading,
  onDecline,
  declineSafeNote
}: DeclineSafeModalProps) => {
  const handleDecline = async () => {
    await declineSafeNote({
      data: { id: safeNote.id },
      options: {
        onSuccess: () => {
          closeModal()
          onDecline()
        }
      }
    })
  }

  return (
    <Modal size="sm" id="accept-safe-model" visible setVisible={closeModal}>
      <Col items="center">
        <img src={SafeNoteImages.DeclineSafe} alt="decline-safe" width={142} />

        <Heading
          type={HeadingTypes.H2}
          className="tw-w-8/12 tw-mt-7"
          align={TextAlign.CENTER}
        >
          You’re about to decline this SAFE
        </Heading>
      </Col>

      <ModalFooter
        className="tw-mt-30"
        leftButtonText="Cancel"
        leftButtonAction={closeModal}
        rightButtonText={loading ? 'Loading...' : 'DECLINE'}
        rightButtonActon={handleDecline}
        disabled={loading}
      />
    </Modal>
  )
}

export default withStore(mapStateToProps)(DeclineSafeModal)
