import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip
} from 'chart.js'
import clsx from 'clsx'
import React, { FC } from 'react'
import { Line } from 'react-chartjs-2'

import { toCurrencyFormat } from '@/common/utils'
import paymentImg from '@/features/investments/assets/payment.svg'
import { InvestmentsHistory } from '@/features/investments/components'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import {
  Button,
  Col,
  FontWeight,
  Heading,
  HeadingTypes,
  Row,
  Select,
  Text,
  TextAlign,
  TextTypes
} from '@/packages/ui'

import styles from './MakeInvestmentsView.module.scss'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler
)

const options = {
  scales: {
    x: {
      grid: {
        display: false,
        drawBorder: false
      },
      ticks: {
        color: '#242424'
      }
    },
    y: {
      beginAtZero: true,
      border: { dash: [4, 4], color: 'transparent' },
      grid: {
        drawBorder: false,
        showLabelBackdrop: false,
        drawTicks: false
      },
      ticks: {
        padding: 10,
        color: '#242424'
      }
    }
  },
  maintainAspectRatio: false,
  elements: {
    point: {
      radius: 0
    }
  },
  responsive: true
}

const labels = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
]

export const data = {
  labels,
  datasets: [
    {
      fill: true,
      lineWidth: 1,
      label: 'Payment received',
      data: labels.map(() => Math.floor(Math.random() * 11) + 5),
      borderColor: '#618CFA',
      backgroundColor: 'rgba(97,140,250,0.2)'
    },
    {
      fill: true,
      lineWidth: 1,
      label: 'Sent payment',
      data: labels.map(() => Math.floor(Math.random() * 11) + 5),
      borderColor: '#F5B325',
      backgroundColor: 'rgba(245,179,37,0.2)'
    }
  ]
}

const MakeInvestmentsView: FC = () => {
  const values = {
    received: 10000,
    made: 123325.66
  }
  return (
    <>
      <section className={styles.topSection}>
        <Heading type={HeadingTypes.H3}>Summary</Heading>
        <div className={clsx(styles.statistics, 'tw-mt-[20px]')}>
          <Col items="stretch" gap={18} className="tw-overflow-hidden">
            <Row gap={18} items="stretch">
              <Col className={styles.statsItem}>
                <Text
                  className="tw-text-[22px]"
                  weight={FontWeight.BOLD}
                  color={Color.primary400}
                >
                  $ {toCurrencyFormat(values.received)}
                </Text>
                <Text
                  className="tw-text-[16px]"
                  weight={FontWeight.SEMIBOLD}
                  color={Color.neutral500}
                >
                  Investments received
                </Text>
              </Col>
              <Col className={styles.statsItem}>
                <Text
                  className="tw-text-[22px]"
                  weight={FontWeight.BOLD}
                  color={Color.warning300}
                >
                  $ {toCurrencyFormat(values.made)}
                </Text>
                <Text
                  className="tw-text-[16px]"
                  weight={FontWeight.SEMIBOLD}
                  color={Color.neutral500}
                >
                  Investments made
                </Text>
              </Col>
            </Row>
            <div className={styles.chart}>
              <Row justify="between" items="center">
                <Text className="tw-text-[16px]" weight={FontWeight.SEMIBOLD}>
                  Monthly Balance
                </Text>
                <Row gap={5} items="center">
                  <SharedIcons.Calendar />
                  <Select
                    name="year"
                    small
                    placeholder="Year"
                    options={[
                      { value: '2020', label: '2020' },
                      { value: '2021', label: '2021' },
                      { value: '2022', label: '2022' },
                      { value: '2023', label: '2023' }
                    ]}
                    value="2023"
                    className={styles.select}
                  />
                </Row>
              </Row>
              <Row
                gap={15}
                items="start"
                justify="between"
                className={styles.chartRow}
              >
                <ul>
                  <li>Payment received</li>
                  <li>Sent payment</li>
                </ul>
                <div className={styles.chartWrapper}>
                  <Line options={options} data={data} />
                </div>
              </Row>
            </div>
          </Col>
          <Col
            className={styles.addPaymentMethod}
            justify="between"
            items="center"
          >
            <Col items="center" className={styles.paymentMethodInner} gap={20}>
              <img src={paymentImg} alt="Payment icon" />
              <Heading type={HeadingTypes.H3}>
                You don&apos;t have any payment methods added
              </Heading>
              <Text type={TextTypes.BODY_DEFAULT} align={TextAlign.CENTER}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod.
              </Text>
            </Col>
            <Button width="full" appearance="primary" className={styles.addBtn}>
              <Row items="center" gap={10}>
                <span className={styles.iconWrapper}>
                  <SharedIcons.Plus size={10} color={Color.primary400} />
                </span>
                Add Payment Methods
              </Row>
            </Button>
          </Col>
        </div>
      </section>
      <section className={styles.tableSection}>
        <Heading type={HeadingTypes.H3}>Investment History</Heading>
        <InvestmentsHistory />
      </section>
    </>
  )
}

export default MakeInvestmentsView
