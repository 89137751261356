import PaymentFailed from './payment-failed.svg'
import PaymentIcon from './payment-icon.svg'
import PaymentProcessing from './payment-processing.svg'
import PaymentSuccess from './payment-success.png'
import PaymentMethods from './payment-methods.png'
import Security from './security.svg'

export const PaymentImages = {
  PaymentSuccess,
  PaymentFailed,
  PaymentIcon,
  PaymentProcessing,
  PaymentMethods,
  Security
}
