import clsx from 'clsx'
import React, { FC, ReactNode } from 'react'

import { Color } from '@/packages/palette'
import { Text } from '@/packages/ui'
import { TextTypes } from '@/packages/ui/typography'

import styles from './styles.module.scss'

interface IProps {
  children?: ReactNode
  errors?: string | string[] | unknown
  color?: Color
  className?: string
}

export const FormItem: FC<IProps> = ({
  children,
  errors,
  color,
  className
}: IProps) => {
  const isError = Boolean(
    (typeof errors === 'string' && errors.trim() !== '') ||
      (Array.isArray(errors) && errors.length >= 1)
  )

  return (
    <div className={clsx(styles.formItem, className)}>
      {children}
      <div className={clsx(styles.errorContainer, isError && styles.active)}>
        <Text type={TextTypes.BODY_DEFAULT} className={color || styles.error}>
          {Array.isArray(errors) ? errors[0] : errors}
        </Text>
      </div>
    </div>
  )
}
