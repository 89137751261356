import clsx from 'clsx'
import React, { FC } from 'react'

import { FormatterHelper } from '@/common/helpers'
import { Color } from '@/packages/palette'
import { Heading, HeadingTypes, Row, Text, TextTypes } from '@/packages/ui'

import styles from './PaymentDetails.module.scss'

interface PaymentDetailsProps {
  cashAmount: number
  platformFee?: number
  stripeFee?: number
  className?: string
}

const PaymentDetails: FC<PaymentDetailsProps> = ({
  cashAmount,
  platformFee = 0,
  stripeFee = 0,
  className
}: PaymentDetailsProps) => {
  const totalAmount = cashAmount + platformFee + stripeFee

  const listItems = [
    {
      text: 'SAFE note amount',
      value: cashAmount
    },
    {
      text: 'Bank fee (1%)',
      value: stripeFee
    },
    {
      text: "MySAFEnotes' Fee",
      value: platformFee
    },
    {
      text: 'Total Amount',
      value: totalAmount
    }
  ]

  return (
    <>
      <div className={clsx(styles.detailsSection, className)}>
        <Heading type={HeadingTypes.H4}>Investment summary</Heading>

        <div className={styles.details}>
          <ul>
            {listItems.map((item, index) => (
              <li key={index}>
                <Row items="center" justify="between" gap={20}>
                  <Row items="center" gap={10}>
                    <Text type={TextTypes.BODY_SMALL} color={Color.neutral400}>
                      {item.text}
                    </Text>
                  </Row>
                  <span className={styles.itemPrice}>
                    {FormatterHelper.toCurrency(item.value)}
                  </span>
                </Row>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  )
}

export default PaymentDetails
