const ROUTES = {
  HOME: '/',
  PRICING: '/pricing',
  AGENCIES: '/agencies',
  ENTREPRENEURS: '/entrepreneurs',
  ANGELS: '/angels',

  LOGIN: '/auth/login',
  SIGN_UP: '/auth/sign-up',
  FORGOT_PASSWORD: '/auth/forgot-password',
  COMPLETE_SIGN_UP: '/auth/sign-up/complete',
  VERIFY_EMAIL: '/auth/verify/complete',
  OAUTH_LOGIN_CALLBACK: '/auth/oauth-login-callback',
  LOGOUT: '/logout',

  DASHBOARD: '/dashboard',
  DASHBOARD_COMPANY: '/dashboard/:id',
  DASHBOARD_ANGEL_DETAILS: '/dashboard/:id/:innerCompanyId',

  PROFILE_COMMON: '/profile/:page',
  PROFILE: '/profile/user-settings',
  PAYMENT_SETTINGS: '/profile/payment',
  SUPPORT: '/profile/support',

  COMPANY_PROFILE: '/company-profile',

  CREATE_SAFE: '/:companyId/create-safe-note',
  VIEW_SAFE: '/safe-note/:id',
  PREVIEW_SAFE: '/safe-note/preview', // ?token=... to allow fetch safe note
  MY_SAFE: '/my-safes',
  REQUEST_SAFE: '/request-safe',
  MAKE_INVESTMENT: '/make-investment',
  ALL_SAFES: '/:id/all-safe-notes',

  CREATE_TERMS_SHEET: '/:companyId/create-term-sheet',
  VIEW_TERM_SHEET: '/term-sheet/:id'
}

export default ROUTES
