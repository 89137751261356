import React, { FC } from 'react'

import { ModalContentBox, ModalFooter } from '@/common/components'
import { SafeForItem } from '@/features/safe-note'
import { SafeNoteImages } from '@/features/safe-note/assets'
import { IModalWithCloseFn, Modal } from '@/packages/ui'
import { useNavigator } from '@/router'

import styles from './SelectSafeRecipientModal.module.scss'

interface IProps extends IModalWithCloseFn {
  companyId: string
}

const SelectSafeRecipientModal: FC<IProps> = (props) => {
  const { closeModal, companyId } = props

  const navigator = useNavigator()

  const toCreateSafe = () => {
    navigator.toCreateSafeNote(companyId)
  }

  return (
    <Modal visible id="select-safe-recipient" size="sm" setVisible={closeModal}>
      <ModalContentBox
        name="Select SAFE Recipient"
        title="Choose who will receive this SAFE note"
        description="Select which description matches the recipient of this SAFE."
      >
        <div className={styles.itemsHolder}>
          <SafeForItem
            checked
            title="Angel"
            description="Angel investors provide seed funding in exchange for future company shares"
            image={SafeNoteImages.AngelBig}
          />
          <SafeForItem
            disabled
            title="Freelancer"
            description="Freelancers provide services in exchange for future company shares."
            image={SafeNoteImages.FreelancerBig}
          />
        </div>

        <ModalFooter
          leftButtonText="Cancel"
          leftButtonAction={closeModal}
          rightButtonText="Next"
          rightButtonActon={toCreateSafe}
        />
      </ModalContentBox>
    </Modal>
  )
}

export default SelectSafeRecipientModal
