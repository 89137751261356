import React, { FC } from 'react'

import acceptedTermSheetIcon from '@/features/term-sheet/assets/images/term-sheet-accepted.png'
import { Color } from '@/packages/palette'
import {
  Button,
  Col,
  Heading,
  HeadingTypes,
  IModalWithCloseFn,
  Modal,
  Text,
  TextAlign,
  TextTypes
} from '@/packages/ui'

const MODAL_ID = 'term-sheet-accepted-modal'

const TermSheetAcceptedModal: FC<IModalWithCloseFn> = (props) => {
  const { closeModal } = props

  return (
    <Modal size="sm" id={MODAL_ID} visible setVisible={closeModal}>
      <Col items="center" gap={24}>
        <img width={255} src={acceptedTermSheetIcon} alt="Accepted" />

        <Col items="center" gap={6}>
          <Heading type={HeadingTypes.H3} align={TextAlign.CENTER}>
            Term sheet accepted!
          </Heading>

          <Text
            type={TextTypes.BODY_DEFAULT}
            color={Color.neutral500}
            align={TextAlign.CENTER}
          >
            You’ve accepted the term sheet. You’ll be notified once the SAFE is
            created.
          </Text>
        </Col>

        <Button uppercase width="default" onClick={closeModal}>
          BACK TO DASHBOARD
        </Button>
      </Col>
    </Modal>
  )
}

export default TermSheetAcceptedModal
