import React, { FC } from 'react'

import reminderImg from '@/features/dashboard/assets/images/reminder.svg'
import { Color } from '@/packages/palette'
import {
  Button,
  Heading,
  HeadingTypes,
  Modal,
  Text,
  TextAlign,
  TextTypes
} from '@/packages/ui'

import styles from './ResendEmailModal.module.scss'

interface ResendEmailProps {
  closeModal: () => void
}

const ResendEmailModal: FC<ResendEmailProps> = (props) => {
  const { closeModal } = props

  return (
    <Modal size="sm" id="addCompanyModal" visible setVisible={closeModal}>
      <div className={styles.wrapper}>
        <img
          src={reminderImg}
          alt="identity verification"
          className="tw-mb-[32px]"
        />
        <Heading
          type={HeadingTypes.H3}
          align={TextAlign.CENTER}
          className="tw-mb-[12px]"
        >
          Thank you! Email has been sent.
        </Heading>
        <Text
          className="tw-mb-[32px]"
          type={TextTypes.BODY_DEFAULT}
          color={Color.neutral500}
          align={TextAlign.CENTER}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua, dolor sit
          amet, consectetur adipiscing elit.
        </Text>
        <Button
          width="default"
          type="button"
          appearance="primary"
          uppercase
          onClick={closeModal}
        >
          Back to safe
        </Button>
      </div>
    </Modal>
  )
}

export default ResendEmailModal
