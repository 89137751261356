import { IStep } from '@/packages/ui/stepper/types'

export default [
  {
    id: 'recipient',
    title: 'Investors',
    valid: null
  },
  {
    id: 'terms',
    title: 'Terms',
    valid: null
  },
  {
    id: 'reviewAndSign',
    title: 'Review and Sign',
    valid: null
  }
] as IStep[]
