import React, { FC } from 'react'

import { IconProps, withIcon } from '@/common/hocs'

const RecordPaymentIcon: FC<IconProps> = withIcon((props) => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" {...props}>
    <path
      d="M17.3337 5.8665V14.1332C17.3337 15.3998 17.217 16.2998 16.917 16.9415C16.917 16.9498 16.9086 16.9665 16.9003 16.9748C16.717 17.2082 16.4753 17.3248 16.192 17.3248C15.7503 17.3248 15.217 17.0332 14.642 16.4165C13.9587 15.6832 12.9086 15.7415 12.3086 16.5415L11.467 17.6582C11.1337 18.1082 10.692 18.3332 10.2503 18.3332C9.80866 18.3332 9.36697 18.1082 9.03364 17.6582L8.18368 16.5332C7.59201 15.7415 6.55032 15.6832 5.86698 16.4082L5.85864 16.4165C4.91698 17.4248 4.08368 17.5748 3.60034 16.9748C3.59201 16.9665 3.58366 16.9498 3.58366 16.9415C3.28366 16.2998 3.16699 15.3998 3.16699 14.1332V5.8665C3.16699 4.59984 3.28366 3.69984 3.58366 3.05817C3.58366 3.04984 3.58368 3.0415 3.60034 3.03317C4.07534 2.42484 4.91698 2.57484 5.85864 3.58317L5.86698 3.59151C6.55032 4.31651 7.59201 4.25817 8.18368 3.4665L9.03364 2.34151C9.36697 1.89151 9.80866 1.6665 10.2503 1.6665C10.692 1.6665 11.1337 1.89151 11.467 2.34151L12.3086 3.45817C12.9086 4.25817 13.9587 4.3165 14.642 3.58317C15.217 2.9665 15.7503 2.67484 16.192 2.67484C16.4753 2.67484 16.717 2.79984 16.9003 3.03317C16.917 3.0415 16.917 3.04984 16.917 3.05817C17.217 3.69984 17.3337 4.59984 17.3337 5.8665Z"
      stroke="#525456"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.33398 8.5415H14.0007"
      stroke="#525456"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.33398 11.4585H12.334"
      stroke="#525456"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
))

export default RecordPaymentIcon
