import React, { FC, useState } from 'react'

import { ArrowNavigation } from '@/common/components'
import { SafesList } from '@/features/dashboard/modals/IncomingSafesModal/parts'
import { IPendingTermSheet } from '@/features/term-sheet/api/types'
import { IModalWithCloseFn, Modal, Row } from '@/packages/ui'

import styles from './IncomingTermSheetsModal.module.scss'

const MODAL_ID = 'pending-termsheets-modal'

interface IncomingTermSheetsModalProps extends IModalWithCloseFn {
  termSheets: IPendingTermSheet[]
}

const IncomingTermSheetsModal: FC<IncomingTermSheetsModalProps> = (props) => {
  const { termSheets, closeModal } = props

  const [activeTermSheet, setActiveTermSheet] = useState<number>(0)

  const currentTermsheet = termSheets?.[activeTermSheet]

  if (!Array.isArray(termSheets) || !currentTermsheet) {
    return null
  }

  return (
    <Modal size="sm" id={MODAL_ID} visible setVisible={closeModal}>
      {termSheets.length > 1 && (
        <Row className={styles.navigation}>
          <ArrowNavigation
            active={activeTermSheet}
            setActive={setActiveTermSheet}
            size={termSheets.length}
          />
        </Row>
      )}

      <SafesList
        id={currentTermsheet.id}
        isTermSheet
        mfn={currentTermsheet.mfn}
        senderCompany={currentTermsheet.senderCompany}
        amount={currentTermsheet.roundAmount}
        discount={currentTermsheet.discountRate}
        valuation={currentTermsheet.valuationCap}
        sender={currentTermsheet.sender}
      />
    </Modal>
  )
}

export default IncomingTermSheetsModal
