import React, { FC } from 'react'

import { TermsList } from '@/features/safe-note'
import { TermsProps } from '@/features/term-sheet/layout/CreateTermSheetLayout/CreateTermSheetLayout'
import { Color } from '@/packages/palette'
import {
  Col,
  Heading,
  HeadingTypes,
  Text,
  TextAlign,
  TextTypes
} from '@/packages/ui'

interface TermSheetTermsProps {
  terms: TermsProps
  onChange: (values: TermsProps) => void
}

const TermSheetTerms: FC<TermSheetTermsProps> = (props) => {
  const { terms, onChange } = props

  return (
    <Col items="center">
      <Heading
        type={HeadingTypes.H2}
        color={Color.neutral500}
        align={TextAlign.CENTER}
      >
        Do you want to set terms?
      </Heading>

      <Text
        type={TextTypes.BODY_DEFAULT}
        color={Color.neutral400}
        align={TextAlign.CENTER}
        className="tw-mt-8"
      >
        You can choose between using an MFN, or setting your own, custom terms.
      </Text>

      <Col className="tw-mt-30">
        <TermsList {...terms} onSelect={onChange} />
      </Col>
    </Col>
  )
}

export default TermSheetTerms
