import { action, computed, makeObservable, observable, runInAction } from 'mobx'

import { getErrorMessage } from '@/common/api/helpers'
import { ToastService } from '@/common/services'
import { RootStoreType } from '@/common/store/store'

export interface ServiceOptions {
  onSuccess?: (data?: any) => void
  onError?: () => void
  onSettled?: () => void
}

export type ExecutableArgs<T = any> = {
  data: T
  options?: ServiceOptions
}

type Executable<Data, ReturnType> = (
  args: ExecutableArgs<Data>
) => Promise<ReturnType>

type ExecuteOptions = {
  showToastOnError?: boolean
  loaderName?: string
  manualLoader?: boolean
  manualOnSuccess?: boolean
}

const DEFAULT_ERROR_MESSAGE =
  'Something goes wrong while doing this request. Please, try again later...'

class StoreHandler {
  rootStore: RootStoreType

  @observable private $loading: Record<string, boolean> = {}

  constructor(rootStore: RootStoreType) {
    this.rootStore = rootStore
    makeObservable(this)
  }

  @computed
  get loading(): boolean {
    return Object.values(this.$loading).some((loading) => loading)
  }

  @computed
  get functionLoading(): Record<string, boolean> {
    // return the loading state for each function
    return this.$loading
  }

  @action
  public setLoading = (loaderName: string, loading: boolean) => {
    runInAction(() => {
      this.$loading[loaderName] = loading
    })
  }

  execute<Data, ReturnType = any>(
    executable: Executable<Data, ReturnType>,
    optionsOrLoaderName?: string | ExecuteOptions
  ) {
    const options: ExecuteOptions =
      typeof optionsOrLoaderName === 'object' ? optionsOrLoaderName : {}

    const {
      showToastOnError = true,
      loaderName: optionsLoaderName,
      manualLoader,
      manualOnSuccess
    } = options

    const $loaderName: string =
      typeof optionsOrLoaderName === 'string'
        ? (optionsOrLoaderName as string)
        : optionsLoaderName || 'global'

    return async (args?: ExecutableArgs<Data>): Promise<ReturnType> => {
      if (!manualLoader) {
        this.setLoading($loaderName, true)
      }

      try {
        const result = await executable(args as ExecutableArgs<Data>)

        if (!manualOnSuccess) {
          args?.options?.onSuccess?.(result)
        }
        return result
      } catch (error: any) {
        const errorMessage: string =
          getErrorMessage(error) || DEFAULT_ERROR_MESSAGE

        if (showToastOnError) {
          ToastService.showWarning(errorMessage.toString())
        }

        if (error.statusCode === 401) {
          await this.rootStore.auth.logout()
        }

        args?.options?.onError?.()

        throw new Error(error)
      } finally {
        if (!manualLoader) {
          this.setLoading($loaderName, false)
        }
        args?.options?.onSettled?.()
      }
    }
  }
}

export default StoreHandler
