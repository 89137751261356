import React, { FC } from 'react'

import { WarningModal } from '@/common/modals'
import { StoreType, withStore } from '@/common/store'
import { ITermSheet, TermSheetStatus } from '@/features/term-sheet/api/types'
import { TermSheetActions } from '@/features/term-sheet/assets/icons'
import { useModal } from '@/packages/hooks'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import { Disabled, Row, Tooltip } from '@/packages/ui'
import { useNavigator } from '@/router'

import styles from './TermSheetActionsColumn.module.scss'

interface TermSheetActionsColumnProps {
  row: ITermSheet
  isEntrepreneur: boolean
}

const mapStateToProps = ({ termSheet }: StoreType) => ({
  deleteSheet: termSheet.delete
})

const TermSheetActionsColumn: FC<
  TermSheetActionsColumnProps & ReturnType<typeof mapStateToProps>
> = ({ row, isEntrepreneur, deleteSheet }) => {
  const { visible, openModal, closeModal } = useModal<boolean>(false)

  const navigate = useNavigator()

  const isAccepted = isEntrepreneur
    ? row.recipients.some(
        (recipient) => recipient.status === TermSheetStatus.Accepted
      )
    : row.termSheetUser.status === TermSheetStatus.Accepted

  const deleteTermSheet = () => {
    deleteSheet({ data: { id: row.id } })
  }

  return (
    <Row items="center">
      {visible && (
        <WarningModal
          id="cancel-termsheet"
          closeModal={closeModal}
          description="All of your progress will be lost."
          title="Are you sure you want to delete this term sheet?"
          leftButtonText="Cancel"
          rightButtonText="Delete"
          rightButtonAction={deleteTermSheet}
        />
      )}

      {isEntrepreneur && (
        <Tooltip content="View summary">
          <div
            className={styles.actionItem}
            onClick={() =>
              navigate.toTermSheet(row.id, { activeTab: 'summary' })
            }
          >
            <TermSheetActions.ViewSummary size={21} color={Color.neutral400} />
          </div>
        </Tooltip>
      )}

      <Tooltip content="View document">
        <div
          className={styles.actionItem}
          onClick={() =>
            navigate.toTermSheet(row.id, { activeTab: 'document' })
          }
        >
          <TermSheetActions.ViewTermSheet size={21} color={Color.neutral400} />
        </div>
      </Tooltip>

      {isEntrepreneur && (
        <Disabled disabled={!isAccepted}>
          <Tooltip content="Create SAFE">
            <div className={styles.actionItem}>
              <TermSheetActions.Add size={21} color={Color.neutral400} />
            </div>
          </Tooltip>
        </Disabled>
      )}

      <Disabled disabled={isAccepted} onClick={() => openModal()}>
        <Tooltip content="Delete">
          <div className={styles.actionItem}>
            <SharedIcons.Trash size={23} color={Color.neutral400} />
          </div>
        </Tooltip>
      </Disabled>
    </Row>
  )
}

export default withStore(mapStateToProps)(TermSheetActionsColumn)
