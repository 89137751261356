import WithGoal from './with-goal.png'
import WithoutGoal from './without-goal.png'
import PartOfRound from './part-of-round.png'
import NotPartOfRound from './not-part-of-round.png'

export const RoundIcons = {
  WithGoal,
  WithoutGoal,
  PartOfRound,
  NotPartOfRound
}
