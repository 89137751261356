import React, { FC, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import { StaticIcons } from '@/assets/icons'
import { StoreType, withStore } from '@/common/store'
import {
  LeftSide,
  RightSide
} from '@/features/auth/views/VerifyEmailView/Images'
import { Color } from '@/packages/palette'
import {
  Button,
  FontWeight,
  Heading,
  HeadingTypes,
  Text,
  TextAlign,
  TextTypes
} from '@/packages/ui'
import { useNavigator } from '@/router'

import mainImage from './Images/verified-img.png'
import styles from './VerifyEmailView.module.scss'

const mapStateToProps = ({ auth }: StoreType) => ({
  isLoggedIn: auth.isLoggedIn
})

type VerifyEmailProps = ReturnType<typeof mapStateToProps>

const VerifyEmailView: FC<VerifyEmailProps> = (props) => {
  const { isLoggedIn } = props
  const [searchParams] = useSearchParams()
  const email = searchParams.get('email')

  const navigate = useNavigator()

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!email) {
      if (isLoggedIn) {
        return navigate.toDashboard()
      }

      return navigate.toLogin()
    }
  }, [email, isLoggedIn])

  return (
    <div className={styles.wrapper}>
      <LeftSide className={styles.leftImage} />

      <div className={styles.content}>
        <div className={styles.contentInner}>
          <StaticIcons.Logo className={styles.logo} />
          <div className={styles.textInfo}>
            <Heading
              type={HeadingTypes.H1}
              align={TextAlign.CENTER}
              weight={FontWeight.BLACK}
            >
              Success!
            </Heading>
            <Text
              type={TextTypes.BODY_DEFAULT}
              align={TextAlign.CENTER}
              color={Color.neutral500}
            >
              Your email <b>{email}</b> has been confirmed. Hit login to get a
              first look at your new workspace. We think you’re really gonna
              like it. Happy SAFE note-ing!
            </Text>
            <Button
              appearance="primary"
              width="fit"
              uppercase
              onClick={() =>
                isLoggedIn ? navigate.toDashboard() : navigate.toLogin()
              }
            >
              Login
            </Button>
          </div>
          <img
            src={mainImage}
            alt="email verified"
            className={styles.guyImage}
          />
        </div>
      </div>

      <RightSide className={styles.rightImage} />
    </div>
  )
}

export default withStore(mapStateToProps)(VerifyEmailView)
