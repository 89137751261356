import clsx from 'clsx'
import { noop } from 'lodash'
import React, { CSSProperties, FC, PropsWithChildren, ReactNode } from 'react'

import { TooltipKind } from '@/packages/ui'

import { Row } from '../../grid'
import { Spacer } from '../../spacer'
import { Tooltip } from '../../tooltip'
import { FontWeight, TextAlign, TextTypes } from '../enums'
import styles from './Text.module.scss'

export interface ITextProps {
  type?: TextTypes
  weight?: FontWeight

  // just a simple class, but for better readability we call it color
  color?: string | string[]
  align?: TextAlign
  children?: ReactNode
  clickable?: boolean
  tooltip?: string | ReactNode
  tooltipTitle?: string

  // if false, all text become a trigger for tooltip
  // if true then icon appears near the text
  showTooltipTriggerIcon?: boolean
  asSpan?: boolean
  style?: CSSProperties
  className?: string
  onClick?: () => void
}

const Text: FC<PropsWithChildren<ITextProps>> = ({
  type = TextTypes.BODY_DEFAULT,
  weight = FontWeight.REGULAR,
  color,
  clickable = false,
  align = TextAlign.LEFT,
  asSpan = false,
  tooltip,
  showTooltipTriggerIcon = false,
  tooltipTitle,
  className,
  onClick,
  style,
  children
}: ITextProps) => {
  const isBody = type === TextTypes.BODY_MAIN || type === TextTypes.BODY_DEFAULT

  const baseClasses = [
    styles.inlineBlock,
    styles[type],
    weight,
    `text-${align}`,
    className
  ]

  if (color && Array.isArray(color)) {
    baseClasses.unshift(...color)
  } else if (color) {
    baseClasses.unshift(color)
  }

  if (clickable) baseClasses.unshift(styles.clickable)

  const baseProps = {
    className: clsx(baseClasses),
    onClick: clickable ? onClick : noop
  }

  let Component = (
    <span {...baseProps} style={style}>
      {children}
    </span>
  )

  if (isBody && !asSpan) {
    Component = (
      <p {...baseProps} style={style}>
        {children}
      </p>
    )
  }

  if (!!tooltip && showTooltipTriggerIcon) {
    return (
      <Row items="center">
        {Component}
        <Spacer size={5} vertical />
        <Tooltip
          content={tooltip}
          title={tooltipTitle}
          interactive
          kind={TooltipKind.INFO}
        />
      </Row>
    )
  }

  if (!!tooltip) {
    return (
      <Tooltip content={tooltip} interactive title={tooltipTitle}>
        {Component}
      </Tooltip>
    )
  }

  return Component
}

export default Text
