import React, { FC } from 'react'

import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import { Disabled, Row, Text, TextTypes } from '@/packages/ui'

import styles from './ArrowNavigation.module.scss'

interface ArrowNavigationProps {
  active: number
  setActive: (index: number) => void
  size: number
}

const ArrowNavigation: FC<ArrowNavigationProps> = (props) => {
  const { active, setActive, size } = props

  return (
    <Row items="center" justify="center" gap={24}>
      <Disabled disabled={active === 0} onClick={() => setActive(active - 1)}>
        <div className={styles.arrow}>
          <SharedIcons.MinimalisticArrow
            size={12}
            color={Color.neutral0}
            className={styles.leftArrow}
          />
        </div>
      </Disabled>
      <Row items="center">
        <Text type={TextTypes.BODY_DEFAULT} color={Color.neutral500}>
          {active + 1}/
        </Text>
        <Text type={TextTypes.BODY_DEFAULT} color={Color.neutral300}>
          {size}
        </Text>
      </Row>
      <Disabled
        disabled={active === size - 1}
        onClick={() => setActive(active + 1)}
      >
        <div className={styles.arrow}>
          <SharedIcons.MinimalisticArrow size={12} color={Color.neutral0} />
        </div>
      </Disabled>
    </Row>
  )
}

export default ArrowNavigation
