const port = import.meta.env.VITE_PORT
const apiUrl = import.meta.env.VITE_API_URL
const wssUrl = import.meta.env.VITE_WSS_URL
const googleAuthUrl = import.meta.env.VITE_GOOGLE_AUTH_URL
const linkedInAuthUrl = import.meta.env.VITE_LINKEDIN_AUTH_URL
const stripePkKey = import.meta.env.VITE_STRIPE_PK_KEY
const nodeEnv = import.meta.env.VITE_NODE_ENV

export default {
  port,
  apiUrl,
  wssUrl,
  googleAuthUrl,
  linkedInAuthUrl,
  stripePkKey,
  nodeEnv
}
