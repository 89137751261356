import React, { FC, useCallback, useState } from 'react'

import { asideInfo } from '@/features/auth/views/CompleteSignUpView/CompleteSignUpView'
import {
  CompleteSignUpContextProvider,
  SignUpSteps
} from '@/features/auth/views/CompleteSignUpView/context'
import { AddNewProfile } from '@/features/dashboard'
import { ProfileTypes } from '@/features/user/enums'
import { Color } from '@/packages/palette'
import {
  Col,
  Heading,
  HeadingTypes,
  IModalWithCloseFn,
  Modal,
  Text,
  TextTypes
} from '@/packages/ui'

import styles from './AddNewProfileModal.module.scss'

type AddNewProfileModal = IModalWithCloseFn & {
  predefinedRole?: ProfileTypes
}

const MODAL_ID = 'add-new-profile-modal'

const AddNewProfileModal: FC<AddNewProfileModal> = ({
  closeModal,
  predefinedRole
}) => {
  const [step, setStep] = useState<SignUpSteps>(SignUpSteps.SELECT_ROLE)

  const handleScrollToTOp = useCallback(() => {
    document.getElementById(`${MODAL_ID}-modal-body`)?.scrollTo({ top: 0 })
  }, [])

  return (
    <Modal
      id={MODAL_ID}
      visible
      size="lg"
      modalInnerClassName="tw-w-full"
      setVisible={closeModal}
      className={styles.modalStyles}
    >
      <div className={styles.wrapper}>
        <div className={styles.form}>
          <CompleteSignUpContextProvider step={step} setStep={setStep}>
            <AddNewProfile
              onSuccess={closeModal}
              secondaryView
              predefinedRole={predefinedRole}
              onScrollToTop={handleScrollToTOp}
            />
          </CompleteSignUpContextProvider>
        </div>
        <div className={styles.stepImage}>
          <img src={asideInfo[step].img} alt="" />

          <Col gap={12}>
            <Heading type={HeadingTypes.H2} color={Color.neutral500}>
              {asideInfo[step].title}
            </Heading>

            <Text type={TextTypes.BODY_DEFAULT} color={Color.neutral500}>
              {asideInfo[step].description}
            </Text>
          </Col>
        </div>
      </div>
    </Modal>
  )
}

export default AddNewProfileModal
