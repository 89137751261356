import React, { FC, useEffect, useState } from 'react'
import { Outlet, useSearchParams } from 'react-router-dom'

import { ConditionalRender } from '@/common/components'
import { WarningModal } from '@/common/modals'
import { ToastService } from '@/common/services'
import { StoreType, withStore } from '@/common/store'
import { AddNewProfileModal } from '@/features/dashboard/modals'
import { ITermSheet, TermSheetStatus } from '@/features/term-sheet/api/types'
import {
  SelectCompanyForTermsheet,
  TermSheetAcceptedModal,
  WhoCreatesSafeModal
} from '@/features/term-sheet/components'
import { ProfileTypes } from '@/features/user/enums'
import { useModal } from '@/packages/hooks'
import { Color } from '@/packages/palette'
import {
  Button,
  Row,
  Tab,
  Tabs,
  Text,
  TextArea,
  TextTypes
} from '@/packages/ui'
import { useNavigator } from '@/router'

import styles from './ViewTermSheetLayout.module.scss'

const mapStateToProps = ({ user, termSheet }: StoreType) => ({
  me: user.me,
  companies: user.sortedCompanies.angel,
  updateTermSheet: termSheet.updateTermSheet,
  loading: termSheet.functionLoading.update
})

const ViewTermSheetLayout: FC<ReturnType<typeof mapStateToProps>> = (props) => {
  const { me, companies, updateTermSheet, loading } = props

  const navigate = useNavigator()

  const [comment, setComment] = useState<string | undefined>()
  const [activeTab, setActiveTab] = useState<string>()
  const [selectedCompany, setSelectedCompany] = useState<string | undefined>()
  const [termSheet, setTermSheet] = useState<ITermSheet | undefined>(undefined)

  const { visible, openModal, closeModal } = useModal<
    | 'selectCompany'
    | 'whoCreatesSafe'
    | 'decline'
    | 'createProfile'
    | 'accepted'
    | 'permanentlyDelete'
  >()

  const [searchParams] = useSearchParams()
  const tabFromQuery = searchParams.get('activeTab')

  const meAsRecipient = termSheet?.recipients?.find(
    (item) => item.userId === me?.id
  )

  const notAccepted =
    meAsRecipient?.status === TermSheetStatus.Pending || !meAsRecipient?.status

  const onCompanySelect = async (id: string) => {
    if (visible === 'selectCompany') {
      setSelectedCompany(id)
      openModal('whoCreatesSafe')

      return
    }

    if (termSheet?.id && id) {
      await updateTermSheet({
        data: {
          id: termSheet?.id,
          dto: {
            status: TermSheetStatus.Rejected,
            companyId: id,
            comment
          }
        }
      })

      ToastService.showSuccess(
        'You declined the term sheet, and your feedback has been sent!'
      )
      navigate.toDashboard()
    }
  }

  const decline = async () => {
    if (termSheet?.id) {
      await updateTermSheet({
        data: {
          id: termSheet?.id,
          dto: { status: TermSheetStatus.Rejected, comment }
        }
      })

      ToastService.showSuccess(
        'You declined the term sheet, and your feedback has been sent!'
      )
      navigate.toDashboard()
    }
  }

  const save = async () => {
    if (termSheet?.id && selectedCompany) {
      await updateTermSheet({
        data: {
          id: termSheet?.id,
          dto: { status: TermSheetStatus.Accepted, companyId: selectedCompany }
        }
      })

      openModal('accepted')
    }
  }

  const onClose = () => {
    if (termSheet?.sender?.id === me?.id && termSheet?.senderCompany?.id) {
      navigate.toDashboardCompany(termSheet?.senderCompany?.id)
    } else {
      navigate.toDashboard()
    }
  }

  useEffect(() => {
    if (tabFromQuery) {
      setActiveTab(tabFromQuery)
    }
  }, [tabFromQuery])

  // @ts-ignore
  return (
    <div className={styles.termsheetLayout}>
      {['selectCompany', 'permanentlyDelete'].includes(visible!) && (
        <SelectCompanyForTermsheet
          permanentlyDelete={visible === 'permanentlyDelete'}
          toCreateCompany={() => openModal('createProfile')}
          closeModal={closeModal}
          onSecondaryAction={decline}
          onMainAction={onCompanySelect}
        />
      )}

      {visible === 'createProfile' && (
        <AddNewProfileModal
          predefinedRole={ProfileTypes.ANGEL}
          closeModal={closeModal}
        />
      )}

      {visible === 'accepted' && (
        <TermSheetAcceptedModal
          closeModal={() => {
            closeModal()

            if (selectedCompany && termSheet?.senderCompany?.id) {
              navigate.toDashboardInnerCompany(
                selectedCompany,
                termSheet.senderCompany.id
              )
            } else {
              navigate.toDashboard()
            }
          }}
        />
      )}

      {visible === 'decline' && (
        <WarningModal
          loading={loading}
          id="cancel-termsheet"
          closeModal={closeModal}
          title="Are you sure you want to decline this term sheet?"
          leftButtonText="Cancel"
          rightButtonText="Decline"
          rightButtonAction={() => openModal('permanentlyDelete')}
        >
          <div className="tw-self-stretch">
            <Text type={TextTypes.BODY_DEFAULT} color={Color.neutral400}>
              If you don’t like the terms, you can send feedback to begin
              negotiations. The sender can then choose to revise the terms.
            </Text>

            <TextArea
              className="tw-mt-10"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Write your feedback here"
            />
          </div>
        </WarningModal>
      )}

      {visible === 'whoCreatesSafe' && (
        <WhoCreatesSafeModal
          save={save}
          loading={loading}
          sender={termSheet?.sender}
          me={me}
          myCompany={companies?.find((item) => item.id === selectedCompany)}
          senderCompany={termSheet?.senderCompany}
          closeModal={closeModal}
        />
      )}

      <header className={styles.header}>
        <Text
          clickable
          type={TextTypes.BODY_DEFAULT}
          color={Color.neutral300}
          className="tw-cursor-pointer"
          onClick={onClose}
        >
          Close
        </Text>

        <ConditionalRender condition={!!termSheet?.id && !meAsRecipient}>
          <Tabs
            withoutContent
            kind="simple"
            className={styles.tabsHeader}
            initialTabId={activeTab}
            onTabChange={(key: any) => setActiveTab(key)}
          >
            <Tab id="summary" title="View summary" />
            <Tab id="document" title="View document" />
          </Tabs>
        </ConditionalRender>

        <ConditionalRender condition={!!meAsRecipient?.id && notAccepted}>
          <Row items="center" gap={10}>
            <Button
              appearance="secondary"
              width="default"
              uppercase
              onClick={() => openModal('decline')}
            >
              Decline
            </Button>
            <Button
              width="default"
              uppercase
              onClick={() => openModal('selectCompany')}
            >
              Accept
            </Button>
          </Row>
        </ConditionalRender>
      </header>

      <Outlet
        context={{
          termSheet,
          setTermSheet,
          activeTab,
          isOwner: !meAsRecipient
        }}
      />
    </div>
  )
}

export default withStore(mapStateToProps)(ViewTermSheetLayout)
