import clsx from 'clsx'
import React, { FC, PropsWithChildren } from 'react'

import styles from './RequiredFieldStar.module.scss'

const RequiredFieldStar: FC<PropsWithChildren & { required?: boolean }> = ({
  children,
  required
}) => <div className={clsx(required && styles.required)}>{children}</div>

export default RequiredFieldStar
