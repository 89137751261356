import React, { FC, PropsWithChildren } from 'react'

import { ModalContentBox } from '@/common/components'
import warningImg from '@/features/dashboard/modals/InvitationsModal/images/decline-request.png'
import { Button, Modal } from '@/packages/ui'

import styles from './WarningModal.module.scss'

interface WarningModalProps {
  id: string
  closeModal?: () => void
  title: string
  description?: string
  leftButtonText?: string
  rightButtonText?: string
  loading?: boolean
  rightButtonAction?: () => void
}

const WarningModal: FC<WarningModalProps & PropsWithChildren> = (props) => {
  const {
    id,
    children,
    loading,
    closeModal,
    title,
    description,
    leftButtonText = 'Cancel',
    rightButtonAction,
    rightButtonText
  } = props

  return (
    <Modal size="sm" id={id} visible setVisible={closeModal}>
      <ModalContentBox
        imgSize={150}
        img={warningImg}
        name="Warning"
        title={title}
        description={description}
      >
        {children}

        <div className={styles.footer}>
          <Button
            uppercase
            appearance="secondary"
            width="full"
            onClick={closeModal}
          >
            {leftButtonText}
          </Button>
          <Button
            uppercase
            width="full"
            disabled={loading}
            onClick={() => {
              closeModal?.()
              rightButtonAction?.()
            }}
          >
            {loading ? 'Loading...' : rightButtonText}
          </Button>
        </div>
      </ModalContentBox>
    </Modal>
  )
}

export default WarningModal
