import React, { FC } from 'react'

import { ConditionalRender, LearnMoreLink } from '@/common/components'
import { useStore } from '@/common/store'
import { capitalize } from '@/common/utils'
import { WelcomeCharacters } from '@/features/auth/assets'
import {
  AddNewProfileModal,
  WorkspaceWelcomeModal
} from '@/features/dashboard/modals'
import { ProfileTypes } from '@/features/user/enums'
import { useModal } from '@/packages/hooks'
import { Clock } from '@/packages/icons/Icons'
import { Color } from '@/packages/palette'
import {
  Badge,
  BadgeType,
  Button,
  FontWeight,
  Heading,
  HeadingTypes,
  Row,
  Text,
  TextTypes
} from '@/packages/ui'

import styles from './DashboardEmptyScreen.module.scss'

interface DashboardEmptyScreenProps {
  type: ProfileTypes
}

const copy = {
  [ProfileTypes.ENTREPRENEUR]: {
    subtitle: 'Welcome to your (online) Entrepreneurial office.',
    subtext:
      "Looking to fundraise? You came to the right place. Use this workspace to easily create, negotiate, and send SAFEs to investors. Click below to learn more about the workspace, which is every entrepreneur's dream."
  },
  [ProfileTypes.ANGEL]: {
    subtitle: 'You’ve entered the Angel workspace.',
    subtext:
      'As an Angel, you can invest in the companies and entrepreneurs you believe in. Provide seed funding to friends and family, and help them kickstart their ideas, all from this platform.'
  },
  [ProfileTypes.ADVISOR]: {
    subtitle: 'You’re in the Advisor and Attorney workspace.',
    subtext:
      'As an Advisor or Attorney, you’re gonna love it here. Use this workspace to create and manage client SAFEs all from one place. It couldn’t get easier than this.'
  }
}

const imageMapper = {
  [ProfileTypes.ANGEL]: WelcomeCharacters.AngelWelcomePage,
  [ProfileTypes.ENTREPRENEUR]: WelcomeCharacters.EntrepreneurWelcomePage,
  [ProfileTypes.ADVISOR]: WelcomeCharacters.AdvisorWelcomePage
}

const DashboardEmptyScreen: FC<DashboardEmptyScreenProps> = (props) => {
  const { type } = props
  const user = useStore((store) => store.user.me)

  const roleName = capitalize(type)

  const {
    visible: modalActive,
    closeModal,
    openModal
  } = useModal<'add-profile' | 'learn-more'>()

  const Intro = (
    <div>
      <ConditionalRender condition={type === ProfileTypes.ADVISOR}>
        <Row items="center" gap={6} className="tw-mb-20">
          <Clock color={Color.warning300} size={24} />
          <Text
            type={TextTypes.BODY_DEFAULT}
            weight={FontWeight.MEDIUM}
            color={Color.warning300}
          >
            Coming soon...
          </Text>
        </Row>
      </ConditionalRender>

      <Row items="end" className={styles.heading}>
        <Heading weight={FontWeight.BLACK} type={HeadingTypes.H1}>
          Hey there, <br /> {user?.fullName}!
          <span className={styles.name}>
            <Badge
              type={BadgeType.WARNING}
              size="normal"
              className={styles.badge}
            >
              {roleName}
            </Badge>
          </span>
        </Heading>
      </Row>

      <Heading type={HeadingTypes.H3}>{copy[type].subtitle}</Heading>

      <Text
        type={TextTypes.BODY_DEFAULT}
        color={Color.neutral400}
        className={styles.workspaceDescription}
      >
        {copy[type].subtext}
      </Text>

      <LearnMoreLink
        className="tw-mb-40"
        onClick={() => openModal('learn-more')}
      />

      {type !== ProfileTypes.ADVISOR && (
        <Button
          width="default"
          uppercase
          onClick={() => openModal('add-profile')}
        >
          Create Company
        </Button>
      )}
    </div>
  )

  return (
    <Row gap={86} items="center" justify="between" className={styles.container}>
      {modalActive === 'add-profile' && (
        <AddNewProfileModal predefinedRole={type} closeModal={closeModal} />
      )}

      {modalActive === 'learn-more' && (
        <WorkspaceWelcomeModal type={type} closeModal={closeModal} />
      )}

      <div className={styles.leftSide}>{Intro}</div>
      <img
        src={imageMapper[type]}
        className={styles[`${type}-character`]}
        alt=""
      />
    </Row>
  )
}

export default DashboardEmptyScreen
