import 'tippy.js/animations/shift-away-subtle.css'
import '@/packages/ui/tooltip/tippy-styles.scss'

import Tippy from '@tippyjs/react'
import clsx from 'clsx'
import { AnimatePresence, motion, MotionProps } from 'framer-motion'
import React, { FC, useMemo, useRef } from 'react'
import { useOnClickOutside } from 'usehooks-ts'

import { Avatar, UserProfile } from '@/common/components'
import { useModalContext } from '@/features/modals'
import { FeedbackSentModal } from '@/features/profile-settings/modals'
import { IUser } from '@/features/user'
import { useModal } from '@/packages/hooks'
import { Color } from '@/packages/palette'
import { Button, Col, FontWeight, Row, Text, TextTypes } from '@/packages/ui'
import { useNavigator } from '@/router'

import SendFeedbackModal from '../../../features/profile-settings/modals/SendFeedbackModal/SendFeedbackModal'
import userProfileOptions from './userProfileOptions'
import styles from './UserProfilePopup.module.scss'

const POPUP_WIDTH = 280

const UserProfilePopup: FC<{ user: IUser | null }> = (props) => {
  const { user } = props
  const navigate = useNavigator()

  const { logoutModal } = useModalContext()

  const popupRef = useRef<any>()
  const elementRef = useRef<any>()

  const [open, setOpen] = React.useState(false)
  const { visible, openModal, closeModal } = useModal<
    'feedback-form' | 'feedback-sent'
  >()

  const animationOptions: MotionProps = useMemo(
    () => ({
      initial: { x: -POPUP_WIDTH },
      animate: { x: 0 },
      exit: {
        x: -POPUP_WIDTH,
        position: 'absolute'
      },
      transition: {
        duration: 0.2,
        ease: 'easeInOut'
      }
    }),
    []
  )

  const handleMenuItemClick = (id: string) => {
    setOpen(false)

    if (id === 'logout') {
      logoutModal.openModal()
      return
    }

    navigate.toProfile(id)
  }

  const openFeedbackModal = () => {
    openModal('feedback-form')
    setOpen(false)
  }

  const sendFeedback = () => {
    openModal('feedback-sent')
  }

  useOnClickOutside(popupRef, (e) => {
    if (elementRef.current.contains(e.target)) return

    if (open) {
      setOpen(false)
    }
  })

  const PopupContent = (
    <div className={clsx(styles.content)} ref={popupRef}>
      <AnimatePresence initial={false}>
        <motion.div
          key="firstScreen"
          initial={animationOptions.initial}
          animate={animationOptions.animate}
          exit={animationOptions.exit}
          transition={animationOptions.transition}
        >
          <Col items="center" className={styles.mainInfo}>
            <Avatar
              image={user?.image}
              name={user?.fullName}
              size={60}
              className="tw-mb-10"
            />
            <Text
              type={TextTypes.BODY_DEFAULT}
              weight={FontWeight.SEMIBOLD}
              color={Color.neutral500}
              className="!tw-leading-snug"
            >
              {user?.fullName}
            </Text>
            <Text
              type={TextTypes.BODY_SMALL}
              color={Color.neutral300}
              className="!tw-leading-snug"
            >
              {user?.email}
            </Text>
          </Col>

          <ul className={styles.profileOptions}>
            {userProfileOptions.map(({ id, title, icon: Icon }: any) => (
              <li
                key={id}
                onClick={() => handleMenuItemClick(id)}
                id="user-popup-option"
              >
                <Row items="center" gap={6}>
                  {Icon?.({ size: 20 })}
                  <Text type={TextTypes.BODY_SMALL} color={Color.neutral500}>
                    {title}
                  </Text>
                </Row>
              </li>
            ))}
          </ul>

          <Button
            width="full"
            id="send-feedback-btn"
            className={styles.sendFeedback}
            onClick={openFeedbackModal}
          >
            Send a feedback
          </Button>
        </motion.div>
      </AnimatePresence>
    </div>
  )

  return (
    <>
      {visible === 'feedback-form' && (
        <SendFeedbackModal onSend={sendFeedback} closeModal={closeModal} />
      )}

      {visible === 'feedback-sent' && (
        <FeedbackSentModal closeModal={closeModal} />
      )}

      <Tippy
        arrow={false}
        visible={open}
        interactive
        trigger="click"
        offset={[0, 25]}
        placement="bottom-end"
        content={PopupContent}
        animation="shift-away-subtle"
        appendTo={document.body}
        duration={500}
      >
        <div onClick={() => setOpen(!open)} ref={elementRef}>
          <UserProfile
            subscription={!!user?.activeSubscription}
            image={user?.image}
            email={user?.email || ''}
            userName={user?.fullName || ''}
          />
        </div>
      </Tippy>
    </>
  )
}

export default UserProfilePopup
