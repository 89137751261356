import clsx from 'clsx'
import Lottie from 'lottie-react'
import React, { FC, useState } from 'react'

import { LottieConfetti } from '@/assets/lottie'
import { Avatar, ConditionalRender } from '@/common/components'
import {
  BlueVerified,
  Verified
} from '@/common/components/UserProfile/VerificationStatusIcons'
import { VerificationPaymentType } from '@/common/enums'
import { StoreType, withStore } from '@/common/store'
import { ArrowDown } from '@/features/auth/assets/icons'
import plusIcon from '@/features/auth/assets/images/plus-mini.png'
import successImg from '@/features/auth/assets/images/success-subscription.png'
import { VerificationStepper } from '@/features/auth/modals/VerificationCheckmarkModal/parts'
import { ICompany } from '@/features/profile/types'
import { Color } from '@/packages/palette'
import {
  Badge,
  BadgeType,
  Button,
  Col,
  FontWeight,
  Heading,
  HeadingTypes,
  Modal,
  Row,
  Switch,
  Text,
  TextAlign,
  TextTypes,
  useStepper
} from '@/packages/ui'

import styles from './SubscriptionsModal.module.scss'

interface SubscriptionsModalProps {
  closeModal: () => void
  onSuccess?: () => void
  ownerPhoto?: string
}

const list = [
  {
    title: 'Create up to 2 SAFE notes',
    basic: true,
    premium: true
  },
  {
    title: 'Create up to 2 investor companies',
    basic: true,
    premium: true
  },
  {
    title: 'Add up to 2 team members',
    basic: true,
    premium: true
  },
  {
    title: 'Join up to 2 companies users invite you to',
    basic: true,
    premium: true
  },
  {
    title: 'Create unlimited SAFE notes',
    basic: false,
    premium: true
  },
  {
    title: 'Create unlimited investor companies',
    basic: false,
    premium: true
  },
  {
    title: 'Add unlimited team members',
    basic: false,
    premium: true
  },
  {
    title: 'Join unlimited companies users invite you to',
    basic: false,
    premium: true
  },
  {
    title: 'Share creative permissions (view, edit, create)',
    basic: false,
    premium: true
  },
  {
    title: 'Use 1 free hour of legal consultation per month',
    basic: false,
    premium: true
  },
  {
    title: 'Included company verification',
    basic: false,
    premium: true
  }
]

const mapStateToProps = ({ user }: StoreType) => ({
  updateSubscription: user.updateUserSubscription,
  loading: user.functionLoading.updateUserSubscription,
  me: user.me
})

const SubscriptionsModal: FC<
  SubscriptionsModalProps & ReturnType<typeof mapStateToProps>
> = (props) => {
  const { me, ownerPhoto, closeModal, onSuccess, updateSubscription, loading } =
    props

  const [completed, setCompleted] = React.useState<boolean>(false)
  const { currentStep, prevStep, nextStep } = useStepper(0, 2)

  const [isYearly, setYearly] = useState(true)

  const price = isYearly ? 99.99 : 9.99

  const firstStep = currentStep === 0

  const onFinish = async () => {
    await updateSubscription({
      data: {
        payload: {
          plan: 'extend',
          period: 'month'
        }
      },
      options: {
        onSuccess: () => {
          setCompleted(true)
        }
      }
    })
  }

  const onBack = () => {
    closeModal()
  }

  const Payment = (
    <VerificationStepper
      loading={loading}
      paymentOnly
      selectedPlan={VerificationPaymentType.AdvancedPlan}
      onFinish={onFinish}
      onBack={onBack}
      company={{} as ICompany}
      title="Enter your payment information"
      description="Pay to upgrade your subscription. You’re making a great decision for you and your team."
    />
  )

  const Promo = (
    <>
      <Heading
        type={HeadingTypes.H2}
        align={TextAlign.CENTER}
        color={Color.neutral500}
      >
        Upgrade your subscription and share creative permissions with your team!
      </Heading>

      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <thead>
            <tr>
              <td />
              <td className={styles.premium}>My SAFE Notes+</td>
              <td className={styles.basic}>
                Basic
                <div className={styles.selectedPlan}>
                  <Verified color={Color.success400} width={30} height={30} />
                </div>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={styles.optionName}>
                <Col gap={10} className="tw-mt-[-65px]">
                  <Row items="center" gap={20}>
                    <Heading type={HeadingTypes.H2} color={Color.neutral500}>
                      Select which plan is <br /> best for you
                    </Heading>
                    <ArrowDown />
                  </Row>
                  <Text type={TextTypes.BODY_DEFAULT} color={Color.neutral400}>
                    Use our feature chart below to determine which plan makes
                    the most sense for you.
                  </Text>
                </Col>
              </td>
              <td className={styles.premiumPriceTd}>
                <Col items="center" gap={4}>
                  <div className={styles.price}>
                    ${price} <span>/ {isYearly ? 'year' : 'month'}</span>
                  </div>

                  <Row items="center" gap={4} className="tw-mb-8">
                    <Text
                      color={Color.neutral400}
                      weight={FontWeight.SEMIBOLD}
                      type={TextTypes.BODY_EXTRA_SMALL}
                      align={TextAlign.CENTER}
                      className="tw-italic tw-uppercase"
                    >
                      Pay annually & save 20%
                    </Text>

                    <Switch
                      size="default"
                      value={isYearly}
                      onChange={() => setYearly(!isYearly)}
                    />
                  </Row>

                  <Button
                    uppercase
                    width="fit"
                    className={styles.upgradeBtn}
                    onClick={nextStep}
                  >
                    Upgrade
                  </Button>
                </Col>
              </td>
              <td>
                <Col items="center" gap={8}>
                  <div className={styles.price}>
                    $0 <span>/ month</span>
                  </div>

                  <Text
                    type={TextTypes.BODY_DEFAULT}
                    weight={FontWeight.SEMIBOLD}
                    color={Color.neutral400}
                  >
                    Current plan
                  </Text>
                </Col>
              </td>
            </tr>
            {list.map((item, index) => (
              <tr key={index}>
                <td>
                  <Text type={TextTypes.BODY_SMALL} color={Color.neutral500}>
                    {item.title}
                  </Text>
                </td>

                <td>
                  <BlueVerified size={25} color={Color.primary400} />
                </td>

                <td>
                  {item.basic ? (
                    <BlueVerified size={25} color={Color.primary400} />
                  ) : (
                    <Text
                      type={TextTypes.BODY_MAIN}
                      color={Color.neutral500}
                      weight={FontWeight.BOLD}
                    >
                      —
                    </Text>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )

  const Completed = (
    <div className={styles.completedWrapper}>
      <div>
        <Lottie
          animationData={LottieConfetti}
          className={styles.leftConfetti}
        />

        <Lottie
          animationData={LottieConfetti}
          className={styles.rightConfetti}
        />

        <Col items="center" className="tw-max-w-[400px]" justify="center">
          <img src={successImg} alt="Success" width={300} />

          <Heading
            type={HeadingTypes.H2}
            color={Color.neutral500}
            className="tw-mt-[24px]"
          >
            You’ve subscribed to mySAFEnotes+!
          </Heading>
          <Text
            type={TextTypes.BODY_DEFAULT}
            color={Color.neutral300}
            className="tw-mt-[8px]"
            align={TextAlign.CENTER}
          >
            Review your investment details, and download the SAFE and receipt
            for your records below.
          </Text>

          <Button
            uppercase
            width="default"
            onClick={onSuccess || closeModal}
            className="tw-mt-[32px]"
          >
            Close
          </Button>
        </Col>
      </div>
      <div>
        <div className={styles.card}>
          <div className={clsx(styles.background, styles.level1)} />
          <div className={clsx(styles.background, styles.level2)} />
          <div className={clsx(styles.background, styles.level3)} />
          <div className={styles.cardInner}>
            <Col items="center" gap={12} className="tw-relative tw-top-[-24px]">
              <div className={styles.imagesContainer}>
                <Avatar
                  size={55}
                  name={me?.fullName}
                  image={me?.image || ownerPhoto}
                />
                <div className={styles.logoMini}>
                  <Avatar
                    image={plusIcon}
                    size={22}
                    className="!tw-border-2 !tw-border-white"
                  />
                </div>
              </div>
              <Text
                type={TextTypes.BODY_DEFAULT}
                weight={FontWeight.BOLD}
                color={Color.neutral500}
              >
                Welcome, Plus member!
              </Text>
              <Badge type={BadgeType.WARNING} size="small">
                Plus Member
              </Badge>
            </Col>

            <Col gap={11} className={styles.listOfOptions}>
              <Row gap={10} items="center">
                <BlueVerified size={20} color={Color.primary400} />
                <Text type={TextTypes.BODY_SMALL} color={Color.neutral400}>
                  All features <b>in Basic</b>
                </Text>
              </Row>

              <Row gap={10} items="center">
                <BlueVerified size={20} color={Color.primary400} />
                <Text type={TextTypes.BODY_SMALL} color={Color.neutral400}>
                  Company verification <b>checkmark</b>
                </Text>
              </Row>

              <Row gap={10} items="center">
                <BlueVerified size={20} color={Color.primary400} />
                <Text type={TextTypes.BODY_SMALL} color={Color.neutral400}>
                  Legal consultation <b>one hour</b>
                </Text>
              </Row>

              <Row gap={10} items="center">
                <BlueVerified size={20} color={Color.primary400} />
                <Text type={TextTypes.BODY_SMALL} color={Color.neutral400}>
                  Ability to create <b>more than 2 SAFE notes</b>
                </Text>
              </Row>

              <Row gap={10} items="center">
                <BlueVerified size={20} color={Color.primary400} />
                <Text type={TextTypes.BODY_SMALL} color={Color.neutral400}>
                  Invite <b>as many team members</b> as you want
                </Text>
              </Row>
            </Col>
          </div>
        </div>

        <Heading
          type={HeadingTypes.H1}
          color={Color.neutral500}
          className={styles.asideTitle}
        >
          <span>Lorem ipsum</span> dolor sit amet, consectetu
        </Heading>
        <Text
          type={TextTypes.BODY_MAIN}
          color={Color.neutral400}
          className="tw-mt-10"
        >
          By upgraded your subscription, you’re making a great decision for you
          and your team.
        </Text>
      </div>
    </div>
  )

  return (
    <Modal
      visible
      id="subscriptions-modal"
      size="lg"
      className={
        // eslint-disable-next-line no-nested-ternary
        firstStep && !completed
          ? clsx(styles.subscriptionsModal)
          : styles.paymentStyles
      }
      modalInnerClassName={firstStep ? clsx(styles.modalInner) : 'tw-w-full'}
      setVisible={closeModal}
    >
      <ConditionalRender
        condition={currentStep === 0 && !completed}
        fallbackElement={completed ? Completed : Payment}
      >
        {Promo}
      </ConditionalRender>
    </Modal>
  )
}

export default withStore(mapStateToProps)(SubscriptionsModal)
