import { FC, useState } from 'react'

import { ConditionalRender } from '@/common/components'
import { StoreType, withStore } from '@/common/store'
import { ResendEmailModal } from '@/features/dashboard/modals'
import { useModal } from '@/packages/hooks'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import { FontWeight, Info, Row, Text, TextTypes } from '@/packages/ui'

import styles from './VerifyEmailMessage.module.scss'

const mapStateToProps = ({ user }: StoreType) => ({
  me: user.me,
  resendVerifyEmail: user.resendVerifyEmail
})

const VerifyEmailMessage: FC<ReturnType<typeof mapStateToProps>> = (props) => {
  const { me, resendVerifyEmail } = props

  const {
    visible: showEmailSentModal,
    closeModal,
    openModal
  } = useModal<boolean>(false)

  const [emailWasResent, setEmailResent] = useState(false)

  const handleResend = () => {
    resendVerifyEmail({
      data: null,
      options: {
        onSuccess: () => {
          setEmailResent(true)
          openModal()
        }
      }
    })
  }

  return (
    <>
      <ConditionalRender condition={showEmailSentModal}>
        <ResendEmailModal closeModal={closeModal} />
      </ConditionalRender>

      <ConditionalRender condition={me?.emailVerified === false}>
        <Info
          rounded
          iconSize={20}
          textType={TextTypes.BODY_SMALL}
          centerContent
          textClassName={styles.infoInner}
          className={styles.infoBlock}
        >
          <div className={styles.message}>
            <b>Verify</b>: Look for the verification email we sent (check your
            spam, too). &nbsp;
          </div>

          <ConditionalRender condition={!emailWasResent}>
            <Row inline items="center" clickable onClick={handleResend}>
              <Text
                color={Color.primary400}
                type={TextTypes.BODY_SMALL}
                weight={FontWeight.SEMIBOLD}
                asSpan
              >
                Resend email
              </Text>
              <SharedIcons.Arrow color={Color.primary400} className="tw-ml-5" />
            </Row>
          </ConditionalRender>
        </Info>
      </ConditionalRender>
    </>
  )
}

export default withStore(mapStateToProps)(VerifyEmailMessage)
