import React, { FC } from 'react'

import { Color } from '@/packages/palette'
import {
  FontWeight,
  Heading,
  HeadingTypes,
  Text,
  TextTypes
} from '@/packages/ui'

import styles from './PaymentVerificationView.module.scss'

interface PaymentVerificationProps {
  onOpenPopup: () => void
}

const PaymentVerificationView: FC<PaymentVerificationProps> = ({
  onOpenPopup
}: PaymentVerificationProps) => (
  <div className={styles.container}>
    <Heading type={HeadingTypes.H3}>Payment Details</Heading>
    <div className={styles.messageBox}>
      <Text weight={FontWeight.SEMIBOLD}>
        Thank you for choosing to make a payment with us.
      </Text>
      <Text type={TextTypes.BODY_SMALL}>
        We will now redirect you to a different payment screen in a new window.
        If the new window does not load, please&nbsp;
        <Text
          asSpan
          weight={FontWeight.BOLD}
          color={Color.primary400}
          onClick={onOpenPopup}
          clickable
        >
          click here
        </Text>
      </Text>
    </div>
  </div>
)

export default PaymentVerificationView
