import clsx from 'clsx'
import { CSSProperties, FC } from 'react'

import styles from './PulsatingCircle.module.scss'

interface PulsatingCircleProps {
  size?: number
  type: 'error' | 'warning' | 'success' | 'default'
  className?: string
}

const PulsatingCircle: FC<PulsatingCircleProps> = (props) => {
  const { size = 10, className, type } = props

  return (
    <div
      style={
        {
          '--size': `${size}px`
        } as CSSProperties
      }
      className={clsx(styles.pulsatingCircle, styles[type], className)}
    />
  )
}

export default PulsatingCircle
