import React, { FC, MouseEvent, useCallback, useEffect, useState } from 'react'

import { ConditionalRender } from '@/common/components'
import { Dictionary } from '@/common/types'
import { SafeNoteImages } from '@/features/safe-note/assets'
import { SetTermsModal, TermsLearnMoreModal } from '@/features/safe-note/modals'
import { LearnMoreModals, Terms } from '@/features/safe-note/types'
import { useModal } from '@/packages/hooks'

import { TermsListItem, TermsListItemProps } from './components'
import styles from './TermsList.module.scss'

type TermsListType = Dictionary<
  Omit<
    TermsListItemProps,
    'handleOpenLearnMore' | 'openModal' | 'handleClearTerms'
  >
>

const predefinedTerms: TermsListType = {
  [Terms.MFN]: {
    id: Terms.MFN,
    title: 'Most Favored Nation (MFN)',
    description:
      'You won’t set terms now, but this investor has one opportunity to match their terms to those issued to a future investor.',
    image: SafeNoteImages.MfnTerms,
    valuationCap: null,
    discountRate: null
  },
  [Terms.HELP_SET_TERMS]: {
    id: Terms.HELP_SET_TERMS,
    title: 'Discount and/or valuation cap',
    description:
      'Choose the discount and/or valuation cap that you want to offer the investor(s).',
    image: SafeNoteImages.HelpSetTerms,
    imageOnActive: SafeNoteImages.TermsSet,
    valuationCap: null,
    discountRate: null
  }
}

interface TermsListProps {
  selectedTermsId?: Terms
  mfn?: boolean
  discountRate?: number
  valuationCap?: number
  onSelect?: (values: any) => void
  disabled?: boolean
}

const TermsList: FC<TermsListProps> = ({
  onSelect,
  disabled = false,
  ...props
}) => {
  const [selectedTermsId, setSelectedTermsId] = useState<Terms | undefined>(
    undefined
  )
  const { visible, openModal, closeModal } = useModal<
    'valuationCap' | 'discountRate' | LearnMoreModals | undefined
  >()

  useEffect(() => {
    const id = props.selectedTermsId

    let predefinedTermsId

    if (props.valuationCap || props.discountRate) {
      predefinedTermsId = Terms.HELP_SET_TERMS
    }

    if (props.mfn) {
      predefinedTermsId = Terms.MFN
    }

    if (id && id !== selectedTermsId) {
      predefinedTermsId = id
    }

    if (predefinedTermsId) {
      setSelectedTermsId(predefinedTermsId)

      onSelect?.({
        mfn: props.mfn,
        selectedTermsId: predefinedTermsId
      })
    }
  }, [props.mfn, props.selectedTermsId])

  const selectMfn = useCallback(() => {
    setSelectedTermsId(Terms.MFN)

    onSelect?.({
      selectedTermsId: Terms.MFN,
      discountRateActive: false,
      valuationCapActive: false,
      mfn: true
    })
  }, [onSelect])

  const setNewTerms = (values?: any) => {
    setSelectedTermsId(Terms.HELP_SET_TERMS)

    onSelect?.({
      ...(!!values ? values : {}),
      mfn: false,
      selectedTermsId: Terms.HELP_SET_TERMS
    })
  }

  const handleSelectTerms = (id: string) => {
    if (id === Terms.MFN) {
      selectMfn()
      return
    }

    const values = {
      valuationCap: props.valuationCap,
      discountRate: props.discountRate,
      discountRateActive: !!props.discountRate,
      valuationCapActive: !!props.valuationCap
    }

    setNewTerms(values)
  }

  const handleClearTerms = (id: string) => {
    if (id === 'discountRate') {
      onSelect?.({
        discountRateActive: false,
        discountRate: undefined
      })

      return
    }

    onSelect?.({
      valuationCapActive: false,
      valuationCap: undefined
    })
  }

  const handleOpenLearnMore = (
    e: MouseEvent<HTMLElement>,
    id: LearnMoreModals
  ) => {
    e.stopPropagation()

    openModal(id)
  }

  const renderListItems = useCallback(
    () =>
      Object.values(predefinedTerms).map((item: any) => (
        <TermsListItem
          {...item}
          discountRate={props.discountRate}
          valuationCap={props.valuationCap}
          key={item.id}
          handleClearTerms={handleClearTerms}
          onSelect={handleSelectTerms}
          active={selectedTermsId === item.id}
          disabled={disabled}
          openModal={(modalId: 'valuationCap' | 'discountRate') =>
            openModal(modalId)
          }
          handleOpenLearnMore={handleOpenLearnMore}
        />
      )),
    [selectedTermsId, handleSelectTerms]
  )

  return (
    <div className={styles.container}>
      {renderListItems()}

      <ConditionalRender condition={!disabled}>
        <TermsLearnMoreModal
          type={visible === LearnMoreModals.MFN ? 'mfn' : 'terms'}
          visible={
            visible === LearnMoreModals.MFN ||
            visible === LearnMoreModals.HELP_SET_TERMS
          }
          closeModal={closeModal}
        />

        <ConditionalRender
          condition={visible === 'discountRate' || visible === 'valuationCap'}
        >
          <SetTermsModal
            closeModal={closeModal}
            onSelect={setNewTerms}
            type={visible === 'discountRate' ? 'discount' : 'valuation'}
            discountRate={props.discountRate}
            valuationCap={props.valuationCap}
          />
        </ConditionalRender>
      </ConditionalRender>
    </div>
  )
}

export default TermsList
