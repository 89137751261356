import { FC, memo, useEffect } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'

import { ExecutableArgs } from '@/common/store/StoreHandler'
import { FullScreenLoading } from '@/packages/ui'
import { ROUTES, useNavigator } from '@/router'

const redirectPaths = [ROUTES.LOGIN, ROUTES.SIGN_UP]

interface LoginDispatcherProps {
  autoLogin: (args: ExecutableArgs) => Promise<void>
  loading: boolean
}

const LoginDispatcher: FC<LoginDispatcherProps> = ({
  autoLogin,
  loading
}: LoginDispatcherProps) => {
  const location = useLocation()
  const navigate = useNavigator()
  const [searchParams] = useSearchParams()

  const accessToken = searchParams.get('accessToken')

  useEffect(() => {
    autoLogin({
      data: { accessToken },
      options: {
        onSuccess: () => {
          if (redirectPaths.includes(location.pathname)) {
            navigate.toDashboard()
          }
        }
      }
    })
  }, [])

  if (loading) {
    return <FullScreenLoading loading solidColor />
  }

  return <></>
}

export default memo(LoginDispatcher)
