import clsx from 'clsx'
import React, { FC } from 'react'

import { ConditionalRender } from '@/common/components'
import { SharedIcons } from '@/packages/icons'
import { ClickEvent } from '@/packages/ui'

import { FontWeight, Text, TextTypes } from '../../typography'
import styles from './EditButton.module.scss'

interface EditButtonProps {
  className?: string
  onClick?: ClickEvent
  withIcon?: boolean
  size?: 'small' | 'default' | 'large'
}

const mapSizeToNumber = {
  small: 15,
  default: 20,
  large: 30
}

const mapSizeToTextType = {
  small: TextTypes.BODY_SMALL,
  default: TextTypes.BODY_DEFAULT,
  large: TextTypes.BODY_DEFAULT
}

const EditButton: FC<EditButtonProps> = ({
  className,
  onClick,
  withIcon = true,
  size = 'default'
}) => (
  <div className={clsx(styles.edit, className)} onClick={onClick}>
    <ConditionalRender condition={withIcon}>
      <SharedIcons.Edit size={mapSizeToNumber[size]} />
    </ConditionalRender>
    <Text type={mapSizeToTextType[size]} weight={FontWeight.BOLD}>
      Edit
    </Text>
  </div>
)

export default EditButton
