import clsx from 'clsx'
import React, { ChangeEvent, FC, memo } from 'react'

import styles from './Switch.module.scss'

interface IProps {
  disabled?: boolean
  value?: boolean
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  size?: 'default' | 'large'
}

const Switch: FC<IProps> = ({
  disabled,
  value,
  onChange,
  size = 'default'
}: IProps) => (
  <div className={clsx(styles.container, disabled && styles.disabled)}>
    <label className={styles.toggle}>
      <input
        type="checkbox"
        checked={value}
        disabled={disabled}
        onChange={onChange}
        className={styles.checkbox}
      />
      <div className={clsx(styles.switch, styles[size])} />
    </label>
  </div>
)

export default memo(Switch)
