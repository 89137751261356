import clsx from 'clsx'
import React, { FC, MouseEvent } from 'react'

import { ConditionalRender } from '@/common/components'
import { VerificationMark } from '@/features/auth/assets/icons'
import { VerificationCheckmarkModal } from '@/features/auth/modals'
import { ICompany } from '@/features/profile/types'
import { ProfileTypes, VerificationStatus } from '@/features/user/enums'
import { useModal } from '@/packages/hooks'
import { Color } from '@/packages/palette'
import {
  FontWeight,
  Text,
  TextTypes,
  Tooltip,
  TooltipKind
} from '@/packages/ui'

import styles from './VerificationStatusBadge.module.scss'

interface VerificationStatusProps {
  company: ICompany
  isCreator: boolean
  mini?: boolean
}

const statusNames = {
  [VerificationStatus.NotVerified]: 'Not verified',
  [VerificationStatus.Pending]: 'Waiting',
  [VerificationStatus.Verified]: 'Verified'
}

const statusMessages = {
  [VerificationStatus.NotVerified]:
    'Click to verify the company to increase investor reliability!',
  [VerificationStatus.Pending]:
    'Verification is pending! We wiil update your status as soon as possible',
  [VerificationStatus.Verified]: 'Your company is verified!'
}

const VerificationStatusBadge: FC<VerificationStatusProps> = (props) => {
  const { company, isCreator, mini } = props

  const { visible, openModal, closeModal } = useModal<boolean>(false)

  if (mini) {
    return company.verificationStatus === VerificationStatus.Verified ? (
      <VerificationMark color={Color.primary400} />
    ) : null
  }

  const onClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation()

    if (
      company.verificationStatus === VerificationStatus.NotVerified &&
      isCreator
    ) {
      openModal()
    }
  }

  const Badge = (
    <div
      className={clsx(
        styles.verificationStatus,
        company.verificationStatus === VerificationStatus.Pending &&
          styles.pending,
        company.verificationStatus === VerificationStatus.Verified &&
          styles.verified
      )}
      onClick={onClick}
    >
      <VerificationMark
        color={
          company.verificationStatus === VerificationStatus.NotVerified
            ? Color.neutral300
            : Color.primary400
        }
      />

      <Text
        className={styles.statusName}
        type={TextTypes.BODY_EXTRA_SMALL}
        weight={FontWeight.BOLD}
        color={Color.neutral0}
      >
        {statusNames[company.verificationStatus]}
      </Text>
    </div>
  )

  return (
    <>
      {visible && (
        <VerificationCheckmarkModal
          title="Verify your company!"
          companyDetails={company}
          text="Show investors your company is credible. Upgrade your subscription, or pay a one time fee of $50 to get your verification check mark."
          closeModal={closeModal}
        />
      )}
      <ConditionalRender condition={company.type === ProfileTypes.ENTREPRENEUR}>
        <ConditionalRender condition={isCreator} fallbackElement={Badge}>
          <Tooltip
            kind={TooltipKind.INFO}
            placement="bottom-start"
            content={
              <Text type={TextTypes.BODY_SMALL} color={Color.neutral400}>
                {statusMessages[company.verificationStatus]}
              </Text>
            }
          >
            {Badge}
          </Tooltip>
        </ConditionalRender>
      </ConditionalRender>
    </>
  )
}

export default VerificationStatusBadge
