import { observer } from 'mobx-react-lite'
import React from 'react'

import { SendReminder } from '@/features/dashboard/assets/icons/TableOptionsIcons'
import { useModalContext } from '@/features/modals'
import { NavigationSignatureItem } from '@/features/safe-note'
import styles from '@/features/safe-note/components/SafeNote/components/SafeNoteNavigation/SafeNoteNavigation.module.scss'
import { useSafeNoteContext } from '@/features/safe-note/components/SafeNote/context'
import { SendSafeReminderModal } from '@/features/safe-note/modals'
import { useModal } from '@/packages/hooks'
import { Color } from '@/packages/palette'
import { Col, FontWeight, Row, Text, TextTypes } from '@/packages/ui'

const SignaturesBlock = () => {
  const { signatures, sender, viewMode, recipient, safeNoteId, customData } =
    useSafeNoteContext()

  const { reminderSentModal } = useModalContext()
  const { visible, openModal, closeModal } = useModal(false)

  const {
    senderSignature,
    senderSignatureName,
    recipientSignatureName,
    recipientSignature
  } = signatures

  const onSentReminder = () => {
    closeModal()
    reminderSentModal.openModal()
  }

  if (customData.viewAsSender && viewMode) {
    return (
      <Col gap={12} items="stretch" className={styles.navItem}>
        {visible && (
          <SendSafeReminderModal
            onSent={onSentReminder}
            id={safeNoteId as string}
            name={recipientSignatureName as string}
            img={recipient?.image}
            closeModal={closeModal}
          />
        )}

        <Row items="center" justify="between" gap={10}>
          <Text
            className="!tw-leading-snug"
            type={TextTypes.BODY_SMALL}
            color={Color.neutral300}
          >
            Signatures
          </Text>

          <Text
            clickable
            className="!tw-flex tw-items-center tw-gap-4"
            type={TextTypes.BODY_SMALL}
            weight={FontWeight.SEMIBOLD}
            color={Color.primary500}
            onClick={() => openModal()}
          >
            <SendReminder size={18} color={Color.primary500} />
            Remind
          </Text>
        </Row>

        <NavigationSignatureItem
          viewMode
          small
          name={recipientSignatureName}
          image={recipient?.image}
          signed={!!recipientSignature?.signature}
        />

        <div className={styles.divider} />

        <NavigationSignatureItem
          viewMode
          small
          name={senderSignatureName}
          image={sender?.image}
          signed={!!senderSignature?.signature}
        />
      </Col>
    )
  }

  if (customData.viewAsRecipient) {
    return (
      <Col gap={12} items="stretch" className={styles.navItem}>
        <Row items="center" justify="between" gap={10}>
          <Text
            className="!tw-leading-snug"
            type={TextTypes.BODY_SMALL}
            color={Color.neutral300}
          >
            Signatures
          </Text>
        </Row>

        <NavigationSignatureItem
          viewMode
          small
          name={senderSignatureName}
          image={sender?.image}
          signed={!!senderSignature?.signature}
        />

        <NavigationSignatureItem
          viewMode={false}
          small
          name={recipientSignatureName}
          image={recipient?.image}
          signed={!!recipientSignature?.signature}
        />
      </Col>
    )
  }

  return (
    <NavigationSignatureItem
      viewMode={false}
      name={senderSignatureName || sender?.fullName}
      image={sender?.image}
      signed={!!senderSignature}
    />
  )
}

export default observer(SignaturesBlock)
