import React, { FC, PropsWithChildren } from 'react'

import { Color } from '@/packages/palette'
import {
  Col,
  Heading,
  HeadingTypes,
  Text,
  TextAlign,
  TextTypes
} from '@/packages/ui'

interface IProps extends PropsWithChildren {
  name: string
  img?: string
  title: string
  description?: string

  gap?: number
  imgSize?: number

  className?: string
  titleClassName?: string
  descriptionClassName?: string
}

const ModalContentBox: FC<IProps> = (props) => {
  const {
    children,
    name,
    img,
    title,
    description,
    gap = 22,
    imgSize,
    className,
    descriptionClassName,
    titleClassName
  } = props

  return (
    <Col items="center" gap={gap} className={className}>
      {!!img && <img src={img} alt={name} width={imgSize} />}

      <Col items="center" gap={10}>
        <Heading
          type={HeadingTypes.H3}
          align={TextAlign.CENTER}
          className={titleClassName}
        >
          {title}
        </Heading>

        {!!description && (
          <Text
            type={TextTypes.BODY_DEFAULT}
            color={Color.neutral500}
            align={TextAlign.CENTER}
            className={descriptionClassName}
          >
            {description}
          </Text>
        )}
      </Col>

      {children}
    </Col>
  )
}

export default ModalContentBox
