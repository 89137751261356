import React, { FC } from 'react'

import { StaticIcons } from '@/assets/icons'
import { FontWeight, Row, Text, TextTypes } from '@/packages/ui'

import styles from './BankCard.module.scss'

export interface BankCardProps {
  last4Digits?: string | number
  holderName?: string
  cardType?: string
}

const BankCard: FC<BankCardProps> = ({
  last4Digits,
  cardType,
  holderName
}: BankCardProps) => (
  <div className={styles.container}>
    <div className={styles.ornament} />

    <div className={styles.content}>
      <Row gap={40} items="center">
        <Text type={TextTypes.BODY_SMALL} weight={FontWeight.SEMIBOLD}>
          {cardType?.toUpperCase()}
        </Text>

        <StaticIcons.Mastercard />
      </Row>

      <div className={styles.cardInfo}>
        <Text weight={FontWeight.MEDIUM}>** **** {last4Digits}</Text>
        <Text weight={FontWeight.BOLD}>{holderName?.toUpperCase()}</Text>
      </div>
    </div>
  </div>
)

export default BankCard
