import * as yup from 'yup'

import { Regex } from '@/common/regex'
import { store } from '@/common/store'

export const RecipientStepSchema = yup
  .object({
    recipientName: yup.string().required('Name is required'),
    recipientEmail: yup
      .string()
      .matches(Regex.Email, 'Invalid email')
      .matches(Regex.Email)
      .required('Email is required')
      .test('not-me', 'You cannot create SAFE for yourself', (value) => {
        const { me } = store.user
        return value !== me?.email
      }),
    safeAmount: yup
      .number()
      .required('SAFE amount is required')
      .min(1, 'Amount should be in the format of 00.00')
  })
  .required()
