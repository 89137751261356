import React, { FC } from 'react'

import { Avatar, ConditionalRender } from '@/common/components'
import { toCurrencyFormat } from '@/common/utils'
import { VerificationMark } from '@/features/auth/assets/icons'
import { IncomingSafeIcons } from '@/features/dashboard/assets'
import { ICompany } from '@/features/profile/types'
import { IUser } from '@/features/user'
import { VerificationStatus } from '@/features/user/enums'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import {
  Badge,
  BadgeType,
  Button,
  Col,
  FontWeight,
  Heading,
  HeadingTypes,
  Row,
  Text,
  TextAlign,
  TextTypes
} from '@/packages/ui'
import { useNavigator } from '@/router'

import styles from '../IncomingSafesModal.module.scss'

interface SafesListProps {
  id: string
  isTermSheet?: boolean
  amount?: number | null
  discount?: number | null
  valuation?: number | null
  sender: IUser
  mfn: boolean
  senderCompany: ICompany
}

export const CompanyOption: FC<any> = ({ value, label, image }) => (
  <div>
    <Row items="center" gap={8}>
      <ConditionalRender
        condition={value === 'addCompany'}
        fallbackElement={<Avatar size={30} image={image} name={label} />}
      >
        <SharedIcons.PlusThin size={23} color={Color.primary500} />
      </ConditionalRender>
      <Text
        color={Color.neutral500}
        type={TextTypes.BODY_SMALL}
        weight={FontWeight.SEMIBOLD}
      >
        {label}
      </Text>
    </Row>
  </div>
)

const SafesList: FC<SafesListProps> = (props) => {
  const {
    id,
    mfn,
    senderCompany,
    isTermSheet,
    amount,
    valuation,
    discount,
    sender
  } = props

  const navigate = useNavigator()

  const CompanyRow = (
    <Row
      items="center"
      justify="between"
      gap={15}
      className={styles.safeInfoItem}
    >
      <Row items="center" gap={6}>
        <IncomingSafeIcons.Company />
        <Text
          type={TextTypes.BODY_DEFAULT}
          weight={FontWeight.MEDIUM}
          color={Color.neutral400}
        >
          Company:
        </Text>
      </Row>

      <Row items="center" gap={7}>
        <Text
          type={TextTypes.BODY_DEFAULT}
          weight={FontWeight.MEDIUM}
          color={Color.neutral500}
        >
          {senderCompany.name}
        </Text>

        <ConditionalRender
          condition={
            senderCompany.verificationStatus === VerificationStatus.Verified
          }
        >
          <VerificationMark color={Color.primary400} />
        </ConditionalRender>
      </Row>
    </Row>
  )

  const MFN = (
    <Row
      items="center"
      justify="between"
      gap={15}
      className={styles.safeInfoItem}
    >
      <Row items="center" gap={6}>
        <IncomingSafeIcons.MFN />
        <Text
          type={TextTypes.BODY_DEFAULT}
          weight={FontWeight.MEDIUM}
          color={Color.neutral400}
        >
          Terms:
        </Text>
      </Row>

      <Badge type={BadgeType.INFO}>MFN</Badge>
    </Row>
  )

  const Amount =
    isTermSheet && amount === null ? null : (
      <Row
        items="center"
        justify="between"
        gap={15}
        className={styles.safeInfoItem}
      >
        <Row items="center" gap={6}>
          <IncomingSafeIcons.RoundAmount />
          <Text
            type={TextTypes.BODY_DEFAULT}
            weight={FontWeight.MEDIUM}
            color={Color.neutral400}
          >
            {isTermSheet ? 'Round amount' : 'SAFE amount'}:
          </Text>
        </Row>

        <Badge type={BadgeType.INFO}>
          {toCurrencyFormat(amount || 0, '$')}
        </Badge>
      </Row>
    )

  const Discount = (
    <Row
      items="center"
      justify="between"
      gap={15}
      className={styles.safeInfoItem}
    >
      <Row items="center" gap={6}>
        <IncomingSafeIcons.DiscountRate />
        <Text
          type={TextTypes.BODY_DEFAULT}
          weight={FontWeight.MEDIUM}
          color={Color.neutral400}
        >
          Discount rate:
        </Text>
      </Row>

      <Badge type={BadgeType.SUCCESS}>{discount}%</Badge>
    </Row>
  )

  const Valuation = (
    <Row
      items="center"
      justify="between"
      gap={15}
      className={styles.safeInfoItem}
    >
      <Row items="center" gap={6}>
        <IncomingSafeIcons.ValuationCap />
        <Text
          type={TextTypes.BODY_DEFAULT}
          weight={FontWeight.MEDIUM}
          color={Color.neutral400}
        >
          Valuation cap:
        </Text>
      </Row>

      <Badge type={BadgeType.SUCCESS}>
        {toCurrencyFormat(valuation || 0, '$')}
      </Badge>
    </Row>
  )

  return (
    <Col items="stretch">
      <div className={styles.safeInfo}>
        <Avatar
          size={80}
          image={sender.image}
          name={sender.fullName}
          className={styles.avatar}
        />

        <Heading
          type={HeadingTypes.H2}
          align={TextAlign.CENTER}
          color={Color.neutral500}
        >
          {sender.fullName} sent you a {isTermSheet ? 'Term Sheet!' : 'SAFE!'}
        </Heading>

        <div className="tw-my-15">
          {CompanyRow}
          {Amount}
          <ConditionalRender
            condition={mfn}
            fallbackElement={
              <>
                <ConditionalRender condition={!!discount}>
                  {Discount}
                </ConditionalRender>
                <ConditionalRender condition={!!valuation}>
                  {Valuation}
                </ConditionalRender>
              </>
            }
          >
            {MFN}
          </ConditionalRender>
        </div>
      </div>

      {/* <ConditionalRender condition={isTermSheet}> */}
      {/*  <Button */}
      {/*    uppercase */}
      {/*    width="default" */}
      {/*    className="tw-mt-20 tw-self-center" */}
      {/*    onClick={() => navigate.toTermSheet(id as string)} */}
      {/*  > */}
      {/*    View term sheet */}
      {/*  </Button> */}
      {/* </ConditionalRender> */}

      <Button
        uppercase
        width="default"
        className="tw-mt-20 tw-self-center"
        onClick={() => navigate.toViewSafeNote(id)}
      >
        View Safe
      </Button>
    </Col>
  )
}

export default SafesList
