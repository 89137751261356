import { ICompany } from '@/features/profile/types'
import { TeamMemberPermission } from '@/features/user'

const getPermission = (
  userId: string | undefined,
  company: ICompany | undefined
) => {
  if (!userId || !company?.id) return TeamMemberPermission.VIEW

  if (userId === company.owner.id) return TeamMemberPermission.CREATE

  return (
    company.teamMembers.find((teamMember: any) => teamMember.userId === userId)
      ?.permission || TeamMemberPermission.VIEW
  )
}

export default getPermission
