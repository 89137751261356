import React, { FC } from 'react'

import { Color } from '@/packages/palette'
import {
  FileSelect,
  Form,
  Heading,
  HeadingTypes,
  IFile,
  Spacer,
  Text,
  TextTypes
} from '@/packages/ui'

const UploadVerificationDocuments: FC<{
  setFiles: (files: IFile[]) => void
}> = (props) => {
  const { setFiles } = props

  return (
    <Form>
      <Heading type={HeadingTypes.H2} color={Color.neutral500}>
        Upload documents
      </Heading>

      <Text type={TextTypes.BODY_DEFAULT} color={Color.neutral400}>
        Upload the ownership ID or Stock Purchase Agreement
      </Text>
      <Spacer size={10} />

      <FileSelect
        multiple
        caption="Drag and drop to upload file, or import a file from your device (PNG, JPG or SVG)"
        appearance="default"
        allowedExtensions=""
        initialFiles={[]}
        onSelectFiles={setFiles}
      />
    </Form>
  )
}

export default UploadVerificationDocuments
