import React, { FC } from 'react'

import { ModalContentBox } from '@/common/components'
import { SafeNoteImages } from '@/features/safe-note/assets'
import { Button, Col, IModalWithCloseFn, Modal } from '@/packages/ui'

const data = {
  mfn: {
    title: 'Most Favored Nation (MFN)',
    image: SafeNoteImages.MfnInfo,
    description: `If you choose to issue a SAFE with an MFN clause, you will not set a discount rate
    or valuation cap. Instead, you promise your investor that if the company subsequently issues SAFE
    Notes with terms that would be advantageous to them (such as a valuation cap and/or a discount),
    this SAFE can be amended to reflect the terms of the later-issued SAFEs.`
  },
  terms: {
    title: 'Discount and/or Valuation Cap',
    image: SafeNoteImages.TermsInfo,
    description: `A Discount allows investors to convert their SAFE to company stock at a discounted price. A Valuation
      Cap allows investors to convert their SAFE to company stock at a predetermined Cap, even if the company’s
      valuation increases.`
  }
}

const TermsLearnMoreModal: FC<
  IModalWithCloseFn & { type: 'mfn' | 'terms' }
> = ({ type, visible, closeModal }) => (
  <Modal id="mfnTermsModal" size="sm" visible={visible} setVisible={closeModal}>
    <Col items="center" gap={32}>
      <ModalContentBox
        img={data[type].image}
        imgSize={296}
        name="Terms Info"
        title={data[type].title}
        description={data[type].description}
      >
        <Button width="default" onClick={closeModal} uppercase>
          Close
        </Button>
      </ModalContentBox>
    </Col>
  </Modal>
)

export default TermsLearnMoreModal
