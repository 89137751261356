import React from 'react'

import {
  ChangePaymentMethod,
  MasterCard
} from '@/features/profile-settings/assets/icons'
import { Color } from '@/packages/palette'
import {
  Col,
  FontWeight,
  Heading,
  HeadingTypes,
  Row,
  Text,
  TextTypes
} from '@/packages/ui'

import styles from './PaymentMethods.module.scss'

const PaymentMethods = () => {
  const cardNumber = 'XXXX XXXX XXXX 5454'
  const expire = '04/24'

  return (
    <div className="tw-mt-40">
      <Row items="center" gap={20} justify="between">
        <Heading type={HeadingTypes.H2}>Payment methods</Heading>

        <Row items="center" gap={6} className="tw-cursor-pointer">
          <ChangePaymentMethod color={Color.primary400} />
          <Text
            type={TextTypes.BODY_SMALL}
            weight={FontWeight.SEMIBOLD}
            color={Color.primary400}
          >
            Change
          </Text>
        </Row>
      </Row>

      <Row
        items="center"
        justify="between"
        gap={20}
        className={styles.paymentMethod}
      >
        <Row items="center" gap={10}>
          <Col justify="between" className="tw-self-stretch">
            <MasterCard />

            <Text
              type={TextTypes.BODY_EXTRA_SMALL}
              color={Color.neutral300}
              weight={FontWeight.MEDIUM}
            >
              Expires:
            </Text>
          </Col>
          <Col gap={4}>
            <Text
              type={TextTypes.BODY_DEFAULT}
              color={Color.neutral500}
              weight={FontWeight.MEDIUM}
              className="!tw-leading-snug"
            >
              {cardNumber}
            </Text>

            <Text
              type={TextTypes.BODY_EXTRA_SMALL}
              color={Color.neutral300}
              weight={FontWeight.MEDIUM}
            >
              {expire}
            </Text>
          </Col>
        </Row>

        <Text type={TextTypes.BODY_SMALL} color={Color.neutral300}>
          By default
        </Text>
      </Row>
    </div>
  )
}

export default PaymentMethods
