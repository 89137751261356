import clsx from 'clsx'
import React, { FC, MouseEvent } from 'react'

import { Avatar, ConditionalRender } from '@/common/components'
import { verificationPaymentTypes } from '@/common/constants'
import { VerificationPaymentType } from '@/common/enums'
import { StoreType, withStore } from '@/common/store'
import verificationMark from '@/features/auth/assets/images/verification-mark.png'
import { SubscriptionsModal } from '@/features/auth/modals'
import { VerificationCheckmarkModalProps } from '@/features/auth/modals/VerificationCheckmarkModal/VerificationCheckmarkModal'
import { ComparePlans } from '@/features/profile-settings/assets/icons'
import { useModal } from '@/packages/hooks'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import {
  Badge,
  BadgeType,
  Button,
  Col,
  FontWeight,
  Heading,
  HeadingTypes,
  Radio,
  Row,
  Text,
  TextAlign,
  TextTypes
} from '@/packages/ui'

import styles from '../../VerificationCheckmarkModal.module.scss'

interface MainProps extends VerificationCheckmarkModalProps {
  onNext: () => void
  selectedPlan: VerificationPaymentType | undefined
  setSelectedPlan: (plan: VerificationPaymentType | undefined) => void
}

const mapStateToProps = ({ user }: StoreType) => ({
  me: user.me
})

const Main: FC<MainProps & ReturnType<typeof mapStateToProps>> = (props) => {
  const {
    me,
    onNext,
    title,
    selectedPlan,
    setSelectedPlan,
    img,
    companyDetails,
    text,
    closeModal
  } = props

  const {
    visible,
    openModal,
    closeModal: closeCompareModal
  } = useModal<boolean>(false)

  const verifyCompanyMode = !!companyDetails?.id

  const openComparePlans = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    openModal()
  }

  const renderItem = ({
    id,
    icon,
    itemTitle,
    price
  }: {
    id: VerificationPaymentType
    icon: string
    itemTitle: string
    price: number
  }) => (
    <Row
      items="center"
      justify="between"
      className={clsx(
        styles.option,
        selectedPlan === id && verifyCompanyMode && styles.active
      )}
      onClick={() => setSelectedPlan(id)}
    >
      <Row items="center" gap={12}>
        <img src={icon} width={76} alt={itemTitle} />

        <Col gap={3}>
          <Text
            type={TextTypes.BODY_MAIN}
            weight={FontWeight.BOLD}
            color={Color.neutral500}
          >
            {itemTitle}
          </Text>
          <div
            className={clsx(
              styles.price,
              id === VerificationPaymentType.AdvancedPlan &&
                !!me?.activeSubscription &&
                styles.activated
            )}
          >
            ${price}{' '}
            {id === VerificationPaymentType.AdvancedPlan && (
              <span>/per month</span>
            )}
          </div>
        </Col>
      </Row>

      <ConditionalRender
        condition={verifyCompanyMode}
        fallbackElement={
          <SharedIcons.Arrow color={Color.primary400} size={30} />
        }
      >
        <div className={styles.radio}>
          <Radio
            name="plan"
            value={id}
            checked={selectedPlan === id}
            onChange={() => setSelectedPlan(id)}
          />

          {id === VerificationPaymentType.AdvancedPlan && (
            <ConditionalRender
              condition={!me?.activeSubscription}
              fallbackElement={
                <Badge size="small" type={BadgeType.WARNING}>
                  Included
                </Badge>
              }
            >
              <Row
                items="center"
                gap={4}
                className="tw-cursor-pointer"
                onClick={openComparePlans}
              >
                <ComparePlans color={Color.primary500} />
                <Text
                  type={TextTypes.BODY_DEFAULT}
                  weight={FontWeight.MEDIUM}
                  color={Color.primary500}
                >
                  Compare plans
                </Text>
              </Row>
            </ConditionalRender>
          )}
        </div>
      </ConditionalRender>
    </Row>
  )

  return (
    <Col items="center" className={styles.container}>
      {visible && <SubscriptionsModal closeModal={closeCompareModal} />}

      <ConditionalRender
        condition={!!img}
        fallbackElement={
          <div className={styles.companyIcon}>
            <Avatar
              size={90}
              className={styles.avatar}
              image={companyDetails?.image}
              name={companyDetails?.name}
            />

            <img
              src={verificationMark}
              alt=""
              className={styles.verificationMark}
            />
          </div>
        }
      >
        <img
          src={img}
          alt="Verification"
          width={160}
          className={styles.mainImg}
        />
      </ConditionalRender>

      <Heading
        type={HeadingTypes.H2}
        align={TextAlign.CENTER}
        color={Color.neutral500}
      >
        {title}
      </Heading>

      <Text
        type={TextTypes.BODY_DEFAULT}
        color={Color.neutral400}
        align={TextAlign.CENTER}
      >
        {text}
      </Text>

      <Col items="stretch" gap={12} className={styles.options}>
        {verificationPaymentTypes.map((item) =>
          renderItem({
            id: item.id,
            icon: item.icon,
            itemTitle: item.title,
            price: item.price
          })
        )}
      </Col>

      <ConditionalRender condition={verifyCompanyMode}>
        <div className={styles.footer}>
          <Button
            uppercase
            appearance="secondary"
            width="full"
            onClick={closeModal}
          >
            Skip for now
          </Button>
          <Button
            uppercase
            width="full"
            disabled={!selectedPlan}
            onClick={onNext}
          >
            Continue
          </Button>
        </div>
      </ConditionalRender>
    </Col>
  )
}

export default withStore(mapStateToProps)(Main)
