import { FC } from 'react'

import { IconProps, withIcon } from '@/common/hocs'

const InviteIcon: FC<IconProps> = withIcon((props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15 6.25V8.75M15 8.75V11.25M15 8.75H17.5M15 8.75H12.5M10.625 5.3125C10.625 6.8658 9.3658 8.125 7.8125 8.125C6.2592 8.125 5 6.8658 5 5.3125C5 3.7592 6.2592 2.5 7.8125 2.5C9.3658 2.5 10.625 3.7592 10.625 5.3125ZM2.50077 16.0286C2.50026 15.9983 2.5 15.9679 2.5 15.9375C2.5 13.0035 4.87849 10.625 7.8125 10.625C10.7465 10.625 13.125 13.0035 13.125 15.9375V15.9396C13.125 15.9693 13.1247 15.999 13.1242 16.0286C11.5728 16.9627 9.7554 17.5 7.8125 17.5C5.8696 17.5 4.0522 16.9627 2.50077 16.0286Z"
      stroke="#4B73D8"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
))

export default InviteIcon
