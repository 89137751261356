import { BadgeType } from '@/packages/ui/badge'
import { BadgeColumn, MoneyColumn, SAFE } from '@/packages/ui/table/columns'
import { TableColumn, TableColumnType } from '@/packages/ui/table/types'

const statusCombinations = {
  paid: BadgeType.SUCCESS,
  notPaid: BadgeType.ERROR
}

const columns: TableColumn[] = [
  {
    title: 'SAFE',
    accessor: 'safe',
    type: TableColumnType.CUSTOM,
    Component: ({ row }: any) => <SAFE row={row} />
  },
  {
    title: 'Date',
    accessor: 'createdAt',
    withSorting: true,
    type: TableColumnType.DATE
  },
  {
    title: 'Amount',
    accessor: 'safeAmount',
    type: TableColumnType.CUSTOM,
    Component: (props) => <MoneyColumn {...props} withApproveState />
  },
  {
    title: 'SAFE',
    accessor: 'openSafe',
    type: TableColumnType.LINK
  },
  {
    title: 'Status',
    accessor: 'status',
    type: TableColumnType.CUSTOM,
    filterOptions: [
      {
        accessor: 'paid',
        title: 'Paid'
      },
      {
        accessor: 'notPaid',
        title: 'Not Paid'
      }
    ],
    Component: (props) => (
      <BadgeColumn {...props} statusesCombinations={statusCombinations} />
    )
  },
  {
    title: ' ',
    accessor: 'details',
    type: TableColumnType.DETAILS
  }
]

export default columns
