import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { Outlet, useParams, useSearchParams } from 'react-router-dom'

import { HeaderBar } from '@/common/components'
import { useStore } from '@/common/store'
import { VerificationCheckmarkModal } from '@/features/auth/modals'
import { NavigationMenu } from '@/features/dashboard'
import { DashboardNavigationContextProvider } from '@/features/dashboard/context'
import { ProfileTypes } from '@/features/user/enums'
import { useModal } from '@/packages/hooks'
import { useNavigator } from '@/router'

import styles from './DashboardLayout.module.scss'

const DashboardLayout: FC = () => {
  const navigator = useNavigator()

  const [searchParams] = useSearchParams()
  const companyToVerify = searchParams.get('companyToVerify')

  const { visible, closeModal, openModal } = useModal<boolean>(false)

  const [collapsedMenu, setCollapsedMenu] = useState(false)

  const userId = useStore((store) => store.user.me?.id)
  const loading = useStore((store) => store.user.functionLoading.fetchMe)
  const { angel, entrepreneur } = useStore(
    (store) => store.user.sortedCompanies
  )

  const companyToVerifyInfo = entrepreneur?.find?.(
    (company) => company.id === companyToVerify
  )

  const { id: companyId, innerCompanyId } = useParams()
  const [selectedProfile, setSelectedProfile] = useState<
    ProfileTypes | undefined
  >()

  const handleSwitchProfile = useCallback(
    (profile: ProfileTypes) => {
      setSelectedProfile(profile)
      navigator.toDashboard()
    },
    [userId]
  )

  useEffect(() => {
    if (!userId) return

    if (entrepreneur?.length) {
      setSelectedProfile(ProfileTypes.ENTREPRENEUR)
      return
    }

    if (angel?.length) {
      setSelectedProfile(ProfileTypes.ANGEL)
      return
    }

    setSelectedProfile(ProfileTypes.ENTREPRENEUR)
  }, [userId])

  useEffect(() => {
    if (loading) return

    if (companyId) {
      const openedCompany = [...(angel || []), ...(entrepreneur || [])].find(
        (company) => company.id === companyId
      )

      if (openedCompany?.id) {
        setSelectedProfile(openedCompany.type)
        return
      }

      navigator.toDashboard()
    }
  }, [companyId, loading])

  useEffect(() => {
    // TODO: no need to verify company for now
    // if (
    //   !!companyToVerifyInfo?.id &&
    //   companyToVerifyInfo.verificationStatus === VerificationStatus.NotVerified
    // ) {
    //   openModal()
    // }
  }, [companyToVerify, entrepreneur])

  return (
    <DashboardNavigationContextProvider
      selectedProfile={selectedProfile}
      setSelectedProfile={handleSwitchProfile}
    >
      <div>
        {visible && (
          <VerificationCheckmarkModal
            title="Verify your company!"
            companyDetails={companyToVerifyInfo}
            text="Show investors your company is credible. Upgrade your subscription, or pay a one time fee of $50 to get your verification check mark."
            closeModal={closeModal}
          />
        )}

        <div className={styles.wrapper}>
          <HeaderBar
            collapsedMenu={collapsedMenu}
            setCollapsedMenu={setCollapsedMenu}
          />

          <div className={styles.container}>
            <NavigationMenu
              collapsed={collapsedMenu}
              setCollapsed={setCollapsedMenu}
            />
            <div className={styles.dashboardInner}>
              <div className={styles.content}>
                <div
                  className={clsx(
                    styles.contentInner,
                    !!innerCompanyId && styles.withoutLimits
                  )}
                >
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardNavigationContextProvider>
  )
}

export default observer(DashboardLayout)
