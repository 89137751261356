import { ReactNode, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

function createWrapperAndAppendToBody(wrapperId: string, className?: string) {
  const wrapperElement = document.createElement('div')
  wrapperElement.setAttribute('id', wrapperId)

  if (className) {
    wrapperElement.classList.add(className)
  }

  document.body.appendChild(wrapperElement)
  return wrapperElement
}

interface PortalProps {
  children?: ReactNode
  disabled?: boolean
  wrapperId: string
  className?: string
}

const Portal = (props: PortalProps): any => {
  const {
    children,
    wrapperId = 'react-portal-wrapper',
    disabled = false,
    className
  } = props
  const [wrapperElement, setWrapperElement] = useState<HTMLElement | null>(null)

  useEffect(() => {
    let element = document.getElementById(wrapperId)
    let systemCreated = false

    if (!element) {
      systemCreated = true
      element = createWrapperAndAppendToBody(wrapperId, className)
    }
    setWrapperElement(element)

    return () => {
      if (systemCreated && element?.parentNode) {
        element.parentNode.removeChild(element)
      }
    }
  }, [wrapperId, className])

  if (disabled) return children

  if (wrapperElement === null) return null

  return createPortal(children, wrapperElement)
}

export default Portal
