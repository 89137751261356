import clsx from 'clsx'
import React, { FC, useState } from 'react'

import feedbackImg from '@/features/profile-settings/assets/feedback.png'
import rating from '@/features/profile-settings/assets/rating'
import { Color } from '@/packages/palette'
import {
  Button,
  Col,
  Heading,
  HeadingTypes,
  IModalWithCloseFn,
  Label,
  Modal,
  Text,
  TextAlign,
  TextArea,
  TextTypes
} from '@/packages/ui'

import styles from './SendFeedbackModal.module.scss'

const MODAL_ID = 'send-feedback-modal'

const SendFeedbackModal: FC<IModalWithCloseFn & { onSend: () => void }> = (
  props
) => {
  const { closeModal, onSend } = props

  const [active, setActive] = useState<number | undefined>(undefined)
  const [feedback, setFeedback] = useState<string | undefined>(undefined)

  return (
    <Modal size="sm" id={MODAL_ID} visible setVisible={closeModal}>
      <Col items="center">
        <img src={feedbackImg} alt="Give feedback" width={128} />

        <Heading
          type={HeadingTypes.H2}
          align={TextAlign.CENTER}
          className="tw-mt-10 tw-mb-4"
        >
          Your feedback is important to us!
        </Heading>

        <Text
          type={TextTypes.BODY_DEFAULT}
          color={Color.neutral300}
          align={TextAlign.CENTER}
        >
          Let us know how we’re doing. We’d love to hear from you.
        </Text>

        <Col className="tw-self-stretch tw-mt-30" gap={8}>
          <Label
            bold
            htmlFor="feedback-rating"
            label="Please rate your experience with MySAFEnotes."
          />
          <div id="feedback-rating" className={styles.ratingWrapper}>
            {[1, 2, 3, 4, 5].map((item) => (
              <div
                key={item}
                className={clsx(
                  styles.ratingItem,
                  item === active && styles.active
                )}
                onClick={() => setActive(item)}
              >
                <img src={rating[item - 1]} alt={item.toString()} />
              </div>
            ))}
          </div>
        </Col>

        <div className="tw-self-stretch tw-mt-20">
          <TextArea
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            label="Tell us more!"
            placeholder="Let us know how we can improve"
          />
        </div>

        <div className={styles.footer}>
          <Button
            uppercase
            appearance="secondary"
            width="full"
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            uppercase
            width="full"
            disabled={!active && !feedback}
            onClick={onSend}
          >
            Send
          </Button>
        </div>
      </Col>
    </Modal>
  )
}

export default SendFeedbackModal
