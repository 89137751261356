import React, { useEffect } from 'react'

import { StoreType, useStore } from '@/common/store'
import { useNavigator } from '@/router'

const LogoutView = () => {
  const { logout } = useStore((store: StoreType) => store.auth)
  const navigate = useNavigator()

  useEffect(() => {
    logout()
    navigate.toLogin()
  }, [])

  return <></>
}

export default LogoutView
