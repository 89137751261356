import { FC, ReactNode } from 'react'

import styles from './Tab.module.scss'

interface PrivateTabProps {
  // eslint-disable-next-line react/no-unused-prop-types
  id: string
  // eslint-disable-next-line react/no-unused-prop-types
  title?: string | ReactNode
  // eslint-disable-next-line react/no-unused-prop-types
  icon?: string
  children?: ReactNode
}

export type TabProps = Omit<PrivateTabProps, 'children'> & {
  icon?: string
}

const Tab: FC<PrivateTabProps> = ({ children }: PrivateTabProps) => (
  <div className={styles.tab}>{children}</div>
)

export default Tab
