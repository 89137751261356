export enum TextTypes {
  /**
   * 18 px
   */
  BODY_MAIN = 'body-main',
  /**
   * 16 px
   */
  BODY_DEFAULT = 'body-default',
  /**
   * 14 px
   */
  BODY_SMALL = 'body-small',
  /**
   * 12 px
   */
  BODY_EXTRA_SMALL = 'body-extra-small'
}

export enum HeadingTypes {
  /**
   * 44px
   */
  H1 = 'h1',
  /**
   * 22px
   */
  H2 = 'h2',
  /**
   * 20px
   */
  H3 = 'h3',
  /**
   * 18px
   */
  H4 = 'h4'
}

export enum FontWeight {
  REGULAR = 'font-regular',
  MEDIUM = 'font-medium',
  SEMIBOLD = 'font-semibold',
  BOLD = 'font-bold',
  BLACK = 'font-black'
}

export enum TextAlign {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
  JUSTIFY = 'justify'
}
