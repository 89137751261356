import { FC } from 'react'

import { capitalize } from '@/common/utils'
import { Badge, BadgeType } from '@/packages/ui'

interface Combinations {
  [key: string]: BadgeType
}

interface BadgeColumnProps {
  row: any
  accessor: string
  defaultType?: BadgeType
  defaultValue?: string
  statusesCombinations: Combinations
}

const BadgeColumn: FC<BadgeColumnProps> = (props) => {
  const { row, defaultType, defaultValue, statusesCombinations, accessor } =
    props

  const value = row[accessor]
  const type = statusesCombinations[value] || defaultType || BadgeType.INFO

  return (
    <Badge lightMode type={type}>
      {capitalize(value) || defaultValue}
    </Badge>
  )
}

export default BadgeColumn
