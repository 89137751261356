import React from 'react'

import { DateHelper } from '@/common/helpers'
import { toCurrencyFormat } from '@/common/utils'
import { ComparePlans } from '@/features/profile-settings/assets/icons'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import {
  FontWeight,
  Heading,
  HeadingTypes,
  Row,
  Text,
  TextTypes
} from '@/packages/ui'

import styles from './PaymentReports.module.scss'

const PaymentReports = () => {
  const reports = [
    { id: '#65678987654', date: new Date(), price: 9.99 },
    { id: '#23546457654', date: new Date(), price: 9.99 }
  ]

  return (
    <div className="tw-mt-40">
      <Row items="center" gap={20} justify="between">
        <Heading type={HeadingTypes.H2}>Payment reports</Heading>

        <Row items="center" gap={6} className="tw-cursor-pointer">
          <ComparePlans color={Color.primary400} />
          <Text
            type={TextTypes.BODY_SMALL}
            weight={FontWeight.SEMIBOLD}
            color={Color.primary400}
          >
            View all
          </Text>
        </Row>
      </Row>

      <div className="tw-mt-16">
        <table className={styles.reportsTable}>
          <tbody>
            {reports.map((report) => (
              <tr key={report.id}>
                <td>
                  <Text
                    type={TextTypes.BODY_SMALL}
                    className={Color.neutral300}
                  >
                    {report.id}
                  </Text>
                </td>

                <td>
                  <Text
                    type={TextTypes.BODY_SMALL}
                    weight={FontWeight.MEDIUM}
                    className={Color.neutral500}
                  >
                    {DateHelper.toPrettyFormat(report.date)}
                  </Text>
                </td>

                <td>
                  <Text
                    type={TextTypes.BODY_SMALL}
                    weight={FontWeight.MEDIUM}
                    className={Color.neutral500}
                  >
                    {toCurrencyFormat(report.price, '$')}
                  </Text>
                </td>

                <td>
                  <Text
                    type={TextTypes.BODY_SMALL}
                    weight={FontWeight.MEDIUM}
                    className={Color.primary400}
                  >
                    View in detail
                  </Text>
                </td>

                <td className={styles.optionsColumn}>
                  <SharedIcons.Dots
                    color={Color.neutral400}
                    className={styles.more}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default PaymentReports
