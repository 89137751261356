import React, { FC } from 'react'

import { Avatar, ConditionalRender } from '@/common/components'
import { DashboardTeamMembers } from '@/features/dashboard'
import styles from '@/features/dashboard/modals/InvitationsModal/InvitationsModal.module.scss'
import { ICompanyTeamMember, IOwner } from '@/features/profile/types'
import { Color } from '@/packages/palette'
import { FontWeight, Row, Text, TextTypes } from '@/packages/ui'

interface CompanyRowProps {
  teamMembers: ICompanyTeamMember[]
  owner?: IOwner
  pageMode?: boolean
  image: string | null
  name: string
}

const CompanyRow: FC<CompanyRowProps> = ({
  image,
  name,
  pageMode,
  teamMembers,
  owner
}) => (
  <Row items="center" justify="between" gap={15} className={styles.company}>
    <Row items="center" gap={8}>
      <Avatar size={44} image={image} name={name} />

      <Text
        type={TextTypes.BODY_DEFAULT}
        color={Color.neutral500}
        weight={FontWeight.BOLD}
      >
        {name}
      </Text>
    </Row>

    <ConditionalRender
      condition={pageMode}
      fallbackElement={
        <DashboardTeamMembers
          whiteBorder
          teamMembers={teamMembers}
          owner={owner}
          hideText
          companyId=""
          companyName=""
        />
      }
    >
      <Text type={TextTypes.BODY_DEFAULT} color={Color.neutral400}>
        {teamMembers.length + 1} {!!teamMembers.length ? 'members' : 'member'}
      </Text>
    </ConditionalRender>
  </Row>
)

export default CompanyRow
