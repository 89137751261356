import {
  SendReminder,
  ViewSafe
} from '@/features/dashboard/assets/icons/TableOptionsIcons'
import { SafeRequestedOptions } from '@/features/dashboard/views/SafeRequested/columns'
import {
  CapColumn,
  DiscountColumn,
  UserColumn
} from '@/packages/ui/table/columns'
import { TableColumn, TableColumnType } from '@/packages/ui/table/types'

const columns: TableColumn[] = [
  {
    title: 'Name',
    accessor: 'user',
    type: TableColumnType.CUSTOM,
    Component: UserColumn
  },
  {
    title: 'Discount',
    accessor: 'discountRate',
    type: TableColumnType.CUSTOM,
    Component: DiscountColumn
  },
  {
    title: 'Valuation CAP',
    accessor: 'valuationCap',
    type: TableColumnType.CUSTOM,
    Component: CapColumn
  },
  {
    title: 'Date',
    accessor: 'createdAt',
    withSorting: true,
    type: TableColumnType.DATE
  },
  {
    title: ' ',
    accessor: 'options',
    type: TableColumnType.OPTIONS,
    options: [
      {
        accessor: SafeRequestedOptions.SEND_REMINDER,
        title: 'Send reminder',
        icon: <SendReminder />
      },
      {
        accessor: SafeRequestedOptions.CANCEL_REQUEST,
        title: 'View SAFE',
        icon: <ViewSafe />
      }
    ]
  }
]

export default columns
