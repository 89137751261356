import React, {
  createContext,
  FC,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useMemo,
  useRef
} from 'react'
import * as yup from 'yup'

import { useScreenSize } from '@/packages/hooks'

import styles from './styles.module.scss'

interface IProps {
  gap?: number
  children?: ReactNode
  onSubmit?: () => void
  schema?: yup.ObjectSchema<any>
}

export interface FormInputContextValue {
  requiredFields: string[]
}

export const FormInputContext = createContext<FormInputContextValue>(
  {} as FormInputContextValue
)

export const Form: FC<PropsWithChildren<IProps>> = ({
  gap = 10,
  onSubmit,
  schema,
  children
}: IProps) => {
  const { isDesktop } = useScreenSize()
  const ref = useRef<any>()

  const contextValue = useMemo<FormInputContextValue>(() => {
    if (!schema) return { requiredFields: [] }

    const requiredFields: string[] = []

    Object.keys(schema.fields).forEach((fieldName: string) => {
      try {
        // @ts-ignore
        schema.fields[fieldName]?.validateSync?.(undefined)
      } catch (e) {
        requiredFields.push(fieldName)
      }
    })

    return { requiredFields }
  }, [])

  useEffect(() => {
    if (isDesktop) {
      const input = ref.current.querySelector('input')
      input?.focus()
    }
  }, [isDesktop])

  return (
    <FormInputContext.Provider value={contextValue}>
      <form
        ref={ref}
        className={styles.form}
        style={{ '--row-gap': `${gap}px` } as React.CSSProperties}
        onSubmit={onSubmit}
      >
        {children}
      </form>
    </FormInputContext.Provider>
  )
}
