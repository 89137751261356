import React, { FC, useMemo, useState } from 'react'

import { ConditionalRender } from '@/common/components'
import { VerificationPaymentType } from '@/common/enums'
import { StoreType, withStore } from '@/common/store'
import { AsideImages } from '@/features/auth/assets'
import { ICompany } from '@/features/profile/types'
import { Color } from '@/packages/palette'
import {
  BackButton,
  Button,
  Col,
  Heading,
  HeadingTypes,
  IFile,
  Step,
  Stepper,
  Text,
  TextTypes,
  useStepper
} from '@/packages/ui'

import {
  UploadVerificationDocuments,
  VerificationCompanyDetails,
  VerificationPayment
} from './steps'
import styles from './VerificationStepper.module.scss'

enum Steps {
  Info = 'info',
  Documents = 'documents',
  Payment = 'payment'
}

const asideInfo = {
  [Steps.Info]: {
    img: AsideImages.EntrepreneurAsideGeneral,
    title: 'Provide some company info',
    description: 'Verify your company with some details.'
  },
  [Steps.Documents]: {
    img: AsideImages.EntrepreneurAsideDetails,
    title: 'Provide company documents',
    description:
      'These required documents will help us identify the company and owner.'
  },
  [Steps.Payment]: {
    img: AsideImages.Payment,
    title: 'You’re almost verified!',
    description: 'Pay and finalize your verification.'
  }
}

const steps = [Steps.Info, Steps.Documents, Steps.Payment]

const mapStateToProps = ({ user }: StoreType) => ({
  me: user.me
})

const VerificationStepper: FC<
  {
    company: ICompany
    loading: boolean
    selectedPlan: VerificationPaymentType
    onFinish: () => void
    onBack: () => void
    paymentOnly?: boolean
    title?: string
    description?: string
  } & ReturnType<typeof mapStateToProps>
> = (props) => {
  const {
    me,
    title,
    description,
    paymentOnly,
    loading,
    company,
    onFinish,
    onBack,
    selectedPlan
  } = props
  const hidePayment =
    selectedPlan === VerificationPaymentType.AdvancedPlan &&
    !!me?.activeSubscription

  const { currentStep, prevStep, nextStep, isLastStep } = useStepper(
    paymentOnly ? 2 : 0,
    hidePayment ? 2 : 3
  )

  const [isDetailsValid, setIsDetailsValid] = useState<boolean>(false)
  const [selectedCard, setSelectedCard] = useState<string>()
  const [disabled, setDisabled] = useState<boolean>(false)

  const [files, setFiles] = useState<IFile[]>([])

  const step = steps[currentStep]

  const nextDisabled = useMemo(() => {
    if (currentStep === 0) return !isDetailsValid
    if (currentStep === 1) return !files.length

    return false
  }, [isDetailsValid, currentStep, files])

  const handleNext = () => {
    if (isLastStep) {
      onFinish()
      return
    }

    nextStep()
  }

  const handleBack = () => {
    if (currentStep === 0 || paymentOnly) {
      onBack()
      return
    }

    prevStep()
  }

  const PaymentStep = (
    <VerificationPayment
      subscription={!!paymentOnly}
      selectedCard={selectedCard}
      setSelectedCard={setSelectedCard}
      selectedPlan={selectedPlan}
      setDisabled={setDisabled}
    />
  )

  return (
    <div className={styles.wrapper}>
      <div className={styles.form}>
        <ConditionalRender
          condition={!paymentOnly}
          fallbackElement={PaymentStep}
        >
          <Stepper
            navigationType="circled"
            activeStep={currentStep}
            markPreviousAsCompleted={false}
            navigationClassName={styles.stepper}
          >
            <Step title="Company Info">
              <VerificationCompanyDetails
                company={company}
                setValid={setIsDetailsValid}
              />
            </Step>
            <Step title="Documents">
              <UploadVerificationDocuments setFiles={setFiles} />
            </Step>

            {!hidePayment && <Step title="Payment">{PaymentStep}</Step>}
          </Stepper>
        </ConditionalRender>

        <div className={styles.footer}>
          <BackButton disabled={loading} onClick={handleBack}>
            Back
          </BackButton>

          <Button
            width="default"
            loading={loading}
            onClick={handleNext}
            uppercase
            disabled={nextDisabled || loading || disabled}
          >
            {/* eslint-disable-next-line no-nested-ternary */}
            {isLastStep
              ? paymentOnly
                ? 'Upgrade account'
                : 'Place order'
              : 'Next step'}
          </Button>
        </div>
      </div>

      <div className={styles.stepImage}>
        <img src={asideInfo[step].img} alt="" />

        <Col gap={12}>
          <Heading type={HeadingTypes.H2} color={Color.neutral500}>
            {title || asideInfo[step].title}
          </Heading>

          <Text type={TextTypes.BODY_DEFAULT} color={Color.neutral500}>
            {description || asideInfo[step].description}
          </Text>
        </Col>
      </div>
    </div>
  )
}

export default withStore(mapStateToProps)(VerificationStepper)
