import { FC } from 'react'

import { IconProps, withIcon } from '@/common/hocs'

export const Verified: FC<IconProps> = withIcon((props) => (
  <svg
    width="10"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.03574 9.03574C8.09775 9.97328 6.82615 10.5 5.5 10.5C4.17385 10.5 2.90222 9.97327 1.96426 9.03574C1.02672 8.09775 0.5 6.82615 0.5 5.5C0.5 4.17385 1.02673 2.90222 1.96426 1.96426C2.90225 1.02672 4.17385 0.5 5.5 0.5C6.82615 0.5 8.09778 1.02673 9.03574 1.96426C9.97328 2.90225 10.5 4.17385 10.5 5.5C10.5 6.82615 9.97327 8.09778 9.03574 9.03574ZM3.54519 5.63167C3.36792 5.45567 3.08154 5.45567 2.90427 5.63167L2.90426 5.63167C2.81817 5.71732 2.77002 5.83323 2.77002 5.95469C2.77002 6.0757 2.81818 6.19205 2.90426 6.27728L4.26794 7.64095C4.44521 7.81738 4.73159 7.81738 4.90886 7.64095H4.90929L7.86378 4.68647C8.01804 4.50579 8.00782 4.23732 7.83991 4.06941C7.67202 3.90151 7.40311 3.89085 7.22285 4.04555L4.59096 6.67744L3.54519 5.63167Z"
      fill="currentColor"
    />
  </svg>
))

export const BlueVerified: FC<IconProps> = withIcon((props) => (
  <svg
    width="23"
    height="22"
    viewBox="0 0 23 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.5 10.0857V11.0057C21.4988 13.1621 20.8005 15.2604 19.5093 16.9875C18.2182 18.7147 16.4033 19.9782 14.3354 20.5896C12.2674 21.201 10.0573 21.1276 8.03447 20.3803C6.01168 19.633 4.28465 18.2518 3.11096 16.4428C1.93727 14.6338 1.37979 12.4938 1.52168 10.342C1.66356 8.19029 2.49721 6.14205 3.89828 4.5028C5.29935 2.86354 7.19279 1.72111 9.29619 1.24587C11.3996 0.770634 13.6003 0.988061 15.57 1.86572M21.5 3L11.5 13.01L8.5 10.01"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
))

export const Pending: FC<IconProps> = withIcon((props) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="12" height="12" rx="6" fill="white" />
    <path
      d="M6 0C2.694 0 0 2.694 0 6C0 9.306 2.694 12 6 12C9.306 12 12 9.306 12 6C12 2.694 9.306 0 6 0ZM8.61 8.142C8.526 8.286 8.376 8.364 8.22 8.364C8.142 8.364 8.064 8.346 7.992 8.298L6.132 7.188C5.67 6.912 5.328 6.306 5.328 5.772V3.312C5.328 3.066 5.532 2.862 5.778 2.862C6.024 2.862 6.228 3.066 6.228 3.312V5.772C6.228 5.988 6.408 6.306 6.594 6.414L8.454 7.524C8.67 7.65 8.742 7.926 8.61 8.142Z"
      fill="currentColor"
    />
  </svg>
))

export const NotVerified: FC<IconProps> = withIcon((props) => (
  <svg
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx="5.98181"
      cy="5.89105"
      r="5"
      fill="currentColor"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
    />
    <line
      x1="4.1803"
      y1="4.36804"
      x2="7.71584"
      y2="7.90357"
      stroke="white"
      strokeWidth="0.8"
    />
    <line
      x1="4.24767"
      y1="7.90356"
      x2="7.78321"
      y2="4.36802"
      stroke="white"
      strokeWidth="0.8"
    />
  </svg>
))
