import {
  ISafeNote,
  ISafeNoteDraft,
  SafeNoteStatus,
  Terms
} from '@/features/safe-note'

const safeResponseToState = (safeNote: ISafeNote): ISafeNoteDraft => {
  const {
    id,
    data,
    status,
    recipient,
    safeAmount,
    mfn,
    valuationCap,
    discountRate,

    senderSignature,
    senderSignDate,
    senderSignName,
    recipientSignature,
    recipientSignDate,
    recipientSignName
  } = safeNote

  const draft = status === SafeNoteStatus.DRAFT

  return {
    id,
    recipients: draft
      ? data?.recipients || []
      : [
          {
            name: recipient.fullName,
            email: recipient.fullName,
            img: recipient.image,
            amount: safeAmount
          }
        ],

    // eslint-disable-next-line no-nested-ternary
    selectedTermsId: mfn
      ? Terms.MFN
      : !!discountRate || !!valuationCap
      ? Terms.HELP_SET_TERMS
      : undefined,

    mfn,
    discountRate,
    discountRateActive: !!discountRate,
    valuationCap,
    valuationCapActive: !!valuationCap,

    signatures: {
      senderSignature: senderSignature
        ? { signature: senderSignature }
        : undefined,
      senderSignatureDate: senderSignDate,
      senderSignatureName: senderSignName,

      recipientSignature: recipientSignature
        ? { signature: recipientSignature }
        : undefined,
      recipientSignatureDate: recipientSignDate as Date,
      recipientSignatureName: recipientSignName as string
    }
  }
}

export default safeResponseToState
