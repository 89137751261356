import React, { FC } from 'react'

import { Avatar, ConditionalRender } from '@/common/components'
import { Color } from '@/packages/palette'
import { FontWeight, Row, Text, TextTypes } from '@/packages/ui'

interface TermSheetRecipientItemProps {
  fullName?: string
  image?: string
  email: string
  small?: boolean
}

const TermSheetRecipientItem: FC<TermSheetRecipientItemProps> = (props) => {
  const { small, fullName, image, email } = props

  return (
    <Row items="center" gap={small ? 10 : 15}>
      <Avatar size={small ? 32 : 38} image={image} name={fullName} />

      <ConditionalRender condition={!!fullName}>
        <Text
          className="!tw-leading-snug"
          type={TextTypes.BODY_DEFAULT}
          color={Color.neutral500}
          weight={FontWeight.SEMIBOLD}
        >
          {fullName}
        </Text>
      </ConditionalRender>

      <Text
        className="!tw-leading-snug"
        type={TextTypes.BODY_SMALL}
        color={Color.neutral400}
        weight={FontWeight.MEDIUM}
      >
        {email}
      </Text>
    </Row>
  )
}

export default TermSheetRecipientItem
