import { FC } from 'react'

import { IconProps, withIcon } from '@/common/hocs'

const TeamIcon: FC<IconProps> = withIcon((props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.63411 9.05842C7.55078 9.05008 7.45078 9.05008 7.35911 9.05842C5.37578 8.99175 3.80078 7.36675 3.80078 5.36675C3.80078 3.32508 5.45078 1.66675 7.50078 1.66675C9.54245 1.66675 11.2008 3.32508 11.2008 5.36675C11.1924 7.36675 9.61745 8.99175 7.63411 9.05842Z"
      stroke={props.color || 'currentColor'}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.6747 3.33325C15.2914 3.33325 16.5914 4.64159 16.5914 6.24992C16.5914 7.82492 15.3414 9.10825 13.7831 9.16659C13.7164 9.15825 13.6414 9.15825 13.5664 9.16659"
      stroke={props.color || 'currentColor'}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.46758 12.1333C1.45091 13.4833 1.45091 15.6833 3.46758 17.0249C5.75924 18.5583 9.51758 18.5583 11.8092 17.0249C13.8259 15.6749 13.8259 13.4749 11.8092 12.1333C9.52591 10.6083 5.76758 10.6083 3.46758 12.1333Z"
      stroke={props.color || 'currentColor'}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.2832 16.6667C15.8832 16.5417 16.4499 16.3001 16.9165 15.9417C18.2165 14.9667 18.2165 13.3584 16.9165 12.3834C16.4582 12.0334 15.8999 11.8001 15.3082 11.6667"
      stroke={props.color || 'currentColor'}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
))

export default TeamIcon
