import React, { FC } from 'react'

import { Color } from '@/packages/palette'
import {
  Button,
  Col,
  Heading,
  HeadingTypes,
  IModalWithCloseFn,
  Modal,
  Text,
  TextAlign,
  TextTypes
} from '@/packages/ui'

import image from './images/request-permission.png'

const MODAL_ID = 'no-permission-modal'

interface DontHavePermissionModalProps extends IModalWithCloseFn {}

const DontHavePermissionModal: FC<DontHavePermissionModalProps> = (props) => {
  const { closeModal } = props

  return (
    <Modal size="sm" id={MODAL_ID} visible setVisible={closeModal}>
      <Col items="center" gap={20}>
        <img src={image} alt="" width={130} />

        <Col items="center" gap={5}>
          <Heading
            type={HeadingTypes.H2}
            align={TextAlign.CENTER}
            color={Color.neutral500}
          >
            You don`t have permission to do this action
          </Heading>

          <Text
            type={TextTypes.BODY_DEFAULT}
            align={TextAlign.CENTER}
            color={Color.neutral400}
          >
            You can send a request to the owner to upgrade your permissions.
            Send a request?
          </Text>
        </Col>

        <Button uppercase width="default" onClick={closeModal}>
          Send request
        </Button>
      </Col>
    </Modal>
  )
}

export default DontHavePermissionModal
