import clsx from 'clsx'
import { CSSProperties, FC } from 'react'

import { Avatar, ConditionalRender } from '@/common/components'
import { Color } from '@/packages/palette'
import { Badge, BadgeType, FontWeight, Text, TextTypes } from '@/packages/ui'

import styles from './UserProfile.module.scss'

interface IUserProfileProps {
  userName: string
  email: string
  image?: string | null
  avatarSize?: number
  className?: string
  onClick?: () => void
  subscription?: boolean
}

type ModalType = 'why' | 'verify'

const UserProfile: FC<IUserProfileProps> = ({
  email,
  subscription,
  userName,
  image,
  onClick,
  className,
  avatarSize = 46
}: IUserProfileProps) => (
  <div className={clsx(className, styles.userProfile)} onClick={onClick}>
    <div
      className={styles.imgWrapper}
      style={{ '--size': `${avatarSize}px` } as CSSProperties}
    >
      <Avatar image={image} name={userName} size={avatarSize} />
    </div>
    <div className={clsx(styles.content)}>
      <div className={styles.titleWrapper}>
        <Text
          type={TextTypes.BODY_DEFAULT}
          weight={FontWeight.SEMIBOLD}
          className={styles.title}
        >
          {userName}
        </Text>
      </div>

      <ConditionalRender
        condition={subscription}
        fallbackElement={
          <Text type={TextTypes.BODY_EXTRA_SMALL} color={Color.neutral300}>
            {email}
          </Text>
        }
      >
        <Badge size="small" type={BadgeType.WARNING} className="tw-mt-2">
          Plus Member
        </Badge>
      </ConditionalRender>
    </div>
  </div>
)

export default UserProfile
