import './AuthLayout.scss'

import React, { FC, ReactNode } from 'react'

import { StaticIcons } from '@/assets/icons'
import { ConditionalRender } from '@/common/components'
import { PendingSafes } from '@/features/auth/components'
import { ProfileTypes } from '@/features/user/enums'
import { Color } from '@/packages/palette'
import {
  FontWeight,
  Heading,
  HeadingTypes,
  NavLink,
  Spacer,
  Text,
  TextTypes
} from '@/packages/ui'
import { ROUTES } from '@/router'

interface AuthLayoutProps {
  title?: string
  asideImage: string
  description?: string
  children: ReactNode
  pendingSafes?: any[]
  selectedRole?: ProfileTypes
}

export const AuthLayout: FC<AuthLayoutProps> = ({
  title = '',
  asideImage,
  selectedRole,
  description = '',
  pendingSafes = [],
  children
}: AuthLayoutProps) => (
  <div className="auth-layout">
    <button
      style={{ display: 'none' }}
      onClick={() => {
        throw new Error('test error message')
      }}
    >
      test error bt
    </button>
    <div className="auth-layout__aside aside">
      <div className="aside__header">
        <NavLink href={ROUTES.HOME} useActiveState={false}>
          <StaticIcons.Logo className="logo" />
        </NavLink>
      </div>
      <div className="aside__body">
        <ConditionalRender
          condition={!pendingSafes?.length}
          fallbackElement={
            <PendingSafes
              pendingSafes={pendingSafes}
              selectedRole={selectedRole}
            />
          }
        >
          <img src={asideImage} alt={title} className="auth-img" />

          <Heading
            weight={FontWeight.BOLD}
            type={HeadingTypes.H1}
            color={Color.neutral500}
          >
            {title}
          </Heading>

          <Spacer size={20} />
          <Text color={Color.neutral400} type={TextTypes.BODY_MAIN}>
            {description}
          </Text>
        </ConditionalRender>
      </div>
    </div>
    <div id="auth-layout-content" className="auth-layout__content">
      {children}
    </div>
  </div>
)
