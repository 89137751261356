import React, { FC } from 'react'

import { Avatar } from '@/common/components'
import verificationSentMark from '@/features/auth/assets/images/verification.png'
import { ICompany } from '@/features/profile/types'
import { Color } from '@/packages/palette'
import {
  Button,
  Col,
  Heading,
  HeadingTypes,
  Text,
  TextAlign,
  TextTypes
} from '@/packages/ui'

import styles from '../../VerificationCheckmarkModal.module.scss'

interface VerificationRequestSentProps {
  company: ICompany
  closeModal: () => void
}

const VerificationRequestSent: FC<VerificationRequestSentProps> = (props) => {
  const { company, closeModal } = props

  return (
    <Col items="center" gap={20}>
      <div className={styles.companyIcon}>
        <Avatar size={120} image={company.image} name={company.name} />

        <img
          src={verificationSentMark}
          alt=""
          className={styles.requestSentMark}
        />
      </div>

      <Col items="center" gap={5}>
        <Heading
          type={HeadingTypes.H2}
          align={TextAlign.CENTER}
          color={Color.neutral500}
        >
          Verification information received!
        </Heading>
        <Text
          type={TextTypes.BODY_DEFAULT}
          align={TextAlign.CENTER}
          color={Color.neutral400}
        >
          This can take up to 72 hours or more. Your check mark will appear when
          the process is completed.
        </Text>
      </Col>

      <Button uppercase width="default" onClick={closeModal}>
        Go To Dashboard
      </Button>
    </Col>
  )
}

export default VerificationRequestSent
