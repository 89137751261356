import api, { withQuery } from '@/common/api'

const NOTIFICATION_ENDPOINTS = {
  NOTIFICATIONS: '/notification',
  NOTIFICATIONS_COUNT: '/notification/count'
}

export class NotificationRequests {
  static fetchGlobalNotificationsCount() {
    return api.get(NOTIFICATION_ENDPOINTS.NOTIFICATIONS_COUNT)
  }

  static fetchGlobalNotifications() {
    return api.get(NOTIFICATION_ENDPOINTS.NOTIFICATIONS)
  }

  static fetchCompanyNotificationsCount(companyId: string) {
    return api.get(
      withQuery(NOTIFICATION_ENDPOINTS.NOTIFICATIONS_COUNT, { companyId })
    )
  }

  static fetchCompanyNotifications(companyId: string) {
    return api.get(
      withQuery(NOTIFICATION_ENDPOINTS.NOTIFICATIONS, { companyId })
    )
  }

  static markReadNotifications(ids: string[]) {
    return api.patch(NOTIFICATION_ENDPOINTS.NOTIFICATIONS, { ids })
  }
}
