import React, { FC } from 'react'

import { ToastService } from '@/common/services'
import { StoreType, useStore } from '@/common/store'
import verifyEmailIcon from '@/features/dashboard/assets/images/verify-email.png'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import {
  Col,
  Heading,
  HeadingTypes,
  IModalWithCloseFn,
  Modal,
  Text,
  TextAlign,
  TextTypes
} from '@/packages/ui'

import styles from './VerifyEmailModal.module.scss'

const MODAL_ID = 'verify-email-modal'

const VerifyEmailModal: FC<IModalWithCloseFn> = ({ closeModal }) => {
  const { resendVerifyEmail } = useStore((store: StoreType) => store.user)
  const handleClick = () => {
    closeModal?.()
    resendVerifyEmail({
      data: null,
      options: {
        onSuccess: () => {
          ToastService.showSuccess('Reminder was sent successfully')
          closeModal?.()
        }
      }
    })
  }

  return (
    <Modal size="sm" id={MODAL_ID} visible setVisible={closeModal}>
      <Col items="center" gap={20} className={styles.container}>
        <img src={verifyEmailIcon} alt="Verify email" />

        <Col items="center" gap={16}>
          <Heading type={HeadingTypes.H3} align={TextAlign.CENTER}>
            Verify your email
          </Heading>

          <Text
            type={TextTypes.BODY_DEFAULT}
            color={Color.neutral500}
            align={TextAlign.CENTER}
          >
            Check your email inbox (including the spam/junk folder) for
            verification email.
          </Text>
        </Col>

        <div className={styles.resendWrapper}>
          <Text type={TextTypes.BODY_SMALL} color={Color.neutral500}>
            Do not receive the email?{' '}
            <span className={styles.resendLink} onClick={handleClick}>
              Click here to resend <SharedIcons.Arrow size={20} />
            </span>
          </Text>
        </div>
      </Col>
    </Modal>
  )
}

export default VerifyEmailModal
