import clsx from 'clsx'
import React, { FC } from 'react'

import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import { FontWeight, Text, TextTypes } from '@/packages/ui'

import styles from './DownloadReceipt.module.scss'

interface DownloadReceiptProps {
  onDownload?: () => void
  className?: string
}

export const DownloadReceipt: FC<DownloadReceiptProps> = ({
  onDownload,
  className
}: DownloadReceiptProps) => (
  <div className={clsx(styles.receipt, className)} onClick={onDownload}>
    <div className={styles.icon}>
      <SharedIcons.File color={Color.neutral0} clickable={false} size={15} />
    </div>
    <Text
      className={styles.text}
      type={TextTypes.BODY_SMALL}
      weight={FontWeight.SEMIBOLD}
    >
      Receipt.pdf
    </Text>
    <div className={styles.download}>
      <SharedIcons.Download size={15} />
    </div>
  </div>
)

export default DownloadReceipt
