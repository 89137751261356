import React, { FC, useCallback } from 'react'

import { ProfileTypes } from '@/features/user/enums'

import { RoleItem } from './RoleItem'
import styles from './RoleList.module.scss'
import roles, { IRoleItem } from './roles'

interface RoleListProps {
  role: ProfileTypes | undefined
  onSelect: (value: ProfileTypes) => void
  secondaryView?: boolean
  pendingSafesNumber?: number
}

const RoleList: FC<RoleListProps> = ({
  role,
  onSelect,
  secondaryView,
  pendingSafesNumber
}: RoleListProps) => {
  const handleSelectRole = useCallback(
    (_value: string) => {
      onSelect(_value as ProfileTypes)
    },
    [onSelect]
  )

  const renderRoles = useCallback(
    () =>
      roles.map((_role: IRoleItem) => (
        <RoleItem
          key={_role.id}
          {..._role}
          value={role}
          pendingSafesNumber={pendingSafesNumber}
          onSelect={handleSelectRole}
          secondary={secondaryView}
        />
      )),
    [role, secondaryView, handleSelectRole]
  )

  return <div className={styles.container}>{renderRoles()}</div>
}

export default RoleList
