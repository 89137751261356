import clsx from 'clsx'
import React, { FC, ReactNode } from 'react'

import styles from './Stepper.module.scss'
import { StepperNavigation } from './StepperNavigation'
import { reactElementPropsToArray } from './utils'

interface StepperProps {
  activeStep?: number
  onStepChange?: (index: number) => void
  allowManuallyChangeStep?: boolean
  children?: ReactNode
  className?: string
  navigationClassName?: string
  markPreviousAsCompleted?: boolean
  navigationType?: 'default' | 'small' | 'circled'
}

const Stepper: FC<StepperProps> = ({
  navigationType,
  activeStep = 0,
  onStepChange,
  allowManuallyChangeStep = false,
  children,
  className,
  markPreviousAsCompleted = true,
  navigationClassName
}: StepperProps) => {
  const steps: any = React.Children.toArray(children)

  return (
    <div className={clsx(styles.container, className)}>
      <StepperNavigation
        type={navigationType}
        className={navigationClassName}
        steps={reactElementPropsToArray(steps)}
        activeStep={activeStep}
        onSelectStep={onStepChange}
        markPreviousAsCompleted={markPreviousAsCompleted}
        allowManuallyChangeStep={allowManuallyChangeStep}
      />
      {steps[activeStep]}
    </div>
  )
}

export default Stepper
