import React, { FC } from 'react'

import { RoundIcons } from '@/features/term-sheet/assets/images'
import { Color } from '@/packages/palette'
import {
  Button,
  Col,
  Heading,
  HeadingTypes,
  IModalWithCloseFn,
  Modal,
  Text,
  TextAlign,
  TextTypes
} from '@/packages/ui'

interface PartOfRoundModalProps extends IModalWithCloseFn {
  isPartOfRound: boolean
}

const MODAL_ID = 'part-of-round-modal'

const PartOfRoundModal: FC<PartOfRoundModalProps> = (props) => {
  const { isPartOfRound, closeModal } = props

  const detailsOptions = {
    isPartOfRound: {
      title: 'This term sheet is part of a round',
      description: `If you make the term sheet a part of a round, then the triggering event for a
        SAFE will be the whenever the next financing round occurs, regardless of how much
        money it is. As a result, your angel’s investment will convert to shares at that
        point in time.`,
      img: RoundIcons.PartOfRound
    },
    notPartOfRound: {
      title: 'This term sheet is not part of a round',
      description: `If this term sheet is not part of a round, there is no specific funding round
      that will trigger the SAFE to convert to shares. Discuss with your angel investor to determine
      what should be the triggering event, whether it be reaching a certain amount of raised funds,
      or something else.`,
      img: RoundIcons.NotPartOfRound
    }
  }

  const selectedOptionDetails = isPartOfRound
    ? detailsOptions.isPartOfRound
    : detailsOptions.notPartOfRound

  return (
    <Modal size="sm" id={MODAL_ID} visible setVisible={closeModal}>
      <Col items="center" gap={32}>
        <img src={selectedOptionDetails.img} width={150} alt="" />

        <Col items="center" className="tw-self-stretch" gap={12}>
          <Heading type={HeadingTypes.H3} align={TextAlign.CENTER}>
            {selectedOptionDetails.title}
          </Heading>

          <Text
            type={TextTypes.BODY_DEFAULT}
            color={Color.neutral500}
            align={TextAlign.CENTER}
          >
            {selectedOptionDetails.description}
          </Text>
        </Col>

        <Button uppercase width="default" onClick={closeModal}>
          Close
        </Button>
      </Col>
    </Modal>
  )
}

export default PartOfRoundModal
