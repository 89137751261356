import { observer } from 'mobx-react-lite'
import { FC, useCallback, useEffect, useState } from 'react'

import { useToast } from '@/common/hooks'
import { StoreType, useStore } from '@/common/store'
import {
  PaymentMethod,
  PaymentMethodData,
  PaymentOverview
} from '@/features/payment'
import { PaymentBridgeChildrenProps } from '@/features/payment/components'
import { IBankAccountItem } from '@/features/payment/components/BankItem'
import { ISafeNote } from '@/features/safe-note'
import { Loader, Row } from '@/packages/ui'

interface PaymentStepProps {
  safeNote?: ISafeNote
}

declare global {
  interface Window {
    Plaid: any
  }
}

const PaymentStep: FC<PaymentStepProps> = (props) => {
  const { safeNote } = props
  const { data } = safeNote || {}

  const {
    createSafeNotePaymentIntent,
    fetchPlaidLinkToken,
    exchangePublicTokenToAccessToken,
    getPlaidAccounts,
    loading
  } = useStore((store: StoreType) => store.payment)

  const { payForSafe } = useStore((store: StoreType) => store.safeNote)

  const toast = useToast()
  const [bankAccount, setBankAccount] = useState<IBankAccountItem | null>(null)

  useEffect(() => {
    getPlaidAccounts().then((response) => {
      if (response.accounts.length > 0) {
        const account = response.accounts[0]
        setBankAccount({
          id: account.account_id,
          name: account.name,
          last4Digits: +account.mask
        })
      }
    })
  }, [])

  const decideToDisableButton = useCallback(
    (method: PaymentMethod) => {
      if (safeNote?.paid) return

      if (method === PaymentMethod.RECORD_PAYMENT) {
        // setNextDisabled(false)
      }

      // setNextDisabled(true)
    },
    [data?.paid]
  )

  const handleSelectPaymentMethod = useCallback(
    (method: PaymentMethod) => {
      decideToDisableButton(method)

      if (method === PaymentMethod.RECORD_PAYMENT) {
        // updateData({ paid: true }, true)
      }
      // updateData({ paid: false }, true)
    },
    [decideToDisableButton]
  )

  const handleVerifyBankAccount = useCallback(() => {
    fetchPlaidLinkToken().then((_token) => {
      if (!('Plaid' in window)) {
        toast.showWarning('Plaid is not initialized!')
        return
      }

      const handler = window.Plaid.create({
        token: _token,
        onSuccess: (publicToken: string, metadata: any) => {
          exchangePublicTokenToAccessToken({ data: { publicToken } }).then(
            () => {
              setBankAccount({
                // TODO define account id
                id: '',
                name: metadata.institution?.name,
                last4Digits: metadata.account?.mask
              })
            }
          )
        }
      })

      handler.open()
    })
  }, [])

  const handlePay = useCallback(
    ({
        processPayment,
        constructCardData,
        constructBankTransferData
      }: PaymentBridgeChildrenProps) =>
      async (_data: PaymentMethodData) => {
        if (!safeNote) {
          toast.showError(
            'SAFE note is not available. Please try again later...'
          )
          return
        }

        if (safeNote.paid) {
          toast.showInfo('Payment was already completed!')
          return
        }

        if (!bankAccount?.id) return

        payForSafe({
          data: {
            safeNoteId: safeNote.id,
            accountId: bankAccount?.id,
            amount: 10
          }
        })
      },
    [createSafeNotePaymentIntent, bankAccount]
  )

  if (!safeNote) return <></>

  if (loading) {
    return (
      <Row justify="center">
        <Loader />
      </Row>
    )
  }

  return (
    <PaymentOverview
      platformFee={0}
      stripeFee={0}
      // handler
      onSelectPaymentMethod={handleSelectPaymentMethod}
      onPay={handlePay}
      onVerifyBankAccount={handleVerifyBankAccount}
      // investor data
      recipientCompany={safeNote.senderCompany.name}
      recipientName={safeNote.sender.fullName}
      investorName={safeNote.recipient.fullName}
      investorCompany={safeNote.recipientCompany?.name || '-'}
      investmentAmount={safeNote?.safeAmount}
      bankAccount={bankAccount}
    />
  )
}

export default observer(PaymentStep)
