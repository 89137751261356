import { FC } from 'react'

import { StaticIcons } from '@/assets/icons'
import { Avatar } from '@/common/components'
import { ICompany } from '@/features/profile/types'
import { Color } from '@/packages/palette'
import {
  Button,
  Col,
  FontWeight,
  Heading,
  HeadingTypes,
  Row,
  Text,
  TextTypes
} from '@/packages/ui'

import styles from '../InvitationsModal.module.scss'

interface ListProps {
  companies: ICompany[]
  onItemAction: (index: number, type: 'details' | 'decline') => void
}

const List: FC<ListProps> = (props) => {
  const { companies, onItemAction } = props

  return (
    <>
      <div className={styles.headingWrapper}>
        <Heading
          type={HeadingTypes.H3}
          color={Color.neutral500}
          weight={FontWeight.SEMIBOLD}
        >
          Invitations ({companies.length})
        </Heading>
      </div>

      {companies.map((company, index) => (
        <Row key={company.id} className={styles.inviteItem} gap={8}>
          <Avatar
            size={40}
            image={company.owner.image}
            name={company.owner.fullName}
          />

          <Col items="stretch" className="tw-flex-1">
            <Text type={TextTypes.BODY_SMALL} color={Color.neutral400}>
              <b>{company.owner.fullName}</b> invited you to become a team
              member of his company{' '}
              <div className="tw-inline-block">
                <Row items="center" gap={2}>
                  <div className="tw-inline-block">
                    <Avatar
                      size={22}
                      defaultImage={StaticIcons.CompanyPlaceholder}
                      image={company.image}
                    />
                  </div>
                  <span className={styles.companyName}>{company.name}</span>
                </Row>
              </div>
            </Text>

            <Row items="stretch" justify="between" gap={8} className="tw-mt-12">
              <Button
                className="!tw-p-8"
                onClick={() => onItemAction(index, 'details')}
              >
                View
              </Button>
              <Button
                className="!tw-p-8"
                appearance="secondary"
                onClick={() => onItemAction(index, 'decline')}
              >
                Decline
              </Button>
            </Row>
          </Col>
        </Row>
      ))}
    </>
  )
}

export default List
