import React, { FC } from 'react'

import { IconProps, withIcon } from '@/common/hocs'

const Clock: FC<IconProps> = withIcon((props) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.8307 9.99996C18.8307 14.6 15.0974 18.3333 10.4974 18.3333C5.8974 18.3333 2.16406 14.6 2.16406 9.99996C2.16406 5.39996 5.8974 1.66663 10.4974 1.66663C15.0974 1.66663 18.8307 5.39996 18.8307 9.99996Z"
      stroke="#292D32"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.5953 12.65L11.012 11.1083C10.562 10.8416 10.1953 10.2 10.1953 9.67497V6.2583"
      stroke="#292D32"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
))

export default Clock
