import { ICompany } from '@/features/profile/types'
import { IUser } from '@/features/user'

export interface CreateTermSheetDto {
  discountRate?: number
  mfn?: boolean
  senderCompanyId: string
  roundAmount?: number
  recipients: string[]
}

export enum TermSheetStatus {
  Accepted = 'accepted',
  Rejected = 'rejected',
  Pending = 'pending'
}

export enum TermSheetSafeProgress {
  Awaiting = 'awaiting',
  Created = 'created',
  Paid = 'paid'
}

export interface ITermSheetRecipient {
  comment: string | null
  createdAt: Date
  id: string
  status: TermSheetStatus
  termSheetId: string
  updatedAt: Date
  user: IUser
  userCompany: ICompany | null
  userCompanyId: string | null
  userId: string
  safeProgress: TermSheetSafeProgress
}

export interface ITermSheetUser {
  comment: null | string
  createdAt: Date
  id: string
  safeProgress: TermSheetSafeProgress
  status: TermSheetStatus
  termSheetId: string
  updatedAt: Date
  userCompanyId: string | null
  userId: string
}

export interface ITermSheet {
  createdAt: Date
  discountRate: number | null
  id: string
  mfn: boolean

  roundAmount: number | null
  sender: IUser
  senderCompany: ICompany
  senderCompanyId: string
  updatedAt: Date
  valuationCap: number | null

  recipients: ITermSheetRecipient[]
  termSheetUser: ITermSheetUser
}

export interface UpdateTermSheetDto {
  comment?: string
  status: TermSheetStatus
  companyId?: string
}

export interface IPendingTermSheet {
  createdAt: Date
  discountRate: number | null
  id: string
  mfn: boolean
  roundAmount: number | null
  sender: IUser
  senderCompany: ICompany
  senderCompanyId: string
  termSheetUser: any
  updatedAt: Date
  valuationCap: number | null
}
