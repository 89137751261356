import 'react-toastify/dist/ReactToastify.css'

import { FC } from 'react'

import { ConditionalRender } from '@/common/components'
import { ToastContainer } from '@/common/containers'
import { StoreType, withStore } from '@/common/store'
import { AppContextProvider } from '@/context'
import LoginDispatcher from '@/features/auth/LoginDispatcher'
import { ModalContextProvider } from '@/features/modals'
import SharedModals from '@/features/modals/SharedModals'
import { RootRouter } from '@/router'

const mapStateToProps = ({ auth }: StoreType) => ({
  autoLogin: auth.autoLogin,
  loading: auth.functionLoading.autoLogin
})

type AppProps = ReturnType<typeof mapStateToProps>

const App: FC<AppProps> = ({ autoLogin, loading = true }: AppProps) => (
  <>
    <LoginDispatcher autoLogin={autoLogin} loading={loading} />
    <ConditionalRender condition={!loading}>
      <AppContextProvider>
        <ModalContextProvider>
          <SharedModals />
          <RootRouter />
        </ModalContextProvider>
      </AppContextProvider>
    </ConditionalRender>
    <ToastContainer />
  </>
)

export default withStore(mapStateToProps)(App)
