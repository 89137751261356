import * as yup from 'yup'

import { Regex } from '@/common/regex'
import { store } from '@/common/store'
import { InvestorType } from '@/features/user'

export const LoginSchema = yup
  .object({
    email: yup
      .string()
      .matches(Regex.Email, 'Invalid email')
      .required('Email is required')
      .test(
        'non-exist-email',
        'This email has not been found in our system ',
        function validation() {
          const { failedFields, clearErrors } = store.auth

          if (
            Object.keys(failedFields).length === 1 &&
            'email' in failedFields
          ) {
            clearErrors()
            return false
          }

          if (Object.keys(failedFields).length >= 1) {
            clearErrors()
            return this.createError({
              message: 'Invalid credentials',
              path: 'email'
            })
          }

          clearErrors()
          return true
        }
      ),
    password: yup
      .string()
      .trim()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required')
      .test('invalid-credentials', '', () => {
        const { failedFields } = store.auth

        return !(Object.keys(failedFields).length && 'password' in failedFields)
      })
  })
  .required()

export const SignUpSchema = LoginSchema.concat(
  yup
    .object()
    .shape({
      email: yup
        .string()
        .matches(Regex.Email, 'Invalid email')
        .required('Email is required')
        .test('unique-email', 'This email has already been taken', () => {
          const { failedFields, clearErrors } = store.auth

          const condition = !('email' in failedFields)

          clearErrors()
          return condition
        }),
      fullName: yup.string().trim().required('Name is required')
    })
    .required()
)

export const EditUserSchema = yup
  .object({
    fullName: yup.string().trim().required('Name is required'),
    email: yup
      .string()
      .matches(Regex.Email, 'Invalid email')
      .required('Email is required')
      .test('unique-email', 'This email has already been taken', () => {
        const { failedFields, clearErrors } = store.auth

        const condition = !('email' in failedFields)

        clearErrors()
        return condition
      })
  })
  .required()

export const ResetPasswordSchema = yup
  .object({
    email: yup
      .string()
      .matches(Regex.Email, 'Invalid email')
      .required('Email is required')
  })
  .required()

export const ChangePasswordSchema = yup
  .object({
    password: yup
      .string()
      .trim()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required'),
    newPassword: yup
      .string()
      .oneOf([yup.ref('password')], 'Passwords does not match')
      .required('Confirm password is required')
  })
  .required()

export const AgencySchema = yup
  .object({
    name: yup.string().trim().required('Agency name is required'),
    linkToWebsite: yup
      .string()
      .test(
        'url-validation-if-not-empty',
        'Url is not valid',
        (value) => !value || !!value.match(Regex.Url)
      )
  })
  .required()

// export const AngelSchema = yup
//   .object({
//     investorType: yup.string().required('Investor type is required'),
//     fullName: yup.string().trim().required('Name is required'),
//     address1: yup.string().trim().required('Address is required'),
//     country: yup.string(),
//     city: yup.string().trim().required('City is required'),
//     state: yup.string().required('State is required'),
//     zipCode: yup.string().required('Zip Code is required'),
//     companyName: yup
//       .string()
//       .when(['investorType'], ([value], schema) =>
//         value === InvestorType.CORPORATE
//           ? schema.trim().required('Company name is required')
//           : schema
//       ),
//   })
//   .required()

export const EntrepreneurFillInfoSchema = yup
  .object({
    stateOfIncorporation: yup
      .string()
      .required('State of incorporation is required'),
    address1: yup.string().trim().required('Address is required'),
    state: yup.string().trim().required('State is required'),
    country: yup.string(),
    city: yup.string().trim().required('City is required'),
    zipCode: yup.string().required('ZIP Code is required')
  })
  .required()

export const AngelSchema = yup
  .object({
    ownerTitle: yup.string().trim().required('Role is required'),
    investorType: yup.string().required('Investor type is required'),
    address1: yup.string().trim(),
    country: yup.string(),
    city: yup.string().trim(),
    state: yup.string(),
    zipCode: yup.string(),
    companyName: yup
      .string()
      .when(['investorType'], ([value], schema) =>
        value === InvestorType.CORPORATE ? schema.required() : schema
      )
  })
  .required()

export const EntrepreneurSchema = yup
  .object({
    ownerTitle: yup.string().trim().required('Role is required'),
    name: yup.string().trim().required('Name is required'),
    stateOfIncorporation: yup.string(),
    address1: yup.string().trim(),
    address2: yup.string().trim(),
    state: yup.string().trim(),
    city: yup.string().trim(),
    country: yup.string(),
    zipCode: yup.string()
  })
  .required()

export const JoinToCompanySchema = yup
  .object({
    title: yup.string().trim().required('Title is required')
  })
  .required()

export const TeamMemberSchema = yup
  .object({
    name: yup.string().trim().required('Name is required'),
    email: yup
      .string()
      .required('Email is required')
      .matches(Regex.Email, 'Invalid email'),
    permission: yup.string().trim().required('Permission is required')
  })
  .required()

export const EmailSchema = yup
  .object({
    email: yup
      .string()
      .matches(Regex.Email, 'Invalid email')
      .required('Email is required')
      .test(
        'non-exist-email',
        'This email has not been found in our system ',
        () => {
          const { failedFields, clearErrors } = store.auth

          const condition = !('email' in failedFields)

          clearErrors()
          return condition
        }
      )
  })
  .required()
