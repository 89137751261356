import clsx from 'clsx'
import React, { FC, ReactNode } from 'react'

import styles from './Badge.module.scss'

export enum BadgeType {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  INFO = 'info',
  DEFAULT = 'default'
}

interface BadgeProps {
  type?: BadgeType | null
  addonBefore?: ReactNode
  addonAfter?: ReactNode
  noBackground?: boolean
  children: ReactNode
  size?: 'small' | 'normal' | 'large'
  className?: string
  lightMode?: boolean
}

const Badge: FC<BadgeProps> = ({
  type = BadgeType.INFO,
  children,
  addonBefore,
  addonAfter,
  noBackground,
  size = 'normal',
  className,
  lightMode
}: BadgeProps) => (
  <div
    className={clsx(
      styles.container,
      lightMode && styles.lightMode,
      type && styles[`type-${type}`],
      noBackground && styles.noBackground,
      styles[size],
      className
    )}
  >
    {!!addonBefore && <div className={styles.addonBefore}>{addonBefore}</div>}
    {!!children && <div className={styles.status}>{children}</div>}
    {!!addonAfter && <div className={styles.addonAfter}>{addonAfter}</div>}
  </div>
)

export default Badge
