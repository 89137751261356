import React from 'react'

import { ConditionalRender } from '@/common/components'
import {
  AddCompanyModal,
  DontHavePermissionModal,
  FillCompanyInfoModal,
  VerifyEmailModal
} from '@/features/dashboard/modals'
import { useModalContext } from '@/features/modals/context'
import { LogoutModal } from '@/features/profile-settings/modals'
import {
  LoginToSignModal,
  ReminderSentModal
} from '@/features/safe-note/modals'

const SharedModals = () => {
  const {
    logoutModal,
    verifyEmailModal,
    companyInfoModal,
    loginToSignModal,
    reminderSentModal,
    fillCompanyInfoModal,
    dontHavePermissionModal,
    fillCompanyInfoForTermSheetModal
  } = useModalContext()
  return (
    <>
      <ConditionalRender condition={logoutModal.visible}>
        <LogoutModal {...logoutModal} />
      </ConditionalRender>

      <ConditionalRender condition={companyInfoModal.visible}>
        <AddCompanyModal {...companyInfoModal} />
      </ConditionalRender>

      <ConditionalRender condition={loginToSignModal.visible}>
        <LoginToSignModal {...loginToSignModal} />
      </ConditionalRender>

      <ConditionalRender condition={reminderSentModal.visible}>
        <ReminderSentModal {...reminderSentModal} />
      </ConditionalRender>

      <ConditionalRender condition={verifyEmailModal.visible}>
        <VerifyEmailModal {...verifyEmailModal} />
      </ConditionalRender>

      <ConditionalRender condition={dontHavePermissionModal.visible}>
        <DontHavePermissionModal {...dontHavePermissionModal} />
      </ConditionalRender>

      <ConditionalRender condition={fillCompanyInfoModal.visible}>
        <FillCompanyInfoModal {...fillCompanyInfoModal} />
      </ConditionalRender>

      <ConditionalRender condition={fillCompanyInfoForTermSheetModal.visible}>
        <FillCompanyInfoModal
          {...fillCompanyInfoForTermSheetModal}
          type="termsheet"
        />
      </ConditionalRender>
    </>
  )
}

export default SharedModals
