import clsx from 'clsx'
import React, { FC, MouseEvent, useRef } from 'react'

import { ConditionalRender, LearnMoreLink } from '@/common/components'
import { FormatterHelper } from '@/common/helpers'
import { EditButton, LearnMoreModals, Terms } from '@/features/safe-note'
import { SafeNoteImages } from '@/features/safe-note/assets'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import {
  Badge,
  BadgeType,
  Col,
  Disabled,
  FontWeight,
  Radio,
  Row,
  Text,
  TextAlign,
  TextTypes,
  Tooltip,
  TooltipKind
} from '@/packages/ui'

import styles from './TermsListItem.module.scss'

export interface TermsListItemProps {
  id: Terms
  title: string
  description: string
  image: string
  imageOnActive?: string
  valuationCap?: number | null
  discountRate?: number | null
  active?: boolean
  onSelect?: (id: string) => void
  disabled?: boolean
  handleClearTerms: (id: string) => void
  openModal: (modalId: 'valuationCap' | 'discountRate') => void
  handleOpenLearnMore: (e: MouseEvent<HTMLElement>, id: LearnMoreModals) => void
}

const customTermsItems: {
  id: 'discountRate' | 'valuationCap'
  title: string
  description: string
  img: string
  tooltip: string
}[] = [
  {
    id: 'discountRate',
    title: 'Discount',
    description:
      'With a discount rate, investors can convert their SAFEs to company stock at a reduced price.',
    img: SafeNoteImages.Discount,
    tooltip: `If an angel receives a 20% discount, they can buy shares at 80% of the share price when the SAFE
      converts. So, if the price is $1.00 per share, the angel can buy each share at $0.80.`
  },
  {
    id: 'valuationCap',
    title: 'Valuation cap',
    description:
      'Investors can convert their SAFE to company stock at the predetermined Cap, even if the company’s valuation increases.',
    img: SafeNoteImages.Valuation,
    tooltip: `If an angel invests $10K with a $1M Valuation Cap, and your company grows to be valued at $2M, the angel benefits from
      the predetermined Cap. They can convert their shares at the original valuation price of $1M.`
  }
]

interface IValues {
  valuationCap?: number | null
  discountRate?: number | null
}

const TermsListItem: FC<TermsListItemProps> = ({
  id,
  title,
  description,
  image,
  imageOnActive,
  active,
  openModal,
  valuationCap,
  discountRate,
  onSelect,
  disabled = false,
  handleClearTerms,
  handleOpenLearnMore
}: TermsListItemProps) => {
  const values: IValues = { valuationCap, discountRate }
  const ref = useRef<any>()

  const onItemClick = () => {
    if (!disabled) {
      onSelect?.(id)
      setTimeout(() => {
        ref.current.scrollIntoView({ behavior: 'smooth' })
      }, 0)
    }
  }

  return (
    <div
      ref={ref}
      id="terms-list-item"
      className={clsx(
        styles.container,
        active && styles.active,
        disabled && styles.disabled
      )}
      onClick={onItemClick}
    >
      <Row items="start" justify="between" gap={15}>
        <div className={styles.radio}>
          <Radio name="terms" checked={active} disabled={disabled} />
        </div>

        <Col items="stretch" gap={18}>
          <Row items="start" justify="between" gap={10}>
            <Col items="start" gap={8}>
              <Text
                weight={FontWeight.SEMIBOLD}
                type={TextTypes.BODY_MAIN}
                className="!tw-leading-[1.5]"
              >
                {title}
              </Text>
              <Text type={TextTypes.BODY_DEFAULT} align={TextAlign.LEFT}>
                {description}
              </Text>
              <Text type={TextTypes.BODY_DEFAULT} align={TextAlign.CENTER}>
                <LearnMoreLink
                  align="center"
                  onClick={(e) =>
                    handleOpenLearnMore(
                      e!,
                      id === Terms.HELP_SET_TERMS
                        ? LearnMoreModals.HELP_SET_TERMS
                        : LearnMoreModals.MFN
                    )
                  }
                />
              </Text>
            </Col>

            <div className={styles.imgWrapper}>
              <ConditionalRender
                condition={
                  active &&
                  (!!valuationCap || !!discountRate) &&
                  !!imageOnActive
                }
                fallbackElement={<img src={image} alt={title} />}
              >
                <img src={imageOnActive} alt={title} />
              </ConditionalRender>
            </div>
          </Row>

          <ConditionalRender condition={id === Terms.HELP_SET_TERMS && active}>
            <Col items="stretch" gap={10}>
              {customTermsItems.map((item) => (
                <Row
                  items="start"
                  key={item.id}
                  gap={12}
                  className={styles.customTermsItem}
                >
                  <img src={item.img} width={50} alt={item.id} />

                  <Col gap={5}>
                    <Text
                      weight={FontWeight.SEMIBOLD}
                      type={TextTypes.BODY_DEFAULT}
                      className={styles.customItemTitle}
                    >
                      {item.title}
                      <Tooltip
                        title={item.title}
                        kind={TooltipKind.INFO}
                        content={item.tooltip}
                      />
                      {!!values[item.id] && (
                        <Badge size="small" type={BadgeType.SUCCESS}>
                          {item.id === 'valuationCap'
                            ? FormatterHelper.toCurrency(values[item.id]!)
                            : FormatterHelper.toPercentage(values[item.id]!)}
                        </Badge>
                      )}
                    </Text>
                    <Text type={TextTypes.BODY_DEFAULT} align={TextAlign.LEFT}>
                      {item.description}
                    </Text>
                  </Col>

                  <Row
                    items="center"
                    gap={12}
                    justify="end"
                    className="tw-w-[100px] tw-min-w-[100px] tw-self-center"
                  >
                    <ConditionalRender
                      condition={!values[item.id]}
                      fallbackElement={
                        <Disabled
                          disabled={disabled}
                          onClick={(e) => {
                            e.stopPropagation()
                            openModal(item.id)
                          }}
                        >
                          <div>
                            <SharedIcons.PencilModern
                              size={24}
                              color={Color.primary400}
                            />
                          </div>
                        </Disabled>
                      }
                    >
                      <EditButton
                        disabled={disabled}
                        edit={!!values[item.id]}
                        onClick={(e) => {
                          e.stopPropagation()
                          openModal(item.id)
                        }}
                      />
                    </ConditionalRender>

                    <ConditionalRender condition={!!values[item.id]}>
                      <Disabled
                        onClick={(e: MouseEvent<HTMLElement>) => {
                          e.stopPropagation()
                          handleClearTerms(item.id)
                        }}
                        disabled={disabled}
                      >
                        <div className="tw-cursor-pointer">
                          <SharedIcons.Trash
                            color={Color.neutral300}
                            size={24}
                          />
                        </div>
                      </Disabled>
                    </ConditionalRender>
                  </Row>
                </Row>
              ))}
            </Col>
          </ConditionalRender>
        </Col>
      </Row>
    </div>
  )
}

export default TermsListItem
