import { noop } from 'lodash'
import React, {
  createContext,
  type FC,
  type PropsWithChildren,
  useContext
} from 'react'

import { HandleActionFn, type RowData, type TableColumn } from './types'

export interface TableContextData {
  columns: TableColumn[]
  data?: RowData[]
  enableSorting?: boolean
  handleAction?: HandleActionFn
  highlightNewItem?: boolean
}

const TableContext = createContext<TableContextData>({
  columns: [],
  handleAction: noop
})

type TableContextProviderProps = PropsWithChildren<TableContextData>

export const TableContextProvider: FC<any> = ({
  children,
  ...props
}: TableContextProviderProps) => (
  <TableContext.Provider value={props as TableContextData}>
    {children}
  </TableContext.Provider>
)

export const useTableContext = (): TableContextData =>
  useContext<TableContextData>(TableContext)
