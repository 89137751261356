import clsx from 'clsx'
import React, { FC } from 'react'

import { ConditionalRender } from '@/common/components'
import { toCurrencyFormat } from '@/common/utils'

import styles from './Templates.module.scss'

interface TemplateProps {
  id: string
  companyName: string
  roundAmount?: number
  myName: string
  state: string
  highlight: boolean
}

const MfnTemplate: FC<TemplateProps> = (props) => {
  const { id, state, highlight, companyName, roundAmount, myName } = props

  return (
    <div className={styles.wrapper} id={id}>
      <h1>{companyName}</h1>
      <h2>SAFE TERM SHEET</h2>

      <p>
        This is a summary of the principal terms of a SAFE financing (the
        <b>&quot;Financing&quot;</b>) of {companyName}. This term sheet is for
        discussion purposes only and is not intended to be construed as a
        binding agreement. The parties do not intend to be bound until they
        enter into definitive agreements regarding the subject matter of this
        term sheet.
      </p>

      <u>
        <b>Offering Terms</b>
      </u>

      <table>
        <tbody>
          <tr>
            <td>Issuer:</td>
            <td>
              {companyName}, a {state} corporation (the{' '}
              <b>&quot;Company&quot;</b>
              ).
            </td>
          </tr>

          <tr>
            <td>Security:</td>
            <td>
              Simple agreements for future equity (each, a &quot;SAFE,&quot; and
              collectively, the &quot;SAFEs&quot;).
            </td>
          </tr>

          <tr>
            <td>Purchasers:</td>
            <td>
              Investors selected by the Company in its sole discretion (each, a
              &quot;Purchaser,&quot; and collectively, the
              &quot;Purchasers&quot;).
            </td>
          </tr>

          <ConditionalRender condition={!!roundAmount}>
            <tr>
              <td>Goal Amount:</td>
              <td>Up to US${toCurrencyFormat(roundAmount as number)}.</td>
            </tr>
          </ConditionalRender>
        </tbody>
      </table>

      <u className="tw-inline-block tw-mt-25">
        <b>SAFE Terms</b>
      </u>

      <table>
        <tbody>
          <tr>
            <td>Conversion Events:</td>
            <td>
              Each SAFE will convert:
              <p className="tw-mt-15">
                (i) automatically, upon the Company’s issuance of equity
                securities (the <b>“Next Equity Financing”</b>) in a single
                transaction, or series of related transactions with the
                principal purpose of raising capital, pursuant to which the
                Company issues and sells Preferred Stock at a fixed valuation,
                including but not limited to, a pre-money or post-money
                valuation; and
              </p>
              <p>
                (ii) at the Purchaser&apos;s option, in the event of a Corporate
                Transaction (as defined below) while such SAFE remains
                outstanding, into shares of the Company&apos;s common stock (a
                <b>&quot;Corporate Transaction Conversion&quot;</b>).
              </p>
              <p className="!tw-indent-0">
                Securities issued pursuant to the conversion of SAFEs will be
                referred to herein as &quot;Conversion Shares.v
              </p>
            </td>
          </tr>

          <tr>
            <td>Conversion Price:</td>
            <td>
              The price per share of Conversion Shares will be:
              <p className="tw-mt-15">
                (i) with respect to a Next Equity Financing Conversion, the
                price shall be the number of shares of Standard Preferred Stock
                equal to the Purchase Amount divided by the lowest price per
                share of the Standard Preferred Stock; and
              </p>
              <p>
                (ii) with respect to a Corporate Transaction Conversion, the
                Purchase Amount (the <b>“Cash-Out Amount”</b>)
              </p>
            </td>
          </tr>

          <tr>
            <td>Corporate Transaction:</td>
            <td>
              &quot;Corporate Transaction&quot; means (a) a sale by the Company
              of all or substantially all of its assets, (b) a merger of the
              Company with or into another entity (if after such merger the
              holders of a majority of the Company’s voting securities
              immediately prior to the transaction do not hold a majority of the
              voting securities of the successor entity) or (c) the transfer of
              more than 50% of the Company&apos;s voting securities to a person
              or group
            </td>
          </tr>

          <tr>
            <td>MFN Provision</td>
            <td>
              <span className={clsx(highlight && styles.highlightMFN)}>
                If the Company issues any Subsequent Convertible Securities with
                terms more favorable than those listed here the Company will
                promptly provide the Investor with written notice thereof,
                together with a copy of such Subsequent Convertible Securities
                (the “MFN Notice”). In the event the Investor determines that
                the terms of the Subsequent Convertible Securities are
                preferable to the terms here, the Investor will notify the
                Company and the Company will amend the terms to be identical to
                the Subsequent Convertible Securities.
              </span>
            </td>
          </tr>
        </tbody>
      </table>

      <u className="tw-inline-block tw-mt-25">
        <b>Offering Mechanics</b>
      </u>

      <table>
        <tbody>
          <tr>
            <td>Investors:</td>
            <td>
              The Financing is only open to &quot;accredited investors&quot; (as
              defined by the SEC).
            </td>
          </tr>

          <tr>
            <td>Expenses:</td>
            <td>
              The Company and the Purchasers will each bear their own legal and
              other expenses with respect to the Financing.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default MfnTemplate
