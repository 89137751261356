import React, { FC, useState } from 'react'

import { ConditionalRender } from '@/common/components'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import { Row, Tooltip, TooltipKind } from '@/packages/ui'
import { useTableContext } from '@/packages/ui/table/context'

import styles from './TableHeader.module.scss'

const SortingBlock = () => (
  <span className={styles.sortingBlock}>
    <SharedIcons.SortingArrow
      className={styles.sortingIcon}
      color={Color.neutral500}
    />
    <SharedIcons.SortingArrow
      className={styles.sortingIcon}
      color={Color.neutral500}
    />
  </span>
)

const TableHeader: FC = () => {
  const { columns } = useTableContext()

  const [openedFilter, setOpenedFilter] = useState<string | null>(null)

  return (
    <thead>
      <tr>
        {columns.map((column) => {
          const th = (
            <Row items="center" gap={5}>
              {column.title}
              <ConditionalRender condition={!!column.tooltip}>
                <Tooltip
                  small
                  kind={TooltipKind.INFO}
                  content={
                    <div>
                      {typeof column.tooltip === 'string'
                        ? column.tooltip
                        : column.tooltip?.({})}
                    </div>
                  }
                />
              </ConditionalRender>
            </Row>
          )

          return (
            <th key={`header-td-${column.accessor}`}>
              <ConditionalRender
                condition={column.withSorting}
                fallbackElement={th}
              >
                <Row
                  gap={4}
                  items="center"
                  justify="start"
                  className="tw-cursor-pointer"
                >
                  {th}
                  <SortingBlock />
                </Row>
              </ConditionalRender>
            </th>
          )
        })}
      </tr>
    </thead>
  )
}

export default TableHeader
