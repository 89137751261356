import { ICompany } from '@/features/profile/types'
import { ProfileTypes } from '@/features/user/enums'

import { MenuIcons } from './assets/icons/MenuIcons'

const menu = (
  entrepreneurCompanies: ICompany[],
  angelCompanies: ICompany[],
  pendingSafesNumber: number
) => [
  {
    id: ProfileTypes.ENTREPRENEUR,
    icon: MenuIcons.Entrepreneur,
    title: 'Entrepreneur',
    subMenu: entrepreneurCompanies || [],
    description: `Companies (${entrepreneurCompanies.length})`
  },
  {
    id: ProfileTypes.ANGEL,
    icon: MenuIcons.Angel,
    title: 'Angel',
    subMenu: angelCompanies || [],
    description: `Projects (${angelCompanies.length})`,
    pendingSafes: pendingSafesNumber
  }
  // {
  //   id: ProfileTypes.ADVISOR,
  //   icon: MenuIcons.Advisor,
  //   title: 'Advisor',
  //   description: 'Coming soon'
  // }
]

export default menu
