import React, {
  createContext,
  type FC,
  type PropsWithChildren,
  useContext,
  useMemo
} from 'react'

import { ViewSafeAs } from '@/features/safe-note'
import { SafeNoteProps } from '@/features/safe-note/components/SafeNote/SafeNote'

interface CustomData {
  isSafeAccepted: boolean
  viewAsSender: boolean
  viewAsRecipient: boolean
}

export interface SafeNoteContextValue extends SafeNoteProps {
  recipientIndex: number
  setRecipientIndex: (value: number) => void
  customData: CustomData
  onFinish?: () => void
}

type SafeNoteContextProviderProps = PropsWithChildren<
  Omit<SafeNoteContextValue, 'customData'>
>

const SafeNoteContext = createContext<SafeNoteContextValue>(
  {} as SafeNoteContextValue
)

export const SafeNoteContextProvider: FC<SafeNoteContextProviderProps> = ({
  children,
  ...props
}: SafeNoteContextProviderProps) => {
  const memoValue = useMemo(
    () => ({
      ...props,
      customData: {
        isSafeAccepted: props?.isSafeAccepted,
        viewAsSender: props?.viewAs === ViewSafeAs.SENDER,
        viewAsRecipient: props?.viewAs === ViewSafeAs.RECIPIENT
      }
    }),
    [props]
  )
  return (
    <SafeNoteContext.Provider value={memoValue as SafeNoteContextValue}>
      {children}
    </SafeNoteContext.Provider>
  )
}

export const useSafeNoteContext = (): SafeNoteContextValue =>
  useContext<SafeNoteContextValue>(SafeNoteContext)
