import clsx from 'clsx'
import React, { FC, ReactNode, useCallback } from 'react'

import { ClassNameProps } from '@/common/types'

import { SharedIcons } from '../../icons'
import { Text, TextTypes } from '../typography'
import styles from './Info.module.scss'

export enum InfoType {
  WARNING = 'warning',
  SUCCESS = 'success'
}

interface InfoProps extends ClassNameProps {
  type?: InfoType
  textType?: TextTypes
  rounded?: boolean
  centerContent?: boolean
  children?: ReactNode
  iconSize?: number
  textClassName?: string
}

const Info: FC<InfoProps> = ({
  textClassName,
  type = InfoType.WARNING,
  textType = TextTypes.BODY_DEFAULT,
  centerContent,
  rounded = true,
  iconSize = 30,
  children,
  className
}: InfoProps) => {
  const getIcon = useCallback(() => {
    switch (type) {
      case InfoType.WARNING:
        return <SharedIcons.Warning size={iconSize} />
      case InfoType.SUCCESS:
        return <SharedIcons.Checkmark size={iconSize} />

      default:
        return <></>
    }
  }, [type])

  return (
    <div
      className={clsx(
        styles[`${type}-info`],
        rounded && styles.rounded,
        centerContent && styles.center,
        className
      )}
    >
      <div
        className={styles.icon}
        style={{ width: iconSize, height: iconSize }}
      >
        {getIcon()}
      </div>
      <Text type={textType} className={textClassName}>
        {children}
      </Text>
    </div>
  )
}

export default Info
