import clsx from 'clsx'
import React, { FC } from 'react'

import { ConditionalRender } from '@/common/components'
import { toCurrencyFormat } from '@/common/utils'

import styles from './Templates.module.scss'

interface TemplateProps {
  id: string
  companyName: string
  roundAmount?: number
  myName: string
  valuation: number
  discount: number
  state: string
  highlightDiscount: boolean
  highlightValuation: boolean
}

const ValuationAndDiscountTemplate: FC<TemplateProps> = (props) => {
  const {
    id,
    state,
    companyName,
    highlightDiscount,
    highlightValuation,
    roundAmount,
    myName,
    valuation,
    discount
  } = props

  return (
    <div className={styles.wrapper} id={id}>
      <h1>{companyName}</h1>
      <h2>SAFE TERM SHEET</h2>

      <p>
        This is a summary of the principal terms of a SAFE financing (the
        <b>&quot;Financing&quot;</b>) of {companyName}. This term sheet is for
        discussion purposes only and is not intended to be construed as a
        binding agreement. The parties do not intend to be bound until they
        enter into definitive agreements regarding the subject matter of this
        term sheet.
      </p>

      <u>
        <b>Offering Terms</b>
      </u>

      <table>
        <tbody>
          <tr>
            <td>Issuer:</td>
            <td>
              {companyName}, a {state} corporation (the{' '}
              <b>&quot;Company&quot;</b>
              ).
            </td>
          </tr>

          <tr>
            <td>Security:</td>
            <td>
              Simple agreements for future equity (each, a &quot;SAFE,&quot; and
              collectively, the &quot;SAFEs&quot;).
            </td>
          </tr>

          <tr>
            <td>Purchasers:</td>
            <td>
              Investors selected by the Company in its sole discretion (each, a
              &quot;Purchaser,&quot; and collectively, the
              &quot;Purchasers&quot;).
            </td>
          </tr>

          <ConditionalRender condition={!!roundAmount}>
            <tr>
              <td>Goal Amount:</td>
              <td>Up to US${toCurrencyFormat(roundAmount as number)}.</td>
            </tr>
          </ConditionalRender>
        </tbody>
      </table>

      <u className="tw-inline-block tw-mt-25">
        <b>SAFE Terms</b>
      </u>

      <table>
        <tbody>
          <tr>
            <td>Conversion Events:</td>
            <td>
              Each SAFE will convert:
              <p className="tw-mt-15">
                <span
                  className={clsx(
                    highlightValuation && styles.highlightValuation
                  )}
                >
                  (i) automatically, upon the Company&apos;s issuance of equity
                  securities (the <b>&quot;Next Equity Financing&quot;</b>) in a
                  single transaction, or series of related transactions, with
                  aggregate gross proceeds to the Company of at least US$
                  {toCurrencyFormat(valuation)}, excluding proceeds from the
                  issuance of any simple agreements for future equity (including
                  the SAFEs) or convertible debt, into (a) shares of the
                  Company&apos;s capital stock issued to investors in the Next
                  Equity Financing or (b) in the event the Company issues
                  preferred stock with a liquidation preference in the Next
                  Equity Financing, at the Company&apos;s election, shares of a
                  shadow series of preferred stock substantially the same as the
                  series of preferred stock issued in the Next Equity Financing,
                  except that the per share liquidation preference of the shadow
                  series will be equal to the conversion price of the SAFEs (a
                  <b>&quot;Next Equity Financing Conversion&quot;</b>); and
                </span>
              </p>
              <p>
                (ii) at the Purchaser&apos;s option, in the event of a Corporate
                Transaction (as defined below) while such SAFE remains
                outstanding, into shares of the Company&apos;s common stock (a
                <b>&quot;Corporate Transaction Conversion&quot;</b>).
              </p>
              <p className="!tw-indent-0">
                Securities issued pursuant to the conversion of SAFEs will be
                referred to herein as &quot;Conversion Shares.&quot;
              </p>
            </td>
          </tr>

          <tr>
            <td>Conversion Price:</td>
            <td>
              The price per share of Conversion Shares will be:
              <p className="tw-mt-15">
                <span
                  className={clsx(
                    highlightValuation && styles.highlightValuation
                  )}
                >
                  <span
                    className={clsx(
                      highlightDiscount && styles.highlightDiscount
                    )}
                  >
                    (i) with respect to a Next Equity Financing Conversion, the
                    price that is the lesser of (A) {discount}% (the
                    &quot;Discount&quot;) less than the lowest price per share
                    of shares sold in the Next Equity Financing and (B) the
                    price per share implied by a US$
                    {toCurrencyFormat(valuation)} pre-money valuation,
                    calculated based on the Company&apos;s fully diluted
                    capitalization immediately prior to the closing of the Next
                    Equity Financing; and
                  </span>
                </span>
              </p>
              <p>
                <span
                  className={clsx(
                    highlightValuation && styles.highlightValuation
                  )}
                >
                  (ii) with respect to a Corporate Transaction Conversion, the
                  price per share implied by a US${toCurrencyFormat(valuation)}
                  pre-money valuation, calculated based on the Company&apos;s
                  fully diluted capitalization immediately prior to the closing
                  of the Corporate Transaction
                </span>
              </p>
            </td>
          </tr>

          <tr>
            <td>Corporate Transaction Payment:</td>
            <td>
              <p className="!tw-indent-0">
                If a Corporate Transaction occurs before the conversion of the
                SAFEs into Conversion Shares, the Company will pay at the
                closing of the Corporate Transaction to each Purchaser that
                elects not to convert its SAFE in connection with such Corporate
                Transaction the amount such Purchaser paid for its SAFE (a
                &quot;Corporate Transaction Payment&quot;).
              </p>

              <p className="!tw-indent-0">
                &quot;Corporate Transaction&quot; means (a) a sale by the
                Company of all or substantially all of its assets, (b) a merger
                of the Company with or into another entity (if after such merger
                the holders of a majority of the Company’s voting securities
                immediately prior to the transaction do not hold a majority of
                the voting securities of the successor entity) or (c) the
                transfer of more than 50% of the Company&apos;s voting
                securities to a person or group.
              </p>
            </td>
          </tr>
        </tbody>
      </table>

      <u className="tw-inline-block tw-mt-25">
        <b>Offering Mechanics</b>
      </u>

      <table>
        <tbody>
          <tr>
            <td>Investors:</td>
            <td>
              The Financing is only open to &quot;accredited investors&quot; (as
              defined by the SEC).
            </td>
          </tr>

          <tr>
            <td>Expenses:</td>
            <td>
              The Company and the Purchasers will each bear their own legal and
              other expenses with respect to the Financing.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default ValuationAndDiscountTemplate
