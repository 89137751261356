import React, {
  ForwardedRef,
  forwardRef,
  ForwardRefRenderFunction
} from 'react'
import { useToggle } from 'usehooks-ts'

import { conditionalRender } from '@/common/components'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'

import Input from './Input'
import { IInputProps } from './types'

const PasswordInput: ForwardRefRenderFunction<HTMLInputElement, IInputProps> = (
  props: IInputProps,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const [passwordVisible, toggleEye] = useToggle(false)

  return (
    <Input
      ref={ref}
      {...props}
      type={passwordVisible ? 'text' : 'password'}
      addonAfter={conditionalRender(
        passwordVisible,
        <SharedIcons.CrossedEye
          size={20}
          color={Color.neutral300}
          onClick={toggleEye}
        />,
        <SharedIcons.Eye
          size={20}
          color={Color.neutral300}
          onClick={toggleEye}
        />
      )}
    />
  )
}

export default forwardRef(PasswordInput)
