import React, { FC } from 'react'

import { IconProps, withIcon } from '@/common/hocs'

const ArrowDown: FC<IconProps> = withIcon((props) => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M25.938 33.3709C26.1428 33.731 26.6007 33.8568 26.9607 33.652L32.828 30.3147C33.188 30.1099 33.3139 29.652 33.1091 29.292C32.9043 28.9319 32.4464 28.8061 32.0864 29.0109L26.871 31.9774L23.9045 26.762C23.6997 26.402 23.2418 26.2761 22.8818 26.4809C22.5217 26.6857 22.3959 27.1436 22.6007 27.5037L25.938 33.3709ZM1.03843 2.24918C5.61273 2.01447 13.7771 2.7389 19.7972 6.9586C22.7864 9.05384 25.2467 12.0091 26.4969 16.1597C27.7501 20.3203 27.8061 25.7453 25.8667 32.8013L27.3131 33.1989C29.3048 25.9524 29.2892 20.229 27.9332 15.7271C26.5742 11.2153 23.8878 7.994 20.6582 5.73029C14.2407 1.2321 5.679 0.5091 0.961569 0.751147L1.03843 2.24918Z"
      fill="url(#paint0_linear_1067_26601)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1067_26601"
        x1="2.62943e-07"
        y1="2"
        x2="27"
        y2="13"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#86A8FF" stop-opacity="0" />
        <stop offset="1" stop-color="#86A8FF" />
      </linearGradient>
    </defs>
  </svg>
))

export default ArrowDown
