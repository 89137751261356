import clsx from 'clsx'
import React, { PropsWithChildren } from 'react'

import styles from './AnchorLink.module.scss'

interface AnchorLinkProps extends PropsWithChildren {
  to: string
  className?: string
  underline?: boolean
}

const AnchorLink = ({
  to,
  className,
  underline,
  children
}: AnchorLinkProps) => {
  const sharedProps = {
    className: clsx(styles.anchorLink, className),
    href: `#${to}`
  }

  if (underline) {
    return (
      <a {...sharedProps}>
        <u>{children}</u>
      </a>
    )
  }

  return <a {...sharedProps}>{children}</a>
}

export default AnchorLink
