import React, { FC, ReactNode } from 'react'

import { IStep } from '../types'
import styles from './Step.module.scss'

const Step: FC<IStep & { children?: ReactNode }> = ({ children }) => (
  <div className={styles.container}>{children}</div>
)

export default Step
