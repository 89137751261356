import { FC } from 'react'

import { IconProps, withIcon } from '@/common/hocs'

const MakeInvestment: FC<IconProps> = withIcon((props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.67188 14.3298C8.67188 15.6198 9.66188 16.6598 10.8919 16.6598H13.4019C14.4719 16.6598 15.3419 15.7498 15.3419 14.6298C15.3419 13.4098 14.8119 12.9798 14.0219 12.6998L9.99187 11.2998C9.20187 11.0198 8.67188 10.5898 8.67188 9.36984C8.67188 8.24984 9.54187 7.33984 10.6119 7.33984H13.1219C14.3519 7.33984 15.3419 8.37984 15.3419 9.66984"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 6V18"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 21.998C17.5228 21.998 22 17.5209 22 11.998C22 6.4752 17.5228 1.99805 12 1.99805C6.47715 1.99805 2 6.4752 2 11.998C2 17.5209 6.47715 21.998 12 21.998Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
))

const ChangeInvestmentStatus: FC<IconProps> = withIcon((props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.99609 8.5H11.4961"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.99609 16.5H7.99609"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.4961 16.5H14.4961"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.9961 12.03V16.11C21.9961 19.62 21.1061 20.5 17.5561 20.5H6.43609C2.88609 20.5 1.99609 19.62 1.99609 16.11V7.89C1.99609 4.38 2.88609 3.5 6.43609 3.5H14.4961"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.0764 4.12982L15.3664 7.83982C15.2264 7.97982 15.0864 8.25982 15.0564 8.45982L14.8564 9.87982C14.7864 10.3898 15.1464 10.7498 15.6564 10.6798L17.0764 10.4798C17.2764 10.4498 17.5564 10.3098 17.6964 10.1698L21.4064 6.45982C22.0464 5.81982 22.3464 5.07982 21.4064 4.13982C20.4564 3.18982 19.7164 3.48982 19.0764 4.12982Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.5469 4.66016C18.8669 5.79016 19.7469 6.67016 20.8669 6.98016"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
))

const ViewSafe: FC<IconProps> = withIcon((props) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.25 6.41683V15.5835C19.25 18.3335 17.875 20.1668 14.6667 20.1668H7.33333C4.125 20.1668 2.75 18.3335 2.75 15.5835V6.41683C2.75 3.66683 4.125 1.8335 7.33333 1.8335H14.6667C17.875 1.8335 19.25 3.66683 19.25 6.41683Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.2891 4.125V5.95833C13.2891 6.96667 14.1141 7.79167 15.1224 7.79167H16.9557"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.33594 11.917H11.0026"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.33594 15.583H14.6693"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
))

const DeleteSafe: FC<IconProps> = withIcon((props) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.25 5.48177C16.1975 5.17927 13.1267 5.02344 10.065 5.02344C8.25 5.02344 6.435 5.1151 4.62 5.29844L2.75 5.48177"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.79492 4.556L7.99659 3.35516C8.14326 2.48433 8.25325 1.8335 9.80242 1.8335H12.2041C13.7533 1.8335 13.8724 2.521 14.0099 3.36433L14.2116 4.556"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.2771 8.37842L16.6813 17.6093C16.5804 19.0484 16.4979 20.1668 13.9404 20.1668H8.05542C5.49792 20.1668 5.41542 19.0484 5.31458 17.6093L4.71875 8.37842"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.4707 15.125H12.5232"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.70508 11.4585H13.2884"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
))

const SendReminder: FC<IconProps> = withIcon((props) => (
  <svg
    width="19"
    height="20"
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.85906 5.50333L12.5803 3.26292C15.5966 2.2575 17.2353 3.90417 16.2378 6.92042L13.9974 13.6417C12.4932 18.1621 10.0232 18.1621 8.51906 13.6417L7.85406 11.6467L5.85906 10.9817C1.33865 9.4775 1.33865 7.01542 5.85906 5.50333Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8 11.3062L10.8342 8.46411"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
))

export default {
  ViewSafe,
  MakeInvestment,
  SendReminder,
  ChangeInvestmentStatus,
  DeleteSafe
}
