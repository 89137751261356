import React, { FC } from 'react'

import { TeamMemberPermission } from '@/features/user'
import { SharedIcons } from '@/packages/icons'
import { Button, FontWeight, Row, Text, TextTypes } from '@/packages/ui'

const FormatOptionLabel: FC<any> = ({
  value,
  label,
  onClick,
  description,
  disabled
}) => (
  <div className="custom-option">
    <Row gap={30} justify="between">
      <Row items="center" gap={4}>
        {value === TeamMemberPermission.CREATE && disabled && (
          <SharedIcons.Lock size={18} className="custom-option__icon" />
        )}
        <Text
          type={TextTypes.BODY_SMALL}
          weight={FontWeight.SEMIBOLD}
          className="custom-option__label"
        >
          {label}
        </Text>
      </Row>

      {value === TeamMemberPermission.CREATE && disabled && (
        <Button width="fit" className="custom-option__btn" onClick={onClick}>
          Upgrade
        </Button>
      )}
    </Row>
    <Text type={TextTypes.BODY_SMALL} className="custom-option__description">
      {description}
    </Text>
  </div>
)

export default FormatOptionLabel
