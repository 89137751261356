import clsx from 'clsx'
import React, { FC, useCallback } from 'react'

import { ConditionalRender } from '@/common/components'
import { SharedIcons } from '@/packages/icons'
import { Disabled, Row, Text, TextTypes } from '@/packages/ui'

import styles from './DocumentSearch.module.scss'

export interface DocumentSearchProps {
  searchValue?: string
  matchesCount?: number
  currentWordIndex?: number
  prevButtonDisabled?: boolean
  nextButtonDisabled?: boolean
  onSearch?: (value: string) => void
  onPrevWord?: () => void
  onNextWord?: () => void
}

const DocumentSearch: FC<DocumentSearchProps> = ({
  searchValue,
  onSearch,
  matchesCount = 0,
  currentWordIndex = 0,
  nextButtonDisabled,
  prevButtonDisabled,
  onPrevWord,
  onNextWord
}) => {
  const clearSearch = useCallback(() => {
    onSearch?.('')
  }, [onSearch])

  return (
    <Row gap={10} items="center">
      <div className={clsx(styles.container, styles.active)}>
        <input
          type="text"
          className={styles.input}
          placeholder="Search"
          value={searchValue}
          spellCheck={false}
          onChange={(e) => onSearch?.(e.target.value)}
        />
        <ConditionalRender condition={!!searchValue}>
          <div className={styles.control}>
            <Text type={TextTypes.BODY_EXTRA_SMALL} className={styles.text}>
              {currentWordIndex}&nbsp;/&nbsp;{matchesCount}
            </Text>

            <SharedIcons.Cross
              size={15}
              className={styles.closeIcon}
              onClick={clearSearch}
              clickable
            />
          </div>
        </ConditionalRender>
        <SharedIcons.Search className={styles.searchIcon} />
      </div>
      <ConditionalRender condition={!!searchValue}>
        <Row gap={5}>
          <Disabled disabled={prevButtonDisabled}>
            <SharedIcons.Arrow
              onClick={onPrevWord}
              clickable
              size={20}
              className={styles.leftArrow}
            />
          </Disabled>

          <Disabled disabled={nextButtonDisabled}>
            <SharedIcons.Arrow onClick={onNextWord} clickable size={20} />
          </Disabled>
        </Row>
      </ConditionalRender>
    </Row>
  )
}

export default DocumentSearch
