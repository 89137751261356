import React, { FC, MouseEvent } from 'react'

import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import { Button } from '@/packages/ui'

interface EditButtonProps {
  edit: boolean
  disabled?: boolean
  className?: string
  onClick?: (e: MouseEvent<HTMLElement>) => void
}

const EditButton: FC<EditButtonProps> = ({
  edit,
  disabled,
  onClick,
  className
}) => (
  <Button
    size="sm"
    appearance="secondary"
    width="fit"
    disabled={disabled}
    onClick={onClick}
    className={className}
    textClassName="!tw-font-semibold"
  >
    {edit ? (
      <>
        <SharedIcons.EditComplicated className="tw-mr-[5px]" />
        EDIT
      </>
    ) : (
      <>
        <SharedIcons.PlusThin size={19} color={Color.primary400} />
        ADD
      </>
    )}
  </Button>
)

export default EditButton
