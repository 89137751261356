import { noop } from 'lodash'
import React, { FC, useState } from 'react'

import { ConditionalRender } from '@/common/components'
import { StoreType, withStore } from '@/common/store'
import { TeamMembersTableRow } from '@/features/dashboard'
import { ICompanyTeamMember, IOwner } from '@/features/profile/types'
import { TeamMemberPermission } from '@/features/user'
import { Color } from '@/packages/palette'
import { Col, FontWeight, Loader, Row, Text, TextTypes } from '@/packages/ui'

import styles from './TeamMembersPopup.module.scss'

interface TeamMembersPopupProps {
  owner: IOwner
  companyId: string
  teamMembers: ICompanyTeamMember[]
}

const mapStateToProps = ({ user }: StoreType) => ({
  deleteTeamMember: user.deleteTeamMember,
  updateTeamMember: user.updateTeamMember
  // refetchCompany: user.refetchCompany,
})

const TeamMembersPopup: FC<
  TeamMembersPopupProps & ReturnType<typeof mapStateToProps>
> = (props) => {
  const { teamMembers, owner, companyId, deleteTeamMember, updateTeamMember } =
    props

  const [loading, setLoading] = useState(false)

  const handleDeleteTeamMember = async (id: string) => {
    setLoading(() => true)

    try {
      await deleteTeamMember({
        data: {
          companyId,
          id
        }
      })
    } finally {
      setLoading(() => false)
    }
  }

  const handleChangePermission = async (
    id: string,
    permission: TeamMemberPermission
  ) => {
    setLoading(() => true)

    try {
      await updateTeamMember({
        data: {
          companyId,
          teamMemberId: id,
          values: { permission }
        }
      })
    } finally {
      setLoading(() => false)
    }
  }

  return (
    <div className="tw-min-w-[300px]">
      <Row
        justify="between"
        items="center"
        gap={10}
        className={styles.headingWrapper}
      >
        <Text
          type={TextTypes.BODY_DEFAULT}
          color={Color.neutral500}
          weight={FontWeight.SEMIBOLD}
        >
          Team Members:
        </Text>
        <Text
          type={TextTypes.BODY_DEFAULT}
          weight={FontWeight.SEMIBOLD}
          color={Color.neutral400}
        >
          {teamMembers.length + 1}
        </Text>
      </Row>

      <ConditionalRender
        condition={!loading}
        fallbackElement={<Loader width="100%" />}
      >
        <Col items="stretch" className={styles.membersList}>
          <TeamMembersTableRow
            id="owner"
            minimized
            name={owner.fullName}
            email={owner.email}
            avatar={owner.image}
            permission="owner"
            title={owner.position}
            handleChangePermission={noop}
            handleDeleteTeamMember={noop}
          />

          {teamMembers.map((teamMember) => (
            <TeamMembersTableRow
              minimized
              key={teamMember.id}
              id={teamMember.userId}
              name={teamMember.user.fullName}
              email={teamMember.user.email}
              avatar={teamMember.user.image}
              permission={teamMember.permission}
              inviteStatus={teamMember.inviteStatus}
              title={teamMember.position}
              handleChangePermission={handleChangePermission}
              handleDeleteTeamMember={handleDeleteTeamMember}
            />
          ))}
        </Col>
      </ConditionalRender>
    </div>
  )
}

export default withStore(mapStateToProps)(TeamMembersPopup)
