import React, { FC } from 'react'

import NoteIcon from '@/features/dashboard/assets/images/no-safe-notes.png'
import { SAFE_NOTES_TABS } from '@/features/dashboard/enums'
import NoTermSheets from '@/features/safe-note/assets/images/no-term-sheets.png'
import { NoItems } from '@/packages/ui/table/views'

interface NoSafeNotesBlockProps {
  activeTab?: any
  isEntrepreneur: boolean
  onClick: (type: 'safe-note' | 'term-sheet') => void
}

const NoSafeNotesBlock: FC<NoSafeNotesBlockProps> = (props) => {
  const { activeTab, onClick, isEntrepreneur } = props

  const isTermSheet = activeTab === SAFE_NOTES_TABS.TERM_SHEET

  const content = {
    title: isTermSheet
      ? 'You don’t have any term sheets'
      : `You don’t have any ${
          activeTab === SAFE_NOTES_TABS.DRAFT ? 'drafts' : 'SAFE Notes'
        }`,
    description: isTermSheet
      ? 'Once you create or receive a term sheet, it’ll appear here.'
      : 'Once you create or receive a SAFE, it’ll appear here.',
    image: isTermSheet ? NoTermSheets : NoteIcon,
    // eslint-disable-next-line no-nested-ternary
    action: !isEntrepreneur
      ? undefined
      : isTermSheet
      ? 'Create Term Sheet'
      : 'Create SAFE'
  }

  const onActionClick = () => {
    onClick(isTermSheet ? 'term-sheet' : 'safe-note')
  }

  return (
    <NoItems
      onActionClick={onActionClick}
      action={content.action}
      title={content.title}
      description={content.description}
      img={content.image}
    />
  )
}

export default NoSafeNotesBlock
