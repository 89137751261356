import { action, makeObservable, observable, runInAction } from 'mobx'

import { RootStoreType } from '@/common/store'
import StoreHandler from '@/common/store/StoreHandler'
import { TermSheetRequests } from '@/features/term-sheet/api/TermSheetRequests'
import {
  CreateTermSheetDto,
  IPendingTermSheet,
  ITermSheet,
  UpdateTermSheetDto
} from '@/features/term-sheet/api/types'

class TermSheetStore extends StoreHandler {
  @observable termSheetsList: ITermSheet[] = []

  @observable pendingTermSheets: IPendingTermSheet[] = []

  constructor(rootStore: RootStoreType) {
    super(rootStore)
    makeObservable(this)
  }

  @action
  public create = this.execute<CreateTermSheetDto, ITermSheet>(
    async ({ data }) => TermSheetRequests.create(data),
    'create'
  )

  public delete = this.execute<{
    id: string
  }>(async ({ data }) => {
    await TermSheetRequests.delete(data.id)

    runInAction(() => {
      this.termSheetsList = this.termSheetsList.filter(
        (termSheet) => termSheet.id !== data.id
      )
    })
  }, 'delete')

  @action
  public fetchAll = this.execute<{
    entrepreneurCompanyId?: string
    angelCompanyId?: string
  }>(async ({ data }) => {
    const response = await TermSheetRequests.fetchAll(
      data.entrepreneurCompanyId,
      data.angelCompanyId
    )

    if (response) {
      runInAction(() => {
        this.termSheetsList = response
      })
    }
  }, 'fetchAll')

  @action
  public deleteRecipient = this.execute<{
    id: string
    userId: string
  }>(
    async ({ data, options }) => {
      await TermSheetRequests.deleteRecipient(data.id, data.userId)

      options?.onSuccess?.()
    },
    { loaderName: 'deleteRecipient', manualOnSuccess: true }
  )

  @action
  public fetchOne = this.execute<{ id: string }>(
    async ({ data }) => TermSheetRequests.fetchOne(data.id),
    'fetchOne'
  )

  @action
  public updateTermSheet = this.execute<{
    id: string
    dto: UpdateTermSheetDto
  }>(
    async ({ data }) => TermSheetRequests.updateTermSheet(data.id, data.dto),
    'update'
  )

  @action
  public fetchPendingTermSheets = this.execute(async () => {
    const response = await TermSheetRequests.fetchPendingTermSheets()

    if (response) {
      runInAction(() => {
        this.pendingTermSheets = response
      })
    }
  }, 'fetchPendingTermSheets')
}

export default TermSheetStore
