import Angel from './Angel.svg'
import AngelBig from './angel-big.png'
import BackgroundElement from './background-element.svg'
import Discount from './discount.png'
import DiscountBig from './Discount-Big.png'
import Freelancer from './Agency.svg'
import FreelancerBig from './freelancer-big.png'
import HelpSetTerms from './help-set-terms.png'
import MfnDocument from './mfn-document.png'
import EnableMfn from './MFN.svg'
import MfnTerms from './mfn-terms.png'
import OnlySafe from './only-safe.svg'
import SafePlusCash from './safe-plus-cash.svg'
import TermsSet from './terms-set.png'
import Valuation from './valuation.png'
import ValuationBig from './Valuation-Big.png'
import Reminder from './reminder.png'
import LoginToSign from './login-to-sign.png'
import MfnInfo from './mfn-info-modal.png'
import TermsInfo from './terms-info-modal.png'
import ErrorWarning from './error-warning.png'
import AcceptSafe from './accept-safe.png'
import DeclineSafe from './decline-safe.png'

export const SafeNoteImages = {
  Angel,
  AngelBig,
  Freelancer,
  FreelancerBig,
  OnlySafe,
  EnableMfn,
  SafePlusCash,
  MfnTerms,
  HelpSetTerms,
  TermsSet,
  BackgroundElement,
  MfnDocument,
  Discount,
  DiscountBig,
  Valuation,
  ValuationBig,
  Reminder,
  LoginToSign,
  MfnInfo,
  TermsInfo,
  ErrorWarning,
  AcceptSafe,
  DeclineSafe
}
