import api, { FORM_DATA_HEADERS, withParams, withQuery } from '@/common/api'
import {
  ICompanySummary,
  IEntrepreneurProfile,
  UpdateCompanyValues
} from '@/features/profile/api/types'
import { ICompany } from '@/features/profile/types'
import { ITeamMember } from '@/features/user'
import { ProfileTypes, VerificationStatus } from '@/features/user/enums'

export const PROFILE_ENDPOINTS = {
  FETCH_ONE: '/company/:companyId',
  FETCH_SINGLE_SUMMARY: '/company/:id/summary',
  UPDATE_VERIFICATION_STATUS: '/company/:companyId/verification-status',
  FETCH_SUMMARIES: '/company/summary',
  ADD_TEAM_MEMBER: '/company/:companyId/team-member'
}

export class ProfileRequests {
  static async fetchOne(companyId: string): Promise<IEntrepreneurProfile> {
    return api.get(withParams(PROFILE_ENDPOINTS.FETCH_ONE, { companyId }))
  }

  static async fetchSingleSummary(id: string): Promise<ICompanySummary> {
    return api.get(withParams(PROFILE_ENDPOINTS.FETCH_SINGLE_SUMMARY, { id }))
  }

  static async fetchSummaries(
    type?: ProfileTypes,
    angelCompanyId?: string
  ): Promise<ICompanySummary[]> {
    const query: any = {}

    if (type) {
      query.type = type
    }

    query.includeDeleted = true

    if (angelCompanyId) {
      query.viaAngelCompany = angelCompanyId
    }

    return api.get(
      !!type || !!angelCompanyId
        ? withQuery(PROFILE_ENDPOINTS.FETCH_SUMMARIES, query)
        : PROFILE_ENDPOINTS.FETCH_SUMMARIES
    )
  }

  static async updateCompany(
    companyId: string,
    values: UpdateCompanyValues
  ): Promise<ICompany> {
    return api.patch(
      withParams(PROFILE_ENDPOINTS.FETCH_ONE, { companyId }),
      values,
      { headers: FORM_DATA_HEADERS }
    )
  }

  static async updateVerificationStatus(
    companyId: string,
    status: VerificationStatus
  ): Promise<ICompany> {
    return api.patch(
      withParams(PROFILE_ENDPOINTS.UPDATE_VERIFICATION_STATUS, { companyId }),
      { status }
    )
  }

  static async addTeamMember(
    companyId: string,
    member: ITeamMember
  ): Promise<IEntrepreneurProfile> {
    return api.post(
      withParams(PROFILE_ENDPOINTS.ADD_TEAM_MEMBER, { companyId }),
      { teamMembers: [member] }
    )
  }
}
