import clsx from 'clsx'
import React, { FC } from 'react'

import { DateHelper } from '@/common/helpers'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import {
  Col,
  FontWeight,
  Row,
  Select,
  Text,
  TextTypes,
  Tooltip
} from '@/packages/ui'
import { LinkColumn } from '@/packages/ui/table/columns'
import { useTableContext } from '@/packages/ui/table/context'
import {
  RowData,
  TableColumn,
  TableColumnType
} from '@/packages/ui/table/types'

import styles from './TableBody.module.scss'

const TableBody: FC = () => {
  const { columns, data, handleAction, highlightNewItem } = useTableContext()

  const renderCells = (row: RowData) =>
    columns.map((column: TableColumn, idx) => {
      let value = column?.valueExtractor?.(row) ?? row[column.accessor]

      switch (column.type) {
        case TableColumnType.CUSTOM: {
          const { Component } = column
          value = <Component row={row} accessor={column.accessor} />
          break
        }

        case TableColumnType.DATE: {
          value = (
            <Text type={TextTypes.BODY_SMALL} color={Color.neutral300}>
              {DateHelper.toPrettyFormat(value)}
            </Text>
          )

          break
        }

        case TableColumnType.LINK: {
          value = (
            <LinkColumn column={column} row={row} handleAction={handleAction} />
          )

          break
        }

        case TableColumnType.OPTIONS: {
          const options = (
            <Col items="start">
              {column.options.map((option) => (
                <Row
                  gap={5}
                  items="center"
                  className={clsx('tw-cursor-pointer', styles.option)}
                  key={`option-${option.accessor}-${row.id}`}
                  onClick={() =>
                    handleAction?.({
                      action: option.accessor,
                      accessor: column.accessor,
                      row
                    })
                  }
                >
                  {option.icon}
                  <Text type={TextTypes.BODY_SMALL} weight={FontWeight.MEDIUM}>
                    {option.title}
                  </Text>
                </Row>
              ))}
            </Col>
          )

          value = (
            <Tooltip
              interactive
              content={options}
              trigger="click"
              placement="bottom"
              className="tw-py-[12px]"
            >
              <div className={styles.arrowHolder}>
                <SharedIcons.Dots size={15} color={Color.neutral400} />
              </div>
            </Tooltip>
          )

          break
        }

        case TableColumnType.DETAILS: {
          value = (
            <div className={styles.arrowHolder}>
              <SharedIcons.Arrow color={Color.neutral0} />
            </div>
          )

          break
        }

        case TableColumnType.TEXT: {
          value = (
            <Text type={TextTypes.BODY_SMALL} color={Color.neutral400}>
              {row[column.accessor] || '-'}
            </Text>
          )

          break
        }

        case TableColumnType.DELETE: {
          value = (
            <div className={clsx(styles.arrowHolder, styles.delete)}>
              <SharedIcons.Trash color={Color.neutral0} />
            </div>
          )

          break
        }

        case TableColumnType.SELECT: {
          value = (
            <Select
              small
              name={column.accessor}
              value={row[column.accessor]}
              options={column.options}
            />
          )
          break
        }

        default: {
          break
        }
      }

      return <td key={idx}>{value}</td>
    })

  const renderRow = (row: RowData, index: number) => (
    <tr
      key={`row-${row.id || index}`}
      className={clsx(highlightNewItem && index === 0 && styles.new)}
    >
      {renderCells(row)}
    </tr>
  )

  return <tbody>{data?.map(renderRow)}</tbody>
}

export default TableBody
