import { RowData } from '@/packages/ui/table/types'

const search = (
  searchFields: string[] | undefined,
  searchValue: string | undefined,
  rows: RowData | undefined
) => {
  if (!Array.isArray(rows)) return []
  if (!searchValue || !searchFields) return rows

  const filtered = rows.filter((row: any) => {
    let isFound = false

    searchFields.forEach((field) => {
      if (row?.[field]?.toLowerCase?.().includes(searchValue.toLowerCase())) {
        isFound = true
      }
    })

    return isFound
  })

  return filtered
}

export default search
