import React, { FC } from 'react'

import { IconProps, withIcon } from '@/common/hocs'

const BankIcon: FC<IconProps> = withIcon((props) => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" {...props}>
    <path
      d="M10.6417 1.7917L18.1417 4.79168C18.4333 4.90835 18.6667 5.25834 18.6667 5.56667V8.33335C18.6667 8.79168 18.2917 9.16668 17.8333 9.16668H2.83333C2.375 9.16668 2 8.79168 2 8.33335V5.56667C2 5.25834 2.23334 4.90835 2.525 4.79168L10.025 1.7917C10.1917 1.72503 10.475 1.72503 10.6417 1.7917Z"
      stroke="#525456"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.6667 18.3333H2V15.8333C2 15.375 2.375 15 2.83333 15H17.8333C18.2917 15 18.6667 15.375 18.6667 15.8333V18.3333Z"
      stroke="#525456"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.66602 14.9998V9.1665"
      stroke="#525456"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7 14.9998V9.1665"
      stroke="#525456"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.333 14.9998V9.1665"
      stroke="#525456"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.666 14.9998V9.1665"
      stroke="#525456"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17 14.9998V9.1665"
      stroke="#525456"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.16602 18.3335H19.4993"
      stroke="#525456"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.333 7.0835C11.0234 7.0835 11.583 6.52385 11.583 5.8335C11.583 5.14314 11.0234 4.5835 10.333 4.5835C9.64265 4.5835 9.08301 5.14314 9.08301 5.8335C9.08301 6.52385 9.64265 7.0835 10.333 7.0835Z"
      stroke="#525456"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
))

export default BankIcon
