import { useCallback, useEffect, useRef } from 'react'

import { NotificationRequests } from '../api/NotificationRequests'
import IncomingSafeNote from './Types/IncomingSafeNote'
import SignedSafeNote from './Types/SignedSafeNote'
import TeamMemberRequest from './Types/TeamMemberRequest'

interface Notification {
  id: string
  type: 'signed_safe_note' | 'team_member_request' | 'incoming_safe_note'
  isRead: boolean
}

interface NotificationsListProps {
  notifications: Notification[]
  className?: string
  updateNotificationCount?: (count: number) => void
}

const notificationComponents = {
  signed_safe_note: SignedSafeNote,
  team_member_request: TeamMemberRequest,
  incoming_safe_note: IncomingSafeNote
}

const NotificationsList: React.FC<NotificationsListProps> = ({
  notifications,
  className,
  updateNotificationCount
}) => {
  const containerRef = useRef<HTMLDivElement>(null)

  const markRead = useCallback((ids: string[]) => {
    if (ids.length) NotificationRequests.markReadNotifications(ids)
  }, [])

  const handleIntersect: IntersectionObserverCallback = useCallback(
    (entries, observer) => {
      const unreadNotificationIds = entries
        .filter(
          (entry) =>
            entry.isIntersecting &&
            entry.target.getAttribute('data-isread') === 'false'
        )
        .map((entry) => {
          const id = entry.target.getAttribute('id')
          observer.unobserve(entry.target)
          return id as string
        })

      markRead(unreadNotificationIds)

      if (unreadNotificationIds.length) {
        updateNotificationCount?.(unreadNotificationIds.length)
      }
    },
    [markRead]
  )

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersect, {
      threshold: 0.1
    })
    const elements =
      containerRef.current?.querySelectorAll('.notification-item') || []

    elements.forEach((element) => observer.observe(element))

    return () => {
      elements.forEach((element) => observer.unobserve(element))
    }
  }, [notifications, handleIntersect])

  return (
    <div ref={containerRef} className={className}>
      {notifications.map((notification) => {
        const NotificationComponent = notificationComponents[notification.type]
        return NotificationComponent ? (
          <NotificationComponent
            key={notification.id}
            // @ts-ignore
            notification={notification}
          />
        ) : null
      })}
    </div>
  )
}

export default NotificationsList
