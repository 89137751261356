import React, { FC } from 'react'

import advantagesList from '@/features/dashboard/modals/WorkspaceWelcomeModal/advantagesList'
import { ProfileTypes } from '@/features/user/enums'
import { Color } from '@/packages/palette'
import {
  Col,
  FontWeight,
  Heading,
  HeadingTypes,
  IModalWithCloseFn,
  Modal,
  Spacer,
  Text,
  TextAlign,
  TextTypes
} from '@/packages/ui'

import styles from './WorkspaceWelcomeModal.module.scss'

const MODAL_ID = 'welcome-screen-modal'

interface WorkspaceWelcomeModalProps extends IModalWithCloseFn {
  type: ProfileTypes
}

const WorkspaceWelcomeModal: FC<WorkspaceWelcomeModalProps> = (props) => {
  const { type, closeModal } = props

  return (
    <Modal
      size="md"
      id={MODAL_ID}
      visible
      setVisible={closeModal}
      className={styles.modal}
    >
      <div>
        <Heading type={HeadingTypes.H3} align={TextAlign.CENTER}>
          Features in the {type} workspace
        </Heading>

        <Spacer size={8} />
        <Text
          type={TextTypes.BODY_SMALL}
          align={TextAlign.CENTER}
          color={Color.neutral400}
        >
          Check out what MySAFEnotes has for entrepreneurs. From preparing term
          sheets to receiving investments, you’ll find everything you need to
          send SAFEs and raise funding for your startup.
        </Text>

        <div className={styles.advantagesList}>
          {advantagesList[type].map((item) => (
            <Col
              gap={14}
              justify="between"
              items="center"
              className={styles.advantageItem}
              key={item.title}
            >
              <img width="100%" src={item.icon} alt="" />

              <Col items="stretch" className="tw-self-stretch" gap={2}>
                <Text
                  align={TextAlign.CENTER}
                  type={TextTypes.BODY_MAIN}
                  weight={FontWeight.SEMIBOLD}
                  color={Color.primary400}
                >
                  {item.title}
                </Text>
                <Text
                  type={TextTypes.BODY_SMALL}
                  align={TextAlign.CENTER}
                  color={Color.neutral500}
                >
                  {item.description}
                </Text>
              </Col>
            </Col>
          ))}
        </div>
      </div>
    </Modal>
  )
}

export default WorkspaceWelcomeModal
