import jwtDecode from 'jwt-decode'

export function getInitialsFromName(name = '') {
  if (!name || name.trim() === '') return '-'

  const parts = name.split(' ')
  if (parts.length === 1) {
    return parts[0].charAt(0).toUpperCase()
  }

  return parts[0].charAt(0).toUpperCase() + parts[1].charAt(0).toUpperCase()
}

export function toCurrencyFormat(
  num: number,
  prefix: string | null = null,
  minDigits = 0
): string {
  const result = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: minDigits,
    maximumFractionDigits: 2
  }).format(+num)

  if (prefix) {
    return `${prefix}${result}`
  }

  return result
}

export function capitalize(text: string, multiwords?: boolean) {
  if (!text) return ''

  if (multiwords) {
    const words = text.split(' ')

    return words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }
  return text.charAt(0).toUpperCase() + text.slice(1)
}

export function decodeToken(token: string): { [key: string]: any } {
  if (!token) return {}
  return jwtDecode(token)
}
