import api, { withParams, withQuery } from '@/common/api'

import {
  CreateTermSheetDto,
  IPendingTermSheet,
  ITermSheet,
  UpdateTermSheetDto
} from './types'

export const TERM_SHEET_ENDPOINTS = {
  CREATE: '/term-sheet',
  FETCH_ALL: '/term-sheet',
  FETCH_ONE: '/term-sheet/:id',
  UPDATE: '/term-sheet/:id/update-user',
  FETCH_PENDING_SHEETS: '/term-sheet/pending',
  DELETE_SHEET: '/term-sheet/:id',
  DELETE_RECIPIENT: '/term-sheet/:id/user/:userId'
}

export class TermSheetRequests {
  static async create(dto: CreateTermSheetDto): Promise<ITermSheet> {
    const formData = { ...dto }

    if (!formData.roundAmount) {
      delete formData.roundAmount
    }

    if (!formData.discountRate || formData.mfn) {
      delete formData.discountRate
    }

    return api.post(TERM_SHEET_ENDPOINTS.CREATE, formData)
  }

  static async fetchOne(id: string): Promise<ITermSheet | IPendingTermSheet> {
    return api.get(
      withParams(TERM_SHEET_ENDPOINTS.FETCH_ONE, { id: id.trim() })
    )
  }

  static async deleteRecipient(
    id: string,
    userId: string
  ): Promise<ITermSheet> {
    return api.delete(
      withParams(TERM_SHEET_ENDPOINTS.DELETE_RECIPIENT, { id, userId })
    )
  }

  static async delete(id: string): Promise<boolean> {
    return api.delete(withParams(TERM_SHEET_ENDPOINTS.DELETE_SHEET, { id }))
  }

  static async updateTermSheet(
    id: string,
    dto: UpdateTermSheetDto
  ): Promise<ITermSheet> {
    return api.patch(
      withParams(TERM_SHEET_ENDPOINTS.UPDATE, { id: id.trim() }),
      dto
    )
  }

  static async fetchAll(
    entrepreneurCompanyId?: string,
    angelCompanyId?: string
  ): Promise<ITermSheet[]> {
    const query: { entrepreneurCompanyId?: string; angelCompanyId?: string } =
      {}

    if (!!entrepreneurCompanyId) {
      query.entrepreneurCompanyId = entrepreneurCompanyId
    }

    if (!!angelCompanyId) {
      query.angelCompanyId = angelCompanyId
    }

    return api.get(withQuery(TERM_SHEET_ENDPOINTS.FETCH_ALL, query))
  }

  static async fetchPendingTermSheets(): Promise<IPendingTermSheet[]> {
    return api.get(TERM_SHEET_ENDPOINTS.FETCH_PENDING_SHEETS)
  }

  // static async acceptSafeNote(id: string, companyId: string): Promise<boolean> {
  //   return api.post(
  //     withParams(SAFE_NOTE_ENDPOINTS.ACCEPT_SAFE_NOTE, { id, companyId })
  //   )
  // }
}
