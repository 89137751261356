import clsx from 'clsx'
import React, { FC } from 'react'
import Sticky from 'react-stickynode'

import { Avatar, ConditionalRender, PulsatingCircle } from '@/common/components'
import { toCurrencyFormat } from '@/common/utils'
import { highlightsColorMapper } from '@/features/safe-note'
import { TermsProps } from '@/features/term-sheet/layout/CreateTermSheetLayout/CreateTermSheetLayout'
import { IUser } from '@/features/user'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import { Button, Col, FontWeight, Row, Text, TextTypes } from '@/packages/ui'

import styles from './TermSheetSidebar.module.scss'

interface IHighlights {
  mfn: boolean
  discountRate: boolean
  valuationCap: boolean
}

interface TermSheetSidebarProps {
  highlights: IHighlights
  setHighlights: (highlights: any) => void
  terms: TermsProps
  recipients: IUser[]
  owner: IUser | null
}

const TermSheetSidebar: FC<TermSheetSidebarProps> = (props) => {
  const { owner, highlights, setHighlights, recipients, terms } = props

  const renderItem = (
    id: 'discountRate' | 'mfn' | 'valuationCap',
    title: string,
    value?: string
  ) => (
    <div className={styles.controller}>
      <div
        className={clsx(styles.toggle, styles[id])}
        onClick={() => setHighlights({ [id]: !highlights[id] })}
      >
        <ConditionalRender
          condition={highlights[id]}
          fallbackElement={
            <SharedIcons.ClosedEye
              size={18}
              color={highlightsColorMapper[id].inactive}
            />
          }
        >
          <SharedIcons.Eye size={18} color={highlightsColorMapper[id].active} />
        </ConditionalRender>
      </div>

      <Text
        type={TextTypes.BODY_SMALL}
        color={Color.neutral400}
        className="!tw-leading-none"
        weight={FontWeight.MEDIUM}
      >
        {title}

        <ConditionalRender condition={!!value}>
          &nbsp;<b>{value}</b>
        </ConditionalRender>
      </Text>
    </div>
  )

  const Terms = (
    <div className={styles.container}>
      <Text
        type={TextTypes.BODY_SMALL}
        color={Color.neutral300}
        weight={FontWeight.MEDIUM}
      >
        Terms
      </Text>

      <Col gap={14} className="tw-mt-14">
        <ConditionalRender
          condition={terms.mfn}
          fallbackElement={
            <>
              {!!terms.discountRate &&
                renderItem(
                  'discountRate',
                  'Discount rate:',
                  `${terms.discountRate}%`
                )}

              {!!terms.valuationCap &&
                renderItem(
                  'valuationCap',
                  'Valuation cap:',
                  toCurrencyFormat(terms.valuationCap || 0, '$')
                )}
            </>
          }
        >
          {renderItem('mfn', 'Most Favored Nation (MFN)')}
        </ConditionalRender>
      </Col>
    </div>
  )

  const Recipients = (
    <div className={styles.container}>
      <Text
        type={TextTypes.BODY_SMALL}
        color={Color.neutral300}
        weight={FontWeight.MEDIUM}
      >
        Recipients
      </Text>

      <Col gap={14} className="tw-mt-14">
        {recipients.map((recipient) => (
          <Row
            className={styles.recipient}
            key={recipient.email}
            gap={10}
            items="center"
            justify="between"
          >
            <Row items="center" gap={8}>
              <Avatar
                size={26}
                name={recipient.fullName}
                image={recipient.image}
              />

              <Text
                type={TextTypes.BODY_SMALL}
                color={Color.neutral500}
                weight={FontWeight.MEDIUM}
                className={styles.recipientName}
              >
                {recipient.fullName || recipient.email}
              </Text>
            </Row>

            <Text
              type={TextTypes.BODY_EXTRA_SMALL}
              color={Color.neutral300}
              className="tw-italic tw-whitespace-nowrap"
            >
              Not signed
            </Text>
          </Row>
        ))}
      </Col>
    </div>
  )

  const Owner = (
    <div className={styles.container}>
      <Row gap={10} items="center" justify="between">
        <Row items="center" gap={8}>
          <Avatar size={26} name={owner?.fullName} image={owner?.image} />

          <Text
            type={TextTypes.BODY_SMALL}
            color={Color.neutral500}
            weight={FontWeight.MEDIUM}
            className={styles.recipientName}
          >
            {owner?.fullName}
          </Text>

          <PulsatingCircle className="tw-ml-2" size={10} type="warning" />
        </Row>

        <Button width="fit" appearance="ordinary" className={styles.signButton}>
          Sign
          <SharedIcons.PlusThin size={17} />
        </Button>
      </Row>
    </div>
  )

  return (
    <Sticky top={20} innerZ={1}>
      <Col className="tw-relative" items="stretch" gap={16}>
        {Terms}
        {Recipients}
        {Owner}
      </Col>
    </Sticky>
  )
}

export default TermSheetSidebar
