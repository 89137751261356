import React, { FC, useEffect, useState } from 'react'

import { ConditionalRender, OptionsPopup } from '@/common/components'
import { IPopupOption } from '@/common/components/OptionsPopup/OptionsPopup'
import { ToastService } from '@/common/services'
import { StoreType, useStore, withStore } from '@/common/store'
import { toCurrencyFormat } from '@/common/utils'
import { SendReminder } from '@/features/dashboard/assets/icons/TableOptionsIcons'
import { ISafeNote, SafeNoteStatus } from '@/features/safe-note'
import { TermSheetActions } from '@/features/term-sheet/assets/icons'
import { ProfileTypes } from '@/features/user/enums'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import {
  Button,
  Heading,
  HeadingTypes,
  Loader,
  Modal,
  Row,
  TextAlign
} from '@/packages/ui'
import { SAFE } from '@/packages/ui/table/columns'
import { useNavigator } from '@/router'

import styles from './PendingSafesModal.module.scss'

const mapStateToProps = ({ safeNote }: StoreType) => ({
  safeNoteList: safeNote.safeNoteList,
  fetchSafeNotes: safeNote.fetchAll,
  safesLoading: safeNote.functionLoading.fetchAll
})

interface PendingSafesModalProps extends ReturnType<typeof mapStateToProps> {
  closeModal: () => void
  predefinedItems?: ISafeNote[]
  type: ProfileTypes
  fetchRequired?: boolean
  companyId?: string
}

const PendingSafesModal: FC<PendingSafesModalProps> = (props) => {
  const {
    type,
    closeModal,
    companyId,
    predefinedItems = [],
    safesLoading,
    safeNoteList,
    fetchSafeNotes,
    fetchRequired
  } = props

  const safesList = predefinedItems.length
    ? predefinedItems
    : safeNoteList || []

  const [loading, setLoading] = useState(false)
  const navigate = useNavigator()

  const isEntrepreneur = type === ProfileTypes.ENTREPRENEUR

  const sendReminder = useStore(
    (store: StoreType) => store.safeNote.notifyToSign
  )

  const options: IPopupOption[] = [
    {
      id: 'view',
      label: 'View SAFE',
      icon: (
        <TermSheetActions.ViewTermSheet size={18} color={Color.neutral400} />
      )
    },
    {
      id: 'reminder',
      label: 'Send reminder',
      icon: <SendReminder size={18} color={Color.neutral400} />
    }
  ]

  const handleOptionClick = async (option: string, row: ISafeNote) => {
    switch (option) {
      case 'view': {
        navigate.toViewSafeNote(row.id)
        break
      }

      case 'reminder': {
        setLoading(true)

        try {
          await sendReminder({
            data: { id: row.id }
          })

          ToastService.showSuccess('Reminder was sent successfully')
        } catch (e) {
          ToastService.showError('We faced some problems with sending reminder')
        }

        setLoading(false)
        break
      }

      default: {
        break
      }
    }
  }

  const handleSendReminder = async () => {
    setLoading(true)

    try {
      await Promise.all(
        safesList.map((safe: ISafeNote) =>
          sendReminder({
            data: { id: safe.id }
          })
        )
      )

      closeModal()
      ToastService.showSuccess('All reminders were sent')
    } catch (e) {
      ToastService.showError('We faced some problems with sending reminders')
    }

    setLoading(false)
  }

  useEffect(() => {
    if (fetchRequired && companyId) {
      fetchSafeNotes({
        data: {
          [type === ProfileTypes.ANGEL
            ? 'angelCompanyId'
            : 'entrepreneurCompanyId']: companyId,
          query: { status: SafeNoteStatus.SENT }
        }
      })
    }
  }, [fetchRequired, companyId])

  return (
    <Modal size="md" id="addCompanyModal" visible setVisible={closeModal}>
      <div>
        <Heading
          type={HeadingTypes.H3}
          align={TextAlign.LEFT}
          className="tw-mb-[12px]"
        >
          Pending SAFEs
        </Heading>
      </div>

      <ConditionalRender
        condition={!safesLoading && !loading}
        fallbackElement={<Loader width="100%" />}
      >
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th colSpan={2}>Amount</th>
            </tr>
          </thead>
          <tbody>
            {safesList.map((safeNote) => (
              <tr key={safeNote.id}>
                <td>
                  <SAFE row={safeNote} />
                </td>
                <td>
                  {isEntrepreneur
                    ? safeNote.recipient.email
                    : safeNote.sender.email}
                </td>
                <td>{toCurrencyFormat(safeNote.safeAmount, '$')}</td>
                <td>
                  <OptionsPopup
                    options={options}
                    onOptionClick={(option) =>
                      handleOptionClick(option, safeNote)
                    }
                  >
                    <SharedIcons.Dots
                      color={Color.neutral400}
                      className={styles.options}
                    />
                  </OptionsPopup>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </ConditionalRender>

      <Row justify="between" items="center" gap={15}>
        <Button
          width="default"
          type="button"
          appearance="secondary"
          onClick={closeModal}
          uppercase
        >
          Cancel
        </Button>

        <Button
          width="default"
          type="button"
          appearance="primary"
          onClick={handleSendReminder}
          uppercase
          disabled={loading || safesLoading}
        >
          {loading ? 'Loading...' : 'Send Reminder'}
        </Button>
      </Row>
    </Modal>
  )
}

export default withStore(mapStateToProps)(PendingSafesModal)
