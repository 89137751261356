import { FC } from 'react'

import { IconProps, withIcon } from '@/common/hocs'

const BurgerMenuIcon: FC<IconProps> = withIcon((props) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3 7.84961H21"
      stroke="#292D32"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M3 12.8496H21"
      stroke="#292D32"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M3 17.8496H21"
      stroke="#292D32"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
))

export default BurgerMenuIcon
