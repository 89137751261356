import clsx from 'clsx'
import { FC } from 'react'

import { ConditionalRender } from '@/common/components'
import { Verified } from '@/common/components/UserProfile/VerificationStatusIcons'
import { CapStatus } from '@/common/enums'
import { Tooltip, TooltipKind } from '@/packages/ui'

import styles from './CapColumn.module.scss'

interface CapColumnProps {
  row: any
}

const CapColumn: FC<CapColumnProps> = (props) => {
  const { row } = props
  const { newCap = 0, capStatus } = row

  const pending = capStatus === CapStatus.PENDING
  const verified = capStatus === CapStatus.VERIFIED
  const verifiedOrPending = pending || verified

  return (
    <div className={styles.wrapper}>
      {/* <span className={styles.old}>TBD</span> */}
      <span className={clsx(styles.new, styles[capStatus])}>
        <ConditionalRender condition={verifiedOrPending}>
          {verified ? (
            <Verified size={12} />
          ) : (
            <span className={styles.pendingCircle} />
          )}
        </ConditionalRender>
        ${newCap}
      </span>
      <ConditionalRender condition={pending}>
        <Tooltip kind={TooltipKind.INFO} content="some info" />
      </ConditionalRender>
    </div>
  )
}

export default CapColumn
