import clsx from 'clsx'
import { noop } from 'lodash'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'

import { ConditionalRender, FloatingBanner } from '@/common/components'
import { ToastService } from '@/common/services'
import { StoreType, withStore } from '@/common/store'
import { fileToDataUrl } from '@/common/utils'
import { DeleteTeamMemberModal, TeamMember } from '@/features/auth/components'
import { AddTeamMemberModal, SubscriptionsModal } from '@/features/auth/modals'
import { TeamMemberPermission } from '@/features/user'
import { ProfileTypes } from '@/features/user/enums'
import { useModal } from '@/packages/hooks'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import {
  Button,
  FontWeight,
  Heading,
  HeadingTypes,
  IFile,
  Spacer,
  Text,
  TextTypes
} from '@/packages/ui'

import styles from './AddTeamMembers.module.scss'

export const permissions = (premium?: boolean) => [
  {
    value: TeamMemberPermission.VIEW,
    label: 'Collaborator',
    description: 'Can view SAFEs'
  },
  {
    value: TeamMemberPermission.CREATE,
    label: 'Creator',
    description: 'Can view, edit, and create SAFEs',
    // disabled: !premium
    disabled: false
  }
]

const mapStateToProps = ({ user }: StoreType) => ({
  user: user.me
})

export interface IOwner {
  name: string
  position: string
  photo?: IFile
  email: string
}

export interface IComponentTeamMember {
  id: string
  name: string
  email: string
  permission: string
}

interface AddTeamMembersProps {
  teamMembers: IComponentTeamMember[]
  company: string
  onUpdateTeamMembers: (teamMembers: IComponentTeamMember[]) => void
  secondaryView?: boolean
  owner: IOwner
  type: ProfileTypes
}

const AddTeamMembers: FC<
  AddTeamMembersProps & ReturnType<typeof mapStateToProps>
> = ({
  type,
  user,
  owner,
  company,
  teamMembers,
  onUpdateTeamMembers,
  secondaryView
}) => {
  const { visible, closeModal, openModal } = useModal<
    'delete' | 'add' | 'edit' | 'upgrade'
  >()

  const [itemToEdit, setItemToEdit] = useState<
    IComponentTeamMember | undefined
  >()

  const [photoUrl, setPhotoUrl] = useState<any>()

  // TODO: upgrade is hidden currently
  // const needUpgrade = teamMembers.length > 0 && !user?.activeSubscription
  const needUpgrade = false

  const permissionsList = useMemo(
    // () => permissions(!!user?.activeSubscription),
    () => permissions(true),
    [user]
  )

  useEffect(() => {
    if (owner.photo) {
      if (owner.photo instanceof File) {
        fileToDataUrl(owner.photo).then(setPhotoUrl)
      } else {
        setPhotoUrl(owner.photo)
      }
    } else {
      setPhotoUrl(undefined)
    }
  }, [owner.photo])

  const handleOpenDeleteModal = useCallback(
    (id: string) => {
      setItemToEdit(teamMembers.find((member) => member.id === id)!)
      openModal('delete')
    },
    [openModal, teamMembers]
  )

  const handleOpenEditModal = useCallback(
    (id: string) => {
      setItemToEdit(teamMembers.find((member) => member.id === id)!)
      openModal('edit')
    },
    [openModal, teamMembers]
  )

  const handleCloseModal = useCallback(() => {
    setItemToEdit(undefined)
    closeModal()
  }, [])

  const handleDelete = useCallback(() => {
    if (!itemToEdit?.id) return
    handleCloseModal()

    const newTeamMembers = teamMembers.filter((p) => p.id !== itemToEdit.id)
    onUpdateTeamMembers(newTeamMembers)
  }, [itemToEdit, teamMembers, onUpdateTeamMembers])

  const handleUpdate = (updatedValue: IComponentTeamMember, add?: boolean) => {
    if (add) {
      const existed =
        !!teamMembers.find((p) => p.email === updatedValue.email) ||
        owner.email === updatedValue.email

      if (existed) {
        ToastService.showError('Team member with this email already exists')
        return
      }

      onUpdateTeamMembers([...teamMembers, updatedValue])
    } else {
      onUpdateTeamMembers(
        teamMembers.map((member) =>
          member.id === updatedValue.id ? updatedValue : member
        )
      )
    }

    closeModal()
  }

  const renderTeamMembers = useCallback(
    () =>
      teamMembers.map(({ id, permission, ...rest }: IComponentTeamMember) => (
        <TeamMember
          key={id}
          id={id}
          onClick={() => handleOpenEditModal(id)}
          allowDelete
          onDelete={handleOpenDeleteModal}
          position={
            permissionsList.find((p) => p.value === permission)?.label || '-'
          }
          {...rest}
        />
      )),
    [teamMembers, handleOpenDeleteModal]
  )

  return (
    <div className={clsx(styles.container, secondaryView && styles.secondary)}>
      {visible === 'delete' && (
        <DeleteTeamMemberModal
          closeModal={handleCloseModal}
          name={itemToEdit?.name || ''}
          handleDelete={handleDelete}
        />
      )}

      {['add', 'edit'].includes(visible!) && (
        <AddTeamMemberModal
          item={itemToEdit}
          onUpgrade={() => openModal('upgrade')}
          handleUpdate={handleUpdate}
          type={type}
          closeModal={handleCloseModal}
        />
      )}

      {visible === 'upgrade' && (
        <SubscriptionsModal closeModal={closeModal} ownerPhoto={owner.photo} />
      )}

      <Heading type={HeadingTypes.H2}>Who is on your team?</Heading>
      <Spacer size={16} />
      <Text type={TextTypes.BODY_DEFAULT} color={Color.neutral400}>
        Invite people to join the {company} team. Collaborate with co-founders,
        advisors, and attorneys.
      </Text>

      <Spacer size={24} />
      <div className={styles.teamMembers}>
        <TeamMember
          id="owner"
          isMe
          clickable={false}
          image={photoUrl}
          name={owner.name}
          email={owner.email}
          position={owner.position}
        />
        {renderTeamMembers()}
      </div>

      {!secondaryView && <Spacer size={20} />}
      <div
        className={clsx(styles.addTeamMember, needUpgrade && styles.withBanner)}
        onClick={() => (needUpgrade ? noop() : openModal('add'))}
      >
        <ConditionalRender condition={needUpgrade}>
          <Button
            uppercase
            width="fit"
            className={styles.upgradeBtn}
            onClick={() => openModal('upgrade')}
          >
            Upgrade
          </Button>

          <FloatingBanner text="Basic subscription is only allowed two team members" />
        </ConditionalRender>

        {needUpgrade ? (
          <SharedIcons.Lock size={20} color={Color.neutral200} />
        ) : (
          <span className={styles.icon}>
            <SharedIcons.User color={Color.neutral0} size={10} />
          </span>
        )}

        <Text
          color={Color.primary400}
          weight={FontWeight.SEMIBOLD}
          type={TextTypes.BODY_SMALL}
          className="!tw-leading-snug"
        >
          ADD TEAM MEMBER
        </Text>
      </div>
    </div>
  )
}

export default withStore(mapStateToProps)(AddTeamMembers)
