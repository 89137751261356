import { yupResolver } from '@hookform/resolvers/yup'
import React, { FC } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { NumberFormatValues } from 'react-number-format'

import { CardPaymentSchema } from '@/features/payment/components/PaymentOptions/schemas'
import addCardImg from '@/features/profile-settings/assets/add-card.png'
import { Color } from '@/packages/palette'
import {
  Button,
  CardExpiryInput,
  Col,
  Form,
  FormHelper,
  FormItem,
  Heading,
  HeadingTypes,
  MaskInput,
  Modal,
  NumberInput,
  Row
} from '@/packages/ui'

import styles from './AddCardModal.module.scss'

interface AddCardModalProps {
  closeModal: () => void
}

const AddCardModal: FC<AddCardModalProps & { onAdd: (data: any) => void }> = (
  props
) => {
  const { closeModal, onAdd } = props

  const {
    control,
    getValues,
    formState: { errors, isValid }
  } = useForm({
    resolver: yupResolver(CardPaymentSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: {
      cardNumber: '',
      expirationDate: '',
      cvv: ''
    }
  })

  const onSubmit = () => {
    const values = getValues()

    onAdd({ id: (Math.random() + 1).toString(36).substring(7), ...values })
    closeModal()
  }

  return (
    <Modal id="add-card-modal" size="sm" visible setVisible={closeModal}>
      <Col items="center" gap={24}>
        <img width={150} src={addCardImg} alt="" />

        <Heading type={HeadingTypes.H2} color={Color.neutral500}>
          Add new card
        </Heading>

        <div className="tw-self-stretch">
          <Form>
            <FormItem errors={errors?.cardNumber?.message as string}>
              <Controller
                control={control}
                name="cardNumber"
                render={({ field: { onChange, value } }) => (
                  <MaskInput
                    value={value}
                    label="Card Number"
                    placeholder="0000 0000 0000 0000"
                    format="#### #### #### ####"
                    onValueChange={(val: NumberFormatValues) => {
                      onChange(val.floatValue)
                    }}
                    invalid={FormHelper.isFieldInvalid('cardNumber', errors)}
                  />
                )}
              />
            </FormItem>
            <Row gap={10}>
              <FormItem errors={errors?.expirationDate?.message as string}>
                <Controller
                  control={control}
                  name="expirationDate"
                  render={({ field: { onChange, value } }) => (
                    <CardExpiryInput
                      value={value}
                      label="Expiration Date"
                      placeholder="MM/YY"
                      onValueChange={(val: NumberFormatValues) => {
                        onChange(val.formattedValue)
                      }}
                      invalid={FormHelper.isFieldInvalid(
                        'expirationDate',
                        errors
                      )}
                    />
                  )}
                />
              </FormItem>
              <FormItem errors={errors?.cvv?.message as string}>
                <Controller
                  control={control}
                  name="cvv"
                  render={({ field: { onChange, value } }) => (
                    <NumberInput
                      label="CVV"
                      placeholder="123"
                      maxLength={4}
                      thousandSeparator=""
                      invalid={FormHelper.isFieldInvalid('cvv', errors)}
                      value={value}
                      onValueChange={(val: NumberFormatValues) => {
                        onChange(val.floatValue)
                      }}
                    />
                  )}
                />
              </FormItem>
            </Row>
          </Form>
        </div>

        <div className={styles.footer}>
          <Button
            uppercase
            appearance="secondary"
            width="full"
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button disabled={!isValid} uppercase width="full" onClick={onSubmit}>
            Save
          </Button>
        </div>
      </Col>
    </Modal>
  )
}

export default AddCardModal
