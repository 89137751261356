import { FC } from 'react'

import { Avatar } from '@/common/components'
import { Col, FontWeight, Row, Text, TextTypes } from '@/packages/ui'

interface UserColumnProps {
  row: any
  accessor: string
}

const UserColumn: FC<UserColumnProps> = (props) => {
  const { row, accessor } = props
  const author = row[accessor]
  const { fullName, email, image } = author || {}

  return (
    <Row items="center" gap={8}>
      <Avatar image={image} name={fullName} size={34} />
      <Col>
        <Text weight={FontWeight.SEMIBOLD} type={TextTypes.BODY_SMALL}>
          {fullName}
        </Text>
        <Text type={TextTypes.BODY_EXTRA_SMALL}>{email}</Text>
      </Col>
    </Row>
  )
}

export default UserColumn
