import { AnimatePresence, motion } from 'framer-motion'
import React, { FC, ReactNode } from 'react'

interface CollapseTransitionProps {
  initial?: boolean
  collapsed?: boolean
  children?: ReactNode
  duration?: number
}

const CollapseTransition: FC<CollapseTransitionProps> = ({
  initial = false,
  collapsed,
  children,
  duration = 0.3
}) => (
  <AnimatePresence initial={initial}>
    {collapsed && (
      <motion.section
        key="content"
        initial="collapsed"
        animate="open"
        exit="collapsed"
        variants={{
          open: {
            opacity: 1,
            height: 'auto'
          },
          collapsed: {
            opacity: 0,
            height: 0
          }
        }}
        transition={{
          duration,
          ease: 'easeInOut'
        }}
      >
        {children}
      </motion.section>
    )}
  </AnimatePresence>
)

export default CollapseTransition
