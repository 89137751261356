import React, { FC } from 'react'
import Sticky from 'react-stickynode'

import ConditionaRender from '@/common/components/ConditionalRender/ConditionaRender'
import {
  InvestorsBlock,
  SignaturesBlock,
  TermsBlock
} from '@/features/safe-note'
import { SentByBlock } from '@/features/safe-note/components/SafeNote/components/SentByBlock'
import { useSafeNoteContext } from '@/features/safe-note/components/SafeNote/context'

import styles from './SafeNoteNavigation.module.scss'

const SafeNoteNavigation: FC = () => {
  const { customData } = useSafeNoteContext()

  const Content = (
    <div
      id="safe-note-navigation-container"
      className={styles.safeNoteNavigation}
    >
      <ConditionaRender condition={customData.viewAsRecipient}>
        <SentByBlock />
      </ConditionaRender>
      <InvestorsBlock />
      <TermsBlock />
      {/* hide signature block only if view safe as recipient and safe not accepted */}
      <ConditionaRender
        condition={!customData.viewAsRecipient || customData.isSafeAccepted}
      >
        <SignaturesBlock />
      </ConditionaRender>
    </div>
  )

  return <Sticky top={20}>{Content}</Sticky>
}

export default SafeNoteNavigation
