import { ProfileTypes } from '@/features/user/enums'

import beAdvised from './images/beAdvised.png'
import getOrganized from './images/get-organized.png'
import goal from './images/goal.png'
import invest from './images/investment.png'
import safe from './images/safe.png'
import signatures from './images/signatures.png'
import termSheet from './images/term-sheet.png'
import withdraw from './images/withdraw.png'

const advantages = {
  [ProfileTypes.ENTREPRENEUR]: [
    {
      icon: safe,
      title: 'SAFE note builder',
      description:
        'Our SAFE builder lets you create SAFEs and set terms quickly. There’s no platform like ours!'
    },
    {
      icon: termSheet,
      title: 'Term Sheets',
      description:
        'Prepare and send term sheets to prospective investors. Seal the deals you want.'
    },
    {
      icon: getOrganized,
      title: 'Organized workspace',
      description:
        'Keep all your term sheets, SAFEs, and investment records organized in one place.'
    },
    {
      icon: goal,
      title: 'Investment goals',
      description:
        'Use the progress bar on your dashboard to track your raised funds and reach your goal.'
    },
    {
      icon: signatures,
      title: 'Online signatures',
      description:
        'We offer legally binding signatures right through our platform. Get business done easily and quickly.'
    },
    {
      icon: withdraw,
      title: 'Simple withdrawals',
      description:
        'Link your bank account, and once you’ve received an investment, initiate a direct deposit in minutes.'
    }
  ],
  [ProfileTypes.ANGEL]: [
    {
      icon: termSheet,
      title: 'Term Sheets',
      description:
        'Review term sheets sent by entrepreneurs. See terms they’re offering without any commitment.'
    },
    {
      icon: safe,
      title: 'SAFEs',
      description:
        'Receive SAFEs in exchange for seed funding. Get terms you want and invest in startups you love.'
    },
    {
      icon: signatures,
      title: 'Online signatures',
      description:
        'We offer legally binding signatures right through our platform. Get business done easily and securely.'
    },
    {
      icon: invest,
      title: 'Simple investing',
      description:
        'Link your bank account or use any major credit/debit card to easily invest through our platform.'
    },
    {
      icon: getOrganized,
      title: 'Organized workspace',
      description:
        'Keep all your term sheets, SAFEs, and investment records organized in one place.'
    },
    {
      icon: beAdvised,
      title: 'Be advised',
      description:
        'Invite your advisors and attorneys to help manage your investments for an effortless experience.'
    }
  ],
  [ProfileTypes.ADVISOR]: [
    {
      icon: termSheet,
      title: 'Option 1',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    {
      icon: safe,
      title: 'Option 2',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    {
      icon: signatures,
      title: 'Option 3',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    {
      icon: invest,
      title: 'Option 4',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    {
      icon: getOrganized,
      title: 'Option 5',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    {
      icon: beAdvised,
      title: 'Option 6',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    }
  ]
}

export default advantages
