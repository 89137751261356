import React, { FC } from 'react'

import { SharedImages } from '@/assets/images'
import { Avatar, ConditionalRender } from '@/common/components'
import { DateHelper } from '@/common/helpers'
import { ISafeNoteRecipient, SafeNoteStatus } from '@/features/safe-note'
import { Color } from '@/packages/palette'
import {
  Badge,
  BadgeType,
  Col,
  FontWeight,
  Row,
  Text,
  TextTypes
} from '@/packages/ui'

import styles from './SAFE.module.scss'

// import requestTo from '@/features/dashboard/assets/images/safe-request-to.svg'

interface SafeProps {
  row: any
  withDate?: boolean
  type?: 'term-sheet' | 'safe-note'
}

const SAFE: FC<SafeProps> = (props) => {
  const {
    withDate,
    type = 'safe-note',
    row: { sender, recipient, createdAt, data, status }
  } = props

  const draft = status === SafeNoteStatus.DRAFT

  const user = (() => {
    if (type === 'term-sheet') {
      return sender
    }

    if (draft && data?.recipients?.length === 1) {
      return data.recipients[0]
    }

    return recipient
  })()

  // fallback to true to not show not active for users which does not have active field
  const isUserActive = 'active' in (user || {}) ? user?.active : true

  const renderDetails = (name: string | undefined, active?: boolean) => (
    <Col gap={0}>
      <Text
        type={TextTypes.BODY_SMALL}
        color={Color.neutral500}
        weight={FontWeight.SEMIBOLD}
      >
        {name}
      </Text>

      <ConditionalRender condition={!active}>
        <Badge size="small" type={BadgeType.DEFAULT}>
          Not registered
        </Badge>
      </ConditionalRender>

      <ConditionalRender condition={withDate}>
        <Text type={TextTypes.BODY_EXTRA_SMALL} color={Color.neutral400}>
          {DateHelper.toPrettyFormat(createdAt)}
        </Text>
      </ConditionalRender>
    </Col>
  )

  return (
    <Row gap={withDate ? 10 : 16} items="center">
      {draft && !!data?.recipients?.length ? (
        <div className={styles.multiRecipients}>
          {data.recipients.map((item: ISafeNoteRecipient) => (
            <Avatar
              key={item.email}
              size={36}
              name={item?.name}
              image={item?.img}
              defaultImage={SharedImages.UserPlaceholder}
            />
          ))}
        </div>
      ) : (
        <Avatar size={36} name={user?.fullName} image={user?.image} />
      )}

      {(!draft || data?.recipients?.length === 1) &&
        renderDetails(user?.fullName || user?.name, isUserActive)}
    </Row>
  )
}

export default SAFE
