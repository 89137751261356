import { FC } from 'react'

import {
  Pending,
  Verified
} from '@/common/components/UserProfile/VerificationStatusIcons'
import { DashboardTeamMembers } from '@/features/dashboard'
import { ICompanyTeamMember } from '@/features/profile/types'
import { TermSheetStatus } from '@/features/term-sheet/api/types'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'

import styles from './TermSheetRecipients.module.scss'

interface TermSheetRecipientsProps {
  recipients: ICompanyTeamMember[]
}

const TermSheetRecipients: FC<TermSheetRecipientsProps> = (props) => {
  const { recipients } = props

  const renderStatus = (status: TermSheetStatus | null) => {
    if (status === TermSheetStatus.Pending || !status) {
      return (
        <div className={styles.memberStatus}>
          <Pending color={Color.warning300} width={12} height={12} />
        </div>
      )
    }

    if (status === TermSheetStatus.Accepted) {
      return (
        <div className={styles.memberStatus}>
          <Verified color={Color.success400} width={13} height={13} />
        </div>
      )
    }

    return (
      <div className={styles.memberStatus}>
        <SharedIcons.CancelFilled size={13} color={Color.error300} />
      </div>
    )
  }

  return (
    <DashboardTeamMembers
      hideText
      disabledCondition={(item: any) =>
        item.status === TermSheetStatus.Rejected
      }
      renderStatus={renderStatus}
      teamMembers={recipients}
      companyId=""
      companyName=""
    />
  )
}

export default TermSheetRecipients
