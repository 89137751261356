import clsx from 'clsx'
import React, { FC, lazy, Suspense, useState } from 'react'

import { ConditionalRender } from '@/common/components'
import { CollapseTransition } from '@/common/transitions'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import { FontWeight, Loader, Spacer, Text, TextTypes } from '@/packages/ui'

import styles from './MenuItem.module.scss'

export interface MenuItemProps {
  id: string
  title: string | JSX.Element
  status?: boolean
  withoutStatus?: boolean
  withBackground?: boolean
  colors?: string[]
  showColors?: boolean
  required?: boolean
  subMenu?: any[]
  nested?: boolean
  onClick?: () => void
}

// eslint-disable-next-line import/no-cycle
const NavigationMenu = lazy(() => import('../NavigationMenu'))

const MenuItem: FC<MenuItemProps> = ({
  id,
  withBackground,
  nested,
  title,
  status,
  withoutStatus,
  subMenu = [],
  required,
  colors,
  showColors,
  onClick
}: MenuItemProps) => {
  const [collapsed, setCollapsed] = useState<boolean>(true)
  const hasChildren = subMenu?.length > 0

  let titleColor = withBackground ? Color.neutral500 : Color.neutral400

  if (!status && required && !subMenu?.length) {
    titleColor = Color.error300
  }

  if (!status && nested && !required) {
    titleColor = Color.neutral300
  }

  const titleWeight = !nested ? FontWeight.SEMIBOLD : FontWeight.REGULAR

  const toggle = () => {
    if (hasChildren) setCollapsed(!collapsed)
  }

  const clickable = typeof onClick === 'function'

  return (
    <li className={clsx(styles.container, !nested && styles.notNested)} id={id}>
      <div
        className={clsx(
          styles.wrapper,
          withBackground && styles.withBg,
          !nested && styles.clickable
        )}
        onClick={toggle}
      >
        <div
          className={clsx(styles.item, clickable && styles.clickable)}
          onClick={onClick}
        >
          <ConditionalRender condition={!withoutStatus}>
            <ConditionalRender
              condition={status}
              fallbackElement={
                required ? (
                  <SharedIcons.Cross
                    size={16}
                    color={required ? Color.error300 : Color.neutral300}
                  />
                ) : (
                  <span className={styles.circle} />
                )
              }
            >
              <SharedIcons.Checkmark size={16} />
            </ConditionalRender>
            <Spacer size={10} vertical />
          </ConditionalRender>

          <ConditionalRender
            condition={typeof title === 'string'}
            fallbackElement={title}
          >
            <Text
              className={styles.text}
              type={TextTypes.BODY_DEFAULT}
              weight={titleWeight}
              color={titleColor}
            >
              {title}
            </Text>
          </ConditionalRender>

          <ConditionalRender
            condition={!!(colors && nested && status && showColors)}
          >
            <div className={styles.colorsContainer}>
              {colors?.map((color, index) => (
                <div
                  key={color + index}
                  className={styles.colorBox}
                  style={{ backgroundColor: color }}
                />
              ))}
            </div>
          </ConditionalRender>
        </div>

        <ConditionalRender condition={!nested}>
          <SharedIcons.Chevron
            size={12}
            onClick={toggle}
            className={clsx(!collapsed && styles.menuCollapseIcon)}
          />
        </ConditionalRender>
      </div>

      <CollapseTransition collapsed={collapsed}>
        <Suspense
          fallback={
            <span>
              <Spacer size={20} vertical />
              <Loader />
            </span>
          }
        >
          <NavigationMenu menu={subMenu as MenuItemProps[]} nested />
        </Suspense>
      </CollapseTransition>
    </li>
  )
}

export default MenuItem
