import { ICompany } from '@/features/profile/types'

const containsMissedFields = (company: ICompany) =>
  !company.stateOfIncorporation ||
  !company.address.address1 ||
  !company.address.state ||
  !company.address.country ||
  !company.address.zipCode

export default containsMissedFields
