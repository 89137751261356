import clsx from 'clsx'
import React, { FC, useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'

import { ConditionalRender } from '@/common/components'
import { StoreType, withStore } from '@/common/store'
import {
  RoundGoal,
  Summary,
  TermSheetPreview,
  TermSheetRecipients,
  TermSheetTerms
} from '@/features/term-sheet/components'
import { TermsProps } from '@/features/term-sheet/layout/CreateTermSheetLayout/CreateTermSheetLayout'
import { IUser } from '@/features/user'

import styles from './CreateTermSheetView.module.scss'

const mapStateToProps = ({ user }: StoreType) => ({
  me: user.me
})

const CreateTermSheetView: FC<ReturnType<typeof mapStateToProps>> = ({
  me
}) => {
  const {
    setNextDisabled,
    currentCompany,
    currentStep,
    completed,
    termState,
    setTermState
  } = useOutletContext<any>()

  const updateGoal = (value: number | undefined) => {
    setTermState({ roundGoal: value })
  }

  const updateWithRound = (value: boolean) => {
    setTermState({ withRound: value })
  }

  const onTermsUpdate = (values: TermsProps) => {
    setTermState({ terms: { ...termState.terms, ...values } })
  }

  const onInvitedPeopleUpdate = (users: IUser[]) => {
    setTermState({ invitedPeople: users })
  }

  useEffect(() => {
    if (currentStep === 0) {
      setNextDisabled(
        termState.withRound === undefined ||
          (termState.withRound && !termState.roundGoal)
      )

      return
    }

    if (currentStep === 1) {
      setNextDisabled(!termState.invitedPeople.length)
      return
    }

    if (currentStep === 2) {
      const noTermsSelected =
        !termState.terms.mfn &&
        !termState.terms.valuationCapActive &&
        !termState.terms.discountRateActive

      setNextDisabled(noTermsSelected)

      return
    }

    setNextDisabled(false)
  }, [termState, currentStep])

  const ComponentMapper = [
    <RoundGoal
      key="roundGoalStep"
      withRound={termState.withRound}
      goal={termState.roundGoal}
      setGoal={updateGoal}
      setWithGoal={updateWithRound}
    />,
    <TermSheetRecipients
      key="termSheetRecipients"
      invitedPeople={termState.invitedPeople}
      onUpdate={onInvitedPeopleUpdate}
    />,
    <TermSheetTerms
      key="termSheetTerms"
      terms={termState.terms}
      onChange={onTermsUpdate}
    />,
    <TermSheetPreview
      owner={me}
      company={currentCompany}
      terms={termState.terms}
      recipients={termState.invitedPeople}
      roundAmount={termState.withRound ? termState.roundGoal : undefined}
      key="termSheetPreview"
    />
  ]

  return (
    <ConditionalRender
      condition={!completed}
      fallbackElement={<Summary {...termState} />}
    >
      <div
        className={clsx(
          styles.termsheetContent,
          currentStep === 3 && styles.reviewStep
        )}
      >
        {ComponentMapper[currentStep]}
      </div>
    </ConditionalRender>
  )
}

export default withStore(mapStateToProps)(CreateTermSheetView)
