import clsx from 'clsx'
import React, { ComponentType, FC } from 'react'

import { Color } from '@/packages/palette'
import { ClickEvent } from '@/packages/ui'

export const XMLNS = 'http://www.w3.org/2000/svg'

export interface IconProps {
  onClick?: ClickEvent<Element>
  className?: string
  width?: number
  height?: number
  size?: number
  color?: Color | string
  clickable?: boolean
  stroke?: string
  strokeWidth?: number
}

export function withIcon<T extends IconProps>(
  WrappedComponent: ComponentType<T>
): FC<T> {
  const displayName: string =
    WrappedComponent.displayName ?? WrappedComponent.name ?? 'Component'

  const ComponentWrapped: FC<T> = ({
    className,
    onClick,
    color,
    width,
    height,
    size,
    clickable = true,
    ...rest
  }: T) => {
    const $width = width ?? size
    const $height = height ?? size
    return (
      <WrappedComponent
        className={clsx(clickable && 'clickable-icon', color, className)}
        onClick={onClick}
        style={{
          width: $width,
          height: $height,
          minWidth: $width,
          minHeight: $height
        }}
        xmlns={XMLNS}
        {...(rest as T)}
      />
    )
  }

  ComponentWrapped.displayName = `withIcon(${displayName})`

  return ComponentWrapped
}

export default withIcon
