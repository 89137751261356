import React, { FC, useEffect, useState } from 'react'

import { Avatar, ConditionalRender } from '@/common/components'
import starsImg from '@/features/auth/assets/images/stars.png'
import CompanyRow from '@/features/dashboard/modals/InvitationsModal/parts/CompanyRow'
import { ICompany, ICompanyTeamMember } from '@/features/profile/types'
import { Color } from '@/packages/palette'
import {
  Badge,
  BadgeType,
  Button,
  Col,
  FontWeight,
  Heading,
  HeadingTypes,
  Row,
  Text,
  TextAlign,
  TextInput,
  TextTypes
} from '@/packages/ui'

import styles from '../InvitationsModal.module.scss'

interface InvitationsProps {
  onDecline: () => void
  onAccept: (position: string) => void
  workspace: string
  pageMode?: boolean
  currentRequest: ICompany
  permissionLabel: string
  isModal?: boolean
  isOneOfMany?: boolean
  acceptedTeamMembers: ICompanyTeamMember[]
}

const Invitations: FC<InvitationsProps> = (props) => {
  const {
    isModal,
    onDecline,
    onAccept,
    isOneOfMany,
    pageMode,
    workspace,
    currentRequest,
    permissionLabel,
    acceptedTeamMembers
  } = props

  const [role, setRole] = useState<string>('')

  useEffect(() => {
    setRole('')
  }, [currentRequest?.id])

  const renderTeamMember = (
    id: string,
    name: string,
    image: string | null,
    position: string | null
  ) => (
    <Row
      key={id}
      items="center"
      justify="between"
      gap={15}
      className={styles.teamMember}
    >
      <Row items="center" gap={10}>
        <Avatar size={44} name={name} image={image} />

        <Text
          type={TextTypes.BODY_DEFAULT}
          color={Color.neutral500}
          weight={FontWeight.BOLD}
        >
          {name}
        </Text>
      </Row>

      <Badge type={BadgeType.WARNING}>{position}</Badge>
    </Row>
  )

  const avatar = (
    <Avatar
      size={isModal ? 100 : 80}
      name={currentRequest.owner.fullName}
      image={currentRequest.owner.image}
    />
  )

  return (
    <Col items="center">
      <ConditionalRender condition={isModal} fallbackElement={avatar}>
        <div className={styles.avatarWithStars}>
          <img width={300} src={starsImg} alt="Stars" />
          <div className={styles.floatingAvatar}>{avatar}</div>
        </div>
      </ConditionalRender>

      <Heading
        type={HeadingTypes.H2}
        align={TextAlign.CENTER}
        color={Color.neutral500}
        className={styles.title}
      >
        {currentRequest.owner.fullName} has invited you to:
      </Heading>

      <Text
        type={TextTypes.BODY_DEFAULT}
        color={Color.neutral400}
        align={TextAlign.CENTER}
      >
        become a <b>“{permissionLabel}”</b> in the{' '}
        <span className={styles.companyName}>{currentRequest.name}</span> to the{' '}
        {workspace} workspace.
      </Text>

      <CompanyRow
        pageMode={pageMode}
        teamMembers={acceptedTeamMembers}
        owner={currentRequest?.owner || {}}
        name={currentRequest.name}
        image={currentRequest.image}
      />

      <ConditionalRender condition={!isModal}>
        <div className="tw-self-start tw-mt-6">
          <Text color={Color.neutral400} type={TextTypes.BODY_DEFAULT}>
            These people have already joined the team:
          </Text>
        </div>
      </ConditionalRender>

      <ConditionalRender
        condition={pageMode}
        fallbackElement={
          <div className="tw-mb-20 tw-mt-8 tw-self-stretch">
            <TextInput
              value={role}
              placeholder="Role"
              label="Choose your role in the company"
              onChange={(e) => setRole(e.target.value)}
            />
          </div>
        }
      >
        <Col items="stretch" className="tw-self-stretch">
          {renderTeamMember(
            'owner',
            currentRequest.owner.fullName,
            currentRequest.owner.image,
            currentRequest.owner.position
          )}

          <>
            {acceptedTeamMembers.map((teamMember) =>
              renderTeamMember(
                teamMember.id,
                teamMember.user.fullName,
                teamMember.user.image,
                teamMember.position
              )
            )}
          </>
        </Col>
      </ConditionalRender>

      <div className={styles.footer}>
        <Button
          uppercase
          appearance="secondary"
          width="full"
          onClick={onDecline}
        >
          {isOneOfMany ? 'Back' : 'Decline'}
        </Button>
        <Button
          uppercase
          width="full"
          disabled={pageMode ? false : !role}
          onClick={() => onAccept(role)}
        >
          Join team
        </Button>
      </div>
    </Col>
  )
}

export default Invitations
