import React, { FC } from 'react'

import { IconProps, withIcon } from '@/common/hocs'

const VerificationMark: FC<IconProps> = withIcon((props) => (
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.2714 7.41002L16.1381 6.09336C15.9214 5.84336 15.7464 5.37669 15.7464 5.04336V3.62669C15.7464 2.74336 15.0214 2.01836 14.1381 2.01836H12.7214C12.3964 2.01836 11.9214 1.84336 11.6714 1.62669L10.3548 0.493355C9.77975 0.00168852 8.83809 0.00168852 8.25475 0.493355L6.94642 1.63502C6.69642 1.84336 6.22142 2.01836 5.89642 2.01836H4.45475C3.57142 2.01836 2.84642 2.74336 2.84642 3.62669V5.05169C2.84642 5.37669 2.67142 5.84336 2.46309 6.09336L1.33809 7.41836C0.854753 7.99336 0.854753 8.92669 1.33809 9.50169L2.46309 10.8267C2.67142 11.0767 2.84642 11.5434 2.84642 11.8684V13.2934C2.84642 14.1767 3.57142 14.9017 4.45475 14.9017H5.89642C6.22142 14.9017 6.69642 15.0767 6.94642 15.2934L8.26309 16.4267C8.83809 16.9184 9.77975 16.9184 10.3631 16.4267L11.6798 15.2934C11.9298 15.0767 12.3964 14.9017 12.7298 14.9017H14.1464C15.0298 14.9017 15.7548 14.1767 15.7548 13.2934V11.8767C15.7548 11.5517 15.9298 11.0767 16.1464 10.8267L17.2798 9.51002C17.7631 8.93502 17.7631 7.98502 17.2714 7.41002ZM12.7714 6.88502L8.74642 10.91C8.62975 11.0267 8.47142 11.0934 8.30475 11.0934C8.13809 11.0934 7.97975 11.0267 7.86309 10.91L5.84642 8.89336C5.60475 8.65169 5.60475 8.25169 5.84642 8.01002C6.08809 7.76836 6.48809 7.76836 6.72975 8.01002L8.30475 9.58502L11.8881 6.00169C12.1298 5.76002 12.5298 5.76002 12.7714 6.00169C13.0131 6.24336 13.0131 6.64335 12.7714 6.88502Z"
      fill="currentColor"
    />
  </svg>
))

export default VerificationMark
