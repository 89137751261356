import { noop } from 'lodash'
import React, { FC } from 'react'

import { ConditionalRender } from '@/common/components'
import { SharedIcons } from '@/packages/icons'
import { Input } from '@/packages/ui'

import styles from './Search.module.scss'

interface SearchProps {
  search?: string
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const Search: FC<SearchProps> = (props) => {
  const { search, onSearchChange } = props
  const [opened, setOpened] = React.useState(false)

  return (
    <ConditionalRender
      condition={opened}
      fallbackElement={
        <div className={styles.searchClosed} onClick={() => setOpened(true)}>
          <SharedIcons.Search size={16} />
        </div>
      }
    >
      <Input
        fitContent
        type="text"
        autoFocus
        addonBefore={<SharedIcons.Search size={16} />}
        value={search}
        onChange={onSearchChange}
        placeholder="Search"
        onBlur={() => (!search ? setOpened(false) : noop())}
        containerClassName={styles.searchInput}
      />
    </ConditionalRender>
  )
}

export default Search
