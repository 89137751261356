import clsx from 'clsx'
import { FC, PropsWithChildren } from 'react'

import { TeamMemberPermission } from '@/features/user'
import { Disabled, Tooltip } from '@/packages/ui'

interface NoPermissionsTooltipProps extends PropsWithChildren {
  permission: TeamMemberPermission
  className?: string
}

const NoPermissionsTooltip: FC<NoPermissionsTooltipProps> = (props) => {
  const { children, permission, className } = props

  if (permission === TeamMemberPermission.CREATE)
    return <div className={className}>{children}</div>

  return (
    <Tooltip
      arrow
      placement="bottom"
      content="This feature is available only for 'Creator' permissions"
    >
      <div className={clsx(className, 'tw-cursor-pointer')}>
        <Disabled>{children}</Disabled>
      </div>
    </Tooltip>
  )
}

export default NoPermissionsTooltip
