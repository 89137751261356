import { FC } from 'react'

import { ConditionalRender } from '@/common/components'
import { FormatterHelper } from '@/common/helpers'
import {
  FontWeight,
  Row,
  Text,
  TextTypes,
  Tooltip,
  TooltipKind
} from '@/packages/ui'

import styles from './DiscountAndCap.module.scss'

interface DiscountAndCapProps {
  row: any
}

const DiscountAndCap: FC<DiscountAndCapProps> = (props) => {
  const {
    row: { discountRate, mfn, isCapApproved = true, valuationCap }
  } = props

  if (mfn) {
    return (
      <Text type={TextTypes.BODY_SMALL} weight={FontWeight.MEDIUM}>
        MFN
      </Text>
    )
  }

  return (
    <Text type={TextTypes.BODY_SMALL} weight={FontWeight.MEDIUM}>
      <Row className={styles.row} items="center" gap={5}>
        <ConditionalRender
          condition={!!discountRate || !!valuationCap}
          fallbackElement="-"
        >
          <ConditionalRender
            condition={!!discountRate}
            fallbackElement="No Discount"
          >
            {discountRate}%
          </ConditionalRender>{' '}
          |{' '}
          <ConditionalRender
            condition={!!valuationCap}
            fallbackElement="No Cap"
          >
            {FormatterHelper.toCurrency(valuationCap, '$')}
          </ConditionalRender>
          <ConditionalRender condition={!isCapApproved}>
            <Tooltip kind={TooltipKind.WARNING} content="Tooltip content" />
          </ConditionalRender>
        </ConditionalRender>
      </Row>
    </Text>
  )
}

export default DiscountAndCap
