import React, { FC } from 'react'

import { IconProps, withIcon } from '@/common/hocs'

const CreditCardIcon: FC<IconProps> = withIcon((props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M1.66309 7.0835H9.57975"
      stroke="#242424"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.99707 13.75H6.66374"
      stroke="#242424"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.74707 13.75H12.0804"
      stroke="#242424"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.3298 10.0248V13.4248C18.3298 16.3498 17.5881 17.0832 14.6298 17.0832H5.36309C2.40475 17.0832 1.66309 16.3498 1.66309 13.4248V6.57484C1.66309 3.64984 2.40475 2.9165 5.36309 2.9165H12.0798"
      stroke="#242424"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.897 3.4416L12.8053 6.53326C12.6886 6.64993 12.572 6.88326 12.547 7.04993L12.3803 8.23326C12.322 8.65826 12.622 8.95826 13.047 8.89993L14.2303 8.73326C14.397 8.70826 14.6303 8.5916 14.747 8.47493L17.8386 5.38326C18.372 4.84993 18.622 4.23326 17.8386 3.44993C17.047 2.65826 16.4303 2.90826 15.897 3.4416Z"
      stroke="#242424"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.4551 3.8833C15.7217 4.82497 16.4551 5.5583 17.3884 5.81663"
      stroke="#242424"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
))

export default CreditCardIcon
