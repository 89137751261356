import React, { FC, useEffect, useMemo } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import { ConditionalRender } from '@/common/components'
import { StoreType, withStore } from '@/common/store'
import { toCurrencyFormat } from '@/common/utils'
import { Breadcrumbs, SafeNoteList } from '@/features/dashboard'
import { useDashboardNavigationContext } from '@/features/dashboard/context'
import { ICompany } from '@/features/profile/types'
import { getPermission } from '@/features/profile/utils'
import { SafeNoteStatus } from '@/features/safe-note'
import { ProfileTypes } from '@/features/user/enums'
import { Color } from '@/packages/palette'
import {
  Heading,
  HeadingTypes,
  Loader,
  Row,
  Spacer,
  Text,
  TextTypes
} from '@/packages/ui'
import { useNavigator } from '@/router'

import styles from './AngelAllSafeNotes.module.scss'

const mapStateToProps = ({ user, safeNote }: StoreType) => ({
  loading: user.functionLoading.fetchMe || safeNote.functionLoading.fetchAll,
  fetchSafeNotes: safeNote.fetchAll,
  safeNotes: safeNote.safeNoteList,
  sortedCompanies: user.sortedCompanies,
  userId: user.me?.id
})

const AngelAllSafeNotes: FC<ReturnType<typeof mapStateToProps>> = (props) => {
  const { userId, loading, fetchSafeNotes, safeNotes, sortedCompanies } = props

  const { id } = useParams()
  const [searchParams] = useSearchParams()
  const { setSelectedProfile } = useDashboardNavigationContext()

  const type = searchParams.get('type')
  const entrepreneurCompanyId = searchParams.get('entrepreneurCompanyId')

  const navigator = useNavigator()

  const currentCompany = useMemo(
    () => sortedCompanies.angel.find((item) => item.id === id),
    [sortedCompanies, id]
  )

  const permission = useMemo(
    () => getPermission(userId, currentCompany),
    [userId, currentCompany]
  )

  const totalAmount = useMemo(
    () => safeNotes?.reduce?.((acc, next) => acc + next.safeAmount, 0) || 0,
    [safeNotes]
  )

  const typeText = useMemo(() => {
    if (safeNotes?.length === 1) {
      return type === 'all' ? 'SAFE sent' : 'Unpaid SAFE'
    }

    return type === 'all' ? 'SAFEs sent' : 'Unpaid SAFEs'
  }, [type, safeNotes])

  const toUpperLevel = () => {
    setSelectedProfile(ProfileTypes.ANGEL)
    navigator.toDashboard()
  }

  useEffect(() => {
    if (!currentCompany?.id && !loading) {
      navigator.toDashboard()
    }
  }, [currentCompany, loading])

  useEffect(() => {
    if (currentCompany?.id) {
      fetchSafeNotes({
        data: {
          angelCompanyId: id,
          ...(entrepreneurCompanyId ? { entrepreneurCompanyId } : {}),
          ...(type === 'unpaid'
            ? {
                query: {
                  status: SafeNoteStatus.SENT
                }
              }
            : {})
        }
      })
    }
  }, [currentCompany, type, entrepreneurCompanyId])

  if (loading) {
    return <Loader width="100%" height="100%" />
  }

  return (
    <div className="tw-w-full">
      <Breadcrumbs
        onTypeClick={toUpperLevel}
        innerCompany="My SAFEs Sent"
        type={ProfileTypes.ANGEL}
        company={currentCompany?.name as string}
        onInnerClick={() => navigator.toDashboardCompany(id as string)}
      />

      <Spacer size={10} vertical />

      <ConditionalRender condition={!!currentCompany?.id}>
        <SafeNoteList
          withoutTabs
          title={
            <Row items="center" gap={14} className="tw-mb-20">
              <Heading type={HeadingTypes.H2} color={Color.neutral500}>
                My SAFEs Sent
              </Heading>

              <div className={styles.spacer} />
              <Text type={TextTypes.BODY_DEFAULT} color={Color.neutral400}>
                {safeNotes?.length} {typeText}
              </Text>

              <div className={styles.spacer} />
              <Text type={TextTypes.BODY_DEFAULT} color={Color.neutral400}>
                Total amount: {toCurrencyFormat(totalAmount, '$')}
              </Text>
            </Row>
          }
          data={safeNotes}
          role={ProfileTypes.ANGEL}
          permission={permission}
          currentCompany={currentCompany as ICompany}
          termSheets={[]}
        />
      </ConditionalRender>
    </div>
  )
}

export default withStore(mapStateToProps)(AngelAllSafeNotes)
