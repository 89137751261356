import React, { FC, useEffect } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'

import { ConditionalRender } from '@/common/components'
import { ToastService } from '@/common/services'
import { StoreType, withStore } from '@/common/store'
import {
  TermSheetPreview,
  TermSheetSummary
} from '@/features/term-sheet/components'
import { TermsProps } from '@/features/term-sheet/layout/CreateTermSheetLayout/CreateTermSheetLayout'
import { Loader } from '@/packages/ui'
import { useNavigator } from '@/router'

import styles from './TermSheetPreviewView.module.scss'

const mapStateToProps = ({ termSheet }: StoreType) => ({
  fetchOne: termSheet.fetchOne,
  loading: termSheet.functionLoading.fetchOne
})

const TermSheetPreviewView: FC<ReturnType<typeof mapStateToProps>> = (
  props
) => {
  const { fetchOne, loading } = props

  const { termSheet, setTermSheet, activeTab, isOwner } =
    useOutletContext<any>()

  const navigate = useNavigator()

  const { id } = useParams()

  const terms: TermsProps = {
    mfn: termSheet?.mfn,
    discountRate: termSheet?.discountRate || undefined,
    valuationCap: termSheet?.valuationCap || undefined,
    discountRateActive: !!termSheet?.discountRate,
    valuationCapActive: !!termSheet?.valuationCap
  }

  const fetchData = async () => {
    try {
      const _termSheet = await fetchOne({ data: { id: id as string } })

      if (_termSheet?.id) {
        setTermSheet(_termSheet)
      } else {
        ToastService.showError('Something went wrong.')
        navigate.toDashboard()
      }
    } catch {
      navigate.toDashboard()
    }
  }

  useEffect(() => {
    fetchData()
  }, [id])

  return (
    <div className={styles.termsheetContent}>
      <ConditionalRender
        condition={!loading && termSheet?.id}
        fallbackElement={<Loader width="100%" />}
      >
        {activeTab === 'document' || !isOwner ? (
          <TermSheetPreview
            company={termSheet?.senderCompany}
            terms={terms}
            roundAmount={termSheet?.roundAmount}
          />
        ) : (
          <TermSheetSummary refetch={fetchData} termSheet={termSheet} />
        )}
      </ConditionalRender>
    </div>
  )
}

export default withStore(mapStateToProps)(TermSheetPreviewView)
