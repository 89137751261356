import clsx from 'clsx'
import React, { FC, useRef, useState } from 'react'
import { useOnClickOutside } from 'usehooks-ts'

import { ConditionalRender, OptionsPopup } from '@/common/components'
import { IPopupOption } from '@/common/components/OptionsPopup/OptionsPopup'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import { Button, FontWeight, Row, Spacer, Text, TextTypes } from '@/packages/ui'

import styles from './SafeNoteButton.module.scss'

interface SafeNoteButtonProps {
  onClick?: (type: 'safe-note' | 'term-sheet') => void
  hidden?: boolean
}

const SafeNoteButton: FC<SafeNoteButtonProps> = ({
  onClick,
  hidden
}: SafeNoteButtonProps) => {
  const [visible, setVisible] = useState(false)

  const popupRef = useRef<any>()

  if (hidden) return null

  const handleClick = (type: 'safe-note' | 'term-sheet') => {
    setVisible(false)
    onClick?.(type)
  }

  const options: IPopupOption[] = [
    {
      id: 'safe-note',
      label: 'Create SAFE'
    }
    // {
    //   id: 'term-sheet',
    //   label: 'Create term sheet'
    // }
  ]

  const onOptionClick = (option: 'safe-note' | 'term-sheet') => {
    handleClick(option)
    setVisible(false)
  }

  useOnClickOutside(popupRef, () => {
    if (visible) {
      setVisible(false)
    }
  })

  return (
    <Row items="center" gap={10}>
      <Button onClick={() => onOptionClick('safe-note')}>Create SAFE</Button>

      {/* Hidden until term sheet feature is not finalized */}
      <ConditionalRender condition={false}>
        <OptionsPopup
          visible={visible}
          options={options}
          onOptionClick={onOptionClick}
        >
          <button
            className={clsx(styles.button)}
            onClick={(e) => {
              e.stopPropagation()
              setVisible((prev) => !prev)
            }}
          >
            <Text
              type={TextTypes.BODY_DEFAULT}
              weight={FontWeight.MEDIUM}
              className="tw-whitespace-nowrap"
            >
              Create
            </Text>
            <Spacer size={11} vertical />
            <SharedIcons.MinimalisticArrow size={14} color={Color.neutral0} />
          </button>
        </OptionsPopup>
      </ConditionalRender>
    </Row>
  )
}

export default SafeNoteButton
