import { FC } from 'react'

import { IconProps, withIcon } from '@/common/hocs'

const BellIcon: FC<IconProps> = withIcon((props) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.6069 17.0817C17.5014 16.857 19.3283 16.4116 21.0611 15.7719C19.6243 14.177 18.7498 12.0656 18.7498 9.75V9.04919C18.7499 9.03281 18.75 9.01641 18.75 9C18.75 5.68629 16.0637 3 12.75 3C9.43629 3 6.75 5.68629 6.75 9L6.7498 9.75C6.7498 12.0656 5.87527 14.177 4.43848 15.7719C6.1714 16.4116 7.99843 16.857 9.89314 17.0818M15.6069 17.0817C14.67 17.1928 13.7166 17.25 12.7498 17.25C11.7832 17.25 10.8299 17.1929 9.89314 17.0818M15.6069 17.0817C15.6998 17.3711 15.75 17.6797 15.75 18C15.75 19.6569 14.4069 21 12.75 21C11.0931 21 9.75 19.6569 9.75 18C9.75 17.6797 9.80019 17.3712 9.89314 17.0818"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
))

export default BellIcon
