import React from 'react'

import { TermsList } from '@/features/safe-note/components'
import { useCreateSafeNoteContext } from '@/features/safe-note/context/CreateSafeNoteContext'
import { Color } from '@/packages/palette'
import {
  Col,
  Heading,
  HeadingTypes,
  Text,
  TextAlign,
  TextTypes
} from '@/packages/ui'

const TermsStep = () => {
  const { data, updateData } = useCreateSafeNoteContext()

  return (
    <Col className="tw-w-full" items="center">
      <Heading
        type={HeadingTypes.H2}
        color={Color.neutral500}
        align={TextAlign.CENTER}
      >
        Select the type of SAFE note you want to create
      </Heading>

      <Text
        type={TextTypes.BODY_DEFAULT}
        color={Color.neutral400}
        align={TextAlign.CENTER}
        className="tw-mt-8 tw-mb-30"
      >
        You can choose between an MFN SAFE or a Discount and/or Valuation Cap
        SAFE.
      </Text>

      <TermsList onSelect={updateData} {...data} />
    </Col>
  )
}

export default TermsStep
