import {
  Cancel,
  SendReminder
} from '@/features/dashboard/assets/icons/TableOptionsIcons'
import { BadgeType } from '@/packages/ui/badge'
import {
  BadgeColumn,
  DiscountAndCap,
  MoneyColumn,
  SAFE
} from '@/packages/ui/table/columns'
import { TableColumn, TableColumnType } from '@/packages/ui/table/types'

export enum SafeRequestedOptions {
  SEND_REMINDER = 'reminder',
  CANCEL_REQUEST = 'cancel'
}

const statusCombinations = {
  new: BadgeType.INFO,
  pending: BadgeType.WARNING,
  accepted: BadgeType.SUCCESS
}

const columns: TableColumn[] = [
  {
    title: 'SAFE',
    accessor: 'safe',
    type: TableColumnType.CUSTOM,
    Component: ({ row }: any) => <SAFE row={row} />
  },
  {
    title: 'Date',
    accessor: 'date',
    withSorting: true,
    type: TableColumnType.DATE
  },
  {
    title: 'Total',
    accessor: 'total',
    type: TableColumnType.CUSTOM,
    withSorting: true,
    Component: MoneyColumn
  },
  {
    title: 'SAFE Amount',
    accessor: 'safeAmount',
    type: TableColumnType.CUSTOM,
    Component: (props) => <MoneyColumn {...props} withApproveState />
  },
  {
    title: 'Cash Amount',
    accessor: 'cashAmount',
    type: TableColumnType.CUSTOM,
    Component: (props) => <MoneyColumn {...props} withApproveState />
  },
  {
    title: 'Discount | Cap',
    accessor: 'discount',
    type: TableColumnType.CUSTOM,
    Component: DiscountAndCap
  },
  {
    title: 'Status',
    accessor: 'status',
    type: TableColumnType.CUSTOM,
    Component: (props) => (
      <BadgeColumn {...props} statusesCombinations={statusCombinations} />
    )
  },
  {
    title: 'SAFE',
    accessor: 'safeLink',
    type: TableColumnType.LINK
  },
  {
    title: ' ',
    accessor: 'options',
    type: TableColumnType.OPTIONS,
    options: [
      {
        accessor: SafeRequestedOptions.SEND_REMINDER,
        title: 'Send reminder',
        icon: <SendReminder />
      },
      {
        accessor: SafeRequestedOptions.CANCEL_REQUEST,
        title: 'Cancel request',
        icon: <Cancel />
      }
    ]
  }
]

export default columns
