import clsx from 'clsx'

import { DateHelper } from '@/common/helpers'
import { Color } from '@/packages/palette'
import { Col, FontWeight, Row, Text, TextTypes } from '@/packages/ui'

interface SignedSafeNotePayload {
  companyImage: string
  companyName: string
  senderImage: string
  senderName: string
}

interface SignedSafeNoteNotification {
  id: string
  userId: string
  companyId: string
  message: string | null
  type: string
  payload: SignedSafeNotePayload
  read: boolean
  createdAt: string
  updatedAt: string
}

interface SignedSafeNoteProps {
  notification: SignedSafeNoteNotification
}

const SignedSafeNote = ({ notification }: SignedSafeNoteProps) => (
  <div
    id={notification.id}
    data-isRead={notification.read}
    className="tw-w-full notification-item"
  >
    <Row gap={8}>
      <img
        src={notification.payload.senderImage}
        width={32}
        height={32}
        className="tw-rounded-full"
        alt={notification.payload.senderName}
        draggable="false"
      />

      <Col className="tw-w-full" gap={2}>
        <div className="tw-inline-block">
          <Text
            asSpan
            type={TextTypes.BODY_DEFAULT}
            className={clsx(FontWeight.SEMIBOLD, 'tw-me-4')}
          >
            {notification.payload.senderName}
          </Text>
          has signed the SAFE Note you sent
        </div>

        <Text color={Color.neutral300} className="tw-mt-8">
          {DateHelper.formatIsoDate(notification.createdAt)}
        </Text>
      </Col>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
          stroke="#618CFA"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.75 12L10.58 14.83L16.25 9.17004"
          stroke="#618CFA"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </Row>
  </div>
)

export default SignedSafeNote
