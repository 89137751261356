import { FC } from 'react'

import { IconProps, withIcon } from '@/common/hocs'

const Document: FC<IconProps> = withIcon((props) => (
  <svg
    width="13"
    height="16"
    viewBox="0 0 13 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="12.9524" height="16" rx="2" fill="currentColor" />
    <rect
      x="1.52435"
      y="4.13794"
      width="8"
      height="1.14286"
      rx="0.571429"
      fill="white"
    />
    <rect
      x="1.52429"
      y="7.18567"
      width="9.14286"
      height="0.761905"
      rx="0.380952"
      fill="white"
    />
    <rect
      x="1.52429"
      y="10.9952"
      width="9.14286"
      height="0.761905"
      rx="0.380952"
      fill="white"
    />
    <rect
      x="1.52429"
      y="9.09045"
      width="9.90476"
      height="0.761905"
      rx="0.380952"
      fill="white"
    />
  </svg>
))

const LoginUser: FC<IconProps> = withIcon(() => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Shape"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 0C11.4039 0 13.6641 0.936172 15.364 2.63603C17.0638 4.33589 18 6.59592 18 9C18 11.4039 17.0638 13.6641 15.364 15.364C13.6641 17.0638 11.4039 18 9 18C6.59592 18 4.33589 17.0638 2.63603 15.364C0.936172 13.6641 0 11.4039 0 9C0 6.59592 0.936172 4.33589 2.63603 2.63603C4.33589 0.936172 6.59592 0 9 0ZM9 11.7867C6.76648 11.7867 4.88837 13.3853 4.51195 15.5527C5.78979 16.4306 7.33585 16.9453 9 16.9453C10.6642 16.9453 12.2102 16.4306 13.4881 15.5527C13.1116 13.3853 11.2337 11.7867 9 11.7867ZM6.13834 7.87033C6.13834 6.29228 7.42209 5.00867 9 5.00867C10.5779 5.00867 11.8617 6.29242 11.8617 7.87033C11.8617 9.44824 10.5779 10.732 9 10.732C7.42209 10.732 6.13834 9.44824 6.13834 7.87033ZM12.7638 12.1816C13.554 12.8967 14.1177 13.8121 14.4016 14.8211C15.9651 13.3691 16.9453 11.2971 16.9453 9C16.9453 4.61893 13.3811 1.05469 9 1.05469C4.61893 1.05469 1.05469 4.61893 1.05469 9C1.05469 11.297 2.03494 13.369 3.59843 14.821C3.88229 13.812 4.44617 12.8966 5.23622 12.1815C5.72099 11.7427 6.27292 11.3967 6.86604 11.1526C5.79391 10.4532 5.08365 9.24321 5.08365 7.87033C5.08365 5.71083 6.8405 3.95398 9 3.95398C11.1595 3.95398 12.9165 5.71083 12.9165 7.87033C12.9165 9.24321 12.2062 10.4532 11.134 11.1526C11.7272 11.3967 12.279 11.7429 12.7638 12.1816Z"
      fill="#618CFA"
    />
  </svg>
))

export const SafeNoteIcons = {
  Document,
  LoginUser
}
