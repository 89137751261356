export enum ProfileTypes {
  ENTREPRENEUR = 'entrepreneur',
  ANGEL = 'angel',
  ADVISOR = 'advisor'
}

export enum VerificationStatus {
  Pending = 'pending',
  Verified = 'verified',
  NotVerified = 'notVerified'
}
