import { Nullable } from '@/common/types'

export enum AccountHolderType {
  INDIVIDUAL = 'individual',
  COMPANY = 'company'
}

export enum PaymentType {
  CARD = 'card',
  BANK_TRANSFER = 'bankTransfer'
}

export enum PaymentReason {
  UNKNOWN = 'unknown',
  SAFE_NOTE = 'safeNote'
}

export enum PayAs {
  ENTREPRENEUR = 'entrepreneur',
  ANGEL = 'angel'
}

export interface CardDataDto {
  cardNumber: string
  expirationMonth: number
  expirationYear: number
  cvv: number
}

export interface BankTransferDto {
  accountNumber: string
  routingNumber: string
  accountHolderType: AccountHolderType
  billingDetails?: {
    name: string
    email?: string
  }
}

export interface CreateBankPaymentIntentDto {
  type: PaymentType.BANK_TRANSFER
  data?: BankTransferDto
  payAs: PayAs
}

export interface CreateCardPaymentIntentDto {
  type: PaymentType.CARD
  data?: CardDataDto
  payAs: PayAs
}

export type CreatePaymentIntentDto = (
  | CreateCardPaymentIntentDto
  | CreateBankPaymentIntentDto
) & {
  payAs: PayAs
}

export enum PaymentStatus {
  SUCCESS = 'succeeded',
  PENDING = 'pending',
  REQUIRES_ACTION = 'requires_action',
  REQUIRES_CONFIRMATION = 'requires_confirmation',
  REQUIRES_PAYMENT_METHOD = 'requires_payment_method',
  CANCELED = 'canceled',
  FAILED = 'failed',
  SENT = 'sent'
}

export interface IPayment {
  id: string
  transactionId: Nullable<string>
  paymentIntentId: string
  customerId: string
  invoiceId: Nullable<number>
  amount: number
  currency: Nullable<string>
  status: PaymentStatus
  paymentMethodType: Nullable<string>
  paymentMethodId: Nullable<string>
  createdAt: string
}

export interface IPaymentIntent {
  clientSecret: string
  payment: IPayment
}
