import React, { FC, useCallback, useEffect, useState } from 'react'

import { ConditionalRender } from '@/common/components'
import { fileToDataUrl } from '@/common/utils'
import { useModal } from '@/packages/hooks/useModal'
import {
  FileItem,
  FileSelect,
  IFile,
  Label,
  RequiredFieldStar,
  Spacer
} from '@/packages/ui'

import { PickImageModal } from './PickImageModal'
import styles from './UploadImage.module.scss'

const DEFAULT_CAPTION = 'Upload Image (PNG or JPG)'

interface UploadImageProps {
  image?: IFile | string
  defaultFileName?: string
  label?: string
  modalTitle?: string
  caption?: string
  description?: string
  required?: boolean
  onImageSelect?: (file: IFile) => void
}

const UploadImage: FC<UploadImageProps> = ({
  image: initialImageFile,
  label = 'Upload image',
  defaultFileName = 'Unnamed.png',
  modalTitle,
  required,
  description,
  caption = DEFAULT_CAPTION,
  onImageSelect
}: UploadImageProps) => {
  const { visible, openModal, closeModal } = useModal<boolean>(false)
  const [file, setFile] = useState<IFile | undefined>(undefined)
  const [image, setImage] = useState<any>(undefined)

  const handleSelectFile = useCallback(
    async (files: IFile | IFile[]) => {
      const _file = Array.isArray(files) ? files[0] : files
      setFile(_file)

      if (_file) {
        const blob = await fileToDataUrl(_file as any)
        setImage(blob)
      } else {
        setImage(undefined)
      }

      onImageSelect?.(_file)
    },
    [onImageSelect]
  )

  useEffect(() => {
    if (initialImageFile) {
      if (typeof initialImageFile === 'string') {
        setImage(initialImageFile)
      } else {
        handleSelectFile(initialImageFile)
      }
    }
  }, [initialImageFile])

  return (
    <div className={styles.logo}>
      {label && (
        <RequiredFieldStar required={required}>
          <Label label={label} bold />
        </RequiredFieldStar>
      )}
      <Spacer size={8} />

      <ConditionalRender
        condition={!!image}
        fallbackElement={
          <FileSelect
            caption={caption}
            appearance="compact"
            onClick={openModal}
            disableDnd
          />
        }
      >
        <FileItem
          id="logo"
          name={file?.name || defaultFileName}
          icon={image}
          onEdit={openModal}
          onDelete={() => handleSelectFile([])}
          roundIcon
        />
      </ConditionalRender>

      <ConditionalRender condition={!!visible}>
        <PickImageModal
          title={modalTitle}
          description={description}
          closeModal={closeModal}
          onSelectFile={handleSelectFile}
        />
      </ConditionalRender>
    </div>
  )
}

export default UploadImage
