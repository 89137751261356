import clsx from 'clsx'
import React, { FC, useEffect, useState } from 'react'

import { ConditionalRender } from '@/common/components'
import { StoreType, withStore } from '@/common/store'
import { SafeNotesMinimizedTable } from '@/features/dashboard'
import NoteIcon from '@/features/dashboard/assets/images/no-safe-notes.png'
import { ICompany } from '@/features/profile/types'
import { ProfileTypes } from '@/features/user/enums'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import {
  Button,
  Col,
  Heading,
  HeadingTypes,
  IModalWithCloseFn,
  Loader,
  Modal,
  Text,
  TextAlign,
  TextTypes
} from '@/packages/ui'

import styles from './DownloadSafesModal.module.scss'

const mapStateToProps = ({ safeNote }: StoreType) => ({
  safeNoteList: safeNote.safeNoteList,
  fetchSafeNotes: safeNote.fetchAll,
  downloadZip: safeNote.downloadZip,
  downloadingFiles: safeNote.functionLoading.downloadZip,
  safesLoading: safeNote.functionLoading.fetchAll
})

interface DownloadSafesModalProps extends IModalWithCloseFn {
  company: ICompany
}

const DownloadSafesModal: FC<
  DownloadSafesModalProps & ReturnType<typeof mapStateToProps>
> = (props) => {
  const {
    closeModal,
    downloadZip,
    company,
    safesLoading,
    downloadingFiles,
    safeNoteList,
    fetchSafeNotes
  } = props

  const loading = safesLoading || downloadingFiles

  const containsSafes = !!safeNoteList?.length

  const [allSelected, setAllSelected] = useState(false)
  const [selectedSafes, setSelectedSafes] = useState<string[]>([])

  useEffect(() => {
    fetchSafeNotes({
      data: {
        [company.type === ProfileTypes.ANGEL
          ? 'angelCompanyId'
          : 'entrepreneurCompanyId']: company.id
      }
    })
  }, [])

  const selectAll = () => {
    const ids = safeNoteList.map((item) => item.id)

    setAllSelected(!allSelected)
    setSelectedSafes(allSelected ? [] : ids)
  }

  const onItemSelect = (id: string) => {
    if (selectedSafes.includes(id)) {
      setSelectedSafes((prev) => prev.filter((item) => item !== id))
    } else {
      setSelectedSafes([...selectedSafes, id])
    }
  }

  const onPrimaryButtonClick = () => {
    if (selectedSafes.length) {
      downloadZip({
        data: {
          companyId: company.id,
          safeNoteIds: selectedSafes
        }
      })
    }
  }

  const NoSafes = (
    <>
      <img src={NoteIcon} alt="Note Icon" width={90} />
      <Heading
        type={HeadingTypes.H2}
        color={Color.neutral500}
        className="tw-mt-20 tw-mb-10"
      >
        SAFE notes were not found
      </Heading>
      <Text
        align={TextAlign.CENTER}
        type={TextTypes.BODY_DEFAULT}
        color={Color.neutral300}
        className="tw-mb-20"
      >
        This company does not have any SAFE notes
      </Text>
    </>
  )

  return (
    <Modal size="sm" visible setVisible={closeModal} id="download-safes">
      <ConditionalRender
        condition={!loading}
        fallbackElement={<Loader width="100%" />}
      >
        <Col items="center">
          <ConditionalRender
            condition={containsSafes}
            fallbackElement={NoSafes}
          >
            <SafeNotesMinimizedTable
              onSelectAll={selectAll}
              isAllSelected={allSelected}
              safeNotes={safeNoteList}
              onSingleSelect={onItemSelect}
              selectedRows={selectedSafes}
            />
          </ConditionalRender>

          <div
            className={clsx(
              styles.footer,
              !containsSafes && '!tw-justify-center'
            )}
          >
            <ConditionalRender condition={containsSafes}>
              <Button
                uppercase
                appearance="secondary"
                width="full"
                onClick={closeModal}
              >
                Cancel
              </Button>
            </ConditionalRender>

            <Button
              uppercase
              width={containsSafes ? 'full' : 'default'}
              onClick={containsSafes ? onPrimaryButtonClick : closeModal}
              disabled={
                containsSafes ? loading || !selectedSafes?.length : false
              }
            >
              <ConditionalRender
                condition={containsSafes}
                fallbackElement="Close"
              >
                Download SAFEs
                <SharedIcons.DownloadModern
                  color={Color.neutral0}
                  className="tw-ml-5"
                />
              </ConditionalRender>
            </Button>
          </div>
        </Col>
      </ConditionalRender>
    </Modal>
  )
}

export default withStore(mapStateToProps)(DownloadSafesModal)
