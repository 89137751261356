import React, { forwardRef, ForwardRefRenderFunction, useCallback } from 'react'

import Input from './Input'
import { IInputProps } from './types'

type TextAreaProps = Omit<IInputProps, 'type'>

const TextArea: ForwardRefRenderFunction<HTMLInputElement, TextAreaProps> = (
  props,
  ref
) => {
  const CustomComponent = useCallback(
    (inputProps: any) => <textarea ref={ref} {...inputProps} />,
    [props]
  )

  return (
    <Input {...props} useCustomComponent customComponent={CustomComponent} />
  )
}

export default forwardRef(TextArea)
