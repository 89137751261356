import clsx from 'clsx'
import Lottie from 'lottie-react'
import React, { CSSProperties, FC } from 'react'
import { LoaderSizeMarginProps } from 'react-spinners/helpers/props'

import { LottieLoading } from '@/assets/lottie'

import styles from './LottieLoader.module.scss'

interface LoaderProps extends Omit<LoaderSizeMarginProps, 'color'> {
  size?: string
  className?: string
  style?: CSSProperties
}

export const LottieLoader: FC<LoaderProps> = ({
  size = '200px',
  className,
  style
}: LoaderProps) => (
  <Lottie
    animationData={LottieLoading}
    className={clsx(styles.loader, className)}
    style={{
      width: size,
      height: size,
      ...style
    }}
  />
)

export default LottieLoader
