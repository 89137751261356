import React, { FC } from 'react'

import { ModalContentBox } from '@/common/components'
import deleteIcon from '@/features/auth/assets/images/delete-team-member.svg'
import { Button, Modal } from '@/packages/ui'

import styles from './DeleteTeamMemberModal.module.scss'

interface DeleteTeamMembersProps {
  closeModal: () => void
  name: string
  handleDelete: () => void
}

const DeleteTeamMembersModal: FC<DeleteTeamMembersProps> = (props) => {
  const { closeModal, name, handleDelete } = props

  return (
    <Modal
      id="delete-team-member-modal"
      size="sm"
      visible
      setVisible={closeModal}
    >
      <ModalContentBox
        name="Delete team member"
        img={deleteIcon}
        title={`Are you sure you want to delete ${name || 'this team member'}?`}
        description="This action cannot be undone."
      >
        <div className={styles.footer}>
          <Button
            width="default"
            type="button"
            appearance="secondary"
            onClick={closeModal}
            uppercase
          >
            Cancel
          </Button>

          <Button
            width="default"
            type="button"
            appearance="primary"
            onClick={handleDelete}
            uppercase
          >
            Delete
          </Button>
        </div>
      </ModalContentBox>
    </Modal>
  )
}

export default DeleteTeamMembersModal
