import React, { FC } from 'react'

import { WarningModal } from '@/common/modals'
import { IModalWithCloseFn } from '@/packages/ui'
import { useNavigator } from '@/router'

const MODAL_ID = 'logout-modal'

const LogoutModal: FC<IModalWithCloseFn> = (props) => {
  const { closeModal } = props

  const navigate = useNavigator()

  return (
    <WarningModal
      id={MODAL_ID}
      closeModal={closeModal}
      title="Are you sure you want to log out?"
      rightButtonText="Yes, log out"
      rightButtonAction={navigate.toLogout}
    />
  )
}

export default LogoutModal
