import api, { withParams } from '@/common/api'
import { IPlaidAccounts } from '@/features/payment/api/plaid.types'

import { CreatePaymentIntentDto, IPayment, IPaymentIntent } from './types'

export const PAYMENT_ENDPOINTS = {
  GET_BY_ID: '/payment/:id',
  SAFE_NOTE_PAYMENT_INTENT: '/safe-note/:id/payment-intent',
  DOWNLOAD_RECEIPT: '/payment/:id/receipt',
  GET_PLAID_LINK_TOKEN: '/plaid/link/token',
  GET_PLAID_ACCESS_TOKEN: '/plaid/access/token',
  GET_PLAID_ACCOUNT: '/plaid/account'
}

export class PaymentRequests {
  static async createSafeNotePaymentIntent(
    id: string,
    dto: CreatePaymentIntentDto
  ): Promise<IPaymentIntent> {
    return api.post(
      withParams(PAYMENT_ENDPOINTS.SAFE_NOTE_PAYMENT_INTENT, { id }),
      dto
    )
  }

  static async getById(id: string): Promise<IPayment> {
    return api.get(withParams(PAYMENT_ENDPOINTS.GET_BY_ID, { id }))
  }

  static async downloadReceipt(id: string): Promise<Blob> {
    return api.get(withParams(PAYMENT_ENDPOINTS.DOWNLOAD_RECEIPT, { id }), {
      responseType: 'blob'
    })
  }

  static async getPlaidLinkToken(): Promise<string> {
    return api.post(PAYMENT_ENDPOINTS.GET_PLAID_LINK_TOKEN)
  }

  static async exchangePublicTokenToAccessToken(
    publicToken: string
  ): Promise<unknown> {
    return api.post(PAYMENT_ENDPOINTS.GET_PLAID_ACCESS_TOKEN, {
      publicToken
    })
  }

  static async getPlaidAccounts(): Promise<IPlaidAccounts> {
    return api.get(PAYMENT_ENDPOINTS.GET_PLAID_ACCOUNT)
  }
}
