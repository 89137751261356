export enum Permissions {
  EDIT = 'edit',
  VIEW_ONLY = 'view_only'
}

export enum CapStatus {
  VERIFIED = 'verified',
  DEFAULT = 'default',
  PENDING = 'pending'
}

export enum VerificationPaymentType {
  OneTimeFee = 'one_time_fee',
  AdvancedPlan = 'advanced_plan'
}
