import clsx from 'clsx'
import React, { FC, ReactNode } from 'react'

import { ClickEvent } from '@/packages/ui'

import styles from './Disabled.module.scss'

interface DisabledProps {
  disabled?: boolean
  className?: string
  children: ReactNode
  onClick?: ClickEvent
}

const Disabled: FC<DisabledProps> = ({
  disabled = true,
  className,
  children,
  onClick
}: DisabledProps) => (
  <div
    className={clsx(disabled && styles.disabled, className)}
    onClick={onClick}
  >
    {children}
  </div>
)

export default Disabled
