import { yupResolver } from '@hookform/resolvers/yup'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { NumberFormatValues } from 'react-number-format'

import { Avatar, ConditionalRender } from '@/common/components'
import { WarningModal } from '@/common/modals'
import { useStore } from '@/common/store'
import { toCurrencyFormat } from '@/common/utils'
import { ISafeNoteRecipient } from '@/features/safe-note'
import noRecipientsYet from '@/features/safe-note/assets/icons/no-recipients-yet.svg'
import { useCreateSafeNoteContext } from '@/features/safe-note/context/CreateSafeNoteContext'
import { RecipientStepSchema } from '@/features/safe-note/schemas'
import { useDebounceEffect, useModal } from '@/packages/hooks'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import {
  Badge,
  BadgeType,
  Button,
  Col,
  FontWeight,
  Form,
  FormHelper,
  FormItem,
  Heading,
  HeadingTypes,
  NumberInput,
  Row,
  Text,
  TextAlign,
  TextInput,
  TextTypes
} from '@/packages/ui'

import styles from './RecipientStep.module.scss'

export enum TransactionType {
  ONLY_SAFE = 'onlySafe',
  SAFE_PLUS_CASH = 'safePlusCash'
}

const RecipientStep = () => {
  const { getPublicUserByEmail, me } = useStore((store) => store.user)
  const { data, updateData } = useCreateSafeNoteContext()

  // const [userFound, setUserFound] = useState<boolean>(false)
  const [userPhoto, setUserPhoto] = useState<string | undefined>()
  const [itemToDelete, setItemToDelete] = useState<
    ISafeNoteRecipient | undefined
  >()

  const { visible, openModal, closeModal } = useModal(false)

  const {
    reset,
    setError,
    clearErrors,
    control,
    register,
    watch,
    setValue,
    formState: { errors, isValid },
    setFocus
  } = useForm({
    resolver: yupResolver(RecipientStepSchema),
    reValidateMode: 'onChange',
    mode: 'onChange'
  })

  const onAdd = () => {
    setFocus('recipientEmail')

    updateData({
      recipients: [
        ...data.recipients,
        {
          name: watch('recipientName'),
          email: watch('recipientEmail'),
          amount: watch('safeAmount'),
          img: userPhoto
        }
      ]
    })

    reset({
      safeAmount: '',
      recipientEmail: '',
      recipientName: ''
    })

    // setUserFound(false)
    setUserPhoto(undefined)
  }

  const onDelete = () => {
    if (!itemToDelete) {
      return
    }

    updateData({
      recipients: data.recipients.filter(
        (item) => item.email !== itemToDelete.email
      )
    })
    setItemToDelete(undefined)
  }

  const onCloseModal = () => {
    closeModal()
    setItemToDelete(undefined)
  }

  useDebounceEffect(
    async () => {
      const email = watch('recipientEmail')

      if (email && email === me?.email) {
        return
      }

      if (!errors?.recipientEmail?.message && email) {
        const potentialUser = await getPublicUserByEmail({ data: { email } })

        if (potentialUser && potentialUser.fullName) {
          // setUserFound(true)
          setUserPhoto(potentialUser.image)

          setValue('recipientName', potentialUser.fullName)
          clearErrors('recipientName')

          return
        }

        // setUserFound(false)
        setUserPhoto(undefined)
      }
    },
    500,
    [watch('recipientEmail')]
  )

  // clear immediately after changing recipientEmail
  useDebounceEffect(
    async () => {
      // setUserFound(false)
      setUserPhoto(undefined)

      const isRecipientAlreadyExist = !!data.recipients.find(
        (item) => item.email === watch('recipientEmail')
      )

      if (isRecipientAlreadyExist) {
        setError('recipientEmail', {
          type: 'custom',
          message: 'Investor is already added'
        })
      }
    },
    0,
    [watch('recipientEmail')]
  )

  return (
    <Col className="tw-w-full">
      {visible && (
        <WarningModal
          id="remove-investor"
          closeModal={onCloseModal}
          title={`Are you sure you want to delete ${itemToDelete?.name}?`}
          description="This action cannot be undone."
          rightButtonText="Delete"
          rightButtonAction={onDelete}
        />
      )}

      <Heading
        type={HeadingTypes.H2}
        color={Color.neutral500}
        align={TextAlign.CENTER}
      >
        Choose who will receive this SAFE note
      </Heading>

      <Text
        type={TextTypes.BODY_DEFAULT}
        color={Color.neutral400}
        align={TextAlign.CENTER}
        className="tw-mt-8"
      >
        Choose who will receive this SAFE note
      </Text>

      <Form schema={RecipientStepSchema}>
        <div className={styles.inputsWrapper}>
          <FormItem errors={errors.recipientEmail?.message}>
            <TextInput
              {...register('recipientEmail')}
              autoFocus
              label="Investor's email"
              placeholder="Email"
              invalid={FormHelper.isFieldInvalid('recipientEmail', errors)}
            />
          </FormItem>

          <FormItem errors={errors.recipientName?.message}>
            <TextInput
              {...register('recipientName')}
              label="Investor's name"
              placeholder="Name"
              invalid={FormHelper.isFieldInvalid('recipientName', errors)}
              // disabled={userFound}
            />
          </FormItem>

          <FormItem errors={errors.safeAmount?.message}>
            <Controller
              control={control}
              name="safeAmount"
              render={({ field: { onChange, value } }) => (
                <NumberInput
                  prefix="$"
                  label="Investment amount"
                  value={value}
                  onValueChange={(val: NumberFormatValues) => {
                    onChange(val.floatValue || 0)
                  }}
                  placeholder="$"
                  name="safeAmount"
                  invalid={FormHelper.isFieldInvalid('safeAmount', errors)}
                />
              )}
            />
          </FormItem>
        </div>
      </Form>

      <Button
        uppercase
        width="fit"
        disabled={!isValid}
        className="tw-self-end"
        onClick={onAdd}
      >
        Add
      </Button>

      <ConditionalRender
        condition={!!data.recipients.length}
        fallbackElement={
          <div className={styles.noRecipients}>
            <img width={94} src={noRecipientsYet} alt="No recipients" />
            <Text
              type={TextTypes.BODY_DEFAULT}
              color={Color.neutral300}
              align={TextAlign.CENTER}
              className="tw-mt-30"
            >
              You haven’t added any recipients yet
            </Text>
          </div>
        }
      >
        <Col gap={12} className="tw-mt-30">
          {data.recipients.map((item) => (
            <Row
              items="center"
              key={item.email}
              justify="between"
              gap={20}
              className={styles.recipientItem}
            >
              <Row items="center" gap={10}>
                <Avatar size={44} image={item.img} name={item.name} />

                <Col>
                  <Text
                    weight={FontWeight.SEMIBOLD}
                    type={TextTypes.BODY_DEFAULT}
                    color={Color.neutral500}
                  >
                    {item.name}
                  </Text>
                  <Text
                    weight={FontWeight.MEDIUM}
                    color={Color.neutral300}
                    type={TextTypes.BODY_EXTRA_SMALL}
                  >
                    {item.email}
                  </Text>
                </Col>
              </Row>

              <Row items="center" gap={12}>
                <Badge type={BadgeType.SUCCESS} size="small">
                  {toCurrencyFormat(item.amount, '$')}
                </Badge>

                <SharedIcons.Trash
                  size={20}
                  color={Color.neutral300}
                  onClick={() => {
                    setItemToDelete(item)
                    openModal()
                  }}
                />
              </Row>
            </Row>
          ))}
        </Col>
      </ConditionalRender>
    </Col>
  )
}

export default observer(RecipientStep)
