import React, { FC } from 'react'

import { Avatar } from '@/common/components'
import styles from '@/features/safe-note/components/SafeNote/components/SafeNoteNavigation/SafeNoteNavigation.module.scss'
import { useSafeNoteContext } from '@/features/safe-note/components/SafeNote/context'
import { Color } from '@/packages/palette'
import { Col, Row, Text, TextTypes } from '@/packages/ui'

interface SentByBlockProps {}
const SentByBlock: FC<SentByBlockProps> = () => {
  const { sender, companyName } = useSafeNoteContext()

  return (
    <Col gap={12} items="stretch" className={styles.navItem}>
      <Row justify="between">
        <Text type={TextTypes.BODY_SMALL} color={Color.neutral300}>
          Sent by
        </Text>
      </Row>

      <Col gap={2} items="stretch">
        <Row items="center" justify="between">
          <Avatar size={26} image={sender?.image} />
          <Text className="tw-mx-8">{sender?.fullName}</Text>
          <Text className="tw-ml-auto">
            <Text asSpan color={Color.neutral300}>
              @
            </Text>
            {companyName}
          </Text>
        </Row>
      </Col>
    </Col>
  )
}

export default SentByBlock
