import clsx from 'clsx'
import React, { FC } from 'react'

import { Avatar, PulsatingCircle } from '@/common/components'
import { scrollToSignatureBlock } from '@/common/utils/dom'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import { Col, FontWeight, Row, Text, TextTypes } from '@/packages/ui'

import styles from '../SafeNoteNavigation/SafeNoteNavigation.module.scss'

interface IProps {
  name: string | undefined
  image: string | undefined
  viewMode: boolean
  signed: boolean
  small?: boolean
}

const NavigationSignatureItem: FC<IProps> = (props) => {
  const { signed, name, image, viewMode, small } = props

  return (
    <Col
      gap={12}
      items="stretch"
      className={clsx(
        styles.navItem,
        styles.signature,
        signed && styles.signed,
        small && styles.smallCard
      )}
    >
      <Row items="center" justify="between" gap={15}>
        <Row items="center" gap={8}>
          <Avatar
            size={26}
            image={image}
            name={name}
            className={styles.miniAvatar}
          />

          <Text
            className="!tw-leading-snug"
            type={TextTypes.BODY_SMALL}
            color={Color.neutral500}
            weight={FontWeight.MEDIUM}
          >
            {name}
          </Text>

          {!signed && !viewMode && <PulsatingCircle size={10} type="warning" />}
        </Row>

        {viewMode && !signed ? (
          <Text
            type={TextTypes.BODY_SMALL}
            color={Color.neutral300}
            className="!tw-leading-snug tw-italic"
          >
            Not signed
          </Text>
        ) : (
          <Text
            clickable={!signed}
            onClick={scrollToSignatureBlock}
            color={signed ? Color.success400 : Color.primary400}
            type={TextTypes.BODY_SMALL}
            weight={FontWeight.SEMIBOLD}
            className="!tw-flex !tw-items-center !tw-leading-snug"
          >
            {signed ? 'Signed' : 'Sign'}
            {signed ? (
              <SharedIcons.CheckmarkWithoutBg
                size={14}
                className="tw-ml-3"
                color={Color.success400}
              />
            ) : (
              <SharedIcons.PlusThin size={15} />
            )}
          </Text>
        )}
      </Row>
    </Col>
  )
}

export default NavigationSignatureItem
