import clsx from 'clsx'
import React, { FC, MouseEvent } from 'react'

import { toCurrencyFormat } from '@/common/utils'
import { PendingSafesModal } from '@/features/dashboard/modals'
import { ProfileTypes } from '@/features/user/enums'
import { useModal } from '@/packages/hooks'
import { Tooltip } from '@/packages/ui'

import styles from './SafesInfoTooltip.module.scss'

interface SafesInfoTooltipProps {
  type: 'complete' | 'incomplete'
  role: ProfileTypes
  amount: number
  count: number
  companyId: string
}

const SafesInfoTooltip: FC<SafesInfoTooltipProps> = (props) => {
  const { type, role, companyId, count, amount } = props

  const isIncomplete = type === 'incomplete'
  const isClickable = count > 0 && isIncomplete

  const { visible, openModal, closeModal } = useModal<boolean>(false)

  const titles = {
    [ProfileTypes.ANGEL]: {
      complete: 'Paid SAFEs',
      incomplete: 'Unpaid SAFEs'
    },
    [ProfileTypes.ENTREPRENEUR]: {
      complete: 'SAFEs complete',
      incomplete: 'SAFEs incomplete'
    },
    [ProfileTypes.ADVISOR]: {
      complete: 'Lorem ipsum',
      incomplete: 'Lorem ipsum'
    }
  }

  const title = titles[role][type]

  const handleCountClick = (e: MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation()

    if (isClickable) {
      openModal()
    }
  }

  const Amount = isIncomplete ? (
    <span className={styles.yellowNumber}>{count}</span>
  ) : (
    count
  )

  const TooltipContent = (
    <div>
      <span className={styles.amount}>{toCurrencyFormat(amount, '$')}</span>{' '}
      {type} <br />
      from <span className={styles.count}>{count}</span>{' '}
      {count === 1 ? 'SAFE' : 'SAFEs'}
    </div>
  )

  const WrapperComponent = isClickable ? Tooltip : 'div'

  return (
    <>
      {visible && (
        <PendingSafesModal
          companyId={companyId}
          closeModal={closeModal}
          fetchRequired
          type={role}
        />
      )}

      <WrapperComponent arrow placement="bottom" content={TooltipContent}>
        <div
          className={clsx(
            styles.amountContainer,
            isClickable && styles.clickable
          )}
          onClick={handleCountClick}
        >
          {title}: {Amount}
        </div>
      </WrapperComponent>
    </>
  )
}

export default SafesInfoTooltip
