import React, { FC } from 'react'

import { ConditionalRender } from '@/common/components'
import { capitalize } from '@/common/utils'
import { ProfileTypes } from '@/features/user/enums'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import { FontWeight, Row, Text, TextTypes } from '@/packages/ui'

import styles from './Breadcrumbs.module.scss'

interface BreadcrumbsProps {
  type: ProfileTypes
  company: string
  innerCompany?: string
  onTypeClick: () => void
  onInnerClick?: () => void
}

const Breadcrumbs: FC<BreadcrumbsProps> = (props) => {
  const { type, onInnerClick, company, innerCompany, onTypeClick } = props

  return (
    <Row className={styles.breadcrumbs} items="center" gap={10}>
      <Text
        clickable
        onClick={onTypeClick}
        className="tw-underline"
        color={Color.primary500}
        type={TextTypes.BODY_SMALL}
        weight={FontWeight.SEMIBOLD}
      >
        {capitalize(type)} Workspace
      </Text>

      <SharedIcons.MinimalisticArrow
        color={Color.neutral300}
        width={5.4}
        height={9}
      />
      <Text
        clickable={!!innerCompany}
        color={!!innerCompany ? Color.neutral300 : Color.neutral500}
        type={TextTypes.BODY_SMALL}
        weight={FontWeight.SEMIBOLD}
        onClick={onInnerClick}
      >
        {company}
      </Text>

      <ConditionalRender condition={!!innerCompany}>
        <SharedIcons.MinimalisticArrow
          color={Color.neutral300}
          width={5.4}
          height={9}
        />
        <Text
          color={Color.neutral500}
          type={TextTypes.BODY_SMALL}
          weight={FontWeight.SEMIBOLD}
        >
          {innerCompany}
        </Text>
      </ConditionalRender>
    </Row>
  )
}

export default Breadcrumbs
