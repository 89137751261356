import clsx from 'clsx'
import React, { FC } from 'react'

import { SharedIcons } from '@/packages/icons'
import { IButtonProps } from '@/packages/ui'

import { Button } from '../Button'
import styles from './BackButton.module.scss'

type BackButtonProps = IButtonProps & {
  reverse?: boolean
}

const BackButton: FC<BackButtonProps> = ({ children, reverse, ...rest }) => (
  <Button
    {...rest}
    appearance="ordinary"
    textClassName={clsx(styles.text, reverse && styles.reverse)}
  >
    <SharedIcons.Arrow size={25} className={styles.arrow} />
    {children || 'Back'}
  </Button>
)

export default BackButton
