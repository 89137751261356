import clsx from 'clsx'
import React, { FC, MouseEvent } from 'react'

import { SharedIcons } from '@/packages/icons'

import styles from './LearnMoreLink.module.scss'

interface IProps {
  text?: string
  onClick?: (e?: MouseEvent<HTMLElement>) => void
  reverse?: boolean
  align?: 'start' | 'end' | 'center'
  className?: string
}

const LearnMoreLink: FC<IProps> = ({
  reverse,
  text,
  onClick,
  align = 'start',
  className
}) => (
  <span
    className={clsx(
      className,
      styles.learnMore,
      align && styles[align],
      reverse && styles.reverse
    )}
    onClick={onClick}
  >
    {text || 'Learn more'} <SharedIcons.Arrow />
  </span>
)

export default LearnMoreLink
