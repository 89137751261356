import { useState } from 'react'
import { useLocalStorage } from 'usehooks-ts'

type Callback<T> = (prev: T) => T
type SetState<T> = (value: Partial<T> | Callback<T>, override?: boolean) => void

interface UserSmartStateOptions {
  persist?: true
  storageKey?: string
}

const RANDOM_KEY = Date.now().toString()

export function useSmartState<T = any>(
  initialValue: T,
  { persist, storageKey = RANDOM_KEY }: UserSmartStateOptions = {}
): [T, SetState<T>] {
  const [state, setState] = persist
    ? useLocalStorage<T>(storageKey, initialValue)
    : useState<T>(initialValue)

  const _getSetStateData =
    (value: Partial<T> | Callback<T>) => (prev: T & object) => {
      const _value =
        value?.constructor === Function
          ? ((value as Callback<T>)(prev) as T)
          : value

      if (prev?.constructor === Object) {
        return {
          ...prev,
          ..._value
        }
      }

      return _value
    }

  const customSetState: SetState<T> = (
    value: Partial<T> | Callback<T>,
    override = false
  ) => {
    if (value?.constructor === Function) {
      if (override) {
        setState((prev: T) => (value as Callback<T>)(prev))
        return
      }

      setState(_getSetStateData(value) as T)
    } else {
      setState(_getSetStateData(value) as T)
    }
  }

  return [state, customSetState]
}
