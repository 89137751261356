import { FC } from 'react'

import { ConditionalRender } from '@/common/components'
import { IconProps } from '@/common/hocs'
import { FontWeight, Row, Text, TextTypes } from '@/packages/ui'

import styles from './InvitesBlock.module.scss'

interface InvitesBlockProps {
  title: string
  count: number
  onClick: () => void
  condition: boolean
  icon: FC<IconProps>
}

const InvitesBlock: FC<InvitesBlockProps> = (props) => {
  const { title, icon, count, onClick, condition } = props

  return (
    <ConditionalRender condition={condition}>
      <div className={styles.spacer} />
      <Row
        items="center"
        gap={5}
        className={styles.invitesBlock}
        onClick={onClick}
      >
        {icon?.({})}
        <Text
          className={styles.invitesBlockText}
          type={TextTypes.BODY_SMALL}
          weight={FontWeight.SEMIBOLD}
        >
          {title} ({count})
        </Text>
      </Row>
    </ConditionalRender>
  )
}

export default InvitesBlock
