import clsx from 'clsx'
import { FC, useEffect, useState } from 'react'

import { ConditionalRender } from '@/common/components'
import { BellIcon } from '@/features/dashboard/assets'
import { Color } from '@/packages/palette'
import {
  FontWeight,
  Loader,
  Row,
  Switch,
  Text,
  TextAlign,
  TextTypes,
  Tooltip
} from '@/packages/ui'

import { NotificationRequests } from './api/NotificationRequests'
import NotificationsList from './components/NotificationsList'
import noNotificationsImg from './images/no-notifications.png'
import styles from './Notifications.module.scss'

interface NotificationProps {
  size?: number
  color?: Color
  minimalistic?: boolean
  placement?: 'bottom-start' | 'bottom-end'
  companyId?: string
}

const Notifications: FC<NotificationProps> = ({
  size,
  color = Color.neutral500,
  minimalistic,
  placement = 'bottom-end',
  companyId
}) => {
  const [unreadOnly, setUnreadOnly] = useState<boolean>(false)

  const [unreadCount, setUnreadCount] = useState<number>(0)
  interface Notification {
    id: string
    read: boolean
  }

  const [notifications, setNotifications] = useState<Notification[]>([])

  const [isLoading, setIsLoading] = useState(false)

  const fetchNotificationsCount = async () => {
    setIsLoading(true)

    try {
      const unread = companyId
        ? await NotificationRequests.fetchCompanyNotificationsCount(companyId)
        : await NotificationRequests.fetchGlobalNotificationsCount()
      // @ts-ignore
      setUnreadCount(unread)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleUpdateNotificationCount = async (count: number) => {
    setUnreadCount(unreadCount - count)
  }

  useEffect(() => {
    fetchNotificationsCount()
  }, [companyId])

  /**
   * Fetches notifications based on the presence of a company ID.
   * If a company ID is provided, fetches company-specific notifications;
   * otherwise, fetches global notifications.
   */
  const fetchNotifications = async (event?: {
    stopPropagation: () => void
  }) => {
    setIsLoading(true)
    event?.stopPropagation()

    try {
      const notificationsData = await (companyId
        ? NotificationRequests.fetchCompanyNotifications(companyId)
        : NotificationRequests.fetchGlobalNotifications())
      setNotifications(
        unreadOnly
          ? // @ts-ignore
            notificationsData?.filter((notification) => !notification.read)
          : notificationsData
      )
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  // Filter out the unread notification
  useEffect(() => {
    if (unreadOnly) {
      setNotifications((prevNotifications) =>
        prevNotifications.filter((notification) => !notification.read)
      )
    } else {
      fetchNotifications()
    }
  }, [unreadOnly])

  const Empty = (
    <div className={styles.emptyNotificationsBody}>
      <img src={noNotificationsImg} width={60} alt="notification" />
      <Text
        align={TextAlign.CENTER}
        type={TextTypes.BODY_SMALL}
        color={Color.neutral400}
      >
        You have no notification
      </Text>
    </div>
  )

  const Wrapper = (
    <div>
      <Row items="center" justify="between" gap={20} className={styles.header}>
        <Text
          type={TextTypes.BODY_DEFAULT}
          color={Color.neutral500}
          weight={FontWeight.SEMIBOLD}
        >
          Notifications ({notifications?.length})
        </Text>

        <Row items="center" gap={8}>
          <Text
            className="!tw-leading-snug"
            type={TextTypes.BODY_SMALL}
            color={Color.neutral300}
          >
            Filter by unread
          </Text>
          <Switch
            value={unreadOnly}
            onChange={(e) => {
              e.stopPropagation()
              setUnreadOnly(!unreadOnly)
            }}
          />
        </Row>
      </Row>

      <ConditionalRender
        condition={!!notifications.length}
        fallbackElement={Empty}
      >
        {isLoading && <Loader width="100%" />}
        {!isLoading && (
          <NotificationsList
            updateNotificationCount={handleUpdateNotificationCount}
            className={styles.notificationsBody}
            // @ts-ignore
            notifications={notifications}
          />
        )}
      </ConditionalRender>
    </div>
  )

  return (
    <ConditionalRender
      condition={!!notifications}
      fallbackElement={<BellIcon color={Color.neutral500} />}
    >
      <Tooltip
        trigger="click"
        interactive
        placement={placement}
        className="tw-w-[417px] !tw-py-15 !tw-px-20 tw-max-h-[75vh] tw-overflow-auto no-scrollbar"
        content={Wrapper}
      >
        <div
          className={clsx(styles.bellWrapper, minimalistic && styles.mini)}
          onClick={(e) => {
            e.stopPropagation()
            if (!notifications.length) {
              fetchNotifications()
            }
          }}
        >
          <BellIcon size={size} color={color} />
          <ConditionalRender condition={unreadCount > 0}>
            <span className={styles.mark}>{unreadCount}</span>
          </ConditionalRender>
        </div>
      </Tooltip>
    </ConditionalRender>
  )
}

export default Notifications
