import clsx from 'clsx'
import React, { FC, ReactNode } from 'react'

import {
  FontWeight,
  Text,
  TextTypes,
  Tooltip,
  TooltipAppearance
} from '@/packages/ui'

import styles from './DocumentControlItem.module.scss'

interface DocumentControlItemProps {
  icon?: ReactNode
  active?: boolean
  tooltipText?: string
  onClick?: () => void
}

const DocumentControlItem: FC<DocumentControlItemProps> = ({
  icon,
  tooltipText,
  active,
  onClick
}: DocumentControlItemProps) => (
  <Tooltip
    content={
      <Text type={TextTypes.BODY_SMALL} weight={FontWeight.SEMIBOLD}>
        {tooltipText}
      </Text>
    }
    placement="bottom"
    appearance={TooltipAppearance.SMALL_BLUE}
  >
    <div
      className={clsx(styles.container, active && styles.active)}
      onClick={onClick}
    >
      {icon}
    </div>
  </Tooltip>
)

export default DocumentControlItem
