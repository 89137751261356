import { useCallback, useState } from 'react'

function useStepper(initialStep = 0, stepsLength = 0) {
  const [currentStep, setCurrentStep] = useState<number>(initialStep)
  const [completed, setCompleted] = useState<boolean>(false)

  const prevStep = useCallback(() => {
    if (currentStep !== 0) {
      setCurrentStep((prev) => prev - 1)
    }
  }, [currentStep])

  const nextStep = useCallback(() => {
    if (currentStep < stepsLength - 1) {
      setCurrentStep((prev) => prev + 1)
    } else {
      setCompleted(true)
    }
  }, [currentStep, stepsLength])

  return {
    currentStep,
    prevStep,
    nextStep,
    completed,
    isLastStep: currentStep === stepsLength - 1,
    setCurrentStep
  }
}

export default useStepper
