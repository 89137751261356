import { FC } from 'react'

type Dictionary<T = any> = Record<string, T>
export type RowData = Dictionary
export type RowsData = RowData[]

interface Options {
  accessor: string
  title: string
  icon?: JSX.Element
}

export interface FilterOption {
  accessor: string
  title: string
}

export interface SelectOption {
  value: string
  label: string
}

export interface BaseTableColumn {
  accessor: string
  title: string
  tooltip?: string | FC<any>
  withSorting?: boolean
  valueExtractor?: (row: RowData) => string
  customActionName?: string
  filterOptions?: FilterOption[]
}

export interface HandleActionFnParams {
  action: string
  row: RowData
  accessor?: string
  value?: any
}

export type HandleActionFn = (params: HandleActionFnParams) => void

export interface BaseColumnProps<T = Dictionary> {
  row: T
  handleAction?: HandleActionFn
}

export enum TableColumnType {
  TEXT = 'text',
  LINK = 'link',
  SELECT = 'select',
  DATE = 'date',
  CUSTOM = 'custom',
  OPTIONS = 'options',
  DETAILS = 'details',
  DELETE = 'delete'
}

export interface ColumnTypeDef extends BaseTableColumn {
  type: TableColumnType
}

export interface CustomTableColumn extends ColumnTypeDef {
  type: TableColumnType.CUSTOM
  Component: FC<any>
}

export interface TextTableColumn extends ColumnTypeDef {
  type: TableColumnType.TEXT
}

export interface DateTableColumn extends ColumnTypeDef {
  type: TableColumnType.DATE
}

export interface SelectTableColumn extends ColumnTypeDef {
  type: TableColumnType.SELECT
  options: SelectOption[]
}

export interface OptionsTableColumn extends ColumnTypeDef {
  type: TableColumnType.OPTIONS
  options: Options[]
}

export interface DetailsTableColumn extends ColumnTypeDef {
  type: TableColumnType.DETAILS
}

export interface DeleteTableColumn extends ColumnTypeDef {
  type: TableColumnType.DELETE
}

export interface LinkTableColumn extends ColumnTypeDef {
  type: TableColumnType.LINK
}

export enum BASE_TABLE_ACTIONS {
  SELECT = 'select',
  DELETE = 'delete',
  LINK_CLICK = 'linkClick'
}

export type TableColumn =
  | CustomTableColumn
  | TextTableColumn
  | SelectTableColumn
  | DateTableColumn
  | OptionsTableColumn
  | DetailsTableColumn
  | DeleteTableColumn
  | LinkTableColumn

export type TableColumns = TableColumn[]
export type TableColumnsMap = Map<string, TableColumn>
