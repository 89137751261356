import clsx from 'clsx'
import {
  ForwardedRef,
  forwardRef,
  ForwardRefRenderFunction,
  ReactNode,
  Ref,
  SyntheticEvent,
  useContext,
  WheelEvent
} from 'react'

import { Marker } from '@/common/helpers'
import { FormInputContext, RequiredFieldStar } from '@/packages/ui'

import { Label } from '../label'
import { Spacer } from '../spacer'
import styles from './style.module.scss'
import { IInputProps } from './types'

interface IProps {
  fitContent?: boolean
  type?: string
  addonBefore?: ReactNode
  addonAfter?: ReactNode
  containerClassName?: string
  useCustomComponent?: boolean
  tooltip?: string
  customComponent?: (inputProps: any) => ReactNode
  small?: boolean
}

interface FocusEvent<T = Element> extends SyntheticEvent<T> {
  relatedTarget: EventTarget | null
  target: EventTarget & T
}

const Input: ForwardRefRenderFunction<
  HTMLInputElement,
  IProps & IInputProps
> = (
  {
    type = 'text',
    addonBefore,
    addonAfter,
    name,
    label,
    value,
    invalid,
    tooltip,
    useCustomComponent,
    customComponent,
    className,
    required,
    fitContent,
    containerClassName,
    small,
    ...rest
  }: IProps & IInputProps,
  ref: Ref<HTMLInputElement> | ForwardedRef<HTMLInputElement>
) => {
  const { requiredFields } = useContext(FormInputContext)
  const hasData = Boolean(value)

  const handleWheel = (e: WheelEvent<HTMLInputElement>) => {
    e.currentTarget.blur()
  }

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    const marker = new Marker(e.currentTarget)

    if (!marker.isInViewPort(e.currentTarget, 20)) {
      e.currentTarget?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }

  const inputProps = {
    id: name,
    type,
    name,
    value,
    onFocus: handleFocus,
    onWheel: handleWheel,
    className: clsx(
      styles.input,
      hasData && styles.hasData,
      invalid && styles.invalid,
      rest.disabled && styles.disabled,
      Boolean(addonBefore) && styles.withAddonBefore,
      Boolean(addonAfter) && styles.withAddonAfter,
      small && styles.small,
      styles.className
    ),
    ...rest
  }

  const _required =
    (!!inputProps.id && requiredFields?.includes?.(inputProps.id)) || required

  return (
    <div
      className={clsx(
        styles.container,
        inputProps.disabled && styles.disabled,
        fitContent && styles.fitContent
      )}
    >
      {Boolean(label) && (
        <>
          <RequiredFieldStar required={_required}>
            <Label bold htmlFor={name} label={label} tooltip={tooltip} />
          </RequiredFieldStar>
          <Spacer size={8} />
        </>
      )}

      <div
        className={clsx(
          styles.wrapper,
          containerClassName && containerClassName
        )}
      >
        {Boolean(addonBefore) && (
          <div className={styles.addonBefore}>{addonBefore}</div>
        )}

        {Boolean(useCustomComponent) ? (
          customComponent?.(inputProps)
        ) : (
          <input ref={ref} {...inputProps} />
        )}

        {Boolean(addonAfter) && (
          <div className={styles.addonAfter}>{addonAfter}</div>
        )}
      </div>
    </div>
  )
}

export default forwardRef(Input)
