import clsx from 'clsx'
import React, { FC } from 'react'

import {
  HighlightToggle,
  Terms,
  TermsBlockAccordion
} from '@/features/safe-note'
import { useSafeNoteContext } from '@/features/safe-note/components/SafeNote/context'
import { Color } from '@/packages/palette'
import { FontWeight, Radio, Row, Text, TextTypes } from '@/packages/ui'

import styles from '../SafeNoteNavigation/SafeNoteNavigation.module.scss'

interface ISectionItem {
  id: Terms
  title: string
  checked: boolean
}

interface IProps {
  item: ISectionItem
  viewMode: boolean
  onItemSelect: (id: Terms) => void
}

const TermsBlockItem: FC<IProps> = (props) => {
  const { item, onItemSelect, viewMode } = props
  const { id, checked, title } = item

  const {
    highlights,
    updateHighlights,
    discountRateActive,
    valuationCapActive
  } = useSafeNoteContext()

  const isCustomTermsSelected = id === Terms.HELP_SET_TERMS && checked
  const showMainEye = isCustomTermsSelected
    ? checked && discountRateActive && valuationCapActive
    : checked

  const hideHeader = viewMode && isCustomTermsSelected

  const Header = (
    <Row
      items="center"
      gap={10}
      justify="between"
      className={clsx(
        styles.termsItemInner,
        isCustomTermsSelected && styles.multiple
      )}
    >
      <Row items="center" gap={10}>
        {!viewMode && <Radio name={id} checked={checked} />}

        <Text
          weight={viewMode ? FontWeight.MEDIUM : FontWeight.SEMIBOLD}
          type={TextTypes.BODY_SMALL}
          color={checked ? Color.neutral500 : Color.neutral400}
        >
          {title}
        </Text>
      </Row>

      {showMainEye && (
        <HighlightToggle
          type={id}
          toggle={() => updateHighlights({ [id]: !highlights[id] })}
          enabled={highlights[id]}
        />
      )}
    </Row>
  )

  return (
    <div
      className={clsx(
        styles.termsItem,
        checked && styles.active,
        viewMode && styles.viewMode
      )}
      onClick={() => onItemSelect(id)}
    >
      {!hideHeader && Header}
      {isCustomTermsSelected && <TermsBlockAccordion />}
    </div>
  )
}

export default TermsBlockItem
