import React, { FC } from 'react'

import { ModalContentBox } from '@/common/components'
import infoImage from '@/features/safe-note/assets/images/signature-info.png'
import { Button, IModalWithCloseFn, Modal } from '@/packages/ui'

interface IProps extends IModalWithCloseFn {
  onNext: () => void
}

const SignatureInfoModal: FC<IProps> = (props) => {
  const { closeModal, onNext } = props

  return (
    <Modal id="signature-info-modal" size="sm" visible setVisible={closeModal}>
      <ModalContentBox
        name="Signature info"
        img={infoImage}
        imgSize={169}
        title="Add your signature once, and we’ll do the rest!"
        description="If you’re sending this SAFE to multiple investors, you only need to add your signature once. We’ll duplicate it and sign the rest of the SAFEs for you."
      >
        <Button width="default" type="button" onClick={onNext} uppercase>
          Add signature
        </Button>
      </ModalContentBox>
    </Modal>
  )
}

export default SignatureInfoModal
