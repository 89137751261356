import clsx from 'clsx'
import React from 'react'

import { OptionsPopup } from '@/common/components'
import { toCurrencyFormat } from '@/common/utils'
import { HighlightToggle } from '@/features/safe-note'
import { useSafeNoteContext } from '@/features/safe-note/components/SafeNote/context'
import { SetTermsModal } from '@/features/safe-note/modals'
import { useModal } from '@/packages/hooks'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import { Col, FontWeight, Row, Text, TextTypes } from '@/packages/ui'

const TermsBlockAccordion = () => {
  const {
    viewMode,
    discountRate,
    valuationCap,
    highlights,
    updateHighlights,
    onUpdateData
  } = useSafeNoteContext()

  const { visible, openModal, closeModal } = useModal<
    'discount' | 'valuation'
  >()
  const titles = { discountRate: 'Discount', valuationCap: 'Valuation Cap' }

  const options = [
    { id: 'edit', label: 'Edit' },
    { id: 'remove', label: 'Remove' }
  ]

  const hideDiscount = viewMode && !discountRate
  const hideValuation = viewMode && !valuationCap

  const onOptionClick = (id: string, type: 'discount' | 'valuation') => {
    if (id === 'edit') {
      openModal(type)
    } else if (type === 'discount') {
      onUpdateData({ discountRateActive: false, discountRate: undefined })
    } else {
      onUpdateData({ valuationCapActive: false, valuationCap: undefined })
    }
  }

  const renderTitle = (
    id: 'discountRate' | 'valuationCap',
    value: number | undefined
  ) => (
    <Col>
      <Text type={TextTypes.BODY_SMALL} color={Color.neutral500}>
        {titles[id]}:
      </Text>

      {!!value && (
        <Text
          color={viewMode ? Color.neutral500 : Color.primary400}
          weight={FontWeight.SEMIBOLD}
          type={TextTypes.BODY_DEFAULT}
        >
          {id === 'discountRate' ? `${value}%` : toCurrencyFormat(value, '$')}
        </Text>
      )}
    </Col>
  )

  const renderItem = (
    id: 'discountRate' | 'valuationCap',
    value: number | undefined
  ) => (
    <Row gap={10} justify="between">
      {value && !viewMode ? (
        <OptionsPopup
          placement="right"
          options={options}
          onOptionClick={(actionId) =>
            onOptionClick(
              actionId,
              id === 'discountRate' ? 'discount' : 'valuation'
            )
          }
        >
          <div className="tw-cursor-pointer">{renderTitle(id, value)}</div>
        </OptionsPopup>
      ) : (
        renderTitle(id, value)
      )}

      {value ? (
        <HighlightToggle
          type={id}
          toggle={() => updateHighlights({ [id]: !highlights[id] })}
          enabled={highlights[id]}
        />
      ) : (
        <Text
          clickable
          onClick={() =>
            openModal(id === 'discountRate' ? 'discount' : 'valuation')
          }
          weight={FontWeight.SEMIBOLD}
          type={TextTypes.BODY_SMALL}
          color={Color.primary400}
          className="!tw-leading-snug !tw-flex tw-items-center"
        >
          <SharedIcons.PlusThin color={Color.primary400} size={15} /> Add
        </Text>
      )}
    </Row>
  )

  return (
    <Col
      gap={8}
      items="stretch"
      className={clsx(!viewMode && 'tw-px-9 tw-py-12')}
    >
      {!!visible && (
        <SetTermsModal
          closeModal={closeModal}
          type={visible}
          onSelect={onUpdateData}
          discountRate={discountRate}
          valuationCap={valuationCap}
        />
      )}

      {!hideDiscount && renderItem('discountRate', discountRate)}
      {!hideValuation && renderItem('valuationCap', valuationCap)}
    </Col>
  )
}

export default TermsBlockAccordion
