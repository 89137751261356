import { AccountHolderType } from '@/features/payment'

export enum PaymentMethod {
  CARD = 'card',
  BANK_TRANSFER = 'bankTransfer',
  RECORD_PAYMENT = 'recordPayment'
}

export interface CardPaymentData {
  method: PaymentMethod.CARD
  cardNumber: string
  expirationDate?: string
  cvv: string
}

export interface BankTransferData {
  method: PaymentMethod.BANK_TRANSFER
  accountNumber: string
  routingNumber: string
  accountHolderType: AccountHolderType
  billingDetails: {
    name: string
    email?: string
  }
}

export type PaymentMethodData = CardPaymentData | BankTransferData
