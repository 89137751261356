import clsx from 'clsx'
import React from 'react'

import { VerificationCheckmarkModal } from '@/features/auth/modals'
import consultation from '@/features/profile-settings/assets/consultation.png'
import contactUs from '@/features/profile-settings/assets/contact-us.png'
import helpCenter from '@/features/profile-settings/assets/help-center.png'
import consultationIcon from '@/features/profile-settings/assets/legal-consultations.png'
import { ContactUsModal } from '@/features/profile-settings/modals'
import { useModal } from '@/packages/hooks'
import { Color } from '@/packages/palette'
import {
  Badge,
  BadgeType,
  Button,
  Col,
  FontWeight,
  Heading,
  HeadingTypes,
  Row,
  Text,
  TextTypes
} from '@/packages/ui'

import styles from './Views.module.scss'

const SupportView = () => {
  const { visible, openModal, closeModal } = useModal<
    'consultation' | 'issues'
  >()

  return (
    <div className="tw-mb-50">
      {visible === 'consultation' && (
        <VerificationCheckmarkModal
          title="Get legal consultation!"
          img={consultationIcon}
          text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor"
          closeModal={closeModal}
        />
      )}

      {visible === 'issues' && <ContactUsModal closeModal={closeModal} />}

      <Heading type={HeadingTypes.H2} color={Color.neutral500}>
        Support
      </Heading>

      <Text
        type={TextTypes.BODY_DEFAULT}
        color={Color.neutral400}
        className="tw-mt-8"
      >
        How can we help today?
      </Text>

      <Col items="stretch" gap={20} className="tw-mt-24">
        <Row justify="between" gap={30} className={styles.supportItem}>
          <Col>
            <Text
              type={TextTypes.BODY_MAIN}
              color={Color.neutral500}
              weight={FontWeight.BOLD}
            >
              Help center
            </Text>

            <Text
              type={TextTypes.BODY_SMALL}
              color={Color.neutral400}
              className="tw-mt-2"
            >
              Access all the tips and tricks to make MySAFEnotes work for you.
            </Text>

            <Button uppercase width="fit" className={styles.btn}>
              Read FAQ
            </Button>
          </Col>

          <img src={helpCenter} alt="" height={110} />
        </Row>

        <Row justify="between" gap={30} className={styles.supportItem}>
          <Col>
            <Text
              type={TextTypes.BODY_MAIN}
              color={Color.neutral500}
              weight={FontWeight.BOLD}
            >
              Contact us
            </Text>

            <Text
              type={TextTypes.BODY_SMALL}
              color={Color.neutral400}
              className="tw-mt-2"
            >
              Getting stuck? Reach out to us for help.
            </Text>

            <Button
              uppercase
              width="fit"
              className={styles.btn}
              onClick={() => openModal('issues')}
            >
              Tell about issue
            </Button>
          </Col>

          <img src={contactUs} alt="" height={110} />
        </Row>

        <Row
          justify="between"
          gap={30}
          className={clsx(styles.supportItem, styles.inactive)}
        >
          <Col>
            <Text
              type={TextTypes.BODY_MAIN}
              color={Color.neutral400}
              weight={FontWeight.BOLD}
            >
              Legal consultation for $100/hour
            </Text>

            <Text
              type={TextTypes.BODY_SMALL}
              color={Color.neutral300}
              className="tw-mt-2"
            >
              Chat with a lawyer at $100/hour and get all the advice minus the
              commitment.
            </Text>

            <Badge
              type={BadgeType.WARNING}
              className="!tw-py-4 !tw-px-9 tw-mt-[10px] !tw-rounded-[20px]"
            >
              Coming soon
            </Badge>
          </Col>

          <img src={consultation} alt="" height={110} />
        </Row>
      </Col>
    </div>
  )
}

export default SupportView
