import React, { FC, useState } from 'react'

import { ConditionalRender } from '@/common/components'
import { VerificationPaymentType } from '@/common/enums'
import { StoreType, withStore } from '@/common/store'
import { ICompany } from '@/features/profile/types'
import { VerificationStatus } from '@/features/user/enums'
import { Modal } from '@/packages/ui'

import { Main, VerificationRequestSent, VerificationStepper } from './parts'
import styles from './VerificationCheckmarkModal.module.scss'

export interface VerificationCheckmarkModalProps {
  closeModal: () => void
  title: string
  text: string
  img?: string
  companyDetails?: ICompany
}

const mapStateToProps = ({ profile, user }: StoreType) => ({
  updateVerificationStatus: profile.updateVerificationStatus,
  refetchCompany: user.refetchCompany,
  updateSubscription: user.updateUserSubscription,
  loading:
    profile.functionLoading.updateVerificationStatus ||
    user.functionLoading.updateUserSubscription,
  me: user.me
})

enum ModalSteps {
  SelectOption = 'selectOption',
  FillCompanyInfo = 'fillInfo',
  RequestSent = 'requestSent'
}

const VerificationCheckmarkModal: FC<
  VerificationCheckmarkModalProps & ReturnType<typeof mapStateToProps>
> = (props) => {
  const {
    me,
    closeModal,
    updateSubscription,
    companyDetails,
    updateVerificationStatus,
    refetchCompany,
    loading
  } = props

  const { activeSubscription } = me || {}

  const [step, setStep] = useState<ModalSteps>(ModalSteps.SelectOption)
  const [selectedPlan, setSelectedPlan] = useState<
    VerificationPaymentType | undefined
  >(!!activeSubscription ? VerificationPaymentType.AdvancedPlan : undefined)

  const onFinish = async () => {
    if (
      selectedPlan === VerificationPaymentType.AdvancedPlan &&
      !me?.activeSubscription
    ) {
      await updateSubscription({
        data: {
          payload: {
            plan: 'extend',
            period: 'month'
          }
        }
      })
    }

    await updateVerificationStatus({
      data: {
        companyId: companyDetails?.id || '',
        status: VerificationStatus.Pending
      },
      options: {
        onSuccess: () => {
          refetchCompany({ data: { id: companyDetails?.id || '' } })
          setStep(ModalSteps.RequestSent)
        }
      }
    })
  }

  return (
    <Modal
      visible
      modalInnerClassName="tw-w-full"
      id="verification-checkmark-modal"
      size={step === ModalSteps.FillCompanyInfo ? 'lg' : 'sm'}
      className={
        step === ModalSteps.FillCompanyInfo ? styles.modalStyles : undefined
      }
      setVisible={closeModal}
    >
      <ConditionalRender condition={step === ModalSteps.SelectOption}>
        <Main
          {...props}
          onNext={() => setStep(ModalSteps.FillCompanyInfo)}
          selectedPlan={selectedPlan}
          setSelectedPlan={setSelectedPlan}
        />
      </ConditionalRender>

      <ConditionalRender condition={step === ModalSteps.FillCompanyInfo}>
        <VerificationStepper
          loading={loading}
          selectedPlan={selectedPlan!}
          onFinish={onFinish}
          onBack={() => setStep(ModalSteps.SelectOption)}
          company={companyDetails as ICompany}
        />
      </ConditionalRender>

      <ConditionalRender condition={step === ModalSteps.RequestSent}>
        <VerificationRequestSent
          closeModal={closeModal}
          company={companyDetails as ICompany}
        />
      </ConditionalRender>
    </Modal>
  )
}

export default withStore(mapStateToProps)(VerificationCheckmarkModal)
