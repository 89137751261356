import React, { forwardRef, ForwardRefRenderFunction, useCallback } from 'react'
import {
  NumberFormatBase,
  NumberFormatValues,
  PatternFormat,
  PatternFormatProps,
  SourceInfo
} from 'react-number-format'

import { ChangeEvent } from '@/packages/ui'

import Input from './Input'
import { IInputProps, OnInputValueChange } from './types'

type MaskInputProps = IInputProps &
  Pick<
    PatternFormatProps,
    | 'format'
    | 'mask'
    | 'allowEmptyFormatting'
    | 'patternChar'
    | 'valueIsNumericString'
  > & {
    onValueChange?: OnInputValueChange
    asNumberFormat?: boolean
  }

const MaskInput: ForwardRefRenderFunction<HTMLInputElement, MaskInputProps> = (
  props,
  ref
) => {
  const {
    format,
    mask,
    allowEmptyFormatting,
    patternChar,
    onValueChange,
    asNumberFormat = false,
    valueIsNumericString = false,
    ...rest
  } = props

  const handleOnValueChange = (
    values: NumberFormatValues,
    { event }: SourceInfo
  ) => {
    if (!event) return

    onValueChange?.(values, event as ChangeEvent)
  }

  const Component = asNumberFormat ? NumberFormatBase : PatternFormat

  const CustomComponent = useCallback(
    (inputProps: any) => (
      <Component
        {...inputProps}
        format={format}
        mask={mask}
        valueIsNumericString={valueIsNumericString}
        allowEmptyFormatting={allowEmptyFormatting}
        patternChar={patternChar}
        onValueChange={handleOnValueChange}
      />
    ),
    [props]
  )

  return (
    <Input
      ref={ref}
      {...rest}
      useCustomComponent
      customComponent={CustomComponent}
    />
  )
}

export default forwardRef(MaskInput)
