import { FC } from 'react'

import styles from './DiscountColumn.module.scss'

interface DiscountColumnProps {
  row: any
}

const DiscountColumn: FC<DiscountColumnProps> = (props) => {
  const { row } = props
  const { discount = 0 } = row

  return (
    <div className={styles.wrapper}>
      {/* <span className={styles.old}>TBD</span> */}
      <span className={styles.new}>{discount}%</span>
    </div>
  )
}

export default DiscountColumn
