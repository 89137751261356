import Lottie from 'lottie-react'
import { FC } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import { LottieConfetti } from '@/assets/lottie'
import { ConditionalRender } from '@/common/components'
import { toCurrencyFormat } from '@/common/utils'
import { ICompanyTeamMember } from '@/features/profile/types'
import successImg from '@/features/term-sheet/assets/images/termsheet-sent.png'
import { RecipientsTooltip } from '@/features/term-sheet/components'
import { CreateTermSheetType } from '@/features/term-sheet/layout/CreateTermSheetLayout/CreateTermSheetLayout'
import { Color } from '@/packages/palette'
import {
  Badge,
  BadgeType,
  Button,
  Col,
  FontWeight,
  Heading,
  HeadingTypes,
  Row,
  Spacer,
  Text,
  TextAlign,
  TextTypes
} from '@/packages/ui'
import { useNavigator } from '@/router'

import styles from './Summary.module.scss'

const Summary: FC<CreateTermSheetType> = (props) => {
  const navigate = useNavigator()

  const { companyId } = useParams()

  const [searchParams] = useSearchParams()

  const termSheetId = searchParams.get('termSheetId')

  const { invitedPeople, terms, roundGoal } = props
  const {
    discountRate,
    discountRateActive,
    valuationCapActive,
    valuationCap,
    mfn
  } = terms

  const invitedPeopleToTeamMembersFormat: ICompanyTeamMember[] =
    invitedPeople.map(
      (person) =>
        ({
          id: person.email,
          user: { ...person, fullName: person.fullName || person.email }
        } as unknown as ICompanyTeamMember)
    )

  const rowItemTitle = (text: string) => (
    <Text
      type={TextTypes.BODY_DEFAULT}
      weight={FontWeight.MEDIUM}
      color={Color.neutral400}
    >
      {text}:
    </Text>
  )

  const renderBadge = (value: string | number, type: BadgeType) => (
    <Badge type={type} size="normal" className={styles.badge}>
      {value}
    </Badge>
  )

  return (
    <div className={styles.wrapper}>
      <Lottie animationData={LottieConfetti} className={styles.leftConfetti} />
      <Lottie animationData={LottieConfetti} className={styles.rightConfetti} />

      <Col items="center" className={styles.content}>
        <img src={successImg} width={476} alt="" className="tw-mt-[-30px]" />
        <Spacer size={8} />

        <Heading type={HeadingTypes.H2}>Your term sheet has been sent!</Heading>
        <Spacer size={8} />

        <Text
          type={TextTypes.BODY_DEFAULT}
          align={TextAlign.CENTER}
          color={Color.neutral400}
        >
          You’re a step closer to getting seed funding! Tell your recipients to
          check their email, and view a summary of the term sheet below.
        </Text>
        <Spacer size={28} />

        <Col items="stretch" gap={12} className="tw-max-w-[436px] tw-w-full">
          <Row
            className={styles.summaryItem}
            items="center"
            gap={20}
            justify="between"
          >
            {rowItemTitle('Sent to')}

            <RecipientsTooltip people={invitedPeopleToTeamMembersFormat} />
          </Row>

          <ConditionalRender condition={!!roundGoal}>
            <Row
              items="center"
              className={styles.summaryItem}
              gap={20}
              justify="between"
            >
              {rowItemTitle('Round amount')}
              {renderBadge(
                toCurrencyFormat(roundGoal || 0, '$'),
                BadgeType.INFO
              )}
            </Row>
          </ConditionalRender>

          <ConditionalRender
            condition={mfn}
            fallbackElement={
              <>
                <ConditionalRender condition={discountRateActive}>
                  <Row
                    items="center"
                    className={styles.summaryItem}
                    gap={20}
                    justify="between"
                  >
                    {rowItemTitle('Discount rate')}
                    {renderBadge(
                      `${100 - (discountRate || 0)}%`,
                      BadgeType.SUCCESS
                    )}
                  </Row>
                </ConditionalRender>

                <ConditionalRender condition={valuationCapActive}>
                  <Row
                    items="center"
                    className={styles.summaryItem}
                    gap={20}
                    justify="between"
                  >
                    {rowItemTitle('Valuation cap')}
                    {renderBadge(
                      toCurrencyFormat(valuationCap || 0, '$'),
                      BadgeType.SUCCESS
                    )}
                  </Row>
                </ConditionalRender>
              </>
            }
          >
            <Row
              items="center"
              className={styles.summaryItem}
              gap={20}
              justify="between"
            >
              {rowItemTitle('Terms')}
              {renderBadge('MFN', BadgeType.INFO)}
            </Row>
          </ConditionalRender>
        </Col>
        <Spacer size={28} />

        <Row items="center" gap={12} className="tw-max-w-[436px] tw-w-full">
          <Button
            uppercase
            width="default"
            appearance="secondary"
            onClick={() => navigate.toDashboardCompany(companyId!)}
          >
            Close
          </Button>

          <Button
            uppercase
            width="default"
            onClick={() =>
              termSheetId
                ? navigate.toTermSheet(termSheetId)
                : navigate.toDashboardCompany(companyId!)
            }
          >
            View term sheet
          </Button>
        </Row>
      </Col>
    </div>
  )
}

export default Summary
