import React, { FC } from 'react'

import { ConditionalRender } from '@/common/components'
import {
  PaymentBridge,
  PaymentBridgeChildrenProps,
  PaymentDetails,
  PaymentMethod,
  PaymentMethodData,
  PaymentOptions,
  PaymentStatus
} from '@/features/payment'
import {} from '@/features/payment/components/BankItem/BankItem'
import { Button, IFile, Row, Spacer } from '@/packages/ui'

import { BankItem, IBankAccountItem } from '../BankItem'
import styles from './PaymentModule.module.scss'

export interface PaymentModuleProps {
  cashAmount?: number
  platformFee: number
  stripeFee: number
  onPay?: (
    data: PaymentBridgeChildrenProps
  ) => (data: PaymentMethodData) => void
  onPayLater?: () => void
  onSelectPaymentMethod?: (method: PaymentMethod) => void
  status?: PaymentStatus
  details?: any
  withRecordPayment?: boolean
  setRecordPaymentFiles?: (files: IFile[] | undefined) => void
  onVerifyBankAccount?: () => void
  bankAccount?: IBankAccountItem | null
}

const PaymentModule: FC<PaymentModuleProps> = ({
  platformFee,
  cashAmount = 0,
  onPay,
  onPayLater,
  onSelectPaymentMethod,
  status,
  stripeFee,
  details,
  setRecordPaymentFiles,
  withRecordPayment = true,
  onVerifyBankAccount,
  bankAccount
}) => (
  <PaymentBridge
    initialDetails={details}
    payAmount={cashAmount + platformFee}
    initialStatus={status}
  >
    {(paymentBridgeProps: PaymentBridgeChildrenProps) => (
      <div className={styles.container}>
        <PaymentOptions
          title="Select investment method"
          withRecordPayment={withRecordPayment}
          onPayLater={onPayLater}
          onPay={onPay?.(paymentBridgeProps)}
          cashAmount={cashAmount}
          onSelect={onSelectPaymentMethod}
          setRecordPaymentFiles={setRecordPaymentFiles}
        />

        <PaymentDetails
          cashAmount={cashAmount}
          platformFee={platformFee}
          stripeFee={stripeFee}
        />

        <Spacer size={24} />

        {!!bankAccount && (
          <BankItem
            name={bankAccount.name}
            last4Digits={bankAccount.last4Digits}
          />
        )}

        <Row justify="end" className="tw-mt-24">
          <ConditionalRender
            condition={!bankAccount?.name}
            fallbackElement={
              <Button
                width="default"
                uppercase
                onClick={onPay?.(paymentBridgeProps) as any}
              >
                Pay now
              </Button>
            }
          >
            <Button
              width="default"
              uppercase
              onClick={onVerifyBankAccount}
              disabled={paymentBridgeProps.verifyBankAccountLoading}
            >
              Verify bank account
            </Button>
          </ConditionalRender>
        </Row>
      </div>
    )}
  </PaymentBridge>
)

export default PaymentModule
