class FormatterHelper {
  static toCurrency(number?: number, intlOptions = {}): string | null {
    // eslint-disable-next-line no-restricted-globals
    if (number === undefined || number === null || isNaN(number)) {
      return null
    }

    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      ...intlOptions
    }).format(number)
  }

  static toCompactCurrency(number: number) {
    return FormatterHelper.toCurrency(number, {
      notation: 'compact',
      maximumFractionDigits: 0
    })
  }

  static toPercentage(number = 0): string | null {
    // eslint-disable-next-line no-restricted-globals
    if (!number || isNaN(number)) return null

    return `${number}%`
  }

  /**
   * Extract number from currency e.g: $250,000 = 250000
   * @param number mask number
   * @returns {number}
   */
  static extractNumberFromCurrency(number: string) {
    if (!number) return number
    return Number(String(number).replaceAll('$', '')?.replaceAll(',', ''))
  }

  /**
   * Extract number from percentages ex. 25% = 25
   * @param number mask number
   * @returns {number}
   */
  static extractNumberFromPercentage(number: string) {
    if (!number) return 0
    return Number(number?.replaceAll('%', ''))
  }

  /**
   * Get initials from full name, eg:
   *  name = John doe,
   *  initials = JD
   * @param fullName
   * @returns {string|null}
   */
  getInitials(fullName: string) {
    try {
      const _split = fullName?.split(' ')
      const firstNameLetter = _split[0]?.charAt(0)
      const lastNameLetter = _split[1]?.charAt(0)

      return (firstNameLetter + lastNameLetter).toUpperCase()
    } catch (err) {
      return null
    }
  }

  static getLast4DigitsFromCard(card: string) {
    if (!card || card.trim() === '') return '****'
    return card.slice(card.length - 4)
  }

  /**
   * Convert camel case to dash case
   * Eg: doubleValue -> double-value
   */
  static camelCaseToDashCase(str: string) {
    return str.replace(/[A-Z]/g, (m) => `-${m.toLowerCase()}`)
  }
}

export default FormatterHelper
