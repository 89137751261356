import React from 'react'

import {
  ITermSheetRecipient,
  TermSheetSafeProgress,
  TermSheetStatus
} from '@/features/term-sheet/api/types'
import { RecipientActions } from '@/features/term-sheet/components'
import { Color } from '@/packages/palette'
import {
  BadgeType,
  TableColumn,
  TableColumnType,
  Text,
  TextTypes
} from '@/packages/ui'
import { BadgeColumn, UserColumn } from '@/packages/ui/table/columns'

const statusCombinations = {
  [TermSheetStatus.Rejected]: BadgeType.ERROR,
  [TermSheetStatus.Accepted]: BadgeType.SUCCESS,
  [TermSheetStatus.Pending]: BadgeType.WARNING
}

const safeProgressMapper = {
  [TermSheetSafeProgress.Awaiting]: 'Awaiting SAFE',
  [TermSheetSafeProgress.Created]: 'SAFE created',
  [TermSheetSafeProgress.Paid]: 'SAFE paid'
}

const columns = (
  companyId: string,
  termSheetOptions: any,
  refetch: () => void
): TableColumn[] => [
  {
    title: 'Recipient',
    accessor: 'user',
    type: TableColumnType.CUSTOM,
    Component: UserColumn
  },
  {
    title: 'Status',
    accessor: 'status',
    type: TableColumnType.CUSTOM,
    Component: (props) => (
      <BadgeColumn
        {...props}
        defaultType={BadgeType.WARNING}
        defaultValue="Pending"
        statusesCombinations={statusCombinations}
      />
    )
  },
  {
    title: 'Comment',
    accessor: 'comment',
    type: TableColumnType.TEXT
  },
  {
    title: 'SAFE progress',
    accessor: 'safeProgress',
    type: TableColumnType.CUSTOM,
    Component: ({ row }: { row: ITermSheetRecipient }) => (
      <Text type={TextTypes.BODY_SMALL} color={Color.neutral500}>
        {row.status === TermSheetStatus.Rejected
          ? '-'
          : safeProgressMapper[row.safeProgress]}
      </Text>
    )
  },
  {
    title: 'Actions',
    accessor: 'options',
    type: TableColumnType.CUSTOM,
    Component: ({ row }: { row: ITermSheetRecipient }) => (
      <RecipientActions
        termSheetOptions={termSheetOptions}
        companyId={companyId}
        recipient={row}
        refetch={refetch}
      />
    )
  }
]

export default columns
