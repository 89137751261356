import { yupResolver } from '@hookform/resolvers/yup'
import clsx from 'clsx'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { NumberFormatValues } from 'react-number-format'

import { PaymentIcons } from '@/assets/icons'
import { ConditionalRender } from '@/common/components'
import { verificationPaymentTypes } from '@/common/constants'
import { IVerificationPaymentTypeDetails } from '@/common/constants/waysToPay'
import { VerificationPaymentType } from '@/common/enums'
import { formatCreditCard } from '@/common/utils/cards'
import { CardPaymentSchema } from '@/features/payment/components/PaymentOptions/schemas'
import { MasterCard } from '@/features/profile-settings/assets/icons'
import { AddCardModal } from '@/features/profile-settings/modals'
import { useModal } from '@/packages/hooks'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import {
  CardExpiryInput,
  Checkbox,
  Col,
  FontWeight,
  Form,
  FormHelper,
  FormItem,
  Heading,
  HeadingTypes,
  MaskInput,
  NumberInput,
  Radio,
  Row,
  Text,
  TextTypes
} from '@/packages/ui'

import styles from './Steps.module.scss'

interface VerificationPaymentProps {
  selectedPlan: VerificationPaymentType
  selectedCard: string | undefined
  subscription: boolean
  setSelectedCard: (method: string | undefined) => void
  setDisabled: (value: boolean) => void
}

const VerificationPayment: FC<VerificationPaymentProps> = (props) => {
  const {
    subscription,
    selectedPlan,
    selectedCard,
    setSelectedCard,
    setDisabled
  } = props

  const { visible, openModal, closeModal } = useModal<boolean>(false)

  const [renew, setRenew] = useState(true)
  const [type, setType] = useState<'monthly' | 'yearly'>('yearly')
  const [paymentMethods, setPaymentMethods] = useState<
    {
      id: string
      cardNumber: number
      cvv: number
      expirationDate: string
    }[]
  >([])

  const {
    control,
    formState: { errors, isValid }
  } = useForm({
    resolver: yupResolver(CardPaymentSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: {
      cardNumber: '',
      expirationDate: '',
      cvv: ''
    }
  })

  useEffect(() => {
    if (selectedCard === 'credit-card' && paymentMethods.length) {
      setSelectedCard(undefined)
      return
    }

    setDisabled(selectedCard === 'credit-card' ? !isValid : !selectedCard)
  }, [isValid, selectedCard, paymentMethods])

  const { planDetails } = useMemo(() => {
    const _details = verificationPaymentTypes.find(
      (item) => item.id === selectedPlan
    ) as IVerificationPaymentTypeDetails

    return { planDetails: _details }
  }, [selectedPlan])

  const price = +(
    planDetails.price * (type === 'yearly' && subscription ? 10 : 1)
  ).toFixed(2)
  const fee = +(price * 0.027).toFixed(2)

  const onCardCreate = (cardData: any) => {
    setPaymentMethods((prev) => [...prev, cardData])
  }

  const renderMethods = (item: any, isCard?: boolean) => (
    <Row items="center" key={item.id} gap={20} justify="between">
      <Row items="center" gap={15}>
        {isCard ? (
          <MasterCard />
        ) : (
          <img width={44} src={item.icon} alt={item.name} />
        )}
        <Text
          type={TextTypes.BODY_DEFAULT}
          color={selectedCard === item.id ? Color.primary500 : Color.neutral500}
        >
          {isCard ? formatCreditCard(item.cardNumber.toString()) : item.name}
        </Text>
      </Row>

      <Radio
        name="payment"
        value={item.id}
        checked={selectedCard === item.id}
        onChange={() => setSelectedCard(item.id)}
      />
    </Row>
  )

  const PaymentCards = (
    <>
      <Row items="center" justify="between" gap={20} className="tw-mt-[20px]">
        <Text
          type={TextTypes.BODY_SMALL}
          color={Color.neutral500}
          weight={FontWeight.SEMIBOLD}
        >
          Payment Method
        </Text>

        <Row
          className="tw-cursor-pointer"
          items="center"
          gap={4}
          onClick={() => openModal()}
        >
          <SharedIcons.PlusThin color={Color.primary400} size={15} />
          <Text
            type={TextTypes.BODY_SMALL}
            weight={FontWeight.SEMIBOLD}
            color={Color.primary400}
          >
            Add card
          </Text>
        </Row>
      </Row>

      <div className={styles.detailsSection}>
        <Col gap={15}>
          <ConditionalRender
            condition={!!paymentMethods.length}
            fallbackElement={
              <div>
                {renderMethods(
                  {
                    id: 'credit-card',
                    name: 'Credit Card',
                    icon: PaymentIcons.CreditCard
                  },
                  false
                )}

                <ConditionalRender condition={selectedCard === 'credit-card'}>
                  <div className={styles.cardDetails}>
                    <Form>
                      <FormItem errors={errors?.cardNumber?.message as string}>
                        <Controller
                          control={control}
                          name="cardNumber"
                          render={({ field: { onChange, value } }) => (
                            <MaskInput
                              value={value}
                              placeholder="Card number"
                              format="#### #### #### ####"
                              onValueChange={(val: NumberFormatValues) => {
                                onChange(val.floatValue)
                              }}
                              invalid={FormHelper.isFieldInvalid(
                                'cardNumber',
                                errors
                              )}
                            />
                          )}
                        />
                      </FormItem>
                      <Row gap={10}>
                        <FormItem
                          errors={errors?.expirationDate?.message as string}
                        >
                          <Controller
                            control={control}
                            name="expirationDate"
                            render={({ field: { onChange, value } }) => (
                              <CardExpiryInput
                                value={value}
                                placeholder="MM/YY"
                                onValueChange={(val: NumberFormatValues) => {
                                  onChange(val.formattedValue)
                                }}
                                invalid={FormHelper.isFieldInvalid(
                                  'expirationDate',
                                  errors
                                )}
                              />
                            )}
                          />
                        </FormItem>
                        <FormItem errors={errors?.cvv?.message as string}>
                          <Controller
                            control={control}
                            name="cvv"
                            render={({ field: { onChange, value } }) => (
                              <NumberInput
                                placeholder="CVV"
                                maxLength={4}
                                thousandSeparator=""
                                invalid={FormHelper.isFieldInvalid(
                                  'cvv',
                                  errors
                                )}
                                value={value}
                                onValueChange={(val: NumberFormatValues) => {
                                  onChange(val.floatValue)
                                }}
                              />
                            )}
                          />
                        </FormItem>
                      </Row>
                    </Form>
                  </div>
                </ConditionalRender>
              </div>
            }
          >
            {paymentMethods.map((item) => renderMethods(item, true))}
          </ConditionalRender>

          {renderMethods(
            {
              id: 'google-pay',
              name: 'Google Pay',
              icon: PaymentIcons.GooglePay
            },
            false
          )}
          {renderMethods(
            { id: 'apple-pay', name: 'Apple Pay', icon: PaymentIcons.ApplePay },
            false
          )}
        </Col>
      </div>
    </>
  )

  const SubscriptionType = (
    <>
      <Text
        type={TextTypes.BODY_SMALL}
        weight={FontWeight.SEMIBOLD}
        color={Color.neutral500}
        className="tw-mb-8 tw-mt-20"
      >
        Subscription type
      </Text>
      <div className={styles.subscriptionTypes}>
        <Row
          gap={10}
          justify="between"
          onClick={() => setType('monthly')}
          className={clsx(styles.type, type === 'monthly' && styles.selected)}
        >
          <Col gap={3}>
            <Text type={TextTypes.BODY_SMALL} color={Color.neutral500}>
              Monthly
            </Text>
            <Text
              type={TextTypes.BODY_SMALL}
              color={type === 'monthly' ? Color.primary500 : Color.neutral500}
              weight={FontWeight.SEMIBOLD}
            >
              $9.99
            </Text>
          </Col>

          <Radio
            name="type"
            value="monthly"
            checked={type === 'monthly'}
            onChange={() => setType('monthly')}
          />
        </Row>
        <Row
          gap={10}
          justify="between"
          onClick={() => setType('yearly')}
          className={clsx(styles.type, type === 'yearly' && styles.selected)}
        >
          <Col>
            <Text type={TextTypes.BODY_SMALL} color={Color.neutral500}>
              Annually
            </Text>
            <Row items="center" gap={6}>
              <Text
                type={TextTypes.BODY_SMALL}
                color={type === 'yearly' ? Color.primary500 : Color.neutral500}
                weight={FontWeight.SEMIBOLD}
              >
                $99.99
              </Text>
              <Text
                type={TextTypes.BODY_SMALL}
                color={Color.neutral400}
                weight={FontWeight.SEMIBOLD}
                className="tw-line-through"
              >
                $119.88
              </Text>
            </Row>
          </Col>

          <Radio
            name="type"
            value="yearly"
            checked={type === 'yearly'}
            onChange={() => setType('yearly')}
          />

          <div className={styles.greenBanner}>Pay annually & save 20%</div>
        </Row>
      </div>
    </>
  )

  const Summary = (
    <>
      <Text
        type={TextTypes.BODY_SMALL}
        weight={FontWeight.SEMIBOLD}
        color={Color.neutral500}
        className="tw-mt-[20px]"
      >
        Order summary:
      </Text>

      <div className={styles.detailsSection}>
        <Row items="center" justify="between" className={styles.detailsRow}>
          <Text type={TextTypes.BODY_SMALL} color={Color.neutral500}>
            {planDetails.title}
          </Text>

          <Text
            type={TextTypes.BODY_SMALL}
            weight={FontWeight.SEMIBOLD}
            color={Color.neutral500}
          >
            ${price}
          </Text>
        </Row>

        <Row items="center" justify="between" className={styles.detailsRow}>
          <Text type={TextTypes.BODY_SMALL} color={Color.neutral500}>
            Fee
          </Text>

          <Text
            type={TextTypes.BODY_SMALL}
            weight={FontWeight.SEMIBOLD}
            color={Color.neutral500}
          >
            ${fee}
          </Text>
        </Row>

        <Row items="center" justify="between" className={styles.detailsRow}>
          <Text type={TextTypes.BODY_SMALL} color={Color.neutral500}>
            Total
          </Text>

          <Text
            type={TextTypes.BODY_MAIN}
            weight={FontWeight.BOLD}
            color={Color.primary400}
          >
            ${+(price + fee).toFixed(2)}
          </Text>
        </Row>
      </div>
    </>
  )

  return (
    <Col>
      {visible && <AddCardModal onAdd={onCardCreate} closeModal={closeModal} />}

      <Heading type={HeadingTypes.H2} color={Color.neutral500}>
        Payment
      </Heading>

      <ConditionalRender condition={subscription}>
        {SubscriptionType}
      </ConditionalRender>

      {PaymentCards}

      {Summary}

      <ConditionalRender condition={subscription}>
        <Row className="tw-mt-25" items="center" gap={15}>
          <Checkbox
            className="tw-mt-20"
            type="checkbox"
            name="renew"
            checked={renew}
            onChange={() => setRenew(!renew)}
          />
          <Text type={TextTypes.BODY_SMALL} color={Color.neutral300}>
            Renew automatically every month
          </Text>
        </Row>
      </ConditionalRender>
    </Col>
  )
}

export default VerificationPayment
