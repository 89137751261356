import clsx from 'clsx'
import React, { FC, useEffect, useRef, useState } from 'react'

import { Marker } from '@/common/helpers'
import { StoreType, withStore } from '@/common/store'
import { capitalize } from '@/common/utils'
import { ICompany } from '@/features/profile/types'
import { DocumentControl } from '@/features/safe-note'
import { TermSheetSidebar } from '@/features/term-sheet/components'
import {
  DiscountOnlyTemplate,
  MfnTemplate,
  ValuationAndDiscountTemplate,
  ValuationOnlyTemplate
} from '@/features/term-sheet/components/TermSheetPreview/templates'
import { TermsProps } from '@/features/term-sheet/layout/CreateTermSheetLayout/CreateTermSheetLayout'
import { IUser } from '@/features/user'
import { useSmartState } from '@/packages/hooks'
import { Color } from '@/packages/palette'
import {
  Col,
  Heading,
  HeadingTypes,
  Text,
  TextAlign,
  TextTypes
} from '@/packages/ui'

import styles from './TermSheetPreview.module.scss'

const TERM_SHEET_TEMPLATE_ID = 'term-sheet-template'

interface TermSheetPreviewProps {
  terms: TermsProps
  roundAmount?: number
  company?: ICompany
  recipients?: IUser[]
  owner: IUser | null
}

const mapStateToProps = ({ user }: StoreType) => ({
  myName: user.me?.fullName
})

const TermSheetPreview: FC<
  TermSheetPreviewProps & ReturnType<typeof mapStateToProps>
> = (props) => {
  const [highlights, setHighlights] = useSmartState({
    mfn: false,
    discountRate: false,
    valuationCap: false
  })

  const { owner, company, terms, roundAmount, myName, recipients } = props

  const companyName = company?.name
  const stateOfIncorporation = capitalize(
    company?.stateOfIncorporation || '',
    true
  )

  const [fullScreen, setFullScreen] = useState<boolean>(false)

  const [state, setState] = useSmartState({
    searchValue: '',
    matchesCount: 0,
    currentWordIndex: 0
  })
  const marker = useRef<any>()

  useEffect(() => {
    setTimeout(() => {
      marker.current = new Marker(TERM_SHEET_TEMPLATE_ID)
    })
  }, [])

  useEffect(() => {
    const markerResult = marker.current?.mark(state.searchValue)
    setState({
      matchesCount: markerResult,
      currentWordIndex: 0
    })
  }, [state.searchValue])

  const onDownload = () => {
    // console
  }

  const toggleFullScreen = () => setFullScreen(!fullScreen)

  return (
    <Col items="center">
      <Heading
        type={HeadingTypes.H2}
        color={Color.neutral500}
        align={TextAlign.CENTER}
      >
        Add your signature
      </Heading>

      <Text
        type={TextTypes.BODY_DEFAULT}
        color={Color.neutral400}
        align={TextAlign.CENTER}
        className="tw-mt-8"
      >
        To complete and send your term sheet, add your signature at the end of
        the document.
      </Text>

      <div className={styles.container}>
        <TermSheetSidebar
          recipients={recipients || []}
          highlights={highlights}
          setHighlights={setHighlights}
          terms={terms}
          owner={owner}
        />

        <div
          className={clsx(
            styles.termsheetContainer,
            fullScreen && styles.fullScreen
          )}
        >
          <DocumentControl
            onFullScreen={toggleFullScreen}
            fullScreenActive={fullScreen}
            searchValue={state.searchValue}
            onSearch={(val: string) => setState({ searchValue: val })}
            matchesCount={state.matchesCount}
            currentWordIndex={
              state.searchValue ? state.currentWordIndex + 1 : 0
            }
            onPrevWord={() =>
              setState({ currentWordIndex: marker.current?.markPrev() })
            }
            onNextWord={() =>
              setState({ currentWordIndex: marker.current?.markNext() })
            }
            prevButtonDisabled={state.currentWordIndex === 0}
            nextButtonDisabled={state.currentWordIndex >= state.matchesCount}
            onDownload={onDownload}
          />

          <div className={clsx(styles.template)}>
            {terms.mfn && (
              <MfnTemplate
                highlight={highlights.mfn}
                state={stateOfIncorporation}
                roundAmount={roundAmount}
                companyName={companyName || 'Company'}
                myName={myName || 'Name'}
                id={TERM_SHEET_TEMPLATE_ID}
              />
            )}
            {terms.discountRateActive && !terms.valuationCapActive && (
              <DiscountOnlyTemplate
                highlight={highlights.discountRate}
                state={stateOfIncorporation}
                discount={terms.discountRate || 0}
                roundAmount={roundAmount}
                companyName={companyName || 'Company'}
                myName={myName || 'Name'}
                id={TERM_SHEET_TEMPLATE_ID}
              />
            )}
            {!terms.discountRateActive && terms.valuationCapActive && (
              <ValuationOnlyTemplate
                highlight={highlights.valuationCap}
                state={stateOfIncorporation}
                valuation={terms.valuationCap || 0}
                roundAmount={roundAmount}
                companyName={companyName || 'Company'}
                myName={myName || 'Name'}
                id={TERM_SHEET_TEMPLATE_ID}
              />
            )}
            {terms.discountRateActive && terms.valuationCapActive && (
              <ValuationAndDiscountTemplate
                highlightDiscount={highlights.discountRate}
                highlightValuation={highlights.valuationCap}
                state={stateOfIncorporation}
                discount={terms.discountRate || 0}
                valuation={terms.valuationCap || 0}
                roundAmount={roundAmount}
                companyName={companyName || 'Company'}
                myName={myName || 'Name'}
                id={TERM_SHEET_TEMPLATE_ID}
              />
            )}
            signature
          </div>
        </div>
      </div>
    </Col>
  )
}

export default withStore(mapStateToProps)(TermSheetPreview)
