export enum AuthType {
  Login = 'Login',
  SignUp = 'SignUp'
}

export enum InviteStatus {
  Pending = 'pending',
  Accepted = 'accepted',
  Declined = 'declined'
}
