import clsx from 'clsx'
import React, { FC } from 'react'

import { Button, Row } from '@/packages/ui'

interface IProps {
  leftButtonText: string
  leftButtonAction?: () => void
  rightButtonText: string
  rightButtonActon?: () => void

  disabled?: boolean
  className?: string
}

const ModalFooter: FC<IProps> = (props) => {
  const {
    leftButtonText,
    leftButtonAction,
    rightButtonText,
    rightButtonActon,

    disabled,
    className
  } = props

  return (
    <Row
      gap={16}
      items="stretch"
      justify="between"
      className={clsx('tw-mt-10 tw-self-stretch', className)}
    >
      <Button
        uppercase
        appearance="secondary"
        width="full"
        onClick={leftButtonAction}
      >
        {leftButtonText}
      </Button>

      <Button
        uppercase
        width="full"
        disabled={disabled}
        onClick={rightButtonActon}
      >
        {rightButtonText}
      </Button>
    </Row>
  )
}

export default ModalFooter
