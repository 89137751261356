import clsx from 'clsx'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { Outlet, useParams, useSearchParams } from 'react-router-dom'

import { ConditionalRender } from '@/common/components'
import { WarningModal } from '@/common/modals'
import { ToastService } from '@/common/services'
import { StoreType, withStore } from '@/common/store'
import { Terms } from '@/features/safe-note'
import { createTermsheetSteps } from '@/features/term-sheet/constants'
import { IUser } from '@/features/user'
import { useModal, useSmartState } from '@/packages/hooks'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import {
  Button,
  Row,
  StepperNavigation,
  Text,
  TextTypes,
  useStepper
} from '@/packages/ui'
import { useNavigator } from '@/router'

import styles from './CreateTermSheetLayout.module.scss'

export interface TermsProps {
  selectedTermsId?: Terms
  mfn?: boolean
  discountRate?: number
  valuationCap?: number
  discountRateActive?: boolean
  valuationCapActive?: boolean
}

export interface CreateTermSheetType {
  withRound?: boolean
  roundGoal?: number
  terms: TermsProps
  invitedPeople: Partial<IUser>[]
}

const mapStateToProps = ({ user, termSheet }: StoreType) => ({
  me: user.me,
  getUserInfo: user.getPublicUserByEmail,
  createTermSheet: termSheet.create,
  companies: user.companies,
  loading: user.functionLoading.fetchMe || termSheet.functionLoading.create
})

const CreateTermSheetLayout: FC<ReturnType<typeof mapStateToProps>> = (
  props
) => {
  const { me, companies, createTermSheet, getUserInfo, loading } = props

  const { visible, openModal, closeModal } = useModal<boolean>(false)

  const { companyId } = useParams()
  const navigate = useNavigator()

  const [searchParams, setSearchParams] = useSearchParams()

  const [nextDisabled, setNextDisabled] = useState<boolean>(false)
  const [termState, setTermState] = useSmartState<CreateTermSheetType>({
    withRound: undefined,
    roundGoal: undefined,
    terms: {
      selectedTermsId: undefined,
      mfn: false,
      discountRate: 20,
      valuationCap: undefined,
      discountRateActive: true,
      valuationCapActive: false
    },
    invitedPeople: [
      { email: 'mishaklyuvak10@gmail.com' },
      { email: 'xavier@xicay.com', fullName: 'Xavier Xicay' }
    ]
  })

  const currentCompany = companies.find((company) => company.id === companyId)

  const { currentStep, prevStep, nextStep, setCurrentStep, completed } =
    useStepper(3, 4)

  const handleSelectStep = useCallback(
    (index: number) => {
      if (index < currentStep) {
        setCurrentStep(index)
      }
    },
    [nextDisabled, setCurrentStep]
  )

  const handleNext = async () => {
    if (completed) {
      navigate.toDashboardCompany(companyId!)
      return
    }

    if (currentStep === 3) {
      const termSheet = await createTermSheet({
        data: {
          // TODO: Misha check this
          recipients: termState.invitedPeople.map((user) => user.email || ''),
          senderCompanyId: companyId!,
          discountRate: termState.terms.discountRate,
          mfn: termState.terms.mfn,
          roundAmount: termState.withRound ? termState.roundGoal : undefined
        }
      })

      if (termSheet?.id) {
        setSearchParams((prev) => ({
          ...prev,
          termSheetId: termSheet.id
        }))
      }
    }

    nextStep()
  }

  const addRecipient = async (email: string) => {
    try {
      const user = await getUserInfo({ data: { email } })

      if (user?.email) {
        setTermState({ invitedPeople: [user] })
      } else {
        setTermState({ invitedPeople: [{ email, fullName: '' } as IUser] })
      }
    } catch {
      setTermState({ invitedPeople: [{ email, fullName: '' } as IUser] })
    }
  }

  useEffect(() => {
    const data = searchParams.get('data')

    if (!data) return

    const { mfn, recipient, discountRate, valuationCap, roundAmount } =
      JSON.parse(decodeURIComponent(data) || '{}')

    setTermState({ withRound: !!roundAmount, roundGoal: roundAmount })

    if (mfn) {
      setTermState({
        terms: { ...termState.terms, mfn: true, selectedTermsId: Terms.MFN }
      })
    }

    if (discountRate || valuationCap) {
      setTermState({
        terms: {
          selectedTermsId: Terms.HELP_SET_TERMS,
          discountRateActive: !!discountRate,
          valuationCapActive: !!valuationCap,
          discountRate,
          valuationCap
        }
      })
    }

    if (recipient && recipient !== me?.email) {
      addRecipient(recipient)
    }
  }, [])

  useEffect(() => {
    if (!!me?.id && !loading && !currentCompany) {
      ToastService.showError('This company does not exist')
      navigate.toDashboard()
    }
  }, [me?.id, companyId, loading, currentCompany])

  useEffect(() => {
    const listener = (event: BeforeUnloadEvent) => {
      event.preventDefault()

      // eslint-disable-next-line no-param-reassign
      event.returnValue = ''

      // Display the warning message
      return 'Are you sure you want to leave this page? All your progress will be lost.'
    }

    window.addEventListener('beforeunload', listener)

    return () => {
      window.removeEventListener('beforeunload', listener)
    }
  }, [])

  return (
    <div className={styles.termsheetLayout}>
      {visible && (
        <WarningModal
          id="cancel-termsheet"
          closeModal={closeModal}
          description="All your progress will be lost."
          title="Do you want to exit this page?"
          leftButtonText="Cancel"
          rightButtonText="Exit"
          rightButtonAction={() => navigate.toDashboardCompany(companyId!)}
        />
      )}

      <ConditionalRender condition={!completed}>
        <header className={styles.header}>
          <Text
            clickable
            type={TextTypes.BODY_DEFAULT}
            color={Color.neutral300}
            className="tw-cursor-pointer"
            onClick={() => !loading && openModal()}
          >
            Exit
          </Text>

          <StepperNavigation
            type="small"
            activeStep={currentStep}
            className={styles.navigation}
            steps={createTermsheetSteps}
            itemTextType={TextTypes.BODY_DEFAULT}
            markPreviousAsCompleted={false}
            allowManuallyChangeStep
            onSelectStep={handleSelectStep}
          />

          <Row items="stretch" gap={18}>
            <div
              className={clsx(styles.back, !currentStep && styles.invisible)}
              onClick={() => !!currentStep && !loading && prevStep()}
            >
              <SharedIcons.Arrow color={Color.neutral300} size={24} />
            </div>

            <Button
              width="fit"
              uppercase
              className="!tw-px-38"
              disabled={nextDisabled || loading}
              onClick={handleNext}
            >
              {/* eslint-disable-next-line no-nested-ternary */}
              {loading
                ? 'Loading...'
                : currentStep < 3
                ? 'Next'
                : 'Send Term Sheet'}
              <SharedIcons.Arrow
                color={Color.neutral0}
                size={24}
                className="tw-ml-5"
              />
            </Button>
          </Row>
        </header>
      </ConditionalRender>

      <Outlet
        context={{
          currentStep,
          setNextDisabled,
          completed,
          termState,
          setTermState,
          currentCompany
        }}
      />
    </div>
  )
}

export default withStore(mapStateToProps)(CreateTermSheetLayout)
