import { useEffect, useState } from 'react'
import theme from 'tailwindcss/defaultTheme'

const removePxAndParseToInt = (value: string) => +value.replace('px', '')

export function useScreenSize() {
  const [screenSize, setScreenSize] = useState({
    isTablet: false,
    isMobile: false,
    isDesktop: false
  })

  useEffect(() => {
    const handleResize = () => {
      const { innerWidth: deviceWidth } = window

      const { screens } = theme

      const sm = removePxAndParseToInt(screens.sm)
      const lg = removePxAndParseToInt(screens.lg)

      const isMobile = deviceWidth <= sm
      const isDesktop = deviceWidth > lg
      const isTablet = deviceWidth < lg && deviceWidth > sm

      setScreenSize({
        isDesktop,
        isMobile,
        isTablet
      })
    }

    window.addEventListener('resize', handleResize)
    handleResize() // Initial detection

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return screenSize
}
