import { VerificationPaymentType } from '@/common/enums'
import advancedPlan from '@/features/auth/assets/images/advanced-plan.png'
import advancedPlanMini from '@/features/auth/assets/images/advanced-plan-mini.png'
import oneTimeFee from '@/features/auth/assets/images/one-time-fee.png'
import oneTimeFeeMini from '@/features/auth/assets/images/one-time-fee-mini.png'

export interface IVerificationPaymentTypeDetails {
  id: VerificationPaymentType
  title: string
  price: number
  icon: string
  miniIcon?: string
}

const verificationPaymentTypes: IVerificationPaymentTypeDetails[] = [
  {
    id: VerificationPaymentType.AdvancedPlan,
    title: 'My SAFE Notes+',
    price: 9.99,
    icon: advancedPlan,
    miniIcon: advancedPlanMini
  },
  {
    id: VerificationPaymentType.OneTimeFee,
    title: 'One-time fee',
    price: 50,
    icon: oneTimeFee,
    miniIcon: oneTimeFeeMini
  }
]

export default verificationPaymentTypes
