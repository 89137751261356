import { ISafeNoteDraft, SafeFor } from '@/features/safe-note'

const safeStateToQuery = ({
  safeNote,
  companyId,
  draft
}: {
  safeNote: ISafeNoteDraft
  companyId: string
  draft: boolean
}) => ({
  draft,
  senderCompanyId: companyId,

  recipients: safeNote.recipients.map((item) => {
    const { img, ...rest } = item

    if (img) return item

    return rest
  }),

  safeFor: SafeFor.ANGEL,
  mfn: safeNote.mfn,
  discountRate: safeNote.discountRate,
  valuationCap: safeNote.valuationCap,

  senderSignName: safeNote.signatures.senderSignatureName as string,
  senderSignature: safeNote.signatures.senderSignature?.signature as string
})

export default safeStateToQuery
