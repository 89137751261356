import { IStep } from '@/packages/ui/stepper/types'

export default [
  {
    id: 'goal',
    title: 'Set round'
  },
  {
    id: 'recipients',
    title: 'Recipients'
  },
  {
    id: 'terms',
    title: 'Terms'
  },
  {
    id: 'review',
    title: 'Review'
  }
] as IStep[]
