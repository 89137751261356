import { yupResolver } from '@hookform/resolvers/yup'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

import { ConditionalRender } from '@/common/components'
import { StoreType, withStore } from '@/common/store'
import { decodeToken } from '@/common/utils'
import resetPasswordImg from '@/features/auth/assets/images/reset-password.png'
import successImg from '@/features/auth/assets/images/success.png'
import { ChangePasswordSchema } from '@/features/auth/schemas/AuthSchema'
import { Color } from '@/packages/palette'
import {
  Button,
  Col,
  FormHelper,
  FormItem,
  Heading,
  HeadingTypes,
  IModalWithCloseFn,
  Modal,
  PasswordInput,
  Text,
  TextAlign,
  TextTypes
} from '@/packages/ui'
import { useNavigator } from '@/router'

import styles from './ResetPasswordModal.module.scss'

const id = 'reset-password-modal'

const mapStateToProps = ({ auth }: StoreType) => ({
  completeResetPassword: auth.completeResetPassword,
  loading: auth.functionLoading.completeResetPassword
})

type ResetPasswordModalProps = IModalWithCloseFn &
  ReturnType<typeof mapStateToProps> & {
    token: string
  }

const ResetPasswordModal: FC<ResetPasswordModalProps> = (props) => {
  const { closeModal, loading, token, completeResetPassword } = props

  const navigate = useNavigator()
  const [success, setSuccess] = useState(false)

  const { email } = useMemo(() => decodeToken(token), [token])

  const {
    watch,
    trigger,
    register,
    formState: { errors, isValid }
  } = useForm({
    resolver: yupResolver(ChangePasswordSchema),
    reValidateMode: 'onChange',
    mode: 'onChange'
  })

  const onSubmit = async () => {
    const password = watch('password')

    if (!password) return

    await completeResetPassword({
      data: {
        token,
        password
      },
      options: {
        onSuccess: () => {
          setSuccess(true)
        }
      }
    })
  }

  useEffect(() => {
    if (watch('password') || watch('newPassword')) {
      trigger()
    }
  }, [watch('password'), watch('newPassword')])

  return (
    <Modal size="sm" id={id} visible setVisible={closeModal}>
      <Col items="center" gap={24}>
        <img
          width={success ? 224 : 142}
          src={success ? successImg : resetPasswordImg}
          alt="Reset Password"
        />

        <Col items="center" gap={8}>
          <Heading
            type={HeadingTypes.H2}
            align={TextAlign.CENTER}
            color={Color.neutral500}
          >
            {success ? 'Password changed successfully!' : 'Reset password'}
          </Heading>

          <Text
            align={TextAlign.CENTER}
            type={TextTypes.BODY_DEFAULT}
            color={Color.neutral400}
          >
            {success ? (
              'Well done! You have successfully changed your password! Log in and continue to influence the community'
            ) : (
              <>
                Enter & confirm your new password for <b>{email}</b>
              </>
            )}
          </Text>
        </Col>

        <ConditionalRender condition={!success}>
          <Col className="tw-self-stretch">
            <FormItem errors={errors.password?.message}>
              <PasswordInput
                {...register('password')}
                name="password"
                label="Create new password"
                placeholder="Password"
                invalid={FormHelper.isFieldInvalid('password', errors)}
              />
            </FormItem>
            <FormItem errors={errors.newPassword?.message}>
              <PasswordInput
                {...register('newPassword')}
                name="newPassword"
                label="Repeat new password"
                placeholder="Password"
                invalid={FormHelper.isFieldInvalid('newPassword', errors)}
              />
            </FormItem>
          </Col>
        </ConditionalRender>

        <ConditionalRender
          condition={!success}
          fallbackElement={
            <Button
              uppercase
              width="default"
              onClick={() => navigate.toLogin({ token })}
            >
              Go to login
            </Button>
          }
        >
          <div className={styles.footer}>
            <Button
              uppercase
              appearance="secondary"
              width="full"
              onClick={closeModal}
            >
              Cancel
            </Button>
            <Button
              uppercase
              width="full"
              onClick={onSubmit}
              disabled={loading || !isValid}
            >
              {loading ? 'Loading...' : 'Confirm'}
            </Button>
          </div>
        </ConditionalRender>
      </Col>
    </Modal>
  )
}

export default withStore(mapStateToProps)(ResetPasswordModal)
