import clsx from 'clsx'
import React, { FC, ReactNode, useEffect } from 'react'
import { useLockedBody } from 'usehooks-ts'

import { Portal } from '../../portal'
import { LottieLoader } from '../LottieLoader'
import styles from './FullScreenLoading.module.scss'

interface FullScreenLoadingProps {
  solidColor?: boolean
  loading?: boolean
  component?: ReactNode
}

const FullScreenLoading: FC<FullScreenLoadingProps> = ({
  solidColor,
  loading,
  component = <LottieLoader />
}: FullScreenLoadingProps) => {
  const [, setLocked] = useLockedBody(false, 'root')

  useEffect(() => {
    setLocked(!!loading)

    if (!loading) {
      setTimeout(() => {
        setLocked(false)
      }, 1000)
    }
  }, [loading])

  if (!loading) return <></>

  return (
    <Portal wrapperId="fullscreen-loading">
      <div className={clsx(styles.container, solidColor && styles.solidColor)}>
        {component}
      </div>
    </Portal>
  )
}

export default FullScreenLoading
