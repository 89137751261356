import { ReactComponent as FilledPlus } from './filled-plus.svg'
import { ReactComponent as Logo } from './logo/default.svg'
import { ReactComponent as SmallLogo } from './logo/small-logo.svg'
import { ReactComponent as SmallLogoWithText } from './logo/small-logo-with-text.svg'
import { ReactComponent as WhiteLogo } from './logo/white-logo.svg'
import { ReactComponent as Mastercard } from './mastercard.svg'

import ApplePay from './payment/apple-pay.png'
import GooglePay from './payment/google-pay.png'
import CreditCard from './payment/credit-card.png'
import CompanyPlaceholder from './CompanyProfileLogo.svg'

export const StaticIcons = {
  Logo,
  SmallLogo,
  WhiteLogo,
  SmallLogoWithText,

  FilledPlus,
  Mastercard,
  CompanyPlaceholder
}

export const PaymentIcons = {
  ApplePay,
  GooglePay,
  CreditCard
}
