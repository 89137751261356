import clsx from 'clsx'
import React, { FC } from 'react'

import { ConditionalRender } from '@/common/components'
import { highlightsColorMapper } from '@/features/safe-note'
import { SharedIcons } from '@/packages/icons'

import styles from './HighlightToggle.module.scss'

interface IProps {
  type: keyof typeof highlightsColorMapper
  toggle: () => void
  enabled: boolean
}

const HighlightToggle: FC<IProps> = (props) => {
  const { type, toggle, enabled } = props

  return (
    <div className={clsx(styles.toggle, styles[type])} onClick={toggle}>
      <ConditionalRender
        condition={enabled}
        fallbackElement={
          <SharedIcons.ClosedEye
            size={16}
            color={highlightsColorMapper[type].inactive}
          />
        }
      >
        <SharedIcons.Eye size={16} color={highlightsColorMapper[type].active} />
      </ConditionalRender>
    </div>
  )
}

export default HighlightToggle
