import React, { FC } from 'react'

import { RoundIcons } from '@/features/term-sheet/assets/images'
import { RoundGoalItem } from '@/features/term-sheet/components'
import { RoundGoalKind } from '@/features/term-sheet/enums'
import { Color } from '@/packages/palette'
import {
  Col,
  Heading,
  HeadingTypes,
  Text,
  TextAlign,
  TextTypes
} from '@/packages/ui'

interface RoundGoalProps {
  goal?: number
  withRound?: boolean
  setWithGoal: (value: boolean) => void
  setGoal: (value: number | undefined) => void
}

const RoundGoal: FC<RoundGoalProps> = (props) => {
  const { goal, withRound, setWithGoal, setGoal } = props

  const onSelect = (value: RoundGoalKind) => {
    setWithGoal(value === RoundGoalKind.WithGoal)
  }

  return (
    <Col items="center">
      <Heading
        type={HeadingTypes.H2}
        color={Color.neutral500}
        align={TextAlign.CENTER}
      >
        Is this term sheet part of a round?
      </Heading>

      <Text
        type={TextTypes.BODY_DEFAULT}
        color={Color.neutral400}
        align={TextAlign.CENTER}
        className="tw-mt-8"
      >
        Select whether the investment is for a fundraising round with multiple
        investors, or one SAFE for only one investor.
      </Text>

      <Col className="tw-self-stretch tw-mt-30" gap={24}>
        <RoundGoalItem
          id={RoundGoalKind.WithoutGoal}
          active={!withRound && withRound !== undefined}
          onSelect={onSelect}
          title="No, it’s not"
          subtitle="Select this option if you want to send a SAFE to an angel investor as a one-time transaction."
          img={RoundIcons.WithoutGoal}
        />

        <RoundGoalItem
          id={RoundGoalKind.WithGoal}
          active={!!withRound}
          onSelect={onSelect}
          img={RoundIcons.WithGoal}
          goal={goal}
          title="Yes, it is"
          subtitle="Select this option if your SAFE is part of a fundraising round, which involves sending multiple SAFEs to multiple investors."
          onChangeGoal={setGoal}
        />
      </Col>
    </Col>
  )
}

export default RoundGoal
