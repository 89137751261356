import clsx from 'clsx'
import React, { FC, useState } from 'react'

import { Avatar, ConditionalRender } from '@/common/components'
import { ToastService } from '@/common/services'
import { StoreType, withStore } from '@/common/store'
import { SafesList } from '@/features/dashboard/modals/IncomingSafesModal/parts'
import { ICompany } from '@/features/profile/types'
import { ISafeNote } from '@/features/safe-note'
import { Color } from '@/packages/palette'
import {
  IModalWithCloseFn,
  Loader,
  Modal,
  Row,
  Text,
  TextTypes
} from '@/packages/ui'

import styles from './IncomingSafesModal.module.scss'

const MODAL_ID = 'pending-safes-modal'

interface IncomingSafesModalProps extends IModalWithCloseFn {
  safes: ISafeNote[]
  filterOutPendingSafes: (id: string, companyId?: string) => void
  toCreateProfile: () => void
  availableCompanies: ICompany[]
}

const mapStateToProps = ({ safeNote }: StoreType) => ({
  acceptSafeNote: safeNote.acceptSafeNote,
  loading: safeNote.functionLoading.acceptSafeNote
})

const IncomingSafesModal: FC<
  IncomingSafesModalProps & ReturnType<typeof mapStateToProps>
> = (props) => {
  const {
    filterOutPendingSafes,
    toCreateProfile,
    availableCompanies,
    safes,
    acceptSafeNote,
    loading,
    closeModal
  } = props

  const [activeSafe, setActiveSafe] = useState<ISafeNote | null>(
    safes.length > 0 ? safes[0] : null
  )
  const [step] = useState<'invites' | 'decline'>('invites')

  const currentSafe =
    safes.length === 1 ? safes[0] : safes?.find((p) => p.id === activeSafe?.id)

  if (!Array.isArray(safes) || !currentSafe) {
    return null
  }

  const handleDecline = () => {
    if (safes.length > 1) {
      filterOutPendingSafes(currentSafe.id)
      setActiveSafe(currentSafe)
    } else {
      filterOutPendingSafes(currentSafe.id)
      closeModal?.()
    }

    ToastService.showSuccess('SAFE was declined')
  }

  const handleAccept = async (companyId: string) => {
    await acceptSafeNote({
      data: { id: currentSafe.id, companyId },
      options: {
        onSuccess: () => {
          if (safes.length > 1) {
            filterOutPendingSafes(currentSafe.id, companyId)
            // setActiveSafe(0)
          } else {
            filterOutPendingSafes(currentSafe.id, companyId)

            closeModal?.()
          }

          ToastService.showSuccess(
            `SAFE from ${currentSafe.sender.fullName} was accepted`
          )
        }
      }
    })
  }

  const renderSafeSenders = () =>
    safes.map((safe) => {
      const isActive = safe.id === activeSafe?.id

      return (
        <Row
          key={safe.id}
          items="center"
          className={clsx(styles.senderItem, isActive && styles.active)}
          onClick={() => setActiveSafe(safe)}
        >
          <Avatar size={28} image={safe.sender.image} />
          <Text type={TextTypes.BODY_SMALL} className="tw-ml-8">
            {safe.sender.fullName}
          </Text>
        </Row>
      )
    })

  return (
    <Modal
      size="md"
      id={MODAL_ID}
      visible
      setVisible={closeModal}
      className={clsx('!tw-p-0')}
      modalInnerClassName="tw-flex"
    >
      <ConditionalRender
        condition={!loading}
        fallbackElement={<Loader width="100%" height="300px" />}
      >
        <Row className="tw-w-full" justify="center">
          <div className={styles.sendersContainer}>
            <Text type={TextTypes.BODY_SMALL} color={Color.neutral300}>
              Senders
            </Text>

            {renderSafeSenders()}
          </div>

          <div className={styles.safeContainer}>
            {step === 'invites' && (
              <SafesList
                id={currentSafe.id}
                mfn={currentSafe.mfn}
                senderCompany={currentSafe.senderCompany}
                amount={currentSafe.safeAmount}
                discount={currentSafe.discountRate}
                valuation={currentSafe.valuationCap}
                sender={currentSafe.sender}
              />
            )}
          </div>
        </Row>
      </ConditionalRender>
    </Modal>
  )
}

export default withStore(mapStateToProps)(IncomingSafesModal)
