import React from 'react'

import {
  PaymentMethods,
  PaymentReports,
  Plans
} from '@/features/profile-settings/components'

const PaymentSettingsView = () => (
  <div className="tw-w-full">
    <Plans activePlan="basic" />
    <PaymentMethods />
    <PaymentReports />
  </div>
)

export default PaymentSettingsView
