import { FC } from 'react'

import { IconProps, withIcon } from '@/common/hocs'

export const Company: FC<IconProps> = withIcon((props) => (
  <svg
    width="21"
    height="22"
    viewBox="0 0 21 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.0007 19.75H14.0007C17.5182 19.75 18.1482 18.3413 18.332 16.6263L18.9882 9.62625C19.2245 7.49125 18.612 5.75 14.8757 5.75H6.1257C2.38945 5.75 1.77695 7.49125 2.0132 9.62625L2.66945 16.6263C2.8532 18.3413 3.4832 19.75 7.0007 19.75Z"
      stroke="#525456"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7 5.75V5.05C7 3.50125 7 2.25 9.8 2.25H11.2C14 2.25 14 3.50125 14 5.05V5.75"
      stroke="#525456"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.25 11.875V12.75C12.25 12.7587 12.25 12.7588 12.25 12.7675C12.25 13.7213 12.2413 14.5 10.5 14.5C8.7675 14.5 8.75 13.73 8.75 12.7762V11.875C8.75 11 8.75 11 9.625 11H11.375C12.25 11 12.25 11 12.25 11.875Z"
      stroke="#525456"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.9438 10.125C16.9225 11.595 14.6125 12.47 12.25 12.7675"
      stroke="#525456"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.28906 10.3613C4.25781 11.7088 6.48031 12.5226 8.74656 12.7763"
      stroke="#525456"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
))

export const RoundAmount: FC<IconProps> = withIcon((props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.3307 9.99935C18.3307 14.5993 14.5974 18.3327 9.9974 18.3327C5.3974 18.3327 2.58906 13.6993 2.58906 13.6993M2.58906 13.6993H6.35573M2.58906 13.6993V17.866M1.66406 9.99935C1.66406 5.39935 5.36406 1.66602 9.9974 1.66602C15.5557 1.66602 18.3307 6.29935 18.3307 6.29935M18.3307 6.29935V2.13268M18.3307 6.29935H14.6307"
      stroke="#525456"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
))

export const DiscountRate: FC<IconProps> = withIcon((props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.60573 16.416C6.28906 15.6827 7.33073 15.741 7.93073 16.541L8.7724 17.666C9.4474 18.5577 10.5391 18.5577 11.2141 17.666L12.0557 16.541C12.6557 15.741 13.6974 15.6827 14.3807 16.416C15.8641 17.9994 17.0724 17.4743 17.0724 15.2577V5.86602C17.0807 2.50768 16.2974 1.66602 13.1474 1.66602H6.8474C3.6974 1.66602 2.91406 2.50768 2.91406 5.86602V15.2493C2.91406 17.4743 4.13073 17.991 5.60573 16.416Z"
      stroke="#525456"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.5 10.834L12.5 5.83398"
      stroke="#525456"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.498 10.8327H12.5055"
      stroke="#525456"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.49803 6.25065H7.50551"
      stroke="#525456"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
))

export const ValuationCap: FC<IconProps> = withIcon((props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.91406 11.4575C7.91406 12.2659 8.53907 12.9159 9.30574 12.9159H10.8724C11.5391 12.9159 12.0807 12.3492 12.0807 11.6409C12.0807 10.8825 11.7474 10.6076 11.2557 10.4326L8.7474 9.55755C8.25573 9.38255 7.9224 9.11588 7.9224 8.34922C7.9224 7.64922 8.46406 7.07422 9.13072 7.07422H10.6974C11.4641 7.07422 12.0891 7.72422 12.0891 8.53255"
      stroke="#525456"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10 6.25V13.75"
      stroke="#525456"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.3307 9.99935C18.3307 14.5993 14.5974 18.3327 9.9974 18.3327C5.3974 18.3327 1.66406 14.5993 1.66406 9.99935C1.66406 5.39935 5.3974 1.66602 9.9974 1.66602"
      stroke="#525456"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.3333 4.99935V1.66602H15"
      stroke="#525456"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.1641 5.83268L18.3307 1.66602"
      stroke="#525456"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
))

const IncomingSafe = withIcon((props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.4987 14.167V9.16699L5.83203 10.8337"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.5 9.16699L9.16667 10.8337"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.3346 8.33366V12.5003C18.3346 16.667 16.668 18.3337 12.5013 18.3337H7.5013C3.33464 18.3337 1.66797 16.667 1.66797 12.5003V7.50033C1.66797 3.33366 3.33464 1.66699 7.5013 1.66699H11.668"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.3346 8.33366H15.0013C12.5013 8.33366 11.668 7.50033 11.668 5.00033V1.66699L18.3346 8.33366Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
))

const MFN = withIcon((props) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.3307 11.3337V8.00032C18.3307 3.83366 16.6641 2.16699 12.4974 2.16699H7.49739C3.33073 2.16699 1.66406 3.83366 1.66406 8.00032V13.0003C1.66406 17.167 3.33073 18.8337 7.49739 18.8337H10.8307"
      stroke="#525456"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.10938 12.5748L8.09271 9.9998C8.37605 9.63314 8.90105 9.56648 9.26772 9.84981L10.7927 11.0498C11.1594 11.3331 11.6844 11.2665 11.9677 10.9081L13.8927 8.4248"
      stroke="#525456"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.2298 13.6832L16.4631 14.1582C16.5798 14.3915 16.8714 14.6082 17.1298 14.6582L17.4464 14.7082C18.3964 14.8665 18.6214 15.5665 17.9381 16.2582L17.6464 16.5499C17.4548 16.7499 17.3464 17.1332 17.4048 17.3999L17.4464 17.5749C17.7048 18.7249 17.0964 19.1665 16.0964 18.5665L15.8798 18.4415C15.6214 18.2915 15.2048 18.2915 14.9464 18.4415L14.7298 18.5665C13.7214 19.1749 13.1131 18.7249 13.3798 17.5749L13.4214 17.3999C13.4798 17.1332 13.3714 16.7499 13.1798 16.5499L12.8881 16.2582C12.2048 15.5665 12.4298 14.8665 13.3798 14.7082L13.6964 14.6582C13.9464 14.6165 14.2464 14.3915 14.3631 14.1582L14.5964 13.6832C15.0464 12.7749 15.7798 12.7749 16.2298 13.6832Z"
      stroke="#525456"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
))

const Amount = withIcon((props) => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.72656 12.4422C7.72656 13.5172 8.55156 14.3839 9.57656 14.3839H11.6682C12.5599 14.3839 13.2849 13.6255 13.2849 12.6922C13.2849 11.6755 12.8432 11.3172 12.1849 11.0839L8.82656 9.91719C8.16823 9.68385 7.72656 9.32552 7.72656 8.30885C7.72656 7.37552 8.45156 6.61719 9.34323 6.61719H11.4349C12.4599 6.61719 13.2849 7.48385 13.2849 8.55885"
      stroke="#525456"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.5 5.5V15.5"
      stroke="#525456"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.5013 18.8327C15.1037 18.8327 18.8346 15.1017 18.8346 10.4993C18.8346 5.89698 15.1037 2.16602 10.5013 2.16602C5.89893 2.16602 2.16797 5.89698 2.16797 10.4993C2.16797 15.1017 5.89893 18.8327 10.5013 18.8327Z"
      stroke="#525456"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
))

export default {
  MFN,
  Company,
  IncomingSafe,
  DiscountRate,
  ValuationCap,
  RoundAmount,
  Amount
}
