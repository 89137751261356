import React from 'react'

import { ProfileTypes } from '@/features/user/enums'
import { Heading, HeadingTypes, Spacer, Text, TextTypes } from '@/packages/ui'

import { RoleList } from '../RoleList'
import styles from './SelectRole.module.scss'

interface SelectRole {
  role: ProfileTypes | undefined
  onSelect: (value: ProfileTypes) => void
  secondaryView?: boolean
  pendingSafesNumber?: number
}

const SelectRole = ({
  role,
  onSelect,
  secondaryView,
  pendingSafesNumber
}: SelectRole) => (
  <div className={styles.container}>
    <div>
      <Heading type={HeadingTypes.H2}>Select a workspace</Heading>
      <Spacer size={16} />
      <Text type={TextTypes.BODY_DEFAULT}>
        Tell us which role matches who you are. Whether you’re looking to
        fundraise, invest, or advise clients, we’ve got you covered.
      </Text>

      <Spacer size={26} />
      <RoleList
        role={role}
        onSelect={onSelect}
        pendingSafesNumber={pendingSafesNumber}
        secondaryView={secondaryView}
      />
    </div>
  </div>
)

export default SelectRole
