import clsx from 'clsx'
import { noop } from 'lodash'
import React, { FC } from 'react'

import { FormatterHelper } from '@/common/helpers'
import { PaymentModule } from '@/features/payment'
import { PaymentImages } from '@/features/payment/assets'
import { PaymentModuleProps } from '@/features/payment/components/PaymentModule/PaymentModule'
import { Color } from '@/packages/palette'
import { Col, FontWeight, Row, Text, TextAlign, TextTypes } from '@/packages/ui'

import styles from './PaymentOverview.module.scss'

interface PaymentOverviewProps extends Omit<PaymentModuleProps, 'cashAmount'> {
  recipientName: string
  recipientCompany: string
  investorName: string
  investorCompany: string
  investmentAmount: number
}

const OverviewItem = (label: string, value: string) => (
  <Row gap={10} justify="between" items="center" className="tw-w-full">
    <Text color={Color.neutral400} type={TextTypes.BODY_SMALL}>
      {label}
    </Text>
    <Text
      color={Color.neutral400}
      type={TextTypes.BODY_SMALL}
      weight={FontWeight.SEMIBOLD}
    >
      {value}
    </Text>
  </Row>
)

const PaymentOverview: FC<PaymentOverviewProps> = ({
  recipientName,
  recipientCompany,
  investorName,
  investorCompany,
  investmentAmount,
  ...props
}: PaymentOverviewProps) => {
  const Divider = <div className={clsx(styles.divider)} />

  return (
    <div className={styles.container}>
      <div className={styles.overviewTab}>
        <Col items="center">
          <img src={PaymentImages.PaymentIcon} alt="payment-icon" width={80} />

          <Text align={TextAlign.CENTER} weight={FontWeight.SEMIBOLD}>
            Overview
          </Text>

          <Col className="tw-w-full tw-mt-24" gap={20}>
            {OverviewItem('Recipient', recipientName)}
            {OverviewItem('Company', recipientCompany)}

            {Divider}

            {OverviewItem('Investor', investorName)}
            {OverviewItem('Company', investorCompany)}

            {Divider}

            {OverviewItem(
              'Investment amount',
              FormatterHelper.toCurrency(investmentAmount) as string
            )}
          </Col>
        </Col>
      </div>

      <div className={styles.paymentTab}>
        <PaymentModule
          {...props}
          cashAmount={investmentAmount}
          onPayLater={noop}
        />
      </div>
    </div>
  )
}

export default PaymentOverview
