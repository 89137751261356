import { noop } from 'lodash'
import React, { createContext, FC, PropsWithChildren, useContext } from 'react'

import { ProfileTypes } from '@/features/user/enums'

export interface DashboardNavigationContextData {
  selectedProfile: ProfileTypes | undefined
  setSelectedProfile: (profile: ProfileTypes | undefined) => void
}

const DashboardNavigationContext =
  createContext<DashboardNavigationContextData>({
    selectedProfile: undefined,
    setSelectedProfile: () => noop()
  })

type DashboardNavigationContextProviderProps =
  PropsWithChildren<DashboardNavigationContextData>

export const DashboardNavigationContextProvider: FC<any> = ({
  children,
  ...props
}: DashboardNavigationContextProviderProps) => (
  <DashboardNavigationContext.Provider
    value={props as DashboardNavigationContextData}
  >
    {children}
  </DashboardNavigationContext.Provider>
)

export const useDashboardNavigationContext =
  (): DashboardNavigationContextData =>
    useContext<DashboardNavigationContextData>(DashboardNavigationContext)
