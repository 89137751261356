import { yupResolver } from '@hookform/resolvers/yup'
import React, { FC, useEffect, useMemo } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { useLocation, useSearchParams } from 'react-router-dom'

import { StoreType } from '@/common/store'
import { withStore } from '@/common/store/withStore'
import { decodeToken } from '@/common/utils'
import config from '@/config'
import { LoginDto } from '@/features/auth'
import { AsideImages } from '@/features/auth/assets'
import { AuthLayout } from '@/features/auth/layouts'
import { LoginSchema } from '@/features/auth/schemas/AuthSchema'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import {
  FontWeight,
  Form,
  FormHelper,
  FormItem,
  FullScreenLoading,
  Heading,
  HeadingTypes,
  NavLink,
  OAuthButton,
  PasswordInput,
  Row,
  Spacer,
  Text,
  TextInput,
  TextTypes
} from '@/packages/ui'
import { Button } from '@/packages/ui/button'
import { NavLinkType } from '@/packages/ui/button/NavLink/NavLInk'
import { ROUTES, useNavigator } from '@/router'

const mapStateToProps = ({ auth }: StoreType) => ({
  failedFields: auth.failedFields,
  loading: auth.functionLoading.login,
  login: auth.login
})

type LoginView = ReturnType<typeof mapStateToProps>

const LoginView: FC<LoginView> = ({ failedFields, login, loading }) => {
  const navigate = useNavigator()

  const [searchParams] = useSearchParams()
  const signUpToken = useMemo(() => searchParams.get('token'), [searchParams])

  const {
    reset,
    register,
    setValue,
    watch,
    clearErrors,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    trigger
  } = useForm({
    resolver: yupResolver(LoginSchema),
    reValidateMode: 'onChange',
    mode: 'onChange'
  })

  const location = useLocation()
  const email = location.state?.email

  useEffect(() => {
    if (email) {
      setValue('email', email)
    }
  }, [email])

  useEffect(() => {
    if (signUpToken && signUpToken.trim() !== '') {
      const decodedData = decodeToken(signUpToken)

      reset(decodedData)
    }
  }, [signUpToken])

  useEffect(() => {
    if (isDirty && Object.keys(failedFields).length > 0) {
      trigger()
    }
  }, [isDirty, JSON.stringify(failedFields)])

  const onSubmit = async (values: FieldValues) => {
    login({
      data: values as LoginDto,
      options: {
        onSuccess: () => {
          navigate.toDashboard()
        }
      }
    })
  }

  const onFocus = () => {
    if (Object.keys(errors).length > 0) {
      const fieldsToClear = []

      if (errors?.email?.type === 'non-exist-email') {
        fieldsToClear.push('email')
      }

      if (errors?.password?.type === 'invalid-credentials') {
        fieldsToClear.push('password')
      }

      if (fieldsToClear.length) {
        clearErrors(fieldsToClear)
      }
    }
  }

  return (
    <AuthLayout
      asideImage={AsideImages.LoginAside}
      title="We missed you!"
      description="Go ahead and login, you can pick up where you left off. Your SAFE notes are right where you left them."
    >
      <div className="login-page">
        <FullScreenLoading loading={loading} />
        <div className="content-header">
          <Heading type={HeadingTypes.H2} color={Color.neutral500}>
            Sign in
          </Heading>
          <Text type={TextTypes.BODY_DEFAULT} color={Color.neutral400}>
            Sign in so we can get you back to business.
          </Text>
        </div>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormItem errors={errors.email?.message}>
            <TextInput
              {...register('email')}
              name="email"
              onFocus={onFocus}
              label="Email"
              placeholder="Email"
              invalid={FormHelper.isFieldInvalid('email', errors)}
            />
          </FormItem>
          <FormItem errors={errors.password?.message}>
            <PasswordInput
              {...register('password')}
              name="password"
              label="Password"
              placeholder="Password"
              onFocus={onFocus}
              invalid={FormHelper.isFieldInvalid('password', errors)}
            />
          </FormItem>

          <Row justify="end" className="tw-mt-[-10px]">
            <NavLink
              href={ROUTES.FORGOT_PASSWORD}
              width="auto"
              useActiveState={false}
            >
              <Text type={TextTypes.BODY_DEFAULT} weight={FontWeight.MEDIUM}>
                Forgot password?
              </Text>
            </NavLink>
          </Row>
          <Spacer size={10} ignoreGridGap />

          <Button type="submit" width="default" disabled={!isValid} uppercase>
            Let`s go
          </Button>
        </Form>

        <div className="social-section">
          <Text
            color={Color.neutral400}
            className="social-section__description"
          >
            You can also login with
          </Text>

          <Spacer size={10} vertical />
          <NavLink
            href={config.googleAuthUrl}
            type={NavLinkType.NONE}
            width="auto"
            useActiveState={false}
          >
            <OAuthButton type="google" />
          </NavLink>

          <Spacer size={10} vertical />
          <NavLink
            href={config.linkedInAuthUrl}
            type={NavLinkType.NONE}
            width="auto"
            useActiveState={false}
          >
            <OAuthButton type="linkedin" />
          </NavLink>
        </div>
        <div className="bottom-section">
          <Text type={TextTypes.BODY_DEFAULT} weight={FontWeight.MEDIUM}>
            Never been?
          </Text>
          <Spacer size={5} vertical />
          <NavLink
            useActiveState={false}
            href={ROUTES.SIGN_UP}
            width="auto"
            state={{ email: watch('email') }}
            className="auth-goto-link"
          >
            <Text type={TextTypes.BODY_DEFAULT} weight={FontWeight.BOLD} asSpan>
              Sign up
            </Text>
            <Spacer size={5} vertical />
            <SharedIcons.Arrow size={16} />
          </NavLink>
        </div>
      </div>
    </AuthLayout>
  )
}

export default withStore(mapStateToProps)(LoginView)
