import clsx from 'clsx'
import React, { useRef } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import { HeaderBar } from '@/common/components'
import userProfileOptions from '@/common/components/UserProfilePopup/userProfileOptions'
import { useModalContext } from '@/features/modals'
import { Arrow } from '@/packages/icons/Icons'
import { Color } from '@/packages/palette'
import { Row, Text, TextTypes } from '@/packages/ui'
import { useNavigator } from '@/router'

import styles from './ProfileLayout.module.scss'

const ProfileLayout = () => {
  const navigate = useNavigator()
  const location = useLocation()
  const { logoutModal } = useModalContext()

  const contentRef = useRef<any>(null)

  const path = location.pathname

  const handleMenuItemClick = (id: string) => {
    if (id === 'logout') {
      logoutModal.openModal()
      return
    }

    if (id === 'dashboard') {
      navigate.toDashboard()
      return
    }

    contentRef.current?.scrollTo(0, 0, { behavior: 'smooth' })
    navigate.toProfile(id)
  }

  const NavigationMenu = (
    <div className={styles.navigation}>
      <ul className={styles.profileOptions}>
        {[{ id: 'dashboard', title: 'Dashboard', icon: Arrow }]
          .concat(userProfileOptions)
          .map(({ id, title, icon: Icon }: any) => (
            <li
              key={id}
              onClick={() => handleMenuItemClick(id)}
              className={clsx(path.includes(id) && styles.active)}
            >
              <Row items="center" gap={10}>
                {Icon?.({
                  size: 20,
                  ...(id === 'dashboard' && {
                    style: { transform: 'rotate(180deg)' }
                  })
                })}
                <Text type={TextTypes.BODY_SMALL} color={Color.neutral400}>
                  {title}
                </Text>
              </Row>
            </li>
          ))}
      </ul>
    </div>
  )

  return (
    <div>
      <div className={styles.wrapper}>
        <HeaderBar />

        <div className={styles.container}>
          {NavigationMenu}

          <div className={styles.profileSettingsInner}>
            <div className={styles.content} ref={contentRef}>
              <div className={styles.contentInner}>
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfileLayout
