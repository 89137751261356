import endOfYear from 'date-fns/endOfYear'
import format from 'date-fns/format'
import isSameDay from 'date-fns/isSameDay'
import isSameYear from 'date-fns/isSameYear'
import startOfYear from 'date-fns/startOfYear'

export class DateHelper {
  public static MM_DD_YYYY = 'MM/dd/yyyy'

  public static DD_MMM_YYYY = 'dd MMM yyyy'

  private static parseDate(date: string | Date | undefined) {
    if (!date) return null
    return date instanceof Date ? date : new Date(date)
  }

  public static format(
    date: string | Date | undefined,
    dateFormat = this.MM_DD_YYYY
  ): string | null {
    if (!date) return null

    const dateToFormat = DateHelper.parseDate(date)
    if (!dateToFormat) return null

    try {
      return format(dateToFormat, dateFormat).toString()
    } catch (error) {
      return ''
    }
  }

  public static toPrettyFormat(
    date: string | Date | undefined,
    defaultValue = ''
  ): string {
    const result = DateHelper.format(date, this.DD_MMM_YYYY)
    if (!result) return defaultValue
    return result
  }

  public static getStartEndDateBasedOnYear(year: number) {
    const $date = new Date(year, 1, 1)

    return {
      start: startOfYear($date),
      end: endOfYear($date)
    }
  }

  public static isFullYearSelected(
    startDate: Date | string | undefined,
    endDate: Date | string | undefined
  ) {
    if (!startDate || !endDate) return false

    const parsedStartDate = DateHelper.parseDate(startDate)
    const parsedEndDate = DateHelper.parseDate(endDate)

    if (!parsedStartDate || !parsedEndDate) {
      return false
    }

    return (
      isSameDay(parsedStartDate, startOfYear(parsedStartDate)) &&
      isSameDay(parsedEndDate, endOfYear(parsedEndDate))
    )
  }

  public static isSameYear(
    startDate: Date | undefined,
    endDate: Date | undefined
  ) {
    if (!startDate || !endDate) return false

    return isSameYear(startDate, endDate)
  }

  public static getCurrentCentury() {
    const currentYear = new Date().getFullYear()
    return Math.ceil(currentYear / 100) - 1
  }

  public static formatIsoDate(input: string): string {
    const date = new Date(input)
    if (Number.isNaN(date.getTime())) {
      throw new Error('Invalid date format')
    }

    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]

    const month = months[date.getMonth()]
    const day = date.getDate()
    const year = date.getFullYear()

    let hours = date.getUTCHours()
    const minutes = date.getUTCMinutes()
    const ampm = hours >= 12 ? 'PM' : 'AM'
    hours %= 12
    hours = hours || 12
    const strMinutes = minutes < 10 ? `0${minutes}` : minutes

    return `${month} ${day}, ${year} at ${hours}:${strMinutes} ${ampm}`
  }
}
