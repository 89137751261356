import React, { FC } from 'react'

import { Avatar } from '@/common/components'
import { BellIcon } from '@/features/dashboard/assets'
import { Color } from '@/packages/palette'
import { Col, FontWeight, Row, Text, TextTypes } from '@/packages/ui'

import styles from './AngelInvestmentCompany.module.scss'

interface AngelInvestmentCompanyProps {
  name: string
  logo: string | null
  owner: string
  paidSafes: number
  unpaidSafes: number
  onClick: () => void
}

const AngelInvestmentCompany: FC<AngelInvestmentCompanyProps> = (props) => {
  const { name, logo, onClick, owner, paidSafes, unpaidSafes } = props

  return (
    <div className={styles.companyWrapper} onClick={onClick}>
      <div className={styles.notifications}>
        <BellIcon color={Color.neutral400} />
      </div>

      <Row items="center" gap={10}>
        <Avatar size={40} image={logo} name={name} />
        <Text weight={FontWeight.SEMIBOLD} type={TextTypes.BODY_DEFAULT}>
          {name}
        </Text>
      </Row>

      <div className={styles.spacer} />

      <Col items="stretch" gap={4}>
        <Row items="center" justify="between" gap={10}>
          <Text
            weight={FontWeight.MEDIUM}
            color={Color.neutral400}
            type={TextTypes.BODY_SMALL}
          >
            Unpaid SAFEs:
          </Text>

          <Text
            weight={FontWeight.SEMIBOLD}
            color={Color.warning300}
            type={TextTypes.BODY_SMALL}
          >
            {unpaidSafes}
          </Text>
        </Row>

        <Row items="center" justify="between" gap={10}>
          <Text
            weight={FontWeight.MEDIUM}
            color={Color.neutral400}
            type={TextTypes.BODY_SMALL}
          >
            Paid SAFEs:
          </Text>

          <Text
            weight={FontWeight.SEMIBOLD}
            color={Color.success400}
            type={TextTypes.BODY_SMALL}
          >
            {paidSafes}
          </Text>
        </Row>

        <Row items="center" justify="between" gap={10}>
          <Text
            weight={FontWeight.MEDIUM}
            color={Color.neutral400}
            type={TextTypes.BODY_SMALL}
          >
            Company owner:
          </Text>

          <Text
            weight={FontWeight.SEMIBOLD}
            color={Color.neutral400}
            type={TextTypes.BODY_SMALL}
          >
            {owner}
          </Text>
        </Row>
      </Col>
    </div>
  )
}

export default AngelInvestmentCompany
