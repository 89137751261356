import { yupResolver } from '@hookform/resolvers/yup'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'

import { ConditionalRender } from '@/common/components'
import { StoreType, withStore } from '@/common/store'
import resetPasswordImg from '@/features/auth/assets/images/forgot-password.png'
import { ResetPasswordModal } from '@/features/auth/modals'
import { EmailSchema } from '@/features/auth/schemas/AuthSchema'
import { useModal } from '@/packages/hooks'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import {
  BackButton,
  Button,
  Col,
  FontWeight,
  FormHelper,
  FormItem,
  Heading,
  HeadingTypes,
  Loader,
  Text,
  TextAlign,
  TextInput,
  TextTypes
} from '@/packages/ui'
import { useNavigator } from '@/router'

import styles from './ForgotPasswordLayout.module.scss'

const mapStateToProps = ({ auth }: StoreType) => ({
  sendResetLink: auth.resetPasswordRequest,
  failedFields: auth.failedFields,
  loading: auth.functionLoading.resetPasswordRequest
})

const ForgotPasswordLayout: FC<ReturnType<typeof mapStateToProps>> = (
  props
) => {
  const { failedFields, sendResetLink, loading } = props
  const navigate = useNavigator()
  const [searchParams] = useSearchParams()

  const token = useMemo(() => searchParams.get('token'), [searchParams])

  const [success, setSuccess] = useState(false)

  const { visible, openModal, closeModal } = useModal<boolean>(false)

  const {
    trigger,
    watch,
    register,
    formState: { errors, isValid, isDirty }
  } = useForm({
    resolver: yupResolver(EmailSchema),
    reValidateMode: 'onChange',
    mode: 'onChange'
  })

  const onSubmit = async () => {
    await sendResetLink({
      data: {
        email: watch('email')
      },
      options: {
        onSuccess: () => {
          setSuccess(true)
        }
      }
    })
  }

  useEffect(() => {
    if (isDirty && Object.keys(failedFields).length > 0) {
      trigger()
    }
  }, [isDirty, JSON.stringify(failedFields)])

  useEffect(() => {
    if (token) {
      openModal()
    }
  }, [])

  return (
    <div className={styles.forgotPasswordLayout}>
      {visible && <ResetPasswordModal closeModal={closeModal} token={token} />}

      <div className={styles.backBtn}>
        <BackButton
          width="default"
          disabled={loading}
          onClick={() => navigate.toLogin()}
        >
          Back
        </BackButton>
      </div>

      <Col items="center" justify="center" gap={24}>
        <img width={330} src={resetPasswordImg} alt="Reset Password" />

        <Col items="center" gap={10}>
          <Heading
            type={HeadingTypes.H2}
            align={TextAlign.CENTER}
            color={Color.neutral500}
          >
            {success ? 'Check your mail!' : 'Forgot password?'}
          </Heading>

          <Text
            type={TextTypes.BODY_DEFAULT}
            color={Color.neutral400}
            align={TextAlign.CENTER}
            className={styles.descriptionText}
          >
            {success
              ? `We sent a recover link to ${watch(
                  'email'
                )}. Follow link sent on your email address to set new password`
              : 'Type your email and we will send reset link'}
          </Text>
        </Col>

        {success ? (
          <ConditionalRender condition={!loading} fallbackElement={<Loader />}>
            <div className={styles.resendWrapper}>
              <Text
                type={TextTypes.BODY_DEFAULT}
                weight={FontWeight.MEDIUM}
                color={Color.neutral500}
              >
                Did not get an email?{' '}
                <span onClick={onSubmit} className={styles.resendLink}>
                  Send me again
                </span>
              </Text>
            </div>

            <Button
              uppercase
              width="default"
              className="tw-mt-12"
              onClick={() => navigate.toLogin()}
            >
              Login
              <SharedIcons.Arrow size={20} className="tw-ml-5" />
            </Button>
          </ConditionalRender>
        ) : (
          <div className={styles.inputWrapper}>
            <FormItem errors={errors.email?.message}>
              <TextInput
                placeholder="Email"
                {...register('email')}
                className={styles.emailInput}
                invalid={FormHelper.isFieldInvalid('email', errors)}
              />
            </FormItem>
          </div>
        )}

        <ConditionalRender condition={!success}>
          <Button
            uppercase
            width="default"
            disabled={!isValid || loading}
            onClick={onSubmit}
          >
            {loading ? 'Loading...' : 'Confirm'}
          </Button>
        </ConditionalRender>
      </Col>
    </div>
  )
}

export default withStore(mapStateToProps)(ForgotPasswordLayout)
