import clsx from 'clsx'
import React, { FC, useEffect, useState } from 'react'

import { StoreType, withStore } from '@/common/store'
import { AsideImages } from '@/features/auth/assets'
import { AuthLayout } from '@/features/auth/layouts'
import {
  CompleteSignUpContextProvider,
  SignUpSteps
} from '@/features/auth/views/CompleteSignUpView/context'
import { AddNewProfile } from '@/features/dashboard'
import { ISafeNote } from '@/features/safe-note'
import { ProfileTypes } from '@/features/user/enums'
import { useNavigator } from '@/router'

import styles from './CompleteSignUpView.module.scss'

export const asideInfo = {
  [SignUpSteps.SELECT_ROLE]: {
    img: AsideImages.SignUpCompleteAside,
    title: 'There’s something for everyone.',
    description:
      "Raise seed funding, invest, advise, or oversee clients' SAFE investments. The more, the merrier!"
  },
  [SignUpSteps.GENERAL]: {
    img: AsideImages.EntrepreneurAsideGeneral,
    title: 'Let’s get to know each other!',
    description:
      'We really think you’re gonna love it here. Upload a photo and personalize your profile.'
  },
  [SignUpSteps.DETAILS]: {
    img: AsideImages.EntrepreneurAsideDetails,
    title: 'Share some company details.',
    description:
      'While most of this information isn’t required now, you’ll have to provide it later to create SAFEs.'
  },
  [SignUpSteps.TEAM]: {
    img: AsideImages.EntrepreneurAsideTeam,
    title: 'Welcome, team!',
    description:
      'Add your team members and choose the ways in which they can contribute.'
  },
  [SignUpSteps.ANGEL_TEAM]: {
    img: AsideImages.AngelAsideTeam,
    title: 'Welcome, team!',
    description:
      'Add your team members and choose the ways in which they can contribute.'
  },
  [SignUpSteps.JOIN_TO_TEAM]: {
    img: AsideImages.EntrepreneurAsideTeam,
    title: 'Let’s join to the team!',
    description:
      'Lorem ipsum dolor sit amet, consectetur  elit, sed do eiusmod tempor'
  },
  [SignUpSteps.JOIN_TO_ANGEL_TEAM]: {
    img: AsideImages.AngelAsideTeam,
    title: 'Let’s join to the team!',
    description:
      'Lorem ipsum dolor sit amet, consectetur  elit, sed do eiusmod tempor'
  },
  [SignUpSteps.PERSONAL]: {
    img: AsideImages.AngelAsideGeneral,
    title: "Let's create your investment portfolio together.",
    description:
      'Provide some details about yourself to get started. You’ll be investing before you know it.'
  },
  [SignUpSteps.INVESTOR_DETAILS]: {
    img: AsideImages.AngelAsideDetails,
    title: 'You’re almost there!',
    description:
      'We want to invest in your success, so just tell us a few more details.'
  }
}

const mapStateToProps = ({ safeNote }: StoreType) => ({
  fetchPendingSafes: safeNote.fetchPendingSafes
})

const CompleteSignUpView: FC<ReturnType<typeof mapStateToProps>> = ({
  fetchPendingSafes
}) => {
  const navigate = useNavigator()

  const [pendingSafes, setPendingSafes] = useState<ISafeNote[]>([])

  const [step, setStep] = useState<SignUpSteps>(SignUpSteps.SELECT_ROLE)
  const [selectedRole, setSelectedRole] = useState<ProfileTypes | undefined>(
    undefined
  )

  const handleScrollToTop = () => {
    const container = document.querySelector('.auth-layout__content')

    if (container) {
      container.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  const fetchSafes = async () => {
    try {
      const result = await fetchPendingSafes()

      if (result?.length) {
        setPendingSafes(result)
      } else {
        setPendingSafes([])
      }
    } catch {
      setPendingSafes([])
    }
  }

  const onSuccess = () => {
    navigate.toDashboard({
      showPendingSafes: 'true'
    })
  }

  useEffect(() => {
    fetchSafes()
  }, [])

  return (
    <AuthLayout
      selectedRole={selectedRole}
      pendingSafes={step === SignUpSteps.SELECT_ROLE ? pendingSafes : []}
      asideImage={asideInfo[step].img}
      title={asideInfo[step].title}
      description={asideInfo[step].description}
    >
      <div
        className={clsx(
          styles.container,
          'complete-page',
          step === SignUpSteps.SELECT_ROLE && 'complete-page__select-role'
        )}
      >
        <CompleteSignUpContextProvider step={step} setStep={setStep}>
          <AddNewProfile
            pendingSafes={step === SignUpSteps.SELECT_ROLE ? pendingSafes : []}
            setSelectedRole={setSelectedRole}
            onSuccess={onSuccess}
            onScrollToTop={handleScrollToTop}
          />
        </CompleteSignUpContextProvider>
      </div>
    </AuthLayout>
  )
}

export default withStore(mapStateToProps)(CompleteSignUpView)
