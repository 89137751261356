import React, { FC, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import { StoreType, withStore } from '@/common/store'
import { FullScreenLoading } from '@/packages/ui'
import { useNavigator } from '@/router'

const mapStateToProps = ({ auth }: StoreType) => ({
  oAuthLogin: auth.oAuthLogin
})

const OAuthLoginCallback: FC<ReturnType<typeof mapStateToProps>> = ({
  oAuthLogin
}) => {
  const navigate = useNavigator()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const accessToken = searchParams.get('accessToken')
    if (!!accessToken) {
      oAuthLogin({
        data: { token: accessToken },
        options: {
          onSuccess: (tokenValid: boolean) => {
            if (tokenValid) {
              navigate.toDashboard()
              return
            }
            navigate.toLogin()
          }
        }
      })
    }
  }, [searchParams])

  return <FullScreenLoading loading solidColor />
}

export default withStore(mapStateToProps)(OAuthLoginCallback)
