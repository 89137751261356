import React, { FC } from 'react'

import { SafeNoteImages } from '@/features/safe-note/assets'
import {
  Button,
  Col,
  Heading,
  IModalWithCloseFn,
  Modal,
  Row,
  Spacer,
  Text,
  TextAlign
} from '@/packages/ui'
import { ROUTES } from '@/router'

import styles from './LoginToSignModal.module.scss'

type LoginToSignModalProps = IModalWithCloseFn

const LoginToSignModal: FC<LoginToSignModalProps> = ({
  visible,
  closeModal
}: LoginToSignModalProps) => (
  <Modal
    id="login-to-sign-modal"
    size="sm"
    visible={visible}
    setVisible={closeModal}
  >
    <Col items="center">
      <img
        className={styles.image}
        src={SafeNoteImages.LoginToSign}
        alt="login-to-sign-image"
      />

      <Spacer size={37} />
      <Heading align={TextAlign.CENTER}>
        You need to login in order to be able to sign the document
      </Heading>

      <Spacer size={14} />
      <Text align={TextAlign.CENTER}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua, dolor sit amet,
        consectetur adipiscing elit.
      </Text>

      <Spacer size={30} />
      <Row items="center">
        <Button
          element="routerLink"
          width="default"
          uppercase
          href={ROUTES.LOGIN}
          bold
          onClick={closeModal}
        >
          Login
        </Button>

        <Button
          width="default"
          uppercase
          href={ROUTES.SIGN_UP}
          className="tw-ml-32"
          element="routerLink"
          bold
          onClick={closeModal}
        >
          Sign up
        </Button>
      </Row>
    </Col>
  </Modal>
)

export default LoginToSignModal
