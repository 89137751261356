import clsx from 'clsx'
import React, { CSSProperties, FC, PropsWithChildren, ReactNode } from 'react'

import { IFlexProps } from '../types'
import styles from './Row.module.scss'

interface IRowProps extends Omit<IFlexProps, 'direction'> {
  children?: ReactNode
  className?: string
  id?: string
}

const Row: FC<PropsWithChildren<IRowProps>> = ({
  justify = 'start',
  items = 'start',
  reverse = false,
  gap = 0,
  id,

  clickable,
  className,
  inline,
  onClick,
  children
}: IRowProps) => (
  <div
    id={id}
    style={{ '--gap': `${gap}px` } as CSSProperties}
    className={clsx(
      styles.row,
      inline && styles.inline,
      styles[`justify-${justify}`],
      styles[`align-${items}`],
      reverse && styles.reverse,
      clickable && 'tw-cursor-pointer',
      className
    )}
    onClick={onClick}
  >
    {children}
  </div>
)

export default Row
