import { FC } from 'react'

import { IconProps, withIcon } from '@/common/hocs'

const WithdrawIcon: FC<IconProps> = withIcon((props) => (
  <svg
    width="23"
    height="22"
    viewBox="0 0 23 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.33203 12.6044C9.33203 13.4935 10.0195 14.2085 10.8629 14.2085H12.5862C13.3195 14.2085 13.9154 13.5852 13.9154 12.806C13.9154 11.9719 13.5487 11.6694 13.0079 11.4769L10.2487 10.5144C9.70786 10.3219 9.34121 10.0285 9.34121 9.18521C9.34121 8.41521 9.93702 7.78271 10.6704 7.78271H12.3937C13.237 7.78271 13.9245 8.49771 13.9245 9.38688"
      stroke="#525456"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.625 6.875V15.125"
      stroke="#525456"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.7904 11.0002C20.7904 16.0602 16.6837 20.1668 11.6237 20.1668C6.5637 20.1668 2.45703 16.0602 2.45703 11.0002C2.45703 5.94016 6.5637 1.8335 11.6237 1.8335"
      stroke="#525456"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.7917 5.50016V1.8335H17.125"
      stroke="#525456"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.207 6.41683L20.7904 1.8335"
      stroke="#525456"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
))

export default WithdrawIcon
