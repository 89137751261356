import React, {
  FC,
  PropsWithChildren,
  useContext,
  useMemo,
  useState
} from 'react'

interface AppContextProps {
  isCurrentCompany?: boolean
}

interface AppContextValue extends AppContextProps {
  navbarTitle: string | JSX.Element
  setNavbarTitle: (title: string | JSX.Element) => void
}

const AppContext = React.createContext<AppContextValue>({} as AppContextValue)

const AppContextProvider: FC<PropsWithChildren<AppContextProps>> = (props) => {
  const [navbarTitle, setNavbarTitle] = useState<string>('')

  const providerValue = useMemo(
    () =>
      ({
        ...props,
        navbarTitle,
        setNavbarTitle
      } as AppContextValue),
    [props, navbarTitle]
  )

  return <AppContext.Provider value={providerValue} {...props} />
}

const useAppContext = () => useContext(AppContext)

export { AppContextProvider, useAppContext }
