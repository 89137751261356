import { IFile } from '@/packages/ui'

export async function dataUrlToFile(
  dataUrl: string,
  fileName = 'image'
): Promise<File> {
  const res: Response = await fetch(dataUrl)
  const blob: Blob = await res.blob()
  return new File([blob], fileName, { type: 'image/png' })
}

export async function fileToDataUrl(file: File | IFile) {
  if (!file) return Promise.resolve(undefined)

  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}
