import React, { FC } from 'react'

import { Avatar, ConditionalRender } from '@/common/components'
import { ICompany } from '@/features/profile/types'
import whoCreatesImg from '@/features/term-sheet/assets/images/who-creates.png'
import { IUser } from '@/features/user'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import {
  Badge,
  BadgeType,
  Col,
  FontWeight,
  Heading,
  HeadingTypes,
  IModalWithCloseFn,
  Loader,
  Modal,
  Row,
  Text,
  TextAlign,
  TextTypes
} from '@/packages/ui'

import styles from './WhoCreatesSafeModal.module.scss'

const MODAL_ID = 'who-creates-safe-modal'

const WhoCreatesSafeModal: FC<
  IModalWithCloseFn & {
    save: () => void
    senderCompany?: ICompany
    me: IUser | null
    myCompany?: ICompany
    sender?: IUser
    loading: boolean
  }
> = (props) => {
  const { me, loading, myCompany, closeModal, save, sender, senderCompany } =
    props

  return (
    <Modal size="sm" id={MODAL_ID} visible setVisible={closeModal}>
      <ConditionalRender
        condition={!loading}
        fallbackElement={<Loader width="100%" />}
      >
        <Col items="center">
          <img src={whoCreatesImg} alt="Who creates" width={128} />

          <Heading
            type={HeadingTypes.H2}
            align={TextAlign.CENTER}
            className="tw-mt-10 tw-mb-4"
          >
            Who should create the SAFE?
          </Heading>

          <Text
            type={TextTypes.BODY_DEFAULT}
            color={Color.neutral300}
            align={TextAlign.CENTER}
          >
            Сhoose in which investment company you want to display SAFE
          </Text>

          <Col className="tw-self-stretch tw-mt-20" gap={12}>
            <Row className={styles.item} gap={16} onClick={save}>
              <div className="tw-relative">
                <Avatar
                  size={60}
                  name={senderCompany?.name}
                  image={senderCompany?.image}
                />

                <div className="tw-absolute tw-bottom-0 tw-right-[-5px]">
                  <Avatar
                    size={22}
                    name={sender?.fullName}
                    image={sender?.image}
                  />
                </div>
              </div>

              <Col gap={2}>
                <Row items="start" justify="between">
                  <Text
                    type={TextTypes.BODY_DEFAULT}
                    weight={FontWeight.SEMIBOLD}
                    color={Color.neutral500}
                  >
                    {senderCompany?.name} ({sender?.fullName})
                  </Text>

                  <SharedIcons.Arrow size={24} color={Color.primary400} />
                </Row>

                <Text type={TextTypes.BODY_SMALL} color={Color.neutral400}>
                  This option will allow {sender?.fullName} to create the SAFE.
                </Text>
              </Col>
            </Row>

            <Row className={styles.item} gap={16} onClick={save}>
              <div className="tw-relative">
                <Avatar
                  size={60}
                  name={myCompany?.name}
                  image={myCompany?.image}
                />

                <div className="tw-absolute tw-bottom-0 tw-right-[-5px]">
                  <Avatar size={22} name={me?.fullName} image={me?.image} />
                </div>
              </div>

              <Col gap={2}>
                <Row justify="between">
                  <Row items="center" gap={5}>
                    <Text
                      type={TextTypes.BODY_DEFAULT}
                      weight={FontWeight.SEMIBOLD}
                      color={Color.neutral500}
                    >
                      {myCompany?.name} company
                    </Text>
                    <Badge type={BadgeType.WARNING} size="small">
                      You
                    </Badge>
                  </Row>

                  <SharedIcons.Arrow size={24} color={Color.primary400} />
                </Row>

                <Text type={TextTypes.BODY_SMALL} color={Color.neutral400}>
                  This option will allow you to create the SAFE.
                </Text>
              </Col>
            </Row>
          </Col>
        </Col>
      </ConditionalRender>
    </Modal>
  )
}

export default WhoCreatesSafeModal
