import clsx from 'clsx'
import React, { FC, MouseEvent, useMemo } from 'react'

import { Avatar, ConditionalRender } from '@/common/components'
import { useStore } from '@/common/store'
import { SubscriptionsModal } from '@/features/auth/modals'
import { TeamMembersPopup } from '@/features/dashboard'
import { InviteIcon } from '@/features/dashboard/assets'
import { AddTeamMembersModal } from '@/features/dashboard/modals'
import { ICompanyTeamMember, IOwner } from '@/features/profile/types'
import { TeamMemberPermission } from '@/features/user'
import { useModal } from '@/packages/hooks'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import { FontWeight, Row, Text, TextTypes, Tooltip } from '@/packages/ui'

import styles from './DashboardTeamMembers.module.scss'

interface DashboardTeamMembersProps {
  teamMembers: ICompanyTeamMember[]
  owner?: IOwner
  companyId: string
  companyName: string
  hideText?: boolean
  whiteBorder?: boolean
  renderStatus?: (status: any) => JSX.Element
  minimizedInviteButton?: boolean
  disabledCondition?: (item: any) => boolean
}

const DashboardTeamMembers: FC<DashboardTeamMembersProps> = (props) => {
  const {
    owner,
    whiteBorder,
    hideText,
    companyId,
    teamMembers,
    companyName,
    disabledCondition,
    renderStatus,
    minimizedInviteButton
  } = props

  const myId = useStore((store) => store.user.me?.id)

  const teamMembersNumber = teamMembers.length
  const extraTeamMembers = teamMembersNumber > 2 ? teamMembersNumber - 2 : 0

  const creator = useMemo(
    () =>
      myId === owner?.id ||
      teamMembers.find((member: ICompanyTeamMember) => member.userId === myId)
        ?.permission === TeamMemberPermission.CREATE,
    [myId, owner?.id, teamMembers]
  )

  const { visible, closeModal, openModal } = useModal<
    'team-members' | 'subscription'
  >()

  const handleInviteClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    openModal('team-members')
  }

  const InviteButton = (
    <Row
      items="center"
      gap={4}
      onClick={handleInviteClick}
      className={styles.invite}
    >
      <InviteIcon />
      <Text
        weight={FontWeight.SEMIBOLD}
        type={TextTypes.BODY_SMALL}
        color={Color.primary500}
      >
        Invite
      </Text>
    </Row>
  )

  const MinimizedInviteButton = (
    <div
      className={clsx(styles.member, styles.add)}
      onClick={handleInviteClick}
    >
      <SharedIcons.PlusThin color={Color.primary500} size={17} />
    </div>
  )

  const ExtraNumber = (
    <div className={clsx(styles.member, styles.extraItem)}>
      {extraTeamMembers}
      <SharedIcons.Plus color={Color.neutral400} size={7} />
    </div>
  )

  const Circles = (
    <>
      <ConditionalRender condition={!!owner}>
        <Avatar
          size={32}
          name={owner?.fullName}
          image={owner?.image}
          className={clsx(styles.member, whiteBorder && styles.whiteBorder)}
        />
      </ConditionalRender>

      {teamMembers.slice(0, 2).map((teamMember: ICompanyTeamMember) => (
        <div className={clsx(styles.member)} key={teamMember.id}>
          <ConditionalRender condition={typeof renderStatus === 'function'}>
            {renderStatus?.(teamMember.status)}
          </ConditionalRender>

          <Avatar
            key={teamMember.id}
            size={32}
            name={teamMember.user.fullName}
            image={teamMember.user.image}
            className={clsx(
              styles.memberIcon,
              whiteBorder && styles.whiteBorder,
              disabledCondition?.(teamMember) && styles.disabled
            )}
          />
        </div>
      ))}

      <ConditionalRender condition={!!extraTeamMembers}>
        {ExtraNumber}
      </ConditionalRender>
    </>
  )

  return (
    <Row items="center" gap={16}>
      {visible === 'team-members' && (
        <AddTeamMembersModal
          owner={owner}
          upgradeSubscription={() => openModal('subscription')}
          companyId={companyId}
          teamMembers={teamMembers}
          companyName={companyName}
          closeModal={closeModal}
        />
      )}

      {visible === 'subscription' && (
        <SubscriptionsModal closeModal={() => openModal('team-members')} />
      )}

      <Row items="center" gap={8}>
        <ConditionalRender condition={!hideText}>
          <Text
            type={TextTypes.BODY_SMALL}
            weight={FontWeight.SEMIBOLD}
            color={Color.neutral400}
          >
            Your team:
          </Text>
        </ConditionalRender>

        <div className={styles.teamMembers}>
          <ConditionalRender condition={creator} fallbackElement={Circles}>
            <Tooltip
              asComponent
              trigger="click"
              interactive
              placement="bottom-end"
              content={
                <TeamMembersPopup
                  companyId={companyId}
                  teamMembers={teamMembers}
                  owner={owner as IOwner}
                />
              }
            >
              <div className={clsx(styles.teamMembers, 'tw-cursor-pointer')}>
                {Circles}
              </div>
            </Tooltip>
          </ConditionalRender>

          <ConditionalRender condition={creator}>
            {minimizedInviteButton ? MinimizedInviteButton : InviteButton}
          </ConditionalRender>
        </div>
      </Row>
    </Row>
  )
}

export default DashboardTeamMembers
