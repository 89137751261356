import { SAFE_NOTE_TEMPLATE_ANCHORS } from '@/features/safe-note'

export const scrollToSignatureBlock = () => {
  const signaturesBlock = document.getElementById(
    SAFE_NOTE_TEMPLATE_ANCHORS.SIGNATURES
  )

  if (signaturesBlock) {
    signaturesBlock.scrollIntoView({ behavior: 'smooth' })
  }
}
