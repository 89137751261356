import clsx from 'clsx'
import React, { FC, useCallback, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { NumberFormatValues } from 'react-number-format'

import { ConditionalRender } from '@/common/components'
import { FormatterHelper } from '@/common/helpers'
import { AccountHolderType, BankTransferData } from '@/features/payment'
import { PaymentIcons } from '@/features/payment/assets'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import {
  CardExpiryInput,
  FileSelect,
  Form,
  FormHelper,
  FormItem,
  Heading,
  HeadingTypes,
  IFile,
  MaskInput,
  NumberInput,
  Row,
  Select,
  Spacer,
  Text,
  TextInput,
  TextTypes
} from '@/packages/ui'

import { PaymentOption } from '../PaymentOption'
import styles from './PaymentOptions.module.scss'
import { BankPaymentSchema, CardPaymentSchema } from './schemas'
import { CardPaymentData, PaymentMethod, PaymentMethodData } from './types'

interface PaymentMethodProps {
  title: string
  withDescription?: boolean
  withRecordPayment?: boolean
  onPayLater?: () => void
  onPay?: (data: PaymentMethodData) => void
  cashAmount?: number
  className?: string
  onSelect?: (method: PaymentMethod) => void
  setRecordPaymentFiles?: (files: IFile[] | undefined) => void
}

const accountHolderTypeOptions = [
  {
    value: AccountHolderType.INDIVIDUAL,
    label: 'Individual'
  },
  {
    value: AccountHolderType.COMPANY,
    label: 'Company'
  }
]

const PaymentOptions: FC<PaymentMethodProps> = ({
  title,
  withDescription,
  withRecordPayment,
  onPay,
  className,
  cashAmount,
  onSelect,
  setRecordPaymentFiles
}) => {
  const {
    control,
    register,
    watch,
    getValues,
    reset,
    setValue,
    formState: { errors, isValid }
  } = useForm({
    resolver: async (data: any) => {
      try {
        const schema =
          data.payWith === 'card' ? CardPaymentSchema : BankPaymentSchema

        const values = await schema.validate(data, { abortEarly: false })

        return {
          values,
          errors: {}
        } as any
      } catch (caughtErrors: any) {
        return {
          values: {},
          errors: FormHelper.formatYupErrors(caughtErrors)
        }
      }
    },
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: {
      payWith: PaymentMethod.CARD,
      cardNumber: '',
      expirationDate: '',
      cvv: '',
      accountNumber: '',
      routingNumber: '',
      accountHolderType: '',
      billingDetails: ''
    }
  })

  const payWithCardSelected = watch('payWith') === PaymentMethod.CARD
  const payWithBankSelected = watch('payWith') === PaymentMethod.BANK_TRANSFER
  const payRecordSelected = watch('payWith') === PaymentMethod.RECORD_PAYMENT

  useEffect(() => {
    reset(getValues())
    setRecordPaymentFiles?.(undefined)
    onSelect?.(watch('payWith') as PaymentMethod)
  }, [watch('payWith')])

  const handlePay = useCallback(() => {
    if (payWithCardSelected) {
      const { cvv, cardNumber, expirationDate } = getValues()
      onPay?.({
        method: PaymentMethod.CARD,
        cvv,
        cardNumber: String(cardNumber),
        expirationDate
      } as CardPaymentData)
    }

    if (payWithBankSelected) {
      const {
        accountNumber,
        routingNumber,
        accountHolderType,
        billingDetails
      } = getValues()
      onPay?.({
        method: PaymentMethod.BANK_TRANSFER,
        accountHolderType,
        routingNumber,
        accountNumber,
        billingDetails: {
          name: billingDetails
        }
      } as BankTransferData)
    }
  }, [onPay, payWithCardSelected])

  const PayWithCardContent = (
    <>
      <Text type={TextTypes.BODY_SMALL}>Enter card details</Text>
      <FormItem errors={errors?.cardNumber?.message as string} className="m-0">
        <Controller
          control={control}
          name="cardNumber"
          render={({ field: { onChange, value } }) => (
            <MaskInput
              small
              value={value}
              placeholder="0000 0000 0000 0000"
              format="#### #### #### ####"
              onValueChange={(val: NumberFormatValues) => {
                onChange(val.floatValue)
              }}
              invalid={FormHelper.isFieldInvalid('cardNumber', errors)}
            />
          )}
        />
      </FormItem>

      <Row gap={12}>
        <FormItem errors={errors?.expirationDate?.message as string}>
          <Controller
            control={control}
            name="expirationDate"
            render={({ field: { onChange, value } }) => (
              <CardExpiryInput
                small
                value={value}
                placeholder="MM/YY"
                onValueChange={(val: NumberFormatValues) => {
                  onChange(val.formattedValue)
                }}
                invalid={FormHelper.isFieldInvalid('expirationDate', errors)}
              />
            )}
          />
        </FormItem>

        <FormItem errors={errors?.cvv?.message as string}>
          <Controller
            control={control}
            name="cvv"
            render={({ field: { onChange, value } }) => (
              <NumberInput
                small
                placeholder="123"
                maxLength={4}
                thousandSeparator=""
                invalid={FormHelper.isFieldInvalid('cvv', errors)}
                value={value}
                onValueChange={(val: NumberFormatValues) => {
                  onChange(val.floatValue)
                }}
              />
            )}
          />
        </FormItem>
      </Row>
    </>
  )

  const RecordPayment = (
    <>
      <Text
        type={TextTypes.BODY_SMALL}
        color={Color.neutral400}
        className="tw-flex tw-items-center"
      >
        <SharedIcons.Checkmark size={16} />
        <Spacer size={6} vertical />
        Record payment:
        <Spacer size={6} vertical />
        <span className={styles.recordPaymentPrice}>
          {FormatterHelper.toCurrency(cashAmount)}
        </span>
      </Text>

      <Spacer size={10} />
      <FileSelect
        label="Upload receipt (optionally)"
        caption="Drop the document, or select a file."
        appearance="compact"
        hideSpotIfFilePresented
        initialFiles={[]}
        onSelectFiles={setRecordPaymentFiles}
      />
    </>
  )

  const PayWithBankContent = (
    <>
      <FormItem errors={errors?.accountNumber?.message as string}>
        <Controller
          control={control}
          name="accountNumber"
          render={({ field: { onChange, value } }) => (
            <NumberInput
              label="Account number"
              placeholder="Account number"
              decimalScale={0}
              thousandSeparator={false}
              onChange={onChange}
              value={value}
              invalid={FormHelper.isFieldInvalid('accountNumber', errors)}
            />
          )}
        />
      </FormItem>
      <Spacer size={12} />

      <FormItem errors={errors?.routingNumber?.message as string}>
        <Controller
          control={control}
          name="routingNumber"
          render={({ field: { onChange, value } }) => (
            <NumberInput
              label="Routing number"
              placeholder="Routing number"
              decimalScale={0}
              thousandSeparator={false}
              onChange={onChange}
              value={value}
              invalid={FormHelper.isFieldInvalid('routingNumber', errors)}
            />
          )}
        />
      </FormItem>
      <Spacer size={12} />

      <Row>
        <FormItem errors={errors?.accountHolderType?.message as string}>
          <Controller
            control={control}
            name="accountHolderType"
            render={({ field: { onChange, value } }) => (
              <Select
                name="accountHolder"
                value={value}
                label="Account holder"
                onChange={onChange}
                options={accountHolderTypeOptions}
                placeholder="Individual/Company"
              />
            )}
          />
        </FormItem>
        <Spacer size={12} vertical />

        <FormItem errors={errors?.cvv?.message as string}>
          <TextInput
            {...register('billingDetails')}
            label="Account holder name"
            placeholder="Account holder name"
          />
        </FormItem>
      </Row>
    </>
  )

  const renderContentDependsOnPaymentMethod = () => {
    const map = {
      [PaymentMethod.CARD]: PayWithCardContent,
      [PaymentMethod.BANK_TRANSFER]: <></>,
      [PaymentMethod.RECORD_PAYMENT]: <></>
    }

    const selectedValue = watch('payWith') as PaymentMethod
    return map[selectedValue] || <></>
  }

  return (
    <div className={clsx(styles.container, className)}>
      <Heading type={HeadingTypes.H3} className="tw-mb-[12px]">
        {title}
      </Heading>
      <ConditionalRender condition={withDescription}>
        <Text type={TextTypes.BODY_SMALL} className="tw-mb-[24px]">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempo
        </Text>
      </ConditionalRender>

      <Form gap={4}>
        <Row gap={8}>
          <PaymentOption
            id={PaymentMethod.CARD}
            selected={watch('payWith') === PaymentMethod.CARD}
            onSelect={(id) => setValue('payWith', id as PaymentMethod)}
            title="Credit/debit card"
            description="3% fee"
            icon={PaymentIcons.CreditCard}
          />

          <PaymentOption
            id={PaymentMethod.RECORD_PAYMENT}
            selected={watch('payWith') === PaymentMethod.RECORD_PAYMENT}
            onSelect={(id) => setValue('payWith', id as PaymentMethod)}
            title="Record your payment"
            description="No fee"
            icon={PaymentIcons.RecordPayment}
          />

          <PaymentOption
            id={PaymentMethod.BANK_TRANSFER}
            selected={watch('payWith') === PaymentMethod.BANK_TRANSFER}
            onSelect={(id) => setValue('payWith', id as PaymentMethod)}
            title="Bank"
            description="1% fee"
            icon={PaymentIcons.Bank}
            disabled
            comingSoon
          />
        </Row>

        <Spacer size={10} />
        {renderContentDependsOnPaymentMethod()}
      </Form>

      <Spacer size={22} />

      {/* <ConditionalRender condition={payWithCardSelected || payWithBankSelected}> */}
      {/*  <Row gap={50} justify="end"> */}
      {/*    <Button */}
      {/*      width="default" */}
      {/*      uppercase */}
      {/*      onClick={handlePay} */}
      {/*      disabled={!isValid} */}
      {/*    > */}
      {/*      Pay now */}
      {/*    </Button> */}
      {/*  </Row> */}
      {/* </ConditionalRender> */}
    </div>
  )
}

export default PaymentOptions
