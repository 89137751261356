import clsx from 'clsx'
import React, { FC, useEffect, useState } from 'react'

import { ConditionalRender } from '@/common/components'
import { StoreType, withStore } from '@/common/store'
import successImg from '@/features/auth/assets/images/subscription-done.png'
import { SafeNotesMinimizedTable } from '@/features/dashboard'
import deleteImg from '@/features/dashboard/assets/images/delete-company.png'
import { ICompany } from '@/features/profile/types'
import { ProfileTypes } from '@/features/user/enums'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import {
  BackButton,
  Button,
  Col,
  Heading,
  HeadingTypes,
  IModalWithCloseFn,
  Info,
  Loader,
  Modal,
  Text,
  TextAlign,
  TextInput,
  TextTypes
} from '@/packages/ui'
import { useNavigator } from '@/router'

import styles from './DeleteCompanyModal.module.scss'

const mapStateToProps = ({ safeNote, user }: StoreType) => ({
  safeNoteList: safeNote.safeNoteList,
  refetchUser: user.fetchMe,
  fetchSafeNotes: safeNote.fetchAll,
  downloadZip: safeNote.downloadZip,
  downloadingFiles: safeNote.functionLoading.downloadZip,
  safesLoading: safeNote.functionLoading.fetchAll,
  removeCompany: user.removeCompany,
  removeCompanyLoading: user.functionLoading.removeCompany
})

interface DeleteCompanyModalProps extends IModalWithCloseFn {
  company: ICompany
}

const DeleteCompanyModal: FC<
  DeleteCompanyModalProps & ReturnType<typeof mapStateToProps>
> = (props) => {
  const {
    closeModal,
    downloadZip,
    company,
    safesLoading,
    downloadingFiles,
    safeNoteList,
    fetchSafeNotes,
    refetchUser,
    removeCompanyLoading,
    removeCompany
  } = props

  const navigate = useNavigator()
  const loading = safesLoading || downloadingFiles || removeCompanyLoading

  const [allSelected, setAllSelected] = useState(false)
  const [selectedSafes, setSelectedSafes] = useState<string[]>([])

  const [completed, setCompleted] = useState(false)
  const [step, setStep] = useState(0)
  const [inputValue, setInputValue] = useState('')

  const primaryButtonText = step === 1 ? 'Delete' : 'Download SAFEs'
  const showSafesTable = !!safeNoteList.length && step === 0

  const closeModalWithRefetch = () => {
    closeModal?.()

    if (completed) {
      refetchUser()
      navigate.toDashboard()
    }
  }

  useEffect(() => {
    fetchSafeNotes({
      data: {
        [company.type === ProfileTypes.ANGEL
          ? 'angelCompanyId'
          : 'entrepreneurCompanyId']: company.id
      }
    })
  }, [])

  useEffect(() => {
    setStep(safeNoteList.length ? 0 : 1)
  }, [safeNoteList])

  const selectAll = () => {
    const ids = safeNoteList.map((item) => item.id)

    setAllSelected(!allSelected)

    if (allSelected) {
      setSelectedSafes([])
    } else {
      setSelectedSafes(ids)
    }
  }

  const onItemSelect = (id: string) => {
    if (selectedSafes.includes(id)) {
      setSelectedSafes((prev) => prev.filter((item) => item !== id))
    } else {
      setSelectedSafes([...selectedSafes, id])
    }
  }

  const deleteCompany = () => {
    removeCompany({
      data: { id: company.id },
      options: {
        onSuccess: () => {
          setCompleted(true)
        }
      }
    })
  }

  const downloadFiles = () => {
    downloadZip({
      data: {
        companyId: company.id,
        safeNoteIds: selectedSafes
      }
    })
  }

  const onPrimaryButtonClick = () => {
    if (step === 0) {
      if (selectedSafes.length) {
        downloadFiles()
        return
      }

      setStep(1)
    } else {
      deleteCompany()
    }
  }

  const MainScreen = (
    <Col items="center">
      <img src={deleteImg} alt="Delete company" width={130} />

      <Heading
        type={HeadingTypes.H2}
        color={Color.neutral500}
        className="tw-mt-[24px]"
        align={TextAlign.CENTER}
      >
        You’re about to delete your company {company.name}
      </Heading>
      <Text
        type={TextTypes.BODY_DEFAULT}
        color={Color.neutral400}
        align={TextAlign.CENTER}
        className="tw-mt-[8px]"
      >
        Deleting your company will delete all of your associated data such as
        files, components and images and content you have shared with others.
        You cannot undue this action.
      </Text>

      <ConditionalRender condition={step === 1}>
        <div className="tw-my-20 tw-self-stretch">
          <TextInput
            label="To confirm, type 'DELETE' below"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
        </div>
      </ConditionalRender>

      <ConditionalRender condition={showSafesTable}>
        <SafeNotesMinimizedTable
          onSelectAll={selectAll}
          isAllSelected={allSelected}
          safeNotes={safeNoteList}
          onSingleSelect={onItemSelect}
          selectedRows={selectedSafes}
        />
      </ConditionalRender>

      <div className={clsx(styles.footer)}>
        <Button
          uppercase
          appearance="secondary"
          width="full"
          onClick={closeModal}
        >
          Cancel
        </Button>

        <Button
          uppercase
          width="full"
          onClick={onPrimaryButtonClick}
          disabled={
            (step === 0 && !selectedSafes?.length) ||
            (step === 1 && inputValue !== 'DELETE')
          }
        >
          {primaryButtonText}
          <ConditionalRender condition={step === 0}>
            <SharedIcons.DownloadModern
              color={Color.neutral0}
              className="tw-ml-5"
            />
          </ConditionalRender>
        </Button>
      </div>

      <ConditionalRender condition={step === 0}>
        <div className="tw-mb-70 tw-mt-20">
          <BackButton reverse onClick={() => setStep(1)}>
            Proceed deleting
          </BackButton>
        </div>
      </ConditionalRender>

      <ConditionalRender condition={showSafesTable}>
        <Info textType={TextTypes.BODY_DEFAULT} className={styles.infoWarning}>
          Before deleting your company, ensure you download your SAFEs for
          recordkeeping.
        </Info>
      </ConditionalRender>
    </Col>
  )

  const SuccessScreen = (
    <Col items="center">
      <img src={successImg} alt="Success" width={255} />

      <Heading
        type={HeadingTypes.H2}
        color={Color.neutral500}
        className="tw-mt-[24px]"
      >
        You`ve successfully deleted {company.name}
      </Heading>
      <Text
        type={TextTypes.BODY_DEFAULT}
        color={Color.neutral300}
        className="tw-mt-[8px]"
        align={TextAlign.CENTER}
      >
        Within 30 days, the{' '}
        <span className={styles.companyName}>{company.name}</span> company will
        be archived in the 'Deleted companies' folder. During this period, you
        have the option to upgrade your account to restore the company.
      </Text>

      <Button
        uppercase
        width="default"
        onClick={closeModalWithRefetch}
        className="tw-mt-[32px]"
      >
        Close
      </Button>
    </Col>
  )

  return (
    <Modal
      size="sm"
      visible
      setVisible={closeModalWithRefetch}
      id="delete-company"
    >
      <ConditionalRender
        condition={!loading}
        fallbackElement={<Loader width="100%" />}
      >
        <ConditionalRender condition={completed} fallbackElement={MainScreen}>
          {SuccessScreen}
        </ConditionalRender>
      </ConditionalRender>
    </Modal>
  )
}

export default withStore(mapStateToProps)(DeleteCompanyModal)
