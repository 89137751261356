import { observer } from 'mobx-react-lite'
import React, { FC } from 'react'
import { Outlet } from 'react-router-dom'

import { ConditionalRender } from '@/common/components'
import { StoreType, useStore } from '@/common/store'
import { useDebounceEffect } from '@/packages/hooks'
import { FullScreenLoading } from '@/packages/ui'
import { useNavigator } from '@/router'

const ProtectedRouteLayout: FC = () => {
  const isLoggedIn = useStore((store: StoreType) => store.auth.isLoggedIn)

  const navigate = useNavigator()

  useDebounceEffect(
    () => {
      if (!isLoggedIn) {
        navigate.toLogin()
      }
    },
    1500,
    [isLoggedIn]
  )

  return (
    <ConditionalRender
      condition={isLoggedIn}
      fallbackElement={<FullScreenLoading loading solidColor />}
    >
      <Outlet />
    </ConditionalRender>
  )
}

export default observer(ProtectedRouteLayout)
