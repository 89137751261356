import React from 'react'

import { VerificationMark } from '@/features/auth/assets/icons'
import { Color } from '@/packages/palette'
import { FontWeight, Row, Text, TextTypes } from '@/packages/ui'

import styles from './BankItem.module.scss'

export interface IBankAccountItem {
  id?: string
  name: string
  last4Digits?: number
}

const BankItem = (props: IBankAccountItem) => {
  const { name, last4Digits } = props

  return (
    <Row className={styles.container} justify="between" items="center">
      <div>
        <Text weight={FontWeight.SEMIBOLD}>
          {name} {!!last4Digits && `(*** ${last4Digits})`}
        </Text>
      </div>

      <div className={styles.badge}>
        <VerificationMark size={18} color={Color.primary400} />
        <Text
          className="tw-ml-6"
          color={Color.primary400}
          weight={FontWeight.MEDIUM}
          type={TextTypes.BODY_SMALL}
        >
          Verified
        </Text>
      </div>
    </Row>
  )
}

export default BankItem
